import { useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import TableBoletos from '../TableBoletos'

interface LogDetailsProps {
  enviados: Array<any>
  naoEnviados: Array<any>
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const LogDetails = (props: LogDetailsProps) => {
  const { enviados, naoEnviados } = props
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ m: 2 }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Enviados" {...a11yProps(0)} />
        <Tab label="Não Enviados" {...a11yProps(1)} />
      </Tabs>

      <TableBoletos
        data={value === 0 ? enviados : naoEnviados}
        type={value === 0 ? 'ok' : 'erro'}
      />
    </Box>
  )
}

export default LogDetails
