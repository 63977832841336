import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Paper, Stack } from '@mui/material'
import { useBoletoContext } from 'contexts/BoletoContext/BoletoContext'
import Container from '@components/Container'
import useBoleto from '@hooks/queries/useBoleto'

import BoletoInformacoes from './components/BoletoInformacoes'
import BoletoIdentificacao from './components/BoletoIdentificacao'
import BoletoConvenio from './components/BoletoConvenio'
import BoletoValores from './components/BoletoValores'
import BoletoBeneficiario from './components/BoletoBeneficiario'
import BoletoPagador from './components/BoletoPagador'
import BoletoProtesto from './components/BoletoProtesto'
import BoletoMensagem from './components/BoletoMensagem'
import BoletoOutrasInformacoes from './components/BoletoOutrasInformacoes'
import Header from './components/Header'

export default function Boleto() {
  const { id: boletoId } = useParams()

  const { setBoleto, setLoading, boleto } = useBoletoContext()

  const { useQueryObterBoletoPorId } = useBoleto()
  const { data, isLoading, isFetching, error } = useQueryObterBoletoPorId(
    boletoId || '',
  )

  useEffect(() => {
    setBoleto(data || null)
    setLoading(isFetching || isLoading)
  }, [data, isFetching, isLoading])

  return (
    <Container
      disablePadding
      isLoading={isLoading}
      error={error}
      customSx={{ paddingBottom: 11 }}
    >
      <Header boleto={boleto} />

      <Stack component={Paper} gap={2} padding={2}>
        <BoletoInformacoes boleto={boleto} />
        <BoletoIdentificacao boleto={boleto} />
        <BoletoConvenio boleto={boleto} />
        <BoletoValores boleto={boleto} />
        <BoletoBeneficiario boleto={boleto} />
        <BoletoPagador boleto={boleto} />
        <BoletoProtesto boleto={boleto} />
        <BoletoMensagem boleto={boleto} />
        <BoletoOutrasInformacoes boleto={boleto} />
      </Stack>
    </Container>
  )
}
