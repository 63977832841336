import { FormProvider } from '@data-c/providers'
import OptionList from '@components/OptionsListContainer/components/OptionList'
import {
  AlterarBoletoDataLimitePagamentoParams,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'
import { OrigemType } from '../..'

interface DataLimitePagamentoProps {
  boleto: BoletoDetalhadoModel | null
}

export default function DataLimitePagamento({
  boleto,
}: DataLimitePagamentoProps) {
  const { id, dataPagamento } = boleto || {}

  const dataAlterarLimitePagamento: AlterarBoletoDataLimitePagamentoParams = {
    boletoId: id || '',
    novaDataLimitePagamento: dataPagamento || '',
  }

  return (
    <FormProvider>
      <OptionList
        name={OrigemType.DATA_LIMITE_PAGAMENTO}
        noRequest={{ initialData: dataAlterarLimitePagamento }}
        form={<Form />}
      />
    </FormProvider>
  )
}
