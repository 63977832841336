import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownBoletoTipoDataEnum {
  EMISSAO_0 = 0,
  VENCIMENTO_1 = 1,
  ALTERACAO_2 = 2,
  PAGAMENTO_3 = 3,
  ALTERACAO_DATA_HORA_4 = 4,
}

export const boletoTipoDataMapping: Record<string, number> = {
  emissao: 0,
  vencimento: 1,
  alteracao: 2,
  pagamento: 3,
}

export const boletoTipoDataReverseMapping: Record<number, string> = {
  0: 'emissao',
  1: 'vencimento',
  2: 'alteracao',
  3: 'pagamento',
}

const menuItems = [
  {
    value: DropDownBoletoTipoDataEnum.EMISSAO_0,
    label: 'Data de Emissão',
  },
  {
    value: DropDownBoletoTipoDataEnum.VENCIMENTO_1,
    label: 'Data de Vencimento',
  },
  {
    value: DropDownBoletoTipoDataEnum.ALTERACAO_2,
    label: 'Data de Alteração',
  },
  {
    value: DropDownBoletoTipoDataEnum.PAGAMENTO_3,
    label: 'Data de Pagamento',
  },
]

export default function DropDownBoletoTipoData(props: DynamicDropDownProps) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="Tipo de Data"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
