import { Grid } from '@mui/material'

import { CurrencyTextField, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import { isValidNumber } from '@data-c/hooks'

import { DropDownBancoCodigoEnum } from '@components/Inputs/DropDownBancoCodigo'
import DropDownTipoDesconto, {
  DropDownTipoDescontoEnum,
} from '@components/Inputs/DropDownTipoDesconto'

import useBoleto, {
  AlterarBoletoDescontoModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import useValidations from '@hooks/useValidations'

import * as yup from 'yup'

const isTipoDesconto = [
  DropDownTipoDescontoEnum.VALOR_FIXO_ATE_DATA_INFORMADA_1,
  DropDownTipoDescontoEnum.PERCENTUAL_ATE_DATA_INFORMADA_2,
  DropDownTipoDescontoEnum.POR_DIA_ANTECIPACAO_3,
]

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  descontoValor: yup.number().when('descontoTipo', ([descontoTipo], schema) => {
    if (isTipoDesconto.includes(descontoTipo)) {
      return schema
        .required('Informe o desconto')
        .moreThan(0, 'O valor do desconto deve ser superior a zero(0)')
        .transform(transform)
    }
    return schema
  }),
})

interface FormProps {
  boleto: BoletoDetalhadoModel | null
}

export default function Form({ boleto }: FormProps) {
  const { setValidationErrors, validationProps } = useValidations()

  const { changeValue, closeForm, toggleSubmit } =
    useFormApi<AlterarBoletoDescontoModel>()
  const { formValues: data } = useFormData<AlterarBoletoDescontoModel>()

  const { useAlterarBoletoDesconto } = useBoleto()
  const { mutateAsync: alterarBoletoDesconto } = useAlterarBoletoDesconto()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoDesconto({
          boletoId: boleto?.id || '',
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  function getDisabledOptions(convenioBancoCodigo?: number) {
    if (convenioBancoCodigo === DropDownBancoCodigoEnum.ITAU_341) {
      return [
        DropDownTipoDescontoEnum.POR_DIA_ANTECIPACAO_3,
        DropDownTipoDescontoEnum.DEFINIDO_PELO_CONVENIO_99,
      ]
    }

    return []
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Alterar Desconto',
        maxWidth: 'sm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <DropDownTipoDesconto
            name="descontoTipo"
            optionToDisabled={getDisabledOptions(boleto?.convenioBancoCodigo)}
            tooltipTitle={`Opção inválida para o banco ${
              boleto?.convenioBancoCodigoDesc || ''
            }`}
            value={String(data?.descontoTipo) || ''}
            onChange={(value) => {
              if (!isTipoDesconto.includes(Number(value))) {
                changeValue('descontoValor', 0)
              }

              changeValue('descontoTipo', value)
            }}
            {...validationProps('descontoTipo')}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            title="Percentual de desconto (quando aplicável)"
            name="descontoValor"
            disabled={!isTipoDesconto.includes(data?.descontoTipo)}
            label="Valor Desconto"
            required={isTipoDesconto.includes(data?.descontoTipo)}
            value={data?.descontoValor || ''}
            onChange={(_, value) => {
              changeValue('descontoValor', value)
            }}
            {...validationProps('descontoValor')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
