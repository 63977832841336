import { HttpResponseInterface } from '@data-c/hooks'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import api from '@services/api'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'
import { FilterOptions } from '@data-c/providers'
import { useState } from 'react'
import useNotification from '@hooks/useNotifications'
import { flexCreateOrUpdateMessage } from '@hooks/useStringManipulation'
import { CidadeModel, UFModel } from './useCidade'

export interface ConvenioResumoModel {
  id?: string
  tipoCobrancaTarifa: number
  empresaId: string
  convenioDesc?: string | null
  descricao?: string | null
  empresaCNPJCPF?: string | null
  empresaNome?: string | null
  bancoCodigo: number
  bancoNomeDesc?: string | null
  ativo: boolean
  convenioAgencia?: number | null
  convenioConta?: number | null
  convenioNumero?: string | null
  carteiraCodigo?: string | null
  carteiraVariacao?: string | null
  nossoNumeroUltimoSequencial: number
  impressaoBoletoPadraoId?: string | null
  dataConsultaUltimoMovimento?: string | null
  ultimoNossoNumeroConsultado: number
}

export interface ConvenioModel {
  id?: string
  empresaId: string
  bancoCodigo: number
  bancoCodigoDesc?: string | null
  descricao?: string | null
  agencia: number
  contaCorrente: number
  ativo: boolean
  dataConsultaUltimoMovimento?: string | null
  ultimoNossoNumeroConsultado: number
  convenioNumero: string
  carteiraCodigo?: string | null
  carteiraVariacao?: string | null
  diasParaNegativacao: number
  orgaoNegativador: number
  indicadorAceiteTituloVencido: number
  indicadorAceiteTituloVencidoDesc?: string | null
  diasLimiteRecebimento: number
  permiteRecebimentoParcial: boolean
  mensagemOcorrencia?: string | null
  beneficiarioFinalCNPJCPF?: string | null
  beneficiarioFinalNome?: string | null
  beneficiarioFinalCEP?: string | null
  beneficiarioFinalLogradouro?: string | null
  beneficiarioFinalBairro?: string | null
  beneficiarioFinalCidadeNome?: string | null
  cidade?: CidadeModel | null
  beneficiarioFinalUF?: string | null
  uf?: UFModel | null
  nossoNumeroUltimoSequencial: number
  descontoTipo: number
  descontoTipoDesc?: string | null
  descontoDiasAntesVencimento: number
  descontoPercentual: number
  protestoTipo: number
  protestoTipoDesc?: string | null
  protestoDias: number
  multaTipo: number
  multaTipoDesc?: string | null
  multaPercentual: number
  jurosTipo: number
  jurosTipoDesc?: string | null
  jurosPercentual: number
  incluirPix: boolean
  identificacaoEmissorBoleto: number
  identificacaoEmissorBoletoDesc?: string | null
  identificacaoDistribuicaoBoleto: number
  identificacaoDistribuicaoBoletoDesc?: string | null
  tipoCobrancaTarifa: number
  tipoCobrancaTarifaDesc?: string | null
  valorTarifa?: number | null
  impressaoBoletoPadraoId?: string | null
  impressaoBoletoPadraoNome?: string | null
  sicoobIgnorarContaCorrenteDiferente: boolean
  diasParaCredito: number
}

export interface ConvenioCredenciaisBancoBrasilModel {
  cliente_id?: string | null
  cliente_secret?: string | null
  AuthorizationBasic?: string | null
  developer_application_key?: string | null
}

export interface ConvenioCredenciaisSicoobModel {
  cliente_id?: string | null
}

export interface ConvenioCredenciaisItauModel {
  client_id: string
  client_secret: string
  certificadoCRT: File
  chaveKey: File
  CertificadoCRTString?: string
  CertificadoPrivateKeyString?: string
}

export interface ConvenioFilters {
  query: FilterOptions<string>
}

export interface ConvenioFilters1 {
  query: string
}

export const convenioFilters: ConvenioFilters = {
  query: {
    value: '',
    label: 'Nome/codigo',
  },
}

async function obterConvenio(
  params?: QueryParamsInterface,
): Promise<HttpResponseInterface<ConvenioResumoModel>> {
  const response = await api.get(`Convenio/Pesquisar/${params?.query || ''}`)
  return response?.data
}

export async function obterConvenioPorId(id: string): Promise<ConvenioModel> {
  const response = await api.get(`Convenio/${id}`)
  return response.data.data
}

export async function createOrUpdateConvenio(
  data: ConvenioModel,
): Promise<ConvenioModel> {
  delete data.cidade
  delete data.uf

  if (data?.id) {
    const response = await api.put(`Convenio/${data.id}`, data)
    return response?.data?.data
  }

  const response = await api.post('Convenio', data)
  return response?.data?.data
}

export async function removerConvenio(data: ConvenioModel): Promise<void> {
  const response = await api.delete(`Convenio/${data.id}`)
  return response.data
}

export async function ativarOuDesativarConvenio(
  data: ConvenioModel,
): Promise<ConvenioModel> {
  const ativarOuDesativar = data.ativo === true ? 'Desativar' : 'Ativar'
  const response = await api.put(
    `Convenio/${ativarOuDesativar}`,
    {},
    { params: { id: data?.id } },
  )
  return response?.data?.data
}

export async function atualizarConvenioCredenciaisBancoBrasil(
  convenioId: string,
  data: ConvenioCredenciaisBancoBrasilModel,
): Promise<ConvenioCredenciaisBancoBrasilModel> {
  const response = await api.put(
    'Convenio/BancoBrasil/AtualizarCredenciaisDeAcesso',
    data,
    {
      params: { convenioId },
    },
  )
  return response?.data?.data
}

export async function atualizarConvenioCredenciaisSicoob(
  convenioId: string,
  data: ConvenioCredenciaisSicoobModel,
): Promise<ConvenioCredenciaisSicoobModel> {
  const response = await api.put(
    'Convenio/Sicoob/AtualizarCredenciaisDeAcesso',
    data,
    {
      params: { convenioId },
    },
  )
  return response?.data?.data
}

export async function atualizarConvenioCredenciaisItau(
  convenioId: string,
  data: ConvenioCredenciaisItauModel,
): Promise<ConvenioCredenciaisItauModel> {
  const { client_id, client_secret, certificadoCRT, chaveKey } = data

  const formData = new FormData()
  formData.append('certificadoCRT', certificadoCRT)
  formData.append('chaveKey', chaveKey)

  const response = await api.put(
    'Convenio/Itau/AtualizarCredenciaisDeAcesso',
    formData,
    {
      params: { convenioId, client_id, client_secret },
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  )
  return response?.data?.data
}

export function useQueryObterConvenio(params?: QueryParamsInterface) {
  return useQuery<
    HttpResponseInterface<ConvenioResumoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CONVENIO', params], () => {
    return obterConvenio(params)
  })
}

export function useObterConvenioPorId() {
  const [isLoading, setLoading] = useState(false)
  const notifications = useNotification()

  async function obterConvenioPorId(
    convenioId: string,
  ): Promise<ConvenioModel | null> {
    try {
      setLoading(true)
      const response = await api.get(`Convenio/${convenioId}`)
      if (response?.data?.data) {
        return response?.data?.data
      }
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }

    return null
  }

  return { obterConvenioPorId, isLoadingObterConvenioPorId: isLoading }
}

export function useObterConvenioPorEmpresaId() {
  const [isLoading, setLoading] = useState(false)
  const notifications = useNotification()

  async function obterConvenioPorEmpresaId(
    empresaId: string,
  ): Promise<HttpResponseInterface<ConvenioModel> | null> {
    try {
      setLoading(true)
      const response = await api.get('Convenio/ObterAtivosPorEmpresaId', {
        params: {
          empresaId,
        },
      })
      if (response?.data) {
        return response?.data
      }
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }

    return null
  }

  return {
    obterConvenioPorEmpresaId,
    isLoadingObterConvenioPorEmpresaId: isLoading,
  }
}

export function useQueryObterConvenioPorId(id: string) {
  return useQuery<ConvenioModel, AxiosError<ErrorInterface, ErrorInterface>>(
    ['CONVENIO', id],
    () => {
      return obterConvenioPorId(id)
    },
  )
}

export function useCreateOrUpdateConvenio() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<ConvenioModel, AxiosError, ConvenioModel>(
    (data) => createOrUpdateConvenio(data),
    {
      onSuccess(_, data) {
        const flexCreateOrUpdate = flexCreateOrUpdateMessage(
          data?.id,
          'masculino',
        )
        notifications.notifySuccess(
          `Convênio ${flexCreateOrUpdate} com sucesso!`,
        )
        queryClient.invalidateQueries(['CONVENIO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useAtivarOuDesativarConvenio() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<ConvenioModel, AxiosError, ConvenioModel>(
    (data) => ativarOuDesativarConvenio(data),
    {
      onSuccess() {
        queryClient.invalidateQueries(['CONVENIO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useRemoverConvenio() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<void, AxiosError, ConvenioModel>(
    (data) => removerConvenio(data),
    {
      onSuccess() {
        queryClient.invalidateQueries(['CONVENIO'])
        notifications.notifySuccess('Convenio excluído com sucesso!')
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useAtualizarConvenioCredenciaisBancoBrasil() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    ConvenioCredenciaisBancoBrasilModel,
    AxiosError,
    {
      convenioId: string
      data: ConvenioCredenciaisBancoBrasilModel
    }
  >(
    ({ convenioId, data }) =>
      atualizarConvenioCredenciaisBancoBrasil(convenioId, data),
    {
      onSuccess() {
        notifications.notifySuccess(
          `Credenciais de Acesso Atualizadas com sucesso!`,
        )
        queryClient.invalidateQueries(['CONVENIO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useAtualizarConvenioCredenciaisSicoob() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    ConvenioCredenciaisSicoobModel,
    AxiosError,
    {
      convenioId: string
      data: ConvenioCredenciaisSicoobModel
    }
  >(
    ({ convenioId, data }) =>
      atualizarConvenioCredenciaisSicoob(convenioId, data),
    {
      onSuccess() {
        notifications.notifySuccess(
          `Credenciais de Acesso Atualizadas com sucesso!`,
        )
        queryClient.invalidateQueries(['CONVENIO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useAtualizarConvenioCredenciaisItau() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    ConvenioCredenciaisItauModel,
    AxiosError,
    { convenioId: string; data: ConvenioCredenciaisItauModel }
  >(
    ({ convenioId, data }) =>
      atualizarConvenioCredenciaisItau(convenioId, data),
    {
      onSuccess() {
        notifications.notifySuccess(
          `Credenciais de Acesso Atualizadas com sucesso!`,
        )
        queryClient.invalidateQueries(['CONVENIO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useConvenio() {
  return {
    useQueryObterConvenio,
    useQueryObterConvenioPorId,
    useObterConvenioPorId,
    useObterConvenioPorEmpresaId,
    useCreateOrUpdateConvenio,
    useRemoverConvenio,
    useAtivarOuDesativarConvenio,
    useAtualizarConvenioCredenciaisBancoBrasil,
    useAtualizarConvenioCredenciaisSicoob,
    useAtualizarConvenioCredenciaisItau,
  }
}
