import { Grid, TextField } from '@mui/material'
import { FormProvider } from '@data-c/providers'

import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import CardDynamic from '@components/CardDynamic'

import {
  AlterarBoletoMensagemParams,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'

interface BoletoMensagemProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoMensagem({ boleto }: BoletoMensagemProps) {
  const { mensagemOcorrencia, id, status, statusDesc } = boleto || {}

  const dataMensagem: AlterarBoletoMensagemParams = {
    boletoId: id || '',
    novaMensagem: mensagemOcorrencia || '',
  }

  const isBoletoIncluido = status === DropDownBoletoStatusEnum.INCLUIDO_0

  return (
    <FormProvider>
      <Form />
      <CardDynamic
        title="Mensagem"
        data={dataMensagem}
        disabledAllChildren
        disableButton={!isBoletoIncluido}
        tooltipTitle={`Não é possível editar a Mensagem de um boleto ${
          statusDesc || 'que não esteja incluído'
        }`}
        initialOpacity
        gridContainer
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Mensagem"
            value={mensagemOcorrencia || ''}
            multiline
            rows={4}
          />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
