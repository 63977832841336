import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  tooltipClasses,
  Box,
  Icon,
  Typography,
  Divider,
} from '@mui/material'
import { styled } from '@mui/material/styles'

interface TooltipProps extends MuiTooltipProps {
  type: 'warning' | 'info'
}

const CustomizedTooltip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    minWidth: 180,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

const titleMaps = {
  info: 'Informação',
  warning: 'Atenção',
}

const Title = (props: {
  title: NonNullable<React.ReactNode>
  type: 'warning' | 'info'
}) => {
  const { title, type } = props
  return (
    <Box sx={{ mt: 0.5 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Icon color={type} fontSize="small">
          {type}
        </Icon>{' '}
        <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
          {titleMaps[type]}
        </Typography>
      </Box>
      <Divider sx={{ mt: 0.5 }} />
      <Box sx={{ mt: 1 }}>{title}</Box>
    </Box>
  )
}

const Tooltip = (props: TooltipProps) => {
  const { type, children, title, ...rest } = props
  return (
    <CustomizedTooltip
      arrow
      title={<Title title={title as any} type={type} />}
      enterDelay={300}
      {...rest}
    >
      {children}
    </CustomizedTooltip>
  )
}

Tooltip.defaultProps = {
  type: 'info',
}

export default Tooltip
