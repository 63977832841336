import { Box, LinearProgress, Switch, useTheme } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { Flag } from '@data-c/ui'

import useConvenio, { ConvenioModel } from '@hooks/queries/useConvenio'

export default function AtivarOuDesativarConvenio() {
  const { changeValues } = useFormApi<ConvenioModel>()
  const { formValues: data } = useFormData<ConvenioModel>()

  const { useAtivarOuDesativarConvenio } = useConvenio()
  const {
    mutateAsync: ativarOuDesativarConvenio,
    isLoading: isLoadingAtivarOuDesativarConvenio,
  } = useAtivarOuDesativarConvenio()

  async function handleChangeAtivarOuDesativarConvenio() {
    const convenio = await ativarOuDesativarConvenio(data)
    const { id, ativo } = convenio || {}

    if (id) {
      changeValues(convenio)
      if (ativo === false) {
        const filter = localStorage.getItem('@storage:boletos-stella-hubbank')
        const parsedFilter = JSON.parse(filter || '')
        if (parsedFilter?.appliedValues?.convenio?.id === id) {
          localStorage.removeItem('@storage:boletos-stella-hubbank')
        }
      }
    }
  }

  const theme = useTheme()

  return (
    <Box
      sx={{
        paddingTop: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        ':after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '3px',
          backgroundColor: isLoadingAtivarOuDesativarConvenio
            ? ''
            : theme.palette.grey[300],
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          zIndex: 1,
          backgroundColor: theme.palette.background.paper,
          paddingX: 2,
        }}
      >
        <Switch
          title="Ativar ou desativar a contingência"
          name="ativo"
          disabled={isLoadingAtivarOuDesativarConvenio}
          checked={Boolean(data?.ativo)}
          onChange={handleChangeAtivarOuDesativarConvenio}
        />
        Convênio {data?.ativo ? 'Ativado' : 'Desativado'}
        <Flag isFlagged type={data?.ativo ? 'success' : 'error'} />
      </Box>

      {isLoadingAtivarOuDesativarConvenio && (
        <Box sx={{ position: 'absolute', left: 0, right: 0 }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  )
}
