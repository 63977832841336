export function floatOptionsCellStyle() {
  return {
    setCellHeaderProps: () => ({
      style: {
        width: '100px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        position: 'sticky',
        right: 0,
        zIndex: 101,
      },
    }),
    setCellProps: () => ({
      style: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        position: 'sticky',
        right: 0,
        zIndex: 100,
        backgroundColor: 'inherit',
      },
    }),
  }
}

export function dateOptionsCellStyle() {
  return {
    style: {
      width: '120px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
  }
}

export function cpfCnpjOptionsCellStyle() {
  return {
    style: {
      width: '136px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
  }
}

export function ativoOptionsCellStyle() {
  return {
    style: {
      width: '56px',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
  }
}

export function selectableCellStyle(rowIndex: number, rowSelected: number) {
  return {
    style: {
      backgroundColor:
        rowIndex === rowSelected ? 'rgba(89, 195, 224, 20%)' : 'initial',
    },
  }
}

export function codigoOptionsCellStyle() {
  return {
    setCellProps: () => ({
      style: {
        width: '96px',
      },
    }),
  }
}

export function whiteSpace() {
  return {
    setCellHeaderProps: () => ({
      style: {
        whiteSpace: 'nowrap',
      },
    }),
    setCellProps: () => ({
      style: {
        whiteSpace: 'nowrap',
      },
    }),
  }
}

export default function useTableCustomCell() {
  return {
    floatOptionsCellStyle,
    dateOptionsCellStyle,
    cpfCnpjOptionsCellStyle,
    ativoOptionsCellStyle,
    selectableCellStyle,
    codigoOptionsCellStyle,
    whiteSpace,
  }
}
