import { Drawer } from '@mui/material'
import { Print as PrintIcon } from '@mui/icons-material'

import { useDialog } from '@data-c/hooks'
import { Button } from '@data-c/ui'

import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'
import useStringManipulation from '@hooks/useStringManipulation'

import CustomToolbarHeader from '@components/CustomToolbarHeader'
import CustomTooltip from '@components/CustomTooltip'
import PDFViewer from '@components/PDFViewer'

interface ToolbarHeaderProps {
  boletos: BoletoDetalhadoModel[]
  indexBoletosSelecionados: number[]
}

export default function ToolbarHeader(props: ToolbarHeaderProps) {
  const { boletos, indexBoletosSelecionados } = props
  const { singularOuPlural } = useStringManipulation()

  const {
    data: dataPDFDrawer,
    isOpen: isOpenPDFDrawer,
    closeDialog: closePDFDrawer,
    openDialog: openPDFDrawer,
  } = useDialog<(string | undefined)[]>()

  function handleOpePDFDrawer() {
    const idBoletosSelecionados = indexBoletosSelecionados.map((index) => {
      return boletos[index]?.id
    })

    openPDFDrawer(idBoletosSelecionados)
  }

  function handleClosePDFDrawer() {
    closePDFDrawer()
  }

  const pdfDrawerTitle = singularOuPlural('Boleto', dataPDFDrawer?.length || 0)
  const chaveId = `BOLETO_${
    boletos[indexBoletosSelecionados[0]]?.convenioBancoCodigo
  }`

  return (
    <>
      <CustomToolbarHeader
        countTitle="Boleto"
        countQuantidade={indexBoletosSelecionados.length}
        customSx={{ marginRight: 6 }}
      >
        <CustomTooltip
          title="Habilitado somente quando algum boleto for selecionado"
          disableHoverListener={indexBoletosSelecionados.length > 0}
        >
          <Button
            disabled={indexBoletosSelecionados.length === 0}
            variant="contained"
            onClick={handleOpePDFDrawer}
            startIcon={<PrintIcon />}
          >
            Visualizar PDF
          </Button>
        </CustomTooltip>
      </CustomToolbarHeader>

      <Drawer
        anchor="bottom"
        open={isOpenPDFDrawer}
        onClose={handleClosePDFDrawer}
      >
        <PDFViewer
          chaveId={chaveId}
          title={pdfDrawerTitle}
          pdfIds={dataPDFDrawer}
          pdfPath="BoletoConsulta/ObterPDFBoletosUnificado"
          relatorioPath="ObterFrxPorChaveId"
          onClose={handleClosePDFDrawer}
        />
      </Drawer>
    </>
  )
}
