import { FormProvider } from '@data-c/providers'
import OptionList from '@components/OptionsListContainer/components/OptionList'
import {
  AlterarBoletoDataVencimentoParams,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'
import { OrigemType } from '../..'

interface DataVencimentoProps {
  boleto: BoletoDetalhadoModel | null
}

export default function DataVencimento({ boleto }: DataVencimentoProps) {
  const { id, dataVencimento } = boleto || {}

  const dataAlterarVencimento: AlterarBoletoDataVencimentoParams = {
    boletoId: id || '',
    novaDataVencimento: dataVencimento || '',
  }

  return (
    <FormProvider>
      <OptionList
        name={OrigemType.DATA_VENCIMENTO}
        noRequest={{ initialData: dataAlterarVencimento }}
        form={<Form />}
      />
    </FormProvider>
  )
}
