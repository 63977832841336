import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogContentProps,
  IconButton,
  styled,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { PageTitle } from '@data-c/ui'

interface DialogProps extends MuiDialogProps {
  title?: string
  onClose?(): void
  onOkClick?(): void
  onCancelClick?(): void
  okLabel?: string
  cancelLabel?: string
  children?: React.ReactNode
  maxWidth?: MuiDialogProps['maxWidth']
  dialogContentProps?: DialogContentProps
}

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
  },
}))

const Dialog = (props: DialogProps) => {
  const {
    onClose,
    title,
    children,
    // dialogContentProps,
    // onCancelClick,
    // onOkClick,
    // okLabel,
    // cancelLabel,
  } = props

  return (
    <StyledDialog fullWidth={true} {...props}>
      <DialogTitle sx={{ padding: '8px 8px' }}>
        <PageTitle title={title || ''}>
          {onClose ? (
            <IconButton
              size="small"
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : null}
        </PageTitle>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  )
}

Dialog.defaultProps = {
  maxWidth: 'sm',
  //   okLabel: 'Salvar',
  //   cancelLabel: 'Cancelar',
  //   onCancelClick: () => {
  //     throw new Error('Method not implemented')
  //   },
  //   onOkClick: () => {
  //     throw new Error('Method not implemented')
  //   },
  // dialogContentProps: {},
}

export { default as DialogActions } from './DialogActions'
export * from './DialogActions'

export { default as DialogContent } from './DialogContent'
export * from './DialogContent'

export default Dialog
