import { Grid } from '@mui/material'

import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import DatePicker from '@components/DatePicker'

import useBoleto, {
  AlterarBoletoDataVencimentoParams,
} from '@hooks/queries/useBoleto'
import { getCurrentDate } from '@hooks/useDates'
import useValidations from '@hooks/useValidations'

import moment from 'moment'
import * as yup from 'yup'

const schema = yup.object().shape({
  novaDataVencimento: yup
    .date()
    .typeError('Data Inválida')
    .required('Informe a nova data de vencimento')
    .test(
      'dataVencimento',
      'A nova data de vencimento não pode ser inferior a data atual',
      (dataVencimento) => {
        const dataAtual = getCurrentDate().format('yyyy-MM-DD')
        return moment(dataVencimento).isSameOrAfter(dataAtual)
      },
    ),
})

export default function Form() {
  const { setValidationErrors, validationProps } = useValidations()

  const { changeValue, closeForm, toggleSubmit } =
    useFormApi<AlterarBoletoDataVencimentoParams>()
  const { formValues: data } = useFormData<AlterarBoletoDataVencimentoParams>()

  const { useAlterarBoletoDataVencimento } = useBoleto()
  const { mutateAsync: alterarBoletoDataVencimento } =
    useAlterarBoletoDataVencimento()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoDataVencimento(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Alterar Data de Vencimento',
        maxWidth: 'xs',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePicker
            name="novaDataVencimento"
            label="Nova Data de Vencimento"
            required
            value={data?.novaDataVencimento || null}
            onChange={(date) => {
              changeValue('novaDataVencimento', date)
            }}
            {...validationProps('novaDataVencimento')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
