import { useMemo } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Search as SearchIcon } from '@mui/icons-material'

import { Button, ButtonContainer, DataTable, Dialog } from '@data-c/ui'
import { formatCurrency, useDialog } from '@data-c/hooks'
import {
  useFormApi as useDialogApi,
  useFormData as useDialogData,
} from '@data-c/providers'

import JsonViewerDialog from '@components/JsonViewerDialog'

import { BoletoConsultaObterMovimentacaoModel } from '@hooks/queries/useBoleto'
import useDates from '@hooks/useDates'

export default function Table() {
  const { toBrazilianDate } = useDates()

  const { closeForm: closeBoletoMovimentacoesDialog } = useDialogApi()
  const { isOpen: isOpenBoletoMovimentacoesDialog, formValues: data } =
    useDialogData<BoletoConsultaObterMovimentacaoModel[]>()

  const {
    isOpen: isOpenJsonViewerDialog,
    openDialog: openJsonViewerDialog,
    closeDialog: closeJsonViewerDialog,
    data: dataJsonViewerDialog,
  } = useDialog<JSON>()

  const dataFormatada = useMemo(() => {
    return data?.map((item) => {
      return {
        ...item,
        dataCriacao: toBrazilianDate(item.dataCriacao),
        dataAlteracao: toBrazilianDate(item.dataAlteracao),
        dataSituacao: toBrazilianDate(item.dataSituacao),
        valorTarifa: formatCurrency(item?.valorTarifa || ''),
      }
    })
  }, [])

  const columns = useMemo((): MUIDataTableColumnDef[] => {
    return [
      {
        name: 'dataCriacao',
        label: 'Inclusão',
      },
      {
        name: 'dataAlteracao',
        label: 'Alteração',
      },
      {
        name: 'dataSituacao',
        label: 'Situação',
      },
      {
        name: 'tipoMovimentoDesc',
        label: 'Tipo de Movimento',
      },
      {
        name: 'valorTarifa',
        label: 'Tarifa',
      },
      {
        name: 'retornoBancoJson',
        label: 'Retorno Banco',
        options: {
          customBodyRender: (value) => {
            return (
              <Button
                startIcon={<SearchIcon />}
                variant="text"
                fullWidth
                onClick={() => openJsonViewerDialog(JSON.parse(value))}
              >
                Visualizar
              </Button>
            )
          },
        },
      },
    ]
  }, [openJsonViewerDialog, data])

  return (
    <>
      <Dialog
        title="Movimentações"
        maxWidth="md"
        open={isOpenBoletoMovimentacoesDialog}
        onClose={closeBoletoMovimentacoesDialog}
        actions={
          <ButtonContainer>
            <Button
              variant="contained"
              onClick={closeBoletoMovimentacoesDialog}
            >
              Cancelar
            </Button>
          </ButtonContainer>
        }
      >
        <DataTable data={dataFormatada || []} columns={columns} />
      </Dialog>

      <JsonViewerDialog
        title="Retorno Banco"
        data={dataJsonViewerDialog}
        isOpen={isOpenJsonViewerDialog}
        onClose={closeJsonViewerDialog}
      />
    </>
  )
}
