import { Button } from '@data-c/ui'
import { Alert } from '@mui/material'

interface ClearSelectionProps {
  total: number
  onClick: () => void
}

export default function ClearSelection(props: ClearSelectionProps) {
  const { total, onClick } = props

  return (
    <>
      <Alert severity="warning">
        Todos os <b>{total}</b> lançamentos estão selecionados
      </Alert>

      <Button onClick={onClick}>Limpar Seleção</Button>
    </>
  )
}
