import moment, { Moment } from 'moment'

export function dateStartOfMonth() {
  return moment().startOf('month').format('YYYY-MM-DD')
}

export function dateEndOfMonth() {
  return moment().endOf('month').format('YYYY-MM-DD')
}

export const getFirstDateOfCurrentMonth = (): Moment => {
  return moment().set('date', 1)
}

export function getCurrentDate(): Moment {
  return moment()
}

export function toBrazilianDate(value: string, invalidDate = '') {
  if (!value) return invalidDate
  return moment(value).format('DD/MM/YYYY')
}

export function toBrazilianDateTime(value: string, invalidDate = '') {
  if (!value) return invalidDate
  return moment(value).format('DD/MM/YYYY HH:mm')
}

export default function useDates() {
  return {
    getFirstDateOfCurrentMonth,
    getCurrentDate,
    toBrazilianDate,
    toBrazilianDateTime,
    dateStartOfMonth,
    dateEndOfMonth,
  }
}
