import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum IndicadorIEEnum {
  NAO_INFORMADO_0 = '0',
  CONTRIBUINTE_ICMS_1 = '1',
  CONTRIBUINTE_ISENTO_INSCRICAO_2 = '2',
  NAO_CONTRIBUINTE_PODE_OU_NAO_POSSUIR_9 = '9',
}

export default function DropDownIndicadorIE(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value={IndicadorIEEnum.NAO_INFORMADO_0}>Não informado</MenuItem>
      <MenuItem value={IndicadorIEEnum.CONTRIBUINTE_ICMS_1}>
        Contribuinte ICMS (Informar a IE)
      </MenuItem>
      <MenuItem value={IndicadorIEEnum.CONTRIBUINTE_ISENTO_INSCRICAO_2}>
        Contribuinte isento da Inscrição
      </MenuItem>
      <MenuItem value={IndicadorIEEnum.NAO_CONTRIBUINTE_PODE_OU_NAO_POSSUIR_9}>
        Não Contribuinte, que pode ou não possuir Inscrição Estadual
      </MenuItem>
    </TextField>
  )
}
