import { Circle } from '@mui/icons-material'
import { Box } from '@mui/material'

interface FlagWithDescriptionProps {
  flagDescription: string
  mappingColor?: any
  color?: 'success' | 'error' | 'warning' | 'info' | 'primary' | 'secondary'
}

export default function FlagWithDescription(props: FlagWithDescriptionProps) {
  const { flagDescription, mappingColor, color } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <Circle
        sx={{
          fontSize: '10pt',
          color: color || mappingColor,
        }}
        color={color}
      />
      <span>{flagDescription}</span>
    </Box>
  )
}
