import { SortPropsInterface } from 'interfaces/SortPropsInterface'
import moment from 'moment'
import { useState } from 'react'

export default function useSort() {
  const [sort, setSort] = useState<SortPropsInterface>()

  function ordenarPorAnoMes<T>(data: T[], fieldName: keyof T) {
    return data?.sort((a, b) => {
      const anoMesA = moment(a[fieldName] as any, 'YYYY/MM')
      const anoMesB = moment(b[fieldName] as any, 'YYYY/MM')
      return anoMesA.diff(anoMesB)
    })
  }

  function ordenarDataPorMaisRecente<T>(data: T[], fieldName: keyof T) {
    const dataOrdenada = data.sort(
      (a, b) =>
        new Date(b[fieldName] as any).getTime() -
        new Date(a[fieldName] as any).getTime(),
    )

    return dataOrdenada
  }

  function ordenarPorValor<T>(
    data: T[],
    fieldName: keyof T,
    ordernarPor?: 'asc' | 'desc',
  ) {
    let dataOrdenada

    if (ordernarPor === 'desc') {
      dataOrdenada = data?.sort(
        (a, b) => (a[fieldName] as any) - (b[fieldName] as any),
      )
    }

    if (ordernarPor === 'asc') {
      dataOrdenada = data?.sort(
        (a, b) => (b[fieldName] as any) - (a[fieldName] as any),
      )
    }

    return dataOrdenada ? dataOrdenada : []
  }

  function changeSort(column: string, direction: string) {
    setSort({ column, direction })
  }

  return {
    ordenarDataPorMaisRecente,
    ordenarPorValor,
    ordenarPorAnoMes,
    changeSort,
    sort,
  }
}
