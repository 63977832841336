import {
  Box,
  IconButton,
  IconButtonProps,
  Tooltip as MuiTooltip,
  useTheme,
} from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'

interface DeleteButtonProps extends IconButtonProps {
  deleteIconTitle?: string
}

export default function DeleteButton(props: DeleteButtonProps) {
  const { deleteIconTitle, disabled, ...rest } = props
  const theme = useTheme()

  return (
    <MuiTooltip
      title={deleteIconTitle ? `${deleteIconTitle}` : 'Excluir Conteúdo'}
    >
      <Box>
        <IconButton disabled={disabled} {...rest}>
          <DeleteIcon
            sx={{
              color: disabled ? 'disabled' : theme.palette.error.dark,
            }}
          />
        </IconButton>
      </Box>
    </MuiTooltip>
  )
}
