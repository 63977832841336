import { ButtonContainer } from '@data-c/ui'
import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'

import BoletoAlteracoes from './components/BoletoAlteracoes'
import BoletoCancelamento from './components/BoletoCancelamento'
import BoletoConsulta from './components/BoletoConsulta'
import BoletoRegistro from './components/BoletoRegistro'
import BoletoOcorrencia from './components/BoletoOcorrencia'

interface ActionsProps {
  boleto: BoletoDetalhadoModel | null
}

export default function Actions({ boleto }: ActionsProps) {
  return (
    <ButtonContainer sx={{ marginBottom: 2 }}>
      <BoletoOcorrencia />
      <BoletoRegistro boleto={boleto} />
      <BoletoConsulta />
      <BoletoCancelamento boleto={boleto} />
      <BoletoAlteracoes />
    </ButtonContainer>
  )
}
