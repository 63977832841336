import { Grid, TextField } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { Checkbox, FormContainer } from '@data-c/ui'

import DatePicker from '@components/DatePicker'

import { onlyNumbers } from '@data-c/hooks'
import { getCurrentDate } from '@hooks/useDates'
import useBoleto, {
  AlterarBoletoIdenfiticacaoModel,
} from '@hooks/queries/useBoleto'
import useValidations from 'hooks/useValidations'

import moment from 'moment'
import * as yup from 'yup'

const schema = yup.object().shape({
  dataEmissao: yup
    .date()
    .typeError('Data Inválida')
    .test(
      'dataVencimento',
      'A data de emissão não pode ser superior a data atual',
      (dataEmissao) => {
        const dataAtual = getCurrentDate().format('yyyy-MM-DD')
        return moment(dataEmissao).isSameOrBefore(dataAtual)
      },
    ),
  dataVencimento: yup
    .date()
    .typeError('Data Inválida')
    .test(
      'dataVencimento',
      'A data de vencimento não pode ser inferior a data atual',
      (dataVencimento) => {
        const dataAtual = getCurrentDate().format('yyyy-MM-DD')
        return moment(dataVencimento).isSameOrAfter(dataAtual)
      },
    ),
})

interface FormProps {
  boletoId: string
}

export default function Form({ boletoId }: FormProps) {
  const { setValidationErrors, validationProps } = useValidations()

  const { formValues: data } = useFormData<AlterarBoletoIdenfiticacaoModel>()
  const { changeValue, toggleSubmit, closeForm, onChangeFormValue } =
    useFormApi<AlterarBoletoIdenfiticacaoModel>()

  const { useAlterarBoletoIdenfiticacao } = useBoleto()
  const { mutateAsync: alterarIndicadoresNF } = useAlterarBoletoIdenfiticacao()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarIndicadoresNF({
          boletoId,
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      dialogProps={{
        title: 'Alterar Identificação do Boleto',
        maxWidth: 'md',
      }}
      triggerButton={<></>}
    >
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            name="dataEmissao"
            label="Data Emissão"
            required
            value={data?.dataEmissao || null}
            onChange={(date) => {
              changeValue('dataEmissao', date)
            }}
            {...validationProps('dataEmissao')}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            name="dataVencimento"
            label="Data Vencimento"
            required
            value={data?.dataVencimento || null}
            onChange={(date) => {
              changeValue('dataVencimento', date)
            }}
            {...validationProps('dataVencimento')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            name="documentoSistema"
            label="Documento"
            inputProps={{ maxLength: 30 }}
            value={data?.documentoSistema || ''}
            onChange={(e) =>
              changeValue('documentoSistema', e.target.value || null)
            }
          />
        </Grid>
        <Grid item xl={3} lg={3} md={2} sm={6} xs={12}>
          <TextField
            name="carteiraCodigo"
            label="Carteira"
            value={data?.carteiraCodigo || ''}
            inputProps={{ maxLength: 3 }}
            onChange={(e) =>
              changeValue('carteiraCodigo', e.target.value || null)
            }
          />
        </Grid>
        <Grid item xl={3} lg={3} md={2} sm={6} xs={12}>
          <TextField
            name="carteiraVariacao"
            label="Variação"
            value={data?.carteiraVariacao || ''}
            inputProps={{ maxLength: 2 }}
            onChange={(e) =>
              changeValue('carteiraVariacao', e.target.value || null)
            }
          />
        </Grid>
        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
          <TextField
            name="diasParaNegativacao"
            label="Dias negativação"
            value={data?.diasParaNegativacao || ''}
            inputProps={{ maxLength: 9 }}
            onChange={(e) =>
              changeValue(
                'diasParaNegativacao',
                onlyNumbers(e.target.value) || 0,
              )
            }
          />
        </Grid>
        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
          <TextField
            name="diasLimiteRecebimento"
            label="Dias Limite Recebimento"
            value={data?.diasLimiteRecebimento || ''}
            inputProps={{ maxLength: 9 }}
            onChange={(e) =>
              changeValue(
                'diasLimiteRecebimento',
                onlyNumbers(e.target.value) || 0,
              )
            }
          />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <TextField
            name="nossoNumeroSequencial"
            label="Nosso Número/Sequencial"
            value={data?.nossoNumeroSequencial || ''}
            inputProps={{ maxLength: 19 }}
            onChange={(e) =>
              changeValue('nossoNumeroSequencial', e.target.value || 0)
            }
          />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <Checkbox
            name="permiteRecebimentoParcial"
            label="Permite Recebimento Parcial"
            checked={Boolean(data?.permiteRecebimentoParcial)}
            onChange={onChangeFormValue}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
