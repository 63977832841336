import { LayoutEmailInterface, useQuery } from '@hooks/queries/useLayoutEmail'
import {
  List,
  ListItemText,
  ListItemButton,
  Box,
  ListSubheader,
} from '@mui/material'

interface TemplatesProps {
  onClick: (template: LayoutEmailInterface) => void
  onAfterClick?: () => void
}

const Templates = (props: TemplatesProps) => {
  const { onClick, onAfterClick } = props
  const { data, isLoading } = useQuery({})

  if (isLoading) {
    return <Box>Carregando</Box>
  }

  return (
    <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Selecione um template para aplicar ao modelo
        </ListSubheader>
      }
    >
      {data?.data?.map((d) => (
        <ListItemButton
          onClick={() => {
            onClick(d)
            if (onAfterClick) onAfterClick()
          }}
          //   secondaryAction={
          //     <IconButton edge="end" aria-label="delete">
          //       <Download />
          //     </IconButton>
          //   }
        >
          <ListItemText primary={d.nome} />
        </ListItemButton>
      ))}
    </List>
  )
}

export default Templates
