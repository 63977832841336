import {
  useQuery as useTQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { HttpResponseInterface } from '@data-c/hooks'
import { FilterOptions } from '@data-c/providers'

import useNotifications from '@hooks/useNotifications'
import useDownload from '@hooks/useDownload'
import useNotification from '@hooks/useNotifications'
import { dateEndOfMonth, dateStartOfMonth } from '@hooks/useDates'

import { EmpresaModel } from './useEmpresa'
import { ConvenioResumoModel } from './useConvenio'

import api from '@services/api'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'

export const empresas: Record<number, string> = {
  1: 'LUIZ EDUARDO MANTOVANI & CIA. LTDA. - ME',
  2: 'LEMAN INFORMATICA LTDA - ME',
  3: 'L E MANTOVANI & CIA LTDA - ME',
  4: 'J N M TREINAMENTOS EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL LTDA ME',
  5: 'LEM SISTEMAS LTDA',
}

export interface BoletoModelInterface {
  id: string
  temDiferencaValor: boolean
  valor: number
  ultimoValor: number
  pagador: {
    emails_cobranca: string
  }
}

export interface AgendamentoStatusModel {
  id?: string
  data?: string | null
  agendamentoId: string
  agendamentoNome?: string | null
  agendamentoTipoData: number
  agendamentoHoraEnvio: number
  agendamentoEnvioAutomatico: boolean
  status: number
  mensagemErro?: string | null
}

export interface EmailConsultaInterface {
  agendamentosStatus?: AgendamentoStatusModel[] | null
  id: string
  origem: number
  origemDesc: string
  status: number
  statusDesc: string
  dataAlteracao: string
  dataRegistro: string
  dataEmissao: string
  dataVencimento: string
  dataPagamento: string
  empresaId: string
  empresaNome: string
  convenioId: string
  convenioDescricao: string
  convenioBancoCodigo: number
  convenioAgencia: number
  convenioContaCorrente: number
  pagadorCNPJCPF: string
  pagadorNome: string
  pagadorCidadeNome: string
  pagadorUF: string
  pagadorEmail: string
  nossoNumero: string
  documentoSistema: string
  valorTitulo: number
  valorMultaCobrada: number
  valorDescontoConcedido: number
  valorPago: number
  erroEnvioEmail: boolean
  dataHoraEmailUltimoEnvio?: string | null
  pagadorConteudo?: string | null
  qtdEmailEnviadoSucesso: number
}

export interface FilterProps {
  empresaId?: string
  convenioId?: string | null
  tipoData?: number
  dataInicio?: string
  dataFim?: string
  tipo?: string
  nomeCliente?: string | null
  somenteAberta?: boolean
  somenteNaoEnviadas?: boolean
  inicio?: string
  fim?: string
  modo?: string
  empresa?: number
}

export interface EnvioBoletoFilters {
  dataInicio: FilterOptions<string>
  dataFim: FilterOptions<string>
  empresa?: FilterOptions<EmpresaModel | null>
  empresaId?: FilterOptions<string | null>
  pagadorConteudo?: FilterOptions<string>
  convenio?: FilterOptions<ConvenioResumoModel | null>
  convenioId?: FilterOptions<string | null>
  tipoData: FilterOptions<number>
}

export interface EnvioBoletoFilters1 {
  dataInicio: string
  dataFim: string
  empresa?: EmpresaModel | null
  empresaId?: string | null
  pagadorConteudo?: string | null
  convenio?: ConvenioResumoModel | null
  convenioId?: string | null
  tipoData: string
}

export const envioBoletofilters: EnvioBoletoFilters = {
  pagadorConteudo: {
    label: 'Cliente',
    value: '',
  },
  dataInicio: {
    label: 'Data Inicial',
    value: dateStartOfMonth(),
  },
  dataFim: {
    label: 'Data Final',
    value: dateEndOfMonth(),
  },
  empresa: {
    label: 'Empresa',
  },
  empresaId: {
    label: 'Empresa',
  },
  convenio: {
    label: 'Convênio',
  },
  convenioId: {
    label: 'Convênio',
  },
  tipoData: {
    label: 'Tipo de Data',
    value: 0,
  },
}

interface EnviarBoletosManualmenteProps {
  agendamentoId: string
  boletosIds: string[]
}

export async function obterBoletos(
  filters: EnvioBoletoFilters1,
  params?: QueryParamsInterface,
): Promise<HttpResponseInterface<EmailConsultaInterface>> {
  const paginationProps = params?.pagination || { page: 1, pageSize: 20 }
  const sortProps = params?.sort || { column: 'pagadorNome', direction: 'asc' }

  const { page, pageSize } = paginationProps
  const { direction } = sortProps

  const columnMapping: Record<string, string> = {
    emissao: 'dataEmissao',
    vencimento: 'dataVencimento',
    valor: 'valorTitulo',
    registro: 'dataRegistro',
    pagamento: 'dataPagamento',
    pagadorNome: 'pagadorNome',
    empresaNome: 'empresaNome',
  }
  const column = columnMapping[sortProps.column]

  delete filters.empresa
  delete filters.convenio

  const response = await api.post('EmailConsulta/Consultar', filters, {
    headers: {
      'Resposta-Compactada': 'Nunca',
      'DC-Page': page,
      'DC-PageSize': pageSize,
      'DC-Ordenation-Fields': `${column} ${direction}`,
    },
  })
  return response.data
}

export async function deletarBoleto(
  boleto: BoletoModelInterface,
): Promise<BoletoModelInterface> {
  await api.delete(`boleto/${boleto.id}`)
  return boleto
}

export async function enviarBoletosManualmente(
  data: EnviarBoletosManualmenteProps,
) {
  const { agendamentoId, boletosIds } = data

  const response = await api.post('EmailEnvio/EnviarManual', boletosIds, {
    params: { agendamentoId },
  })
  return response.data
}

export function useQuery(
  filters: EnvioBoletoFilters1,
  params?: QueryParamsInterface,
) {
  return useTQuery<HttpResponseInterface<EmailConsultaInterface>, AxiosError>(
    ['ENVIO_BOLETO_EMAIL', filters, params],
    () => {
      return obterBoletos(filters, params)
    },
    {
      staleTime: 0,
    },
  )
}

export function useDelete() {
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError, BoletoModelInterface>(deletarBoleto, {
    onSuccess: async (boleto: BoletoModelInterface) => {
      await queryClient.cancelQueries(['ENVIO_BOLETO_EMAIL'])

      queryClient.setQueriesData<Array<BoletoModelInterface>>(
        ['ENVIO_BOLETO_EMAIL'],
        (old) => {
          const newData = old?.filter(
            (b: BoletoModelInterface) => b.id !== boleto.id,
          )
          return newData
        },
      )
      notifications.notifySuccess('O boleto foi excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export function useDownloadBoleto() {
  const { progress, start, stop, isDownloading, updateProgress } = useDownload()
  const notifications = useNotifications()
  const download = async (boleto: EmailConsultaInterface) => {
    try {
      start()
      const response = await api.post(
        `BoletoConsulta/ObterPDFPadrao?boletoId=${boleto.id}`,
        null,
        {
          responseType: 'blob',
          onDownloadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent
            updateProgress(loaded, total)
          },
        },
      )
      var b = new Blob([response.data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(b)
      let tab = window.open()
      if (tab) {
        tab.location.href = fileURL
      }
    } catch (err) {
      console.log(err)
      notifications.notifyError('Não foi possível fazer o download do boleto')
    } finally {
      stop()
    }
  }

  return { download, progress, isDownloading }
}

export function useEnviarBoletosManualmente() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<any>,
    AxiosError,
    EnviarBoletosManualmenteProps
  >((data) => enviarBoletosManualmente(data), {
    onSuccess: (_, data) => {
      const flexPluralSingular =
        data.boletosIds.length === 1 ? 'E-mail enviado' : 'E-mails enviados'
      notifications.notifySuccess(`${flexPluralSingular} com sucesso!`)
      queryClient.invalidateQueries(['ENVIO_BOLETO_EMAIL'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export default function useEnvioBoletos() {
  return {
    useQuery,
    useDelete,
    useDownloadBoleto,
    useEnviarBoletosManualmente,
  }
}
