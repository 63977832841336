import { FormProvider } from '@data-c/providers'

import OptionList from '@components/OptionsListContainer/components/OptionList'

import {
  AlterarBoletoPagadorEnderecoModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import { CidadeModel, UFModel } from '@hooks/queries/useCidade'

import Form from './components/Form'
import { OrigemType } from '../..'

interface PagadorEnderecoProps {
  boleto: BoletoDetalhadoModel | null
}

export default function PagadorEndereco({ boleto }: PagadorEnderecoProps) {
  const {
    id,
    pagadorBairro,
    pagadorCEP,
    pagadorCidadeNome,
    pagadorComplemento,
    pagadorEmail,
    pagadorLogradouro,
    pagadorTelefone,
    pagadorUF,
  } = boleto || {}

  const dataAlterarPagadorEndereco: AlterarBoletoPagadorEnderecoModel = {
    bairro: pagadorBairro,
    cep: pagadorCEP,
    cidadeNome: pagadorCidadeNome,
    complemento: pagadorComplemento,
    email: pagadorEmail,
    logradouro: pagadorLogradouro,
    telefone: pagadorTelefone,
    uf: pagadorUF,
    cidade: {
      nome: pagadorCidadeNome || '',
    } as CidadeModel,
    ufModel: {
      id: pagadorUF || '',
    } as UFModel,
  }

  return (
    <FormProvider>
      <OptionList
        name={OrigemType.ENDERECO_DO_PAGADOR}
        noRequest={{ initialData: dataAlterarPagadorEndereco }}
        form={<Form boletoId={id || ''} />}
      />
    </FormProvider>
  )
}
