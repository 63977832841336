import { useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { Box, Collapse, Icon, ListItemText } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

import { MenuItemProps } from '@components/Menu'
import MenuItem from '@components/Menu/componetns/MenuItem'

import FullMenu from '../..'

interface FullMenuItemProps {
  menuItem: MenuItemProps
}

export default function FullMenuItem(props: FullMenuItemProps) {
  const { menuItem } = props
  const [expanded, setExpanded] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  if (menuItem.visible === false) return null

  const isExpandable: boolean = Boolean(
    menuItem?.items && menuItem?.items.length > 0,
  )

  function toggleExpand() {
    setExpanded(!expanded)
  }

  const splittedPath = location.pathname.split('/')
  const pathName = splittedPath[1]

  const splittedLink = menuItem.link?.split('/') || []
  const linkName = splittedLink[1]

  return (
    <Box>
      <MenuItem
        onClick={() => {
          if (menuItem?.link) {
            navigate(menuItem.link)
            return
          }
          toggleExpand()
        }}
        selected={pathName === linkName}
      >
        {!!menuItem.icon && <Icon>{menuItem.icon}</Icon>}
        <ListItemText primary={menuItem.label} />
        {isExpandable && !expanded && <ExpandMore />}
        {isExpandable && expanded && <ExpandLess />}
      </MenuItem>

      {menuItem?.items && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FullMenu
            sx={{
              pl: (theme) => theme.spacing(1.5),
            }}
            menus={menuItem?.items || []}
          ></FullMenu>
        </Collapse>
      )}
    </Box>
  )
}
