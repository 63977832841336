import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import {
  CurrencyCellStyle,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { findData, formatCurrency, usePagination } from '@data-c/hooks'

import { boletoStatusMappingColors } from '@components/DropDownBoletoStatus'
import EllipsisTextOptionsCellStyle from '@components/EllipsisTextOptionsCellStyle'
import ResponsiveDataTable from '@components/ResponsiveDataTable'

import useBoleto, {
  BoletoDetalhadoModel,
  BoletoFilters1,
} from '@hooks/queries/useBoleto'
import useSort from '@hooks/useSort'
import useDates from '@hooks/useDates'
import useTableCustomCell from '@hooks/useTableCustomCell'

import DataTableProps from 'interfaces/DataTableProps'
import ToolbarHeader from './ToolbarHeader'
import { formatarFiltros } from '../Filter'

interface TableProps
  extends DataTableProps<BoletoDetalhadoModel, BoletoFilters1> {}

export default function Table(props: TableProps) {
  const { filters } = props
  const navigate = useNavigate()

  const { toBrazilianDateTime, toBrazilianDate } = useDates()
  const { changeSort, sort } = useSort()
  const { changePage, changePageSize, pagination } = usePagination()
  const {
    floatOptionsCellStyle,
    dateOptionsCellStyle,
    cpfCnpjOptionsCellStyle,
  } = useTableCustomCell()

  const { useQueryObterBoletos, useObterBoletoPorId } = useBoleto()
  const { obterBoletoPorId, isLoadingObterBoletoPorId } = useObterBoletoPorId()
  const {
    data,
    isLoading: isLoadingObterBoletos,
    isFetching: isFetchingObterBoletos,
    error,
  } = useQueryObterBoletos(formatarFiltros(filters), { pagination, sort })

  const [indexBoletosSelecionados, setIndexBoletosSelecionados] = useState<
    number[]
  >([])

  useEffect(() => {
    setIndexBoletosSelecionados([])
  }, [data?.data])

  const handleClickItem = useCallback(
    async (event: 'edit', data: BoletoDetalhadoModel) => {
      const boletoDetalhado = await obterBoletoPorId(data?.id || '')
      if (boletoDetalhado && event === 'edit') {
        navigate(`/boletos/boleto/${boletoDetalhado?.id}`)
      }
    },
    [obterBoletoPorId, navigate],
  )

  function setCellProps() {
    return {
      style: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
    }
  }

  const dataFormatada = useMemo(() => {
    return data?.data?.map((item) => {
      return {
        ...item,
        dataAlteracao: toBrazilianDateTime(item?.dataAlteracao || ''),
        statusFormatado: {
          statusDesc: item?.statusDesc || '',
          status: item.status,
        },
        dataEmissao: toBrazilianDate(item?.dataEmissao),
        dataVencimento: toBrazilianDate(item?.dataVencimento || ''),
        dataPagamento: toBrazilianDate(item?.dataPagamento || ''),
        pagadorCNPJCPF: formatToCPFOrCNPJ(item?.pagadorCNPJCPF || ''),
        valorTitulo: formatCurrency(item?.valorTitulo),
        valorAbatimento: formatCurrency(item?.valorAbatimento),
        valorAtual: formatCurrency(item?.valorAtual || ''),
        multaValor: formatCurrency(item?.multaValor || ''),
        descontoValor: formatCurrency(item?.descontoValor),
        valorPago: formatCurrency(item?.valorPago || ''),
        valorTarifa: formatCurrency(item?.valorTarifa || ''),
        dataTarifa: toBrazilianDate(item?.dataTarifa || ''),
        dataCancelamento: toBrazilianDate(item?.dataCancelamento || ''),
        dataRegistro: toBrazilianDate(item?.dataRegistro || ''),
        dataMovimento: toBrazilianDate(item?.dataMovimento || ''),
        dataCredito: toBrazilianDate(item?.dataCredito || ''),
        dataAntecipacao: toBrazilianDate(item?.dataAntecipacao || ''),
        dataAntecipacaoCancelamento: toBrazilianDate(
          item?.dataAntecipacaoCancelamento || '',
        ),
      }
    })
  }, [data?.data])

  const columns = useMemo((): MUIDataTableColumnDef[] => {
    return [
      {
        name: 'statusFormatado',
        label: 'Status',
        options: {
          sort: false,
          setCellProps,
          setCellHeaderProps: () => ({
            style: {
              textAlign: 'center',
            },
          }),
          customBodyRender: (value: { statusDesc: string; status: number }) => {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Flag
                  isFlagged={true}
                  type={
                    value
                      ? `${boletoStatusMappingColors[value.status]}`
                      : 'error'
                  }
                />
                <span>{value.statusDesc}</span>
              </Box>
            )
          },
        },
      },
      {
        name: 'dataAlteracao',
        label: 'Alteração',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'convenioBancoCodigoDesc',
        label: 'Banco',
        options: {
          sort: false,
          setCellProps,
        },
      },
      {
        name: 'carteiraCodigo',
        label: 'Carteira',
        options: {
          sort: false,
          setCellProps,
        },
      },
      {
        name: 'nossoNumero',
        label: 'Nosso Número',
        options: {
          setCellProps,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'nossoNumeroSequencial',
        label: 'Sequêncial',
        options: {
          setCellProps,
        },
      },
      {
        name: 'documentoSistema',
        label: 'Documento',
        options: {
          setCellProps,
        },
      },
      {
        name: 'dataEmissao',
        label: 'Emissão',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'dataVencimento',
        label: 'Vencimento',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'dataPagamento',
        label: 'Pagamento',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'pagadorCNPJCPF',
        label: 'Pagador/CNPJ',
        options: {
          setCellProps: cpfCnpjOptionsCellStyle,
        },
      },
      {
        name: 'pagadorNome',
        label: 'Pagador/Nome',
        options: {
          customBodyRender: (value) => {
            return (
              <EllipsisTextOptionsCellStyle
                value={value}
                breakpoint="lg"
                length={25}
              />
            )
          },
        },
      },
      {
        name: 'pagadorCidadeNome',
        label: 'Pagador/Cidade',
      },
      {
        name: 'pagadorUF',
        label: 'UF',
        options: {
          setCellProps,
        },
      },
      {
        name: 'valorTitulo',
        label: 'Valor Título',
        options: {
          setCellProps: CurrencyCellStyle,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'valorAbatimento',
        label: 'Abatimento',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'valorAtual',
        label: 'Valor Atual',
        options: {
          setCellProps: CurrencyCellStyle,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'multaValor',
        label: 'Multa',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'descontoValor',
        label: 'Desconto',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'valorPago',
        label: 'Valor Pago',
        options: {
          setCellProps: CurrencyCellStyle,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'valorTarifa',
        label: 'Tarifa',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'dataTarifa',
        label: 'Tarifa/Data',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'dataCancelamento',
        label: 'Cancelamento',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'dataRegistro',
        label: 'Registro',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'dataMovimento',
        label: 'Movimen.',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'dataCredito',
        label: 'Crédito',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'origemDesc',
        label: 'Origem',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'dataAntecipacao',
        label: 'Antecipação',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'dataAntecipacaoCancelamento',
        label: 'Antecipação Cancel.',
        options: {
          setCellProps: dateOptionsCellStyle,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'id',
        label: `Opções`,
        options: {
          sort: false,
          ...floatOptionsCellStyle(),
          customBodyRender: (value) => {
            const _data = findData<BoletoDetalhadoModel>(
              data?.data || [],
              value,
              'id',
            )

            return (
              <Options
                value={_data}
                displayDeleteButton={false}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ]
  }, [data?.data, handleClickItem])

  const isLoading = isLoadingObterBoletos || isLoadingObterBoletoPorId
  const isFetching = isFetchingObterBoletos || isLoadingObterBoletoPorId

  return (
    <ResponsiveDataTable
      data={dataFormatada || []}
      isLoading={isLoading}
      isFetching={isFetching}
      pagination={data?.pagination}
      changePage={(page) => {
        changePage(page)
        setIndexBoletosSelecionados([])
      }}
      changePageSize={changePageSize}
      columns={columns}
      error={error?.message}
      changeSort={changeSort}
      options={{
        viewColumns: true,
        selectableRows: 'multiple',
        selectToolbarPlacement: 'none',
        rowsSelected: indexBoletosSelecionados,
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
        sort: true,
        selectableRowsHeader: false,
        onRowSelectionChange: (_, __, rowsSelected: any) => {
          return setIndexBoletosSelecionados(rowsSelected)
        },
        isRowSelectable(dataIndex, _) {
          const existeBoletoSelecionado = indexBoletosSelecionados.length > 0
          if (!existeBoletoSelecionado) return true

          const boletoSelecionado = data?.data[indexBoletosSelecionados[0]]
          const convBoletoSelecionado = boletoSelecionado?.convenioBancoCodigo
          const convenioBoleto = data?.data[dataIndex]?.convenioBancoCodigo

          return convBoletoSelecionado !== convenioBoleto ? false : true
        },
        customToolbar: () => (
          <ToolbarHeader
            boletos={data?.data || []}
            indexBoletosSelecionados={indexBoletosSelecionados}
          />
        ),
      }}
    />
  )
}
