import { Grid } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { CurrencyTextField, FormContainer } from '@data-c/ui'
import { isValidNumber } from '@data-c/hooks'

import DropDownTipoDesconto, {
  DropDownTipoDescontoEnum,
} from '@components/Inputs/DropDownTipoDesconto'
import DropDownTipoJuros, {
  DropDownTipoJurosEnum,
} from '@components/Inputs/DropDownTipoJuros'
import DropDownTipoMulta, {
  DropDownTipoMultaEnum,
} from '@components/Inputs/DropDownTipoMulta'
import DatePicker from '@components/DatePicker'

import useBoleto, { AlterarBoletoValoresModel } from '@hooks/queries/useBoleto'
import useValidations from '@hooks/useValidations'

import * as yup from 'yup'

const isTipoDesconto = [
  DropDownTipoDescontoEnum.VALOR_FIXO_ATE_DATA_INFORMADA_1,
  DropDownTipoDescontoEnum.PERCENTUAL_ATE_DATA_INFORMADA_2,
  DropDownTipoDescontoEnum.POR_DIA_ANTECIPACAO_3,
]

const isTipoJuros = [
  DropDownTipoJurosEnum.VALOR_FIXO_1,
  DropDownTipoJurosEnum.PERCENTUAL_2,
]

const isTipoMulta = [
  DropDownTipoMultaEnum.VALOR_FIXO_1,
  DropDownTipoMultaEnum.PERCENTUAL_2,
]

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  valorTitulo: yup.string().required('Informe o Valor do Título'),
  descontoValor: yup.number().when('descontoTipo', ([descontoTipo], schema) => {
    if (isTipoDesconto.includes(descontoTipo)) {
      return schema
        .required('Informe o desconto')
        .moreThan(0, 'O valor do desconto deve ser superior a zero(0)')
        .transform(transform)
    }
    return schema
  }),
  jurosValor: yup
    .number()
    .when('jurosTipo', ([jurosTipo], schema) => {
      if (isTipoJuros.includes(jurosTipo)) {
        return schema
          .required('Informe o juros')
          .moreThan(0, 'O valor do juros deve ser superior a zero(0)')
          .transform(transform)
      }
      return schema
    })
    .nullable(),
  multaValor: yup
    .number()
    .when('multaTipo', ([multaTipo], schema) => {
      if (isTipoMulta.includes(multaTipo)) {
        return schema
          .required('Informe a multa')
          .moreThan(0, 'O valor da multa deve ser superior a zero(0)')
          .transform(transform)
      }
      return schema
    })
    .nullable(),
})

interface FormProps {
  boletoId: string
}

export default function Form({ boletoId }: FormProps) {
  const { setValidationErrors, validationProps } = useValidations()

  const { formValues: data } = useFormData<AlterarBoletoValoresModel>()
  const { changeValue, toggleSubmit, closeForm } =
    useFormApi<AlterarBoletoValoresModel>()

  const { useAlterarBoletoValores } = useBoleto()
  const { mutateAsync: alterarBoletoValores } = useAlterarBoletoValores()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoValores({
          boletoId,
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      dialogProps={{
        title: 'Alterar Identificação do Boleto',
        maxWidth: 'sm',
      }}
      triggerButton={<></>}
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            name="valorTitulo"
            label="Valor Título"
            required
            value={data?.valorTitulo || ''}
            onChange={(_, value) => {
              changeValue('valorTitulo', parseFloat(value) || '')
            }}
            {...validationProps('valorTitulo')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Máxima Desconto"
            name="descontoDataExpiracao"
            value={data?.descontoDataExpiracao || null}
            onChange={(date) => {
              changeValue('descontoDataExpiracao', date || null)
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DropDownTipoDesconto
            name="descontoTipo"
            value={String(data?.descontoTipo) || ''}
            onChange={(value, valueDescription) => {
              if (!isTipoDesconto.includes(Number(value))) {
                changeValue('descontoValor', 0)
              }

              changeValue('descontoTipo', value)
              changeValue('descontoTipoDesc', valueDescription)
            }}
            {...validationProps('descontoTipo')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            title="Percentual de desconto (quando aplicável)"
            name="descontoValor"
            disabled={!isTipoDesconto.includes(data?.descontoTipo)}
            label="Valor Desconto"
            required={isTipoDesconto.includes(data?.descontoTipo)}
            value={data?.descontoValor || ''}
            onChange={(_, value) => {
              changeValue('descontoValor', value || 0)
            }}
            {...validationProps('descontoValor')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DropDownTipoJuros
            name="jurosTipo"
            value={String(data?.jurosTipo) || ''}
            onChange={(value, valueDescription) => {
              if (!isTipoJuros.includes(Number(value))) {
                changeValue('jurosValor', null)
              }

              changeValue('jurosTipo', value)
              changeValue('jurosTipoDesc', valueDescription)
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            name="jurosValor"
            label="Valor Juros"
            disabled={!isTipoJuros.includes(data?.jurosTipo)}
            required={isTipoJuros.includes(data?.jurosTipo)}
            value={data?.jurosValor || ''}
            onChange={(_, value) => changeValue('jurosValor', value || null)}
            {...validationProps('jurosValor')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DropDownTipoMulta
            name="multaTipo"
            value={String(data?.multaTipo) || ''}
            onChange={(value, valueDescription) => {
              if (!isTipoMulta.includes(Number(value))) {
                changeValue('multaValor', null)
              }

              changeValue('multaTipo', value)
              changeValue('multaTipoDesc', valueDescription)
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            name="multaValor"
            label="Valor Multa"
            disabled={!isTipoMulta.includes(data?.multaTipo)}
            required={isTipoMulta.includes(data?.multaTipo)}
            value={data?.multaValor || ''}
            onChange={(_, value) => changeValue('multaValor', value || null)}
            {...validationProps('multaValor')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
