import { useState } from 'react'
import { normalizeText } from '@data-c/hooks'
import useCidades, { CidadeModel, UFModel } from './useCidade'
import api from 'services/api-cep'

function encontrarCidadePorNome(
  cidades: CidadeModel[],
  nomeCidade: string,
): CidadeModel | undefined {
  const normalizedNomeCidade = normalizeText(nomeCidade).toLowerCase()

  return cidades.find(
    (cidade) =>
      normalizeText(cidade.nome).toLowerCase() === normalizedNomeCidade,
  )
}

export interface ApiCepModel {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  ddd: string
}

export interface CepModel {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  cidadeModel?: CidadeModel
  uf?: UFModel
}

export async function consultarCepNaApiViaCep(
  cep: string,
): Promise<ApiCepModel> {
  const response = await api.get<ApiCepModel>(`/${cep}/json`)
  return response.data
}

export default function useConsultaCep() {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const { obterCidades } = useCidades()

  async function _consultarCep(cep: string): Promise<CepModel | undefined> {
    try {
      setLoading(true)

      const data = await consultarCepNaApiViaCep(cep)
      const cidades = await obterCidades('', data.uf)

      const cidade = encontrarCidadePorNome(
        cidades?.data?.data,
        data.localidade,
      )

      return {
        cep,
        logradouro: data?.logradouro,
        bairro: data?.bairro,
        complemento: data?.complemento,
        cidadeModel: cidade,
        uf: cidade?.uf,
      }
    } catch (err) {
      setError('Não foi possível carregar o cep')
    } finally {
      setLoading(false)
    }
  }

  return {
    consultarCep: _consultarCep,
    isLoading,
    error,
  }
}
