import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { RelatorioModel } from 'hooks/queries/usePDF'

type DropDownRelatorioProps = {
  data: RelatorioModel[]
} & TextFieldProps

export default function DropDownRelatorio(props: DropDownRelatorioProps) {
  const { data } = props

  return (
    <TextField select {...props} label="Relatorios">
      {data?.map((relatorio) => {
        return (
          <MenuItem key={relatorio?.id} value={relatorio?.id}>
            {relatorio?.nome}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
