import {
  PendingActionsOutlined as PendingActionsOutlinedIcon,
  SwapHorizOutlined as SwapHorizOutlinedIcon,
} from '@mui/icons-material'
import { useBoletoContext } from 'contexts/BoletoContext/BoletoContext'

import OptionsListContainer from '@components/OptionsListContainer'
import OptionListButton from '@components/OptionsListContainer/components/OptionListButton'

import BoletoLogs from './components/BoletoLogs'
import BoletoMovimentacoes from './components/BoletoMovimentacoes'

export enum OrigemType {
  VISUALIZAR_LOGS = 'VISUALIZAR_LOGS',
  VISUALIZAR_MOVIMENTACOES = 'VISUALIZAR_MOVIMENTACOES',
}

export default function ActionsOptions() {
  const { boleto } = useBoletoContext()

  return (
    <>
      <OptionsListContainer
        buttonOptionsProps={{ variant: 'contained' }}
        triggerLabel="Ocorrência"
      >
        <OptionListButton
          name={OrigemType.VISUALIZAR_LOGS}
          label="Visualizar Logs"
          icon={<PendingActionsOutlinedIcon />}
        />
        <OptionListButton
          name={OrigemType.VISUALIZAR_MOVIMENTACOES}
          label="Visualizar Movimentações"
          icon={<SwapHorizOutlinedIcon />}
        />
      </OptionsListContainer>

      <BoletoLogs boleto={boleto} />
      <BoletoMovimentacoes boleto={boleto} />
    </>
  )
}
