import { Grid, TextField } from '@mui/material'
import { FormProvider } from '@data-c/providers'

import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import CnpjCpfTextField from '@components/CnpjCpfTextField'
import CardDynamic from '@components/CardDynamic'

import {
  AlterarBoletoBeneficiarioModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import { PessoaCadastroModel } from '@hooks/queries/usePessoaCadastro'

import Form from './components/Form'

interface BoletoBeneficiarioProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoBeneficiario({
  boleto,
}: BoletoBeneficiarioProps) {
  const {
    id,
    beneficiarioFinalNome,
    beneficiarioFinalCNPJCPF,
    status,
    statusDesc,
  } = boleto || {}

  const dataBeneficiario: AlterarBoletoBeneficiarioModel = {
    beneficiarioCNPJCPF: beneficiarioFinalCNPJCPF,
    beneficiarioNome: beneficiarioFinalNome,
    pessoa: {
      nome: beneficiarioFinalNome,
    } as PessoaCadastroModel,
  }

  const isBoletoIncluido = status === DropDownBoletoStatusEnum.INCLUIDO_0

  return (
    <FormProvider>
      <Form boletoId={id || ''} />
      <CardDynamic
        title="Beneficiário"
        data={dataBeneficiario}
        disabledAllChildren
        disableButton={!isBoletoIncluido}
        tooltipTitle={`Não é possível editar o Beneficiário de um boleto ${
          statusDesc || 'que não esteja incluído'
        }`}
        initialOpacity
        gridContainer
      >
        <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
          <TextField label="Beneficiário" value={beneficiarioFinalNome || ''} />
        </Grid>
        <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
          <CnpjCpfTextField
            inputProps={{ label: 'CNPJ/CPF' }}
            value={beneficiarioFinalCNPJCPF || ''}
          />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
