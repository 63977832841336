import { useState } from 'react'
import { AxiosError } from 'axios'
import {
  useMutation,
  useQuery as useTQuery,
  useQueryClient,
} from '@tanstack/react-query'

import useNotification from '@hooks/useNotifications'
import geradorUuid from '@hooks/useGeradorUuid'

import QueryResponseInterface from 'interfaces/QueryResponseInterface'
import QueryObjResponseInterface from 'interfaces/QueryObjResponseInterface'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'
import ErrorInterface from 'interfaces/ErrorInterface'

import api from '@services/api'

export type RegraEnvioOperacao = 'add' | 'subtract' | 'now'

export interface RegraEnvioInterface {
  liquidado: boolean
  operacao: RegraEnvioOperacao
  campo: 'vencimento' | 'pagamento' | 'registro'
}

export const tipoDataMapping: { [key: string]: number } = {
  vencimento: 0,
  pagamento: 1,
  registro: 2,
}

export const tipoDataReverseMapping: {
  [key: number]: 'vencimento' | 'pagamento' | 'registro'
} = {
  0: 'vencimento',
  1: 'pagamento',
  2: 'registro',
}

export interface TemplateEmailInterface {
  id?: string
  nome: string
  identificador: string
  tituloEmail?: string
  corpoEmail?: string
  designEmail?: any
  regraEnvio?: RegraEnvioInterface
  horaEnvio?: number
  dias?: number
  envioAutomatico?: boolean
  tipoEnvio?: 'automatico' | 'manual' | null
  template_layout_id?: number
  tipoData?: number
  quantidadeDias?: number
  somenteBoletosLiquidados?: boolean
  ativo: boolean
  anexarPDF?: boolean
}

export interface TemplateEmailInterfacePartials {
  id: string
  nome: string
  identificador: string
  tituloEmail: string
  ativo: boolean
  envioAutomatico: boolean
}

export interface AlterarEmailAgendamentoCorpoModel {
  tituloEmail?: string | null
  corpoEmail?: string | null
  designEmail?: string | null
}

export interface AdicionarEmailAgendamentoModel {
  id?: string
  nome?: string | null
  identificador?: string | null
}

export async function obterTemplates(
  params?: QueryParamsInterface,
): Promise<QueryResponseInterface<TemplateEmailInterface>> {
  const response = await api.get(
    `EmailAgendamento/Pesquisar/${params?.query}`,
    {
      headers: {
        'DC-Ordenation-Fields': `identificador asc`,
      },
    },
  )

  return response.data
}

export function useObterTemplatePorId() {
  const [isLoading, setLoading] = useState(false)
  const notifications = useNotification()

  async function obterTemplatePorId(
    templateId: string,
  ): Promise<TemplateEmailInterface | null> {
    try {
      setLoading(true)
      const response = await api.get('EmailAgendamento/ObterPorId/', {
        params: { id: templateId },
      })
      if (response?.data?.data) {
        return response.data.data
      }
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }

    return null
  }

  return { obterTemplatePorId, isLoadingObterTemplatePorId: isLoading }
}

export async function inserirTemplate(
  data: AdicionarEmailAgendamentoModel,
): Promise<TemplateEmailInterface> {
  const response = await api.post('EmailAgendamento/Adicionar', data)
  return response.data.data
}

export async function atualizarTemplate(
  data: TemplateEmailInterface,
): Promise<TemplateEmailInterface> {
  delete data.regraEnvio

  const response = await api.put('EmailAgendamento/Alterar', data, {
    params: { id: data.id },
  })
  return response.data
}

export async function alterarEmailAgendamentoCorpo(
  agendamentoId: string,
  data: AlterarEmailAgendamentoCorpoModel,
): Promise<TemplateEmailInterface> {
  const response = await api.put('EmailAgendamento/AlterarCorpo', data, {
    params: { id: agendamentoId },
  })
  return response?.data
}

export async function alterarEmailAgendamentoStatus(
  agendamentoId: string,
  ativo: boolean,
): Promise<QueryObjResponseInterface<TemplateEmailInterface>> {
  const response = await api.put(
    'EmailAgendamento/Ativar',
    {},
    {
      params: { id: agendamentoId, ativo },
    },
  )
  return response?.data
}

export async function salvarTemplate(
  data: TemplateEmailInterface,
): Promise<TemplateEmailInterface> {
  if (data.id) {
    return atualizarTemplate(data)
  }

  const { identificador, nome } = data
  const newTemplateId = geradorUuid()
  return inserirTemplate({ id: newTemplateId, identificador, nome })
}

export async function deletarTemplate(
  data: TemplateEmailInterface,
): Promise<TemplateEmailInterface> {
  const { id } = data
  await api.delete('EmailAgendamento/Remover', { params: { id } })
  return data
}

export function useQuery(params: QueryParamsInterface) {
  return useTQuery<QueryResponseInterface<TemplateEmailInterface>>(
    ['TEMPLATE_EMAIL', params],
    () => {
      return obterTemplates(params)
    },
    {
      staleTime: 0,
    },
  )
}

export function useSubmit() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, TemplateEmailInterface>(salvarTemplate, {
    onSuccess(_, data) {
      const flexPluralSingular = data.id ? 'alterado' : 'incluído'
      notifications.notifySuccess(
        `Agendamento ${flexPluralSingular} com sucesso`,
      )
      queryClient.invalidateQueries(['TEMPLATE_EMAIL'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarEmailAgendamentoCorpo() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    TemplateEmailInterface,
    AxiosError<ErrorInterface>,
    {
      agendamentoId: string
      data: AlterarEmailAgendamentoCorpoModel
    }
  >(
    ({ agendamentoId, data }) =>
      alterarEmailAgendamentoCorpo(agendamentoId, data),
    {
      onSuccess() {
        notifications.notifySuccess('Agendamento alterado com sucesso!')
        queryClient.invalidateQueries(['TEMPLATE_EMAIL'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useAlterarEmailAgendamentoStatus() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    QueryObjResponseInterface<TemplateEmailInterface>,
    AxiosError<ErrorInterface>,
    {
      agendamentoId: string
      ativo: boolean
    }
  >(
    ({ agendamentoId, ativo }) =>
      alterarEmailAgendamentoStatus(agendamentoId, ativo),
    {
      onSuccess(data) {
        const message = data?.data?.ativo ? 'Ativado' : 'Desativado'
        notifications.notifySuccess(
          `Agendamento ${message} com sucesso!`,
          'not-repeat-status-notification',
        )
        queryClient.invalidateQueries(['TEMPLATE_EMAIL'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDelete() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError, TemplateEmailInterface>(deletarTemplate, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['TEMPLATE_EMAIL'])
      notifications.notifySuccess('Agendamento excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export default function useTemplateEmail() {
  return {
    useQuery,
    useSubmit,
    useAlterarEmailAgendamentoCorpo,
    useAlterarEmailAgendamentoStatus,
    useDelete,
    // useQueryObterTemplatePorId,
    useObterTemplatePorId,
  }
}
