import { ReactNode } from 'react'
import { Box, BoxProps, Typography, useTheme } from '@mui/material'

interface CardInfoProps extends BoxProps {
  title: string
  subtitle?: string | number | null
  isLink?: boolean
  onClick?: () => void
  children?: ReactNode
  direction?: 'vertical' | 'horizontal'
}

export default function CardInfo(props: CardInfoProps) {
  const {
    title,
    subtitle,
    isLink = false,
    children,
    onClick,
    direction = 'vertical',
    alignItems,
    ...rest
  } = props

  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: direction === 'horizontal' ? 'row' : 'column',
        alignItems: direction === 'horizontal' ? 'center' : alignItems,
        justifyContent: 'space-between',
        padding: 1,
        transition: 'all ease-in-out 100ms',
        ':hover': {
          backgroundColor: 'rgba(89, 195, 224, 10%);',
          borderRadius: '4px',
        },
      }}
      {...rest}
    >
      <Typography
        marginBottom={direction === 'vertical' ? 0.5 : 0}
        variant="body2"
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textTransform: 'uppercase',
          color: isLink ? theme.palette.primary.dark : theme.palette.grey[600],
          ':hover': { cursor: isLink ? 'pointer' : 'default' },
          fontWeight: isLink ? 'normal' : 'bold',
          wordWrap: 'break-word',
        }}
        onClick={onClick}
      >
        {subtitle ? subtitle : ''}
      </Typography>
      {children}
    </Box>
  )
}
