import { Stack, Typography, useTheme } from '@mui/material'
import notifyImage from 'assets/images/notify.svg'

interface EmptyDataProps {
  description: string
}

export default function EmptyData(props: EmptyDataProps) {
  const { description } = props

  const theme = useTheme()

  return (
    <Stack
      gap={3}
      sx={{
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingX: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <img src={notifyImage} alt="Imagem de notificação" width="20%" />

      <Typography variant="h4" component="h2" fontWeight="medium">
        {description}
      </Typography>
    </Stack>
  )
}
