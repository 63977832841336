import DynamicMenuItem from '@components/DynamicMenuItem'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownTipoJurosEnum {
  ISENTO_0 = 0,
  VALOR_FIXO_1 = 1,
  PERCENTUAL_2 = 2,
  DEFINIDO_PELO_CONVENIO_99 = 99,
}

const menuItems = [
  {
    value: DropDownTipoJurosEnum.ISENTO_0,
    label: '00 - Isento',
  },
  {
    value: DropDownTipoJurosEnum.VALOR_FIXO_1,
    label: '01 - Valor fixo',
  },
  {
    value: DropDownTipoJurosEnum.PERCENTUAL_2,
    label: '02 - Percentual',
  },
  {
    value: DropDownTipoJurosEnum.DEFINIDO_PELO_CONVENIO_99,
    label: '99 - Definido pelo convênio',
  },
]

export default function DropDownTipoJuros(props: DynamicDropDownProps) {
  const { onChange, disabled, optionToDisabled, tooltipTitle, ...rest } = props

  return (
    <DynamicDropDown
      label="Tipo de Juros"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <DynamicMenuItem
          key={item.value}
          value={item.value}
          disabled={optionToDisabled?.includes(item.value) || disabled}
          tooltipTitle={tooltipTitle}
        >
          {item.label}
        </DynamicMenuItem>
      ))}
    </DynamicDropDown>
  )
}
