import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownIdentificacaoEmissorBoletosEnum {
  BANCO_EMITE_1 = 1,
  CLIENTE_EMITE_2 = 2,
}

const menuItems = [
  {
    value: DropDownIdentificacaoEmissorBoletosEnum.BANCO_EMITE_1,
    label: '1 - Banco emite',
  },
  {
    value: DropDownIdentificacaoEmissorBoletosEnum.CLIENTE_EMITE_2,
    label: '2 - Cliente emite',
  },
]

export default function DropDownIdentificacaoEmissorBoletos(
  props: DynamicDropDownProps,
) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="Identificação Emissor de Boletos"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
