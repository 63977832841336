import { ReactNode } from 'react'

import { Box, ButtonProps, LinearProgress, Popover } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'

import { Button } from '@data-c/ui'

import { useActionOptionsContext } from 'contexts/ActionOptionsContext/ActionOptionsContext'
import CustomTooltip from '@components/CustomTooltip'

interface OptionsListContainerProps {
  triggerLabel?: string
  buttonOptionsProps?: ButtonProps
  tooltipTitle?: string
  children: ReactNode
}

export default function OptionsListContainer(props: OptionsListContainerProps) {
  const {
    triggerLabel,
    children,
    buttonOptionsProps,
    tooltipTitle = '',
  } = props

  const {
    isLoading,
    anchorEl,
    isOpenOptionsListContainer,
    openOptionsListContainer,
    closeOptionsListContainer,
  } = useActionOptionsContext()

  return (
    <>
      <CustomTooltip
        title={tooltipTitle}
        disableHoverListener={!buttonOptionsProps?.disabled}
      >
        <Button
          onClick={openOptionsListContainer}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{ '&.MuiButtonBase-root': { marginRight: 'auto' } }}
          {...buttonOptionsProps}
        >
          {triggerLabel ? triggerLabel : 'Opções'}
        </Button>
      </CustomTooltip>

      <Popover
        anchorEl={anchorEl}
        open={isOpenOptionsListContainer}
        onClose={closeOptionsListContainer}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ marginTop: 0.5 }}
      >
        <Box>{isLoading && <LinearProgress />}</Box>
        {children}
      </Popover>
    </>
  )
}
