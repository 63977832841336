import { useMemo, useCallback } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatToCPFOrCNPJ, formatToPhone } from 'brazilian-values'
import { Stack } from '@mui/material'

import { DataTable, DataTableOptions as Options, Flag } from '@data-c/ui'
import {
  TransportableDataTableProps,
  findData,
  usePagination,
} from '@data-c/hooks'

import EllipsisTextOptionsCellStyle from '@components/EllipsisTextOptionsCellStyle'

import usePessoaCadastro, {
  PessoaCadastroModel,
} from 'hooks/queries/usePessoaCadastro'
import useTableCustomCell from '@hooks/useTableCustomCell'

import DataTableProps from 'interfaces/DataTableProps'
import Delete from 'components/Delete'

interface TableProps
  extends DataTableProps<PessoaCadastroModel, PessoaCadastroModel>,
    TransportableDataTableProps<PessoaCadastroModel> {}

export default function Table(props: TableProps) {
  const { onTransport, query } = props

  const { changePage, changePageSize, pagination } = usePagination()
  const { cpfCnpjOptionsCellStyle, floatOptionsCellStyle } =
    useTableCustomCell()

  const { useQueryObterPessoasCadastro, useRemoverPessoaCadastro } =
    usePessoaCadastro()
  const { mutateAsync: removerPessoaCadastro } = useRemoverPessoaCadastro()
  const { data, isLoading, isFetching, error } = useQueryObterPessoasCadastro({
    query: query || '',
    pagination,
  })

  const dataFormatada = useMemo(
    () =>
      data?.data?.map((item) => {
        return {
          ...item,
          documentoId: formatToCPFOrCNPJ(item?.documentoId || ''),
          telefone: formatToPhone(item?.telefone || ''),
          inscEstadual:
            item?.inscEstadualId === 2 ? 'ISENTO' : item?.inscEstadual,
        }
      }),
    [data?.data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: PessoaCadastroModel) => {
      switch (event) {
        // case 'edit':
        //   openForm(data)
        //   break
        // case 'delete':
        //   openConfirm(data)
        //   break
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [onTransport],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'documentoId',
        label: 'CNPJ/CPF',
        options: {
          setCellProps: cpfCnpjOptionsCellStyle,
        },
      },
      {
        name: 'nome',
        label: 'Nome',
        options: {
          customBodyRender: (value) => {
            return (
              <EllipsisTextOptionsCellStyle
                value={value}
                breakpoint="lg"
                length={25}
              />
            )
          },
        },
      },
      {
        name: 'nomeFantasia',
        label: 'Nome Fantasia',
        options: {
          customBodyRender: (value) => {
            return (
              <EllipsisTextOptionsCellStyle
                value={value}
                breakpoint="lg"
                length={25}
              />
            )
          },
        },
      },
      {
        name: 'cidadeNome',
        label: 'Cidade',
        options: {
          customBodyRender: (value) => {
            return (
              <EllipsisTextOptionsCellStyle
                value={value}
                breakpoint="lg"
                length={25}
              />
            )
          },
        },
      },
      {
        name: 'cidadeUF',
        label: 'UF',
        options: {
          setCellProps: () => ({
            style: {
              width: '42px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'telefone',
        label: 'Telefone',
        options: {
          setCellProps: () => ({
            style: {
              textAlign: 'center',
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'inscEstadual',
        label: 'Insc. Estadual',
        options: {
          setCellProps: () => ({
            style: {
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'email',
        label: 'E-mail',
        options: {
          setCellProps: () => ({
            style: { whiteSpace: 'nowrap' },
          }),
        },
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '56px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return (
              <Flag
                isFlagged={true}
                type={value === true ? 'success' : 'error'}
              />
            )
          },
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          ...floatOptionsCellStyle(),
          customBodyRender: (value) => {
            const _data = findData<PessoaCadastroModel>(
              data?.data || [],
              value,
              'id',
            )

            return (
              <Options
                displayTransporterButton={true}
                displayDeleteButton={false}
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem],
  )

  return (
    <Stack position="relative">
      <DataTable
        error={error?.message}
        columns={columns}
        data={dataFormatada || []}
        pagination={data?.pagination}
        isLoading={isLoading}
        isFetching={isFetching}
        changePage={changePage}
        changePageSize={changePageSize}
        options={{
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
        }}
      />

      <Delete onDelete={removerPessoaCadastro} />
    </Stack>
  )
}
