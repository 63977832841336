import { useState } from 'react'
import { Container } from '@mui/material'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import { useAmbienteContext } from 'contexts/AmbienteContext/AmbienteContext'
import useConvenio, {
  ConvenioFilters1,
  convenioFilters,
} from '@hooks/queries/useConvenio'
import Delete from '@components/Delete'
import AbsoluteTableContainer from '@components/AbsoluteTableContainer'

import Filter from './components/Filter'
import Table from './components/Table'
import Form from './components/Form'

export default function Convenios() {
  const { empresa } = useAmbienteContext()
  const { useRemoverConvenio } = useConvenio()
  const { mutateAsync: removerConvenio } = useRemoverConvenio()
  const [appliedFilters, setFilters] = useState<ConvenioFilters1>(
    {} as ConvenioFilters1,
  )

  return (
    <Container>
      <FormProvider>
        <PageTitle title="Convênios">
          <ButtonContainer>
            <FilterProvider filterValues={convenioFilters}>
              <Filter
                onApplyFilters={(_appliedFilters) => {
                  setFilters(_appliedFilters)
                }}
              />
            </FilterProvider>
            <Form empresa={empresa} />
          </ButtonContainer>
        </PageTitle>
        <AbsoluteTableContainer>
          <Table filters={appliedFilters} />
        </AbsoluteTableContainer>
        <Delete onDelete={removerConvenio} />
      </FormProvider>
    </Container>
  )
}
