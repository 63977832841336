import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownTipoProtestoEnum {
  INDEFINIDO_0 = 0,
  PROTESTAR_DIAS_CORRIDOS_1 = 1,
  PROTESTAR_DIAS_UTEIS_2 = 2,
  NÃO_PROTESTAR_3 = 3,
  PROTESTAR_FIM_ALIMENTAR_DIAS_UTEIS_4 = 4,
  PROTESTAR_FIM_ALIMENTAR_DIAS_CORRIDOS_5 = 5,
  NEGATIVACAO_SEM_PROTESTO_8 = 8,
  CANCELAMENTO_PROTESTO_AUTOMÁTICO_9 = 9, //SOMENTE VÁLIDO P/ CÓDIGO MOVIMENTO REMESSA = 31
  DEFINIDO_PELO_CONVENIO_99 = 99,
}

const menuItems = [
  {
    value: DropDownTipoProtestoEnum.INDEFINIDO_0,
    label: '00 - Indefinido',
  },
  {
    value: DropDownTipoProtestoEnum.PROTESTAR_DIAS_CORRIDOS_1,
    label: '01 - Protesto por dias corridos',
  },
  {
    value: DropDownTipoProtestoEnum.PROTESTAR_DIAS_UTEIS_2,
    label: '02 - Protesto por dias úteis',
  },
  {
    value: DropDownTipoProtestoEnum.NÃO_PROTESTAR_3,
    label: '03 - Não protestar',
  },
  {
    value: DropDownTipoProtestoEnum.PROTESTAR_FIM_ALIMENTAR_DIAS_UTEIS_4,
    label: '04 - Protestar fim alimentar dias úteis',
  },
  {
    value: DropDownTipoProtestoEnum.PROTESTAR_FIM_ALIMENTAR_DIAS_CORRIDOS_5,
    label: '05 - Protestar fim alimentar dias corridos',
  },
  {
    value: DropDownTipoProtestoEnum.NEGATIVACAO_SEM_PROTESTO_8,
    label: '08 - Negativação sem protesto',
  },
  {
    value: DropDownTipoProtestoEnum.CANCELAMENTO_PROTESTO_AUTOMÁTICO_9,
    label: '09 - Cancelamento de protesto automático',
  },
  {
    value: DropDownTipoProtestoEnum.DEFINIDO_PELO_CONVENIO_99,
    label: '99 - Definido pelo convênio',
  },
]

export default function DropDownTipoProtesto(props: DynamicDropDownProps) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="Tipo de Protesto"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
