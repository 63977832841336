import { Box, BoxProps } from '@mui/material'

interface DialogContentProps extends BoxProps {
  children: React.ReactNode
}

const DialogContent = (props: DialogContentProps) => {
  const { children, ...rest } = props

  return (
    <Box sx={{ padding: `8px 8px 8px` }} {...rest}>
      {children}
    </Box>
  )
}

export default DialogContent
