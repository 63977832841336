const limitNumberMapping: Record<string, number> = {
  'DEFAULT = 999': 999.99,
  'PORCENTAGEM = 100': 100,
}

function limitNumberValue(
  value: string,
  limitNumber?: 'DEFAULT = 999' | 'PORCENTAGEM = 100' | number,
) {
  limitNumber = limitNumber ?? 'DEFAULT = 999'

  function mathMin(_value: string, _limitNumber: number) {
    return Math.min(parseFloat(_value), _limitNumber)
  }

  let valueLimited

  switch (limitNumber) {
    case 'DEFAULT = 999':
      const isDefault = limitNumberMapping[limitNumber]
      valueLimited = mathMin(value, isDefault)
      break
    case 'PORCENTAGEM = 100':
      const isPorcentagem = limitNumberMapping[limitNumber]
      valueLimited = mathMin(value, isPorcentagem)
      break
    default:
      valueLimited = mathMin(value, limitNumber || 0)
      break
  }

  return valueLimited
}

export default function useNumberManipulation() {
  return {
    limitNumberValue,
  }
}
