import { FormProvider } from '@data-c/providers'
import OptionList from '@components/OptionsListContainer/components/OptionList'
import {
  AlterarBoletoAbatimentoParams,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'
import { OrigemType } from '../..'

interface AbatimentoProps {
  boleto: BoletoDetalhadoModel | null
}

export default function Abatimento({ boleto }: AbatimentoProps) {
  const { id, valorAbatimento } = boleto || {}

  const dataAlterarAbatimento: AlterarBoletoAbatimentoParams = {
    boletoId: id || '',
    abatimento: valorAbatimento || 0,
  }

  return (
    <FormProvider>
      <OptionList
        name={OrigemType.ABATIMENTO}
        noRequest={{ initialData: dataAlterarAbatimento }}
        form={<Form />}
      />
    </FormProvider>
  )
}
