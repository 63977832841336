import { DataTable, DataTableProps } from '@data-c/ui'
import { useMediaQuery, useTheme } from '@mui/material'

export default function ResponsiveDataTable(props: DataTableProps) {
  const { options, ...rest } = props

  const theme = useTheme()
  const isUPXL = useMediaQuery(theme.breakpoints.up('xl'))

  let height = '70vh'

  if (isUPXL) {
    height = Boolean(rest?.pagination) ? '75vh' : 'calc(75vh + 65px)'
  }

  if (!isUPXL) {
    height = Boolean(rest?.pagination) ? '70vh' : 'calc(70vh + 65px)'
  }

  return (
    <DataTable
      options={{
        fixedHeader: true,
        fixedSelectColumn: false,
        tableBodyMaxHeight: height,
        ...options,
      }}
      {...rest}
    />
  )
}
