import { useEffect, useState } from 'react'
import { isCPFOrCNPJ } from 'brazilian-values'
import { Grid } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { FormContainer } from '@data-c/ui'
import { onlyNumbers } from '@data-c/hooks'

import PessoaCadastroTransporter from '@components/Transporters/PessoaCadastroTransporter'
import CnpjCpfTextField from '@components/CnpjCpfTextField'

import { PessoaCadastroModel } from '@hooks/queries/usePessoaCadastro'
import useBoleto, {
  AlterarBoletoBeneficiarioModel,
} from '@hooks/queries/useBoleto'
import useValidations from 'hooks/useValidations'

import * as yup from 'yup'

const schema = yup.object().shape({
  beneficiarioCNPJCPF: yup
    .string()
    .min(11, 'Informe 11 caracteres para CPF ou 14 para CNPJ')
    .test(
      'CPF/CNPJ-Validator',
      'Informe um CNPJ/CPF válido',
      (cnpjcpf: any) => {
        return !cnpjcpf ? true : isCPFOrCNPJ(cnpjcpf)
      },
    )
    .nullable(),
})

interface FormProps {
  boletoId: string
}

export default function Form({ boletoId }: FormProps) {
  const { setValidationErrors, validationProps } = useValidations()

  const { formValues: data, isOpen } =
    useFormData<AlterarBoletoBeneficiarioModel>()
  const { changeValue, toggleSubmit, closeForm, changeValues } =
    useFormApi<AlterarBoletoBeneficiarioModel>()

  const { useAlterarBoletoBeneficiario } = useBoleto()
  const { mutateAsync: alterarBoletoBeneficiario } =
    useAlterarBoletoBeneficiario()

  const [podeBuscar, setPodeBuscar] = useState(true)

  useEffect(() => {
    setPodeBuscar(false)
  }, [isOpen])

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoBeneficiario({
          boletoId,
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      dialogProps={{
        title: 'Alterar Beneficiário do Boleto',
        maxWidth: 'md',
      }}
      triggerButton={<></>}
    >
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <CnpjCpfTextField
            name="beneficiarioCNPJCPF"
            value={data?.beneficiarioCNPJCPF || ''}
            inputProps={{ label: 'CPF/CNPJ' }}
            podePesquisarCnpj={podeBuscar}
            onChange={(e) => {
              setPodeBuscar(true)
              changeValue(
                'beneficiarioCNPJCPF',
                onlyNumbers(e.target.value) || null,
              )
            }}
            onFindCnpj={(cnpj) => {
              if (cnpj) {
                const { nome } = cnpj

                changeValues({
                  ...data,
                  beneficiarioNome: nome,
                  pessoa: {
                    nome,
                  } as PessoaCadastroModel,
                })
              }
            }}
            {...validationProps('beneficiarioCNPJCPF')}
          />
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <PessoaCadastroTransporter
            label="Beneficiário"
            hideBackdrop
            value={data?.pessoa || null}
            onChange={(value) => {
              changeValues({
                ...data,
                pessoa: value || null,
                beneficiarioNome: value?.nome || null,
                beneficiarioCNPJCPF: value?.documentoId || null,
              })
            }}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
