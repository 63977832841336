import { ChangeEvent, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@mui/material'

type InscricaoEstadualTextFieldProps = {
  name?: string
  label?: string
  value: string | null
  helperText?: string
  error?: boolean
  required?: boolean
  isentoIE?: boolean
  onChange: (value: string) => void
}

export default function InscricaoEstadualTextField(
  props: InscricaoEstadualTextFieldProps,
) {
  const {
    name,
    label,
    value: _value,
    helperText,
    error,
    onChange,
    required,
    isentoIE,
  } = props

  const [isIsento, setIsento] = useState(false)
  const [value, setValue] = useState<string | null>('')

  useEffect(() => {
    if (isentoIE) {
      setIsento(true)
      setValue('ISENTO')
    } else {
      setIsento(false)
      setValue(_value)
    }
  }, [_value, isentoIE])

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const inscricaoEstadual = event.target.value
    setValue(inscricaoEstadual)
    onChange(inscricaoEstadual)
  }

  function handleClickCheckbox() {
    setIsento(!isIsento)

    if (!isIsento) {
      setValue('ISENTO')
      onChange('ISENTO')
    }

    if (isIsento) {
      setValue('')
      onChange('')
    }
  }

  return (
    <TextField
      name={name}
      label={label}
      required={required}
      value={value}
      disabled={isIsento}
      onChange={handleChange}
      helperText={helperText}
      error={error}
      inputProps={{ maxLength: 20 }}
      sx={{
        '.css-1dmta29-MuiInputBase-root-MuiOutlinedInput-root': {
          padding: 0,
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box>
              <FormControlLabel
                label="Isento"
                control={
                  <Checkbox
                    name="ativo"
                    onClick={handleClickCheckbox}
                    checked={isIsento}
                  />
                }
              />
            </Box>
          </InputAdornment>
        ),
      }}
    />
  )
}
