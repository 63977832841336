import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { isCPFOrCNPJ } from 'brazilian-values'
import { Grid, TextField } from '@mui/material'

import { isValidNumber, onlyNumbers } from '@data-c/hooks'
import { CurrencyTextField, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import ConvenioTransporter from '@components/Transporters/ConvenioTransporter'
import DatePicker from '@components/DatePicker'
import PessoaCadastroTransporter from '@components/Transporters/PessoaCadastroTransporter'
import CnpjCpfTextField from '@components/CnpjCpfTextField'
import AutoCompleteUF from '@components/AutoCompletes/AutoCompleteUF'
import AutoCompleteCidade from '@components/AutoCompletes/AutoCompleteCidade'
import CEPTextField from '@components/CEPTextField'
import TelefoneTextField from '@components/TelefoneTextField'
import DropDownTipoDesconto, {
  DropDownTipoDescontoEnum,
} from '@components/Inputs/DropDownTipoDesconto'
import DropDownTipoJuros, {
  DropDownTipoJurosEnum,
} from '@components/Inputs/DropDownTipoJuros'
import DropDownTipoMulta, {
  DropDownTipoMultaEnum,
} from '@components/Inputs/DropDownTipoMulta'
import CardDynamic from '@components/CardDynamic'

import { CidadeModel, UFModel } from '@hooks/queries/useCidade'
import { PessoaCadastroModel } from '@hooks/queries/usePessoaCadastro'
import { getCurrentDate } from '@hooks/useDates'
import useBoleto, {
  BoletoDetalhadoModel,
  BoletoInclusaoModel,
} from '@hooks/queries/useBoleto'
import useValidations from '@hooks/useValidations'
import geradorUuid from '@hooks/useGeradorUuid'
import useNumberManipulation from '@hooks/useNumberManipulation'

import Actions from './components/Actions'

import moment from 'moment'
import * as yup from 'yup'

const isTipoDesconto = [
  DropDownTipoDescontoEnum.VALOR_FIXO_ATE_DATA_INFORMADA_1,
  DropDownTipoDescontoEnum.PERCENTUAL_ATE_DATA_INFORMADA_2,
  DropDownTipoDescontoEnum.POR_DIA_ANTECIPACAO_3,
]

const isTipoJuros = [
  DropDownTipoJurosEnum.VALOR_FIXO_1,
  DropDownTipoJurosEnum.PERCENTUAL_2,
]

const isTipoMulta = [
  DropDownTipoMultaEnum.VALOR_FIXO_1,
  DropDownTipoMultaEnum.PERCENTUAL_2,
]

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  dataEmissao: yup
    .date()
    .typeError('Data Inválida')
    .test(
      'dataVencimento',
      'A data de emissão não pode ser superior a data atual',
      (dataEmissao) => {
        const dataAtual = getCurrentDate().format('yyyy-MM-DD')
        return moment(dataEmissao).isSameOrBefore(dataAtual)
      },
    ),
  dataVencimento: yup
    .date()
    .typeError('Data Inválida')
    .test(
      'dataVencimento',
      'A data de vencimento não pode ser inferior a data atual',
      (dataVencimento) => {
        const dataAtual = getCurrentDate().format('yyyy-MM-DD')
        return moment(dataVencimento).isSameOrAfter(dataAtual)
      },
    ),
  convenioId: yup.string().required('Informe o convênio'),
  valorTitulo: yup.string().required('Informe o Valor do Título'),
  pagador: yup.object().shape({
    cnpjcpf: yup
      .string()
      .nullable()
      .required('Informe um CNPJ/CPF')
      .min(11, 'Informe 11 caracteres para CPF ou 14 para CNPJ')
      .test(
        'CPF/CNPJ-Validator',
        'Informe um CNPJ/CPF válido',
        (cnpjcpf: any) => {
          return isCPFOrCNPJ(cnpjcpf)
        },
      ),
    cep: yup
      .string()
      .required('Informe o cep')
      .length(8, 'O CEP deve ser composto por 8 caracteres')
      .nullable(),
    logradouro: yup.string().required('Informe o logradouro').nullable(),
    bairro: yup.string().required('Informe o bairro').nullable(),
    cidadeNome: yup.string().required('Informe o nome da cidade').nullable(),
    uf: yup.string().required('Informe o UF').nullable(),
    nome: yup.string().required('Informe o Pagador').nullable(),
  }),
  descontoValor: yup.number().when('descontoTipo', ([descontoTipo], schema) => {
    if (isTipoDesconto.includes(descontoTipo)) {
      return schema
        .required('Informe o desconto')
        .moreThan(0, 'O valor do desconto deve ser superior a zero(0)')
        .transform(transform)
    }
    return schema
  }),
  jurosValor: yup
    .number()
    .when('jurosTipo', ([jurosTipo], schema) => {
      if (isTipoJuros.includes(jurosTipo)) {
        return schema
          .required('Informe o juros')
          .moreThan(0, 'O valor do juros deve ser superior a zero(0)')
          .transform(transform)
      }
      return schema
    })
    .nullable(),
  multaValor: yup
    .number()
    .when('multaTipo', ([multaTipo], schema) => {
      if (isTipoMulta.includes(multaTipo)) {
        return schema
          .required('Informe a multa')
          .moreThan(0, 'O valor da multa deve ser superior a zero(0)')
          .transform(transform)
      }
      return schema
    })
    .nullable(),
})

export default function Form() {
  const { setValidationErrors, validationProps } = useValidations()
  const { limitNumberValue } = useNumberManipulation()
  const navigate = useNavigate()

  const { changeValue, toggleSubmit, changeValues } =
    useFormApi<BoletoInclusaoModel>()
  const { formValues: data, isOpen } = useFormData<BoletoInclusaoModel>()

  const { useIncluirBoleto, useRegistrarBoleto } = useBoleto()
  const { mutateAsync: incluirBoleto } = useIncluirBoleto()
  const { mutateAsync: registrarBoleto } = useRegistrarBoleto()

  const [podeBuscar, setPodeBuscar] = useState(true)

  useEffect(() => {
    setPodeBuscar(false)
  }, [isOpen])

  async function handleSubmit(origem: 'INCLUIR' | 'REGISTRAR') {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        let boletoDetalhado: Partial<BoletoDetalhadoModel> = { id: '' }

        if (origem === 'INCLUIR') {
          boletoDetalhado = await incluirBoleto({
            ...data,
            id: geradorUuid(),
          })
        }

        if (origem === 'REGISTRAR') {
          boletoDetalhado = await registrarBoleto({
            ...data,
            id: geradorUuid(),
          })
        }

        if (boletoDetalhado?.id) {
          navigate(`/boletos/boleto/${boletoDetalhado.id}`)
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  function handleChangePessoa(pessoa: PessoaCadastroModel | null) {
    const {
      cidadeId,
      cidadeNome,
      cidadeUF,
      cidadeUFCodigoIBGE,
      documentoId,
      complemento,
      telefone,
      email,
    } = pessoa || {}

    const ufModel: UFModel = {
      id: cidadeUF || '',
      codigoIBGE: cidadeUFCodigoIBGE || '',
    }

    const cidade = {
      id: cidadeId || '',
      nome: cidadeNome || '',
      uf: ufModel,
      ufId: cidadeUF || '',
    } as CidadeModel

    changeValues({
      ...data,
      pessoaCadastro: pessoa,
      pagador: {
        ...data?.pagador,
        cnpjcpf: documentoId || '',
        uf: cidadeUF || '',
        ufModel,
        cidadeNome: cidadeNome || '',
        cidade,
        nome: pessoa?.nome || '',
        logradouro: pessoa?.logradouro || '',
        complemento,
        bairro: pessoa?.bairro || '',
        cep: pessoa?.cep || '',
        telefone,
        email,
      },
    })
  }

  return (
    <FormContainer
      dialogProps={{
        title: 'Inclusão de Boleto',
        maxWidth: 'lg',
      }}
      actions={<Actions onSubmit={(origem) => handleSubmit(origem)} />}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CardDynamic title="Convênio" hiddenButton gridContainer>
            <Grid item xl={4} lg={4} md={7} sm={12} xs={12}>
              <ConvenioTransporter
                name="convenioId"
                required
                value={data?.convenio || null}
                onChange={(value) => {
                  changeValue('convenio', value || null)
                  changeValue('convenioId', value?.id || '')
                }}
                {...validationProps('convenioId')}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <TextField
                name="convenioNumero"
                label="Número"
                disabled
                value={data?.convenio?.convenioNumero || ''}
              />
            </Grid>
            <Grid item xl={1} lg={2} md={2} sm={6} xs={12}>
              <TextField
                name="carteiraCodigo"
                label="Carteira"
                disabled
                value={data?.convenio?.carteiraCodigo || ''}
              />
            </Grid>
            <Grid item xl={4} lg={3} md={12} sm={12} xs={12}>
              <TextField
                name="ultimoNossoNumeroConsultado"
                label="Último Nosso Número"
                disabled
                value={data?.convenio?.ultimoNossoNumeroConsultado || ''}
              />
            </Grid>
          </CardDynamic>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CardDynamic title="" hiddenButton gridContainer hiddenCollapse>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
              <DatePicker
                label="Emissão"
                name="dataEmissao"
                required
                value={data?.dataEmissao || null}
                onChange={(dataEmissao) => {
                  changeValue('dataEmissao', dataEmissao)
                }}
                {...validationProps('dataEmissao')}
              />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
              <DatePicker
                label="Vencimento"
                name="dataVencimento"
                required
                value={data?.dataVencimento || null}
                onChange={(dataVencimento) => {
                  changeValue('dataVencimento', dataVencimento)
                }}
                {...validationProps('dataVencimento')}
              />
            </Grid>
            <Grid item xl={5} lg={5} md={4} sm={6} xs={12}>
              <TextField
                name="documentoSistema"
                label="Documento"
                inputProps={{ maxLength: 30 }}
                value={data?.documentoSistema || ''}
                onChange={(e) =>
                  changeValue('documentoSistema', e.target.value || null)
                }
              />
            </Grid>
            <Grid item xl={3} lg={3} md={2} sm={6} xs={12}>
              <CurrencyTextField
                name="valorTitulo"
                label="Valor Título"
                required
                value={data?.valorTitulo || ''}
                onChange={(_, value) => {
                  changeValue('valorTitulo', parseFloat(value) || '')
                }}
                {...validationProps('valorTitulo')}
              />
            </Grid>
          </CardDynamic>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CardDynamic title="Pagador" hiddenButton gridContainer>
            <Grid item xl={7} lg={8} md={8} sm={12} xs={12}>
              <PessoaCadastroTransporter
                name="nome"
                label="Pagador"
                required
                hideBackdrop
                value={data?.pessoaCadastro || null}
                onChange={handleChangePessoa}
                {...validationProps('pagador.nome')}
              />
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
              <CnpjCpfTextField
                name="cnpjcpf"
                value={data?.pagador?.cnpjcpf || ''}
                required
                inputProps={{ label: 'CPF/CNPJ' }}
                podePesquisarCnpj={podeBuscar}
                onChange={(e) => {
                  setPodeBuscar(true)

                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      cnpjcpf: onlyNumbers(e.target.value) || '',
                      cep: data?.pagador?.cep || '',
                      bairro: data?.pagador?.bairro || '',
                      logradouro: data.pagador?.logradouro || '',
                      nome: data?.pagador?.nome || '',
                      uf: data?.pagador?.uf || '',
                      cidadeNome: data?.pagador?.cidadeNome || '',
                    },
                  })
                }}
                onFindCnpj={(cnpj) => {
                  if (cnpj) {
                    const {
                      cidadeModel: cidade,
                      ufModel,
                      nomeFantasia,
                      numero,
                      ...rest
                    } = cnpj

                    changeValues({
                      ...data,
                      pagador: {
                        ...data?.pagador,
                        ...rest,
                        cidade,
                        cidadeNome: cidade?.nome || '',
                        uf: ufModel?.id || '',
                        ufModel,
                        cnpjcpf: data?.pagador?.cnpjcpf || '',
                      },
                    })
                  }
                }}
                {...validationProps('pagador.cnpjcpf')}
              />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <CEPTextField
                name="cep"
                required
                value={data?.pagador?.cep || ''}
                inputProps={{ label: 'CEP' }}
                onChange={(e) => {
                  setPodeBuscar(true)

                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      cep: onlyNumbers(e.target.value) || '',
                      cnpjcpf: data?.pagador?.cnpjcpf || '',
                      bairro: data?.pagador?.bairro || '',
                      logradouro: data.pagador?.logradouro || '',
                      nome: data?.pagador?.nome || '',
                      uf: data?.pagador?.uf || '',
                      cidadeNome: data?.pagador?.cidadeNome || '',
                    },
                  })
                }}
                podePesquisarCep={podeBuscar}
                onFindCep={(cep) => {
                  if (cep) {
                    const { cidadeModel: cidade, uf: ufModel, ...rest } = cep

                    setPodeBuscar(false)
                    changeValues({
                      ...data,
                      pagador: {
                        ...data?.pagador,
                        ...rest,
                        cidade,
                        cidadeNome: cidade?.nome || '',
                        ufModel,
                        uf: ufModel?.id || '',
                        cnpjcpf: data?.pagador?.cnpjcpf || '',
                        nome: data?.pagador?.nome || '',
                      },
                    })
                  }
                }}
                {...validationProps('pagador.cep')}
              />
            </Grid>
            <Grid item xl={4} lg={5} md={9} sm={12} xs={12}>
              <TextField
                name="logradouro"
                label="Logradouro"
                required
                value={data?.pagador?.logradouro || ''}
                onChange={(e) =>
                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      logradouro: e.target.value || '',
                      cep: data?.pagador?.cep || '',
                      cnpjcpf: data?.pagador?.cnpjcpf || '',
                      bairro: data?.pagador?.bairro || '',
                      nome: data?.pagador?.nome || '',
                      uf: data?.pagador?.uf || '',
                      cidadeNome: data?.pagador?.cidadeNome || '',
                    },
                  })
                }
                {...validationProps('pagador.logradouro')}
              />
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={12} xs={12}>
              <TextField
                name="bairro"
                label="Bairro"
                required
                value={data?.pagador?.bairro || ''}
                onChange={(e) =>
                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      bairro: e.target.value || '',
                      logradouro: data?.pagador?.logradouro || '',
                      cep: data?.pagador?.cep || '',
                      cnpjcpf: data?.pagador?.cnpjcpf || '',
                      nome: data?.pagador?.nome || '',
                      uf: data?.pagador?.uf || '',
                      cidadeNome: data?.pagador?.cidadeNome || '',
                    },
                  })
                }
                {...validationProps('pagador.bairro')}
              />
            </Grid>
            <Grid item xl={4} lg={5} md={7} sm={12} xs={12}>
              <TextField
                name="complemento"
                label="Complemento"
                value={data?.pagador?.complemento || ''}
                onChange={(e) =>
                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      complemento: e.target.value || '',
                      bairro: data?.pagador?.bairro || '',
                      logradouro: data?.pagador?.logradouro || '',
                      cep: data?.pagador?.cep || '',
                      cnpjcpf: data?.pagador?.cnpjcpf || '',
                      nome: data?.pagador?.nome || '',
                      uf: data?.pagador?.uf || '',
                      cidadeNome: data?.pagador?.cidadeNome || '',
                    },
                  })
                }
              />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={3} xs={12}>
              <AutoCompleteUF
                name="uf"
                label="UF"
                required
                value={data?.pagador?.ufModel || null}
                onChange={(_, value) => {
                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      ufModel: value || null,
                      uf: value?.id || '',
                      cidadeNome: '',
                      cidade: null,
                      bairro: data?.pagador?.bairro || '',
                      logradouro: data?.pagador?.logradouro || '',
                      cep: data?.pagador?.cep || '',
                      cnpjcpf: data?.pagador?.cnpjcpf || '',
                      nome: data?.pagador?.nome || '',
                    },
                  })
                }}
                {...validationProps('pagador.uf')}
              />
            </Grid>
            <Grid item xl={4} lg={5} md={9} sm={9} xs={12}>
              <AutoCompleteCidade
                name="cidadeNome"
                label="Cidade"
                required
                value={data?.pagador?.cidade || null}
                uf={data?.pagador?.uf || ''}
                onChange={(_, value) => {
                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      cidade: value || null,
                      cidadeNome: value?.nome || '',
                      bairro: data?.pagador?.bairro || '',
                      logradouro: data?.pagador?.logradouro || '',
                      cep: data?.pagador?.cep || '',
                      cnpjcpf: data?.pagador?.cnpjcpf || '',
                      nome: data?.pagador?.nome || '',
                      uf: data?.pagador?.uf || '',
                    },
                  })
                }}
                {...validationProps('pagador.cidadeNome')}
              />
            </Grid>
            <Grid item xl={2} lg={3} md={3} sm={12} xs={12}>
              <TelefoneTextField
                label="Telefone"
                name="telefone"
                value={data?.pagador?.telefone || ''}
                onChange={(e) => {
                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      telefone: onlyNumbers(e.target.value) || null,
                      bairro: data?.pagador?.bairro || '',
                      logradouro: data?.pagador?.logradouro || '',
                      cep: data?.pagador?.cep || '',
                      cnpjcpf: data?.pagador?.cnpjcpf || '',
                      nome: data?.pagador?.nome || '',
                      uf: data?.pagador?.uf || '',
                      cidadeNome: data?.pagador?.cidadeNome || ' ',
                    },
                  })
                }}
              />
            </Grid>
            <Grid item xl={4} lg={9} md={9} sm={12} xs={12}>
              <TextField
                name="email"
                label="E-mail"
                value={data?.pagador?.email || ''}
                onChange={(e) =>
                  changeValues({
                    ...data,
                    pagador: {
                      ...data?.pagador,
                      email: e.target.value || null,
                      bairro: data?.pagador?.bairro || '',
                      logradouro: data?.pagador?.logradouro || '',
                      cep: data?.pagador?.cep || '',
                      cnpjcpf: data?.pagador?.cnpjcpf || '',
                      nome: data?.pagador?.nome || '',
                      uf: data?.pagador?.uf || '',
                      cidadeNome: data?.pagador?.cidadeNome || '',
                    },
                  })
                }
              />
            </Grid>
          </CardDynamic>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CardDynamic title="Outras Informações" hiddenButton gridContainer>
            <Grid item xl={6} lg={5} md={6} sm={12} xs={12}>
              <DropDownTipoDesconto
                name="descontoTipo"
                value={data?.descontoTipo || ''}
                onChange={(value, valueDescription) => {
                  if (!isTipoDesconto.includes(Number(value))) {
                    changeValue('descontoValor', 0)
                  }

                  changeValue('descontoTipo', value)
                  changeValue('descontoTipoDesc', valueDescription)
                }}
              />
            </Grid>
            <Grid item xl={3} lg={4} md={3} sm={12} xs={12}>
              <CurrencyTextField
                title="Percentual de desconto (quando aplicável)"
                name="descontoValor"
                label="Valor Desconto"
                disabled={!isTipoDesconto.includes(data?.descontoTipo)}
                required={isTipoDesconto.includes(data?.descontoTipo)}
                value={data?.descontoValor || ''}
                onChange={(_, value) =>
                  changeValue('descontoValor', limitNumberValue(value) || 0)
                }
                {...validationProps('descontoValor')}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <DatePicker
                label="Data Máxima Desconto"
                name="descontoDataExpiracao"
                value={data?.descontoDataExpiracao || null}
                onChange={(descontoDataExpiracao) => {
                  changeValue(
                    'descontoDataExpiracao',
                    descontoDataExpiracao || null,
                  )
                }}
              />
            </Grid>
            <Grid item xl={3} lg={6} md={6} sm={12} xs={12}>
              <DropDownTipoJuros
                name="jurosTipo"
                value={data?.jurosTipo || ''}
                onChange={(value, valueDescription) => {
                  if (!isTipoJuros.includes(Number(value))) {
                    changeValue('jurosValor', null)
                  }

                  changeValue('jurosTipo', value)
                  changeValue('jurosTipoDesc', valueDescription)
                }}
              />
            </Grid>
            <Grid item xl={3} lg={6} md={6} sm={12} xs={12}>
              <CurrencyTextField
                name="jurosValor"
                label="Valor Juros"
                disabled={!isTipoJuros.includes(data?.jurosTipo)}
                required={isTipoJuros.includes(data?.jurosTipo)}
                value={data?.jurosValor || ''}
                onChange={(_, value) =>
                  changeValue('jurosValor', value || null)
                }
                {...validationProps('jurosValor')}
              />
            </Grid>
            <Grid item xl={3} lg={6} md={6} sm={12} xs={12}>
              <DropDownTipoMulta
                name="multaTipo"
                value={data?.multaTipo || ''}
                onChange={(value, valueDescription) => {
                  if (!isTipoMulta.includes(Number(value))) {
                    changeValue('multaValor', null)
                  }

                  changeValue('multaTipo', value)
                  changeValue('multaTipoDesc', valueDescription)
                }}
              />
            </Grid>
            <Grid item xl={3} lg={6} md={6} sm={12} xs={12}>
              <CurrencyTextField
                name="multaValor"
                label="Valor Multa"
                disabled={!isTipoMulta.includes(data?.multaTipo)}
                required={isTipoMulta.includes(data?.multaTipo)}
                value={data?.multaValor || ''}
                onChange={(_, value) =>
                  changeValue('multaValor', value || null)
                }
                {...validationProps('multaValor')}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                name="mensagemOcorrencia"
                label="Mensagem"
                value={data?.mensagemOcorrencia || ''}
                multiline
                rows={4}
                onChange={(e) =>
                  changeValue('mensagemOcorrencia', e.target.value || null)
                }
              />
            </Grid>
          </CardDynamic>
        </Grid>
      </Grid>
    </FormContainer>
  )
}
