import { useState } from 'react'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import Container from '@components/Container'
import {
  EnvioBoletoFilters1,
  envioBoletofilters,
} from '@hooks/queries/useEnvioBoletos'

import Filter from './components/Filter'
import Table from './components/Table'
import Form from './components/Form'

export default function EnvioBoletos() {
  const [appliedFilters, setFilters] = useState<EnvioBoletoFilters1>(
    {} as EnvioBoletoFilters1,
  )

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <PageTitle title="Envio de Boletos">
          <ButtonContainer>
            <FilterProvider
              filterValues={envioBoletofilters}
              storagePath={
                import.meta.env.VITE_STORAGE_PATH || '@storage:stella-hubbank'
              }
            >
              <Filter
                onApplyFilters={(_appliedFilters) => {
                  setFilters(_appliedFilters)
                }}
              />
            </FilterProvider>
          </ButtonContainer>
        </PageTitle>
        <Form />

        <Table filters={appliedFilters} />
      </FormProvider>
    </Container>
  )
}
