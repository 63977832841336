import { Box, ButtonProps } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { Button, Tooltip } from '@data-c/ui'

interface EditButtonProps extends ButtonProps {
  tooltipTitle?: string
}

export default function EditButton(props: EditButtonProps) {
  const { tooltipTitle, disabled, ...rest } = props

  return (
    <Tooltip
      title={tooltipTitle}
      disableHoverListener={disabled === true ? false : true}
    >
      <Box>
        <Button
          variant="text"
          endIcon={<EditIcon color={disabled ? 'disabled' : 'primary'} />}
          disabled={disabled}
          {...rest}
        >
          Editar
        </Button>
      </Box>
    </Tooltip>
  )
}
