import { ReactNode, useEffect } from 'react'
import { useFormApi, useFormData } from '@data-c/providers'
import useNotification from 'hooks/useNotifications'
import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { useActionOptionsContext } from 'contexts/ActionOptionsContext/ActionOptionsContext'

export interface OptionListProps<TOrigem> {
  name: TOrigem
  onSubmit?: (origem?: TOrigem) => void
}

type TOptionListProps<TOrigem, TInitialData> = {
  requestId?: string
  route?: string
  parseResponseToJson?: boolean
  noRequest?: {
    initialData?: TInitialData
  }
  api?: AxiosInstance
  axiosRequestConfig?: AxiosRequestConfig<any>
  form?: ReactNode
  component?: ReactNode
} & OptionListProps<TOrigem>

export default function OptionList<TOrigem, TInitialData>(
  props: TOptionListProps<TOrigem, TInitialData>,
) {
  const {
    requestId,
    name,
    route,
    parseResponseToJson,
    noRequest,
    api,
    axiosRequestConfig,
    form,
    component,
    onSubmit,
  } = props

  const { origem, onLoading, onClearOrigem } = useActionOptionsContext()
  const { openForm } = useFormApi()
  const { isOpen: isOpenForm } = useFormData()
  const notifications = useNotification()

  async function obterPorId(id: string): Promise<any | null> {
    if (!route) {
      throw new Error('Nenhuma rota foi informada para o componente OptionList')
    }

    const endPoint = axiosRequestConfig?.params ? route : `${route}/${id}`

    try {
      onLoading(true)
      const response = await api?.get(endPoint, {
        ...axiosRequestConfig,
        params: { ...axiosRequestConfig?.params },
      })
      if (response?.data?.data !== null) {
        if (parseResponseToJson) {
          return JSON.parse(response?.data?.data)
        }

        return response?.data?.data
      }

      return response?.data?.data
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      onLoading(false)
    }

    return null
  }

  useEffect(() => {
    if (origem && origem === name) {
      if (noRequest) {
        openForm(noRequest?.initialData)
        onClearOrigem({ _closeOptionsListContainer: true })
        return
      }

      handleOpenForm()
    }
  }, [origem, name, noRequest])

  async function handleOpenForm() {
    if (requestId) {
      const response = await obterPorId(requestId)
      onClearOrigem({ _closeOptionsListContainer: true })
      openForm(response)
      return
    }

    onLoading(true)

    setTimeout(() => {
      onLoading(false)
    }, 2000)

    onClearOrigem()
    onSubmit && onSubmit(origem)
  }

  return <>{isOpenForm && (form || component)}</>
}
