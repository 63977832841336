import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
// import theme from './styles/theme'
import { theme } from '@data-c/ui'
import { ToastContainer, Flip } from 'react-toastify'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import AppRoutes from './routes'

import 'react-toastify/dist/ReactToastify.css'
import 'font-awesome/css/font-awesome.css'
import queryClient from '@services/query-cliente'
import AmbienteProvider from 'contexts/AmbienteContext/AmbienteContext'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AmbienteProvider>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br"
          >
            <AppRoutes />
          </LocalizationProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </AmbienteProvider>
      <ToastContainer transition={Flip} position="bottom-right" />
    </ThemeProvider>
  )
}

export default App
