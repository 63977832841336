import { useQuery as useTQuery } from '@tanstack/react-query'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'
import api from '@services/api'
import { AxiosError } from 'axios'

export interface LogsModel {
  id: number
  enviados: Array<any>
  naoEnviados: Array<any>
  createdAt: string
  templateId: number
  template: {
    nome: string
  }
}

export interface CustomQueryParamsInterface extends QueryParamsInterface {
  params?: {
    inicio?: string
    fim?: string
  }
}

export async function obterLogsEnvio(
  queryParams?: CustomQueryParamsInterface,
): Promise<Array<LogsModel> | null> {
  const response = await api.get(`/logs-envio/listar-logs-envio`, {
    params: queryParams?.params,
  })

  const { data } = response
  return data
}

export function useQuery(params: CustomQueryParamsInterface) {
  return useTQuery<Array<LogsModel> | null, AxiosError>(
    ['LOGS_ENVIO', params],
    () => {
      return obterLogsEnvio(params)
    },
    {
      staleTime: 0,
    },
  )
}
