import { useEffect, useState } from 'react'
import { useDropzone, FileRejection, Accept, ErrorCode } from 'react-dropzone'

import {
  Typography,
  LinearProgress,
  useTheme,
  Stack,
  SxProps,
} from '@mui/material'
import { Backup as UploadIcon } from '@mui/icons-material'

import DisableAllChildren from 'components/DisableAllChildren'
import NomeArquivoSelecionado from './components/NomeArquivoSelecionado'

interface DragDropFileProps {
  title?: string
  tooltipTitle?: string
  extencaoDoArquivo?: '.pdf' | '.key' | '.crt' | '.pfx' | '.XML'
  uploadMessage: string
  maxSize?: number
  maxFiles?: number
  acceptFiles?: Accept
  isLoading?: boolean
  disabled?: boolean
  custonSx?: SxProps
  helperText?: string
  error?: boolean
  onDropped: <T extends File>(acceptedFile: T) => void
}

export default function DragDropFile(props: DragDropFileProps) {
  const {
    tooltipTitle,
    title,
    extencaoDoArquivo,
    maxSize = 10485760,
    maxFiles = 1,
    acceptFiles,
    uploadMessage = 'Clique ou solte os arquivos aqui para fazer o upload',
    onDropped,
    isLoading = false,
    disabled = false,
    error,
    helperText,
    custonSx,
  } = props

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: maxSize,
    maxFiles: maxFiles,
    accept: acceptFiles,
    onDrop,
    onDropRejected,
    disabled,
  })

  const [messageError, setMessageError] = useState('')
  const [nomeArquivoSelecionado, setNomeArquivoSelecionado] = useState('')

  useEffect(() => {
    if (error) {
      setMessageError(helperText || '')
    }
  }, [error])

  function onDrop<T extends File>(acceptedFiles: T[]) {
    const file = acceptedFiles[0]
    const fileName = file.name

    const extencaoToLowerCase = extencaoDoArquivo?.toUpperCase()
    const fileNameToLowerCase = fileName.toUpperCase()

    setNomeArquivoSelecionado(fileName)

    if (
      extencaoToLowerCase &&
      !fileNameToLowerCase.includes(extencaoToLowerCase)
    ) {
      setMessageError(
        `O arquivo "${fileName}" não possui a extenção "${extencaoDoArquivo}"`,
      )
      return
    }

    setMessageError('')
    onDropped(file)
  }

  function onDropRejected(fileRejections: FileRejection[]) {
    fileRejections.forEach((file) => {
      file.errors.forEach((error) => {
        let message = ''

        switch (error.code) {
          case ErrorCode.FileTooLarge: {
            message =
              'Não foi possível inserir, tamanho máximo de arquivos atingido'
            break
          }
          case ErrorCode.TooManyFiles: {
            message = 'Não foi possível inserir, limite de arquivos atingido'
            break
          }
          case ErrorCode.FileInvalidType: {
            message = 'Não foi possível inserir, tipo de arquivo inválido'
            break
          }

          default:
            break
        }

        setMessageError(message)
      })
    })
  }

  const theme = useTheme()

  return (
    <DisableAllChildren
      disabled={disabled}
      tooltipTitle={tooltipTitle}
      disableHoverListener={!disabled}
    >
      <Stack width="100%" alignItems="center" gap={1} sx={{ ...custonSx }}>
        <Stack
          title={title}
          width="100%"
          height="100%"
          justifyContent="center"
          gap={2}
          padding={2}
          borderRadius={1}
          border={`2px dashed ${
            disabled ? theme.palette.action.disabled : theme.palette.grey[300]
          }`}
          sx={{ cursor: 'pointer' }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <Stack alignItems="center" justifyContent="center" gap={1}>
            <UploadIcon fontSize="large" color="primary" />
            <Typography variant="body2" fontWeight="bold">
              {uploadMessage}
            </Typography>

            {nomeArquivoSelecionado.length > 0 && (
              <NomeArquivoSelecionado
                nomeArquivoSelecionado={nomeArquivoSelecionado}
                messageError={messageError}
              />
            )}

            <LinearProgress
              sx={{
                width: '200px',
                visibility: isLoading ? 'initial' : 'hidden',
              }}
            />
          </Stack>
        </Stack>

        {messageError && (
          <Typography variant="caption" color="error">
            {messageError}
          </Typography>
        )}
      </Stack>
    </DisableAllChildren>
  )
}
