import QueryParamsInterface from 'interfaces/QueryParamsInterface'
import api from '@services/api'
import useNotifications from '@hooks/useNotifications'
import {
  useMutation,
  useQuery as useTQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import QueryResponseInterface from 'interfaces/QueryResponseInterface'
import geradorUuid from '@hooks/useGeradorUuid'

export interface LayoutEmailInterface {
  id?: string
  nome: string
  design?: string
  html?: string
  dataAlteracao?: string
  dataCriacao?: string
}

export async function obterLayouts(
  params?: QueryParamsInterface,
): Promise<QueryResponseInterface<LayoutEmailInterface>> {
  const response = await api.get(
    `EmailTemplate/Pesquisar/${params?.query || ''}`,
  )

  return response.data
}

export async function inserirLayout(
  data: LayoutEmailInterface,
): Promise<LayoutEmailInterface> {
  const { id, nome } = data

  const response = await api.post<{ data: LayoutEmailInterface }>(
    'EmailTemplate/Adicionar',
    {
      id,
      nome,
    },
  )

  return response.data.data
}

export async function atualizarLayout(
  data: LayoutEmailInterface,
): Promise<LayoutEmailInterface> {
  const { id, ...rest } = data

  const response = await api.put<{ data: LayoutEmailInterface }>(
    `EmailTemplate/Alterar`,
    rest,
    {
      params: { id },
    },
  )

  return response.data.data
}

export async function salvarLayout(
  layout: LayoutEmailInterface,
): Promise<LayoutEmailInterface> {
  if (layout.id) {
    return atualizarLayout(layout)
  }

  const newTemplateId = geradorUuid()
  return inserirLayout({ ...layout, id: newTemplateId })
}

export async function deletarLayout(
  data: LayoutEmailInterface,
): Promise<LayoutEmailInterface> {
  await api.delete('EmailTemplate/Remover', {
    params: { id: data.id },
  })
  return data
}

export async function duplicarLayout(data: LayoutEmailInterface) {
  const { nome } = data
  const newTemplateId = geradorUuid()

  const response = await api.post('EmailTemplate/Adicionar', {
    id: newTemplateId,
    nome,
  })

  return response.data
}

export function useQuery(params: QueryParamsInterface) {
  return useTQuery<QueryResponseInterface<LayoutEmailInterface>>(
    ['LAYOUT_EMAIL', params],
    () => {
      return obterLayouts(params)
    },
    {
      staleTime: 0,
    },
  )
}

export function useSubmit() {
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  return useMutation<LayoutEmailInterface, AxiosError, LayoutEmailInterface>(
    salvarLayout,
    {
      onSuccess(_, data) {
        const flexPluralSingular = data.id ? 'alterado' : 'incluído'

        notifications.notifySuccess(
          `Template ${flexPluralSingular} com sucesso`,
        )
        queryClient.invalidateQueries(['LAYOUT_EMAIL'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDelete() {
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError, LayoutEmailInterface>(deletarLayout, {
    onSuccess() {
      queryClient.invalidateQueries(['LAYOUT_EMAIL'])
      notifications.notifySuccess('Template de e-mail excluído com sucesso!')
    },
    onError(err) {
      notifications.notifyException(err)
    },
  })
}

export function useDuplicarLayout() {
  const notifications = useNotifications()
  const queryClient = useQueryClient()
  return useMutation<
    { data: LayoutEmailInterface },
    AxiosError,
    LayoutEmailInterface
  >(duplicarLayout, {
    onSuccess: async (newTemplate, copyTemplate) => {
      notifications.notifySuccess('Template duplicado com sucesso')

      const newData: LayoutEmailInterface = {
        ...copyTemplate,
        id: newTemplate.data.id,
      }

      await atualizarLayout(newData)
      queryClient.invalidateQueries(['LAYOUT_EMAIL'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useLayoutEmail() {
  return {
    useDuplicarLayout,
    useDelete,
    useSubmit,
    useQuery,
  }
}
