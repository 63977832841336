import { Grid } from '@mui/material'

import { CurrencyTextField, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import { isValidNumber } from '@data-c/hooks'

import { DropDownBancoCodigoEnum } from '@components/Inputs/DropDownBancoCodigo'
import DropDownTipoJuros, {
  DropDownTipoJurosEnum,
} from '@components/Inputs/DropDownTipoJuros'

import useBoleto, {
  AlterarBoletoJurosModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import useValidations from '@hooks/useValidations'

import * as yup from 'yup'

const isTipoJuros = [
  DropDownTipoJurosEnum.VALOR_FIXO_1,
  DropDownTipoJurosEnum.PERCENTUAL_2,
]

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  jurosValor: yup
    .number()
    .when('jurosTipo', ([jurosTipo], schema) => {
      if (isTipoJuros.includes(jurosTipo)) {
        return schema
          .required('Informe o juros')
          .moreThan(0, 'O valor do juros deve ser superior a zero(0)')
          .transform(transform)
      }
      return schema
    })
    .nullable(),
})

interface FormProps {
  boleto: BoletoDetalhadoModel | null
}

export default function Form({ boleto }: FormProps) {
  const { setValidationErrors, validationProps } = useValidations()

  const { changeValue, closeForm, toggleSubmit } =
    useFormApi<AlterarBoletoJurosModel>()
  const { formValues: data } = useFormData<AlterarBoletoJurosModel>()

  const { useAlterarBoletoJuros } = useBoleto()
  const { mutateAsync: alterarBoletoJuros } = useAlterarBoletoJuros()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoJuros({
          boletoId: boleto?.id || '',
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  function getDisabledOptions(convenioBancoCodigo?: number) {
    if (convenioBancoCodigo === DropDownBancoCodigoEnum.ITAU_341) {
      return [DropDownTipoJurosEnum.DEFINIDO_PELO_CONVENIO_99]
    }

    return []
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Alterar Juros',
        maxWidth: 'sm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <DropDownTipoJuros
            name="jurosTipo"
            optionToDisabled={getDisabledOptions(boleto?.convenioBancoCodigo)}
            tooltipTitle={`Opção inválida para o banco ${
              boleto?.convenioBancoCodigoDesc || ''
            }`}
            value={String(data?.jurosTipo) || ''}
            onChange={(value) => {
              if (!isTipoJuros.includes(Number(value))) {
                changeValue('jurosValor', null)
              }

              changeValue('jurosTipo', value)
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            name="jurosValor"
            label="Valor Juros"
            disabled={!isTipoJuros.includes(data?.jurosTipo)}
            required={isTipoJuros.includes(data?.jurosTipo)}
            value={data?.jurosValor || ''}
            onChange={(_, value) => changeValue('jurosValor', value || null)}
            {...validationProps('jurosValor')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
