import { Button, ButtonContainer } from '@data-c/ui'
import { useFormData } from '@data-c/providers'

import { TemplateEmailInterface } from '@hooks/queries/useTemplateEmail'

interface ActionsProps {
  activeStep: number
  onSubmit: () => void
  onPrevious: () => void
  onNext: () => void
}

export default function Actions(props: ActionsProps) {
  const { activeStep, onSubmit, onPrevious, onNext } = props
  const { formValues: data, isSubmitting } =
    useFormData<TemplateEmailInterface>()

  return (
    <ButtonContainer>
      {data.envioAutomatico && (
        <>
          <Button
            disabled={isSubmitting || activeStep === 0}
            onClick={onPrevious}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            disabled={isSubmitting || activeStep === 5}
            onClick={onNext}
          >
            Próximo
          </Button>
        </>
      )}
      {(!data.envioAutomatico || activeStep === 5) && (
        <Button
          variant="contained"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      )}
    </ButtonContainer>
  )
}
