import { FormProvider as DialogProvider } from '@data-c/providers'
import OptionList from '@components/OptionsListContainer/components/OptionList'
import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'
import apiHubbank from 'services/api'
import Table from './components/Table'
import { OrigemType } from '../..'

interface BoletoMovimentacoesProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoMovimentacoes({
  boleto,
}: BoletoMovimentacoesProps) {
  const { id } = boleto || {}

  return (
    <DialogProvider>
      <OptionList
        name={OrigemType.VISUALIZAR_MOVIMENTACOES}
        api={apiHubbank}
        route="BoletoConsulta/ObterMovimentacoes"
        requestId={id}
        axiosRequestConfig={{ params: { boletoId: id || '' } }}
        component={<Table />}
      />
    </DialogProvider>
  )
}
