import { useState } from 'react'
import { TextField, Grid, Divider, Stack } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { isValidNumber } from '@data-c/hooks'
import { FormContainer } from '@data-c/ui'

import ChooseOne from '@components/ChooseOne'
import TabPanel from '@components/TabPanel'

import useTemplateEmail, {
  RegraEnvioOperacao,
  TemplateEmailInterface,
} from '@hooks/queries/useTemplateEmail'
import useValidations from '@hooks/useValidations'
import useUtils from '@hooks/useUtils'

import AgendamentoStepper from './components/AgendamentoStepper'
import BoletoAnexo from './components/BoletoAnexo'
import TipoEvento from './components/TipoEnvento'
import TipoEnvio from './components/TipoEnvio'
import HoraEnvio from './components/HoraEnvio'
import DataEnvio from './components/DataEnvio'
import Revisao from './components/Revisao'
import Actions from './components/Actions'

import * as yup from 'yup'

const validationSchema = yup.object().shape({
  nome: yup.string().required('Informe o Nome do Agendamento'),
  identificador: yup.string().required('Informe o Identificador'),
})

const step1ValidationSchema = yup.object().shape({
  horaEnvio: yup.number().required('Informe a Hora do envio'),
})

const step2ValidationSchema = yup.object().shape({
  campo: yup.string().required('Informe a data referência para o envio'),
})

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const step3ValidationSchema = yup.object().shape(
  {
    quantidadeDias: yup
      .number()
      .when('operacao', ([operacao], schema) =>
        operacao !== 'now'
          ? schema
              .required('Informe os dias')
              .moreThan(0, 'Informe os dias')
              .transform(transform)
          : schema,
      ),
    operacao: yup.string().required('Informe a operação'),
  },
  [['quantidadeDias', 'operacao']],
)

export default function Form() {
  const { snakeCase } = useUtils()
  const { validationProps, setValidationErrors } = useValidations()

  const {
    changeValue,
    onChangeFormValue,
    changeValues,
    toggleSubmit,
    closeForm,
  } = useFormApi<TemplateEmailInterface>()
  const { formValues: data } = useFormData<TemplateEmailInterface>()

  const { useSubmit } = useTemplateEmail()
  const { mutateAsync: salvarTemplate } = useSubmit()

  const [activeStep, setActiveStep] = useState<number>(0)

  function handleSubmit() {
    setValidationErrors(null)
    validationSchema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        const isEnvioAutomatico = data.envioAutomatico
        let newData = data

        if (isEnvioAutomatico && data?.regraEnvio?.operacao) {
          let quantidadeDias = data?.quantidadeDias || 0

          const operacaoMapping: Record<RegraEnvioOperacao, number> = {
            add: -quantidadeDias,
            subtract: quantidadeDias,
            now: 0,
          }
          quantidadeDias = operacaoMapping[data?.regraEnvio?.operacao]
          newData = { ...data, quantidadeDias }
        } else {
          newData = {
            ...data,
            dias: 0,
            horaEnvio: 0,
            quantidadeDias: 0,
            anexarPDF: true,
            ativo: true,
            somenteBoletosLiquidados: false,
            tipoData: 0,
          }
        }

        await salvarTemplate(newData)

        changeValues({
          nome: '',
          identificador: '',
          envioAutomatico: true,
          ativo: true,
        })
        setActiveStep(0)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  function handleNext() {
    const nextStep = () => {
      setActiveStep(activeStep + 1)
    }
    if (activeStep === 2) {
      setValidationErrors(null)
      step1ValidationSchema
        .validate(data, { abortEarly: false })
        .then(() => nextStep())
        .catch((err) => {
          setValidationErrors(err)
        })
      return
    }

    if (activeStep === 3) {
      setValidationErrors(null)
      step2ValidationSchema
        .validate(data.regraEnvio, { abortEarly: false })
        .then(() => nextStep())
        .catch((err) => {
          setValidationErrors(err)
        })
      return
    }

    if (activeStep === 4) {
      setValidationErrors(null)
      step3ValidationSchema
        .validate(
          {
            quantidadeDias: data?.quantidadeDias,
            operacao: data?.regraEnvio?.operacao,
          },
          { abortEarly: false },
        )
        .then(() => nextStep())
        .catch((err) => {
          setValidationErrors(err)
        })
      return
    }

    nextStep()
  }

  function handlePrevious() {
    setActiveStep(activeStep - 1)
  }

  const quantidadeDiasFormatado = (data?.quantidadeDias || '---')
    .toString()
    .replace(/-/g, '')

  const campoValidationProps = validationProps('campo')
  const operacaoValidationProps = validationProps('operacao')

  return (
    <FormContainer
      dialogProps={{ maxWidth: 'md', title: 'Agendamento' }}
      triggerButton="none"
      actions={
        <Actions
          activeStep={activeStep}
          onSubmit={handleSubmit}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            name="nome"
            label="Nome do Agendamento"
            autoFocus
            required
            value={data?.nome || ''}
            onChange={(e) => {
              changeValue('nome', e.target.value)

              if (!data?.identificador) {
                changeValue('identificador', snakeCase(e.target.value.trim()))
              }
            }}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            name="identificador"
            label="Identificador"
            required
            disabled={Boolean(data?.id)}
            value={data?.identificador || ''}
            onChange={onChangeFormValue}
            {...validationProps('identificador')}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ChooseOne
            onChoose={(item) => {
              if (item.value === 'manual') {
                changeValue('envioAutomatico', false)
                changeValue('regraEnvio', null)
                changeValue('anexarPDF', true)
              } else {
                changeValue('envioAutomatico', true)
                if (!data.regraEnvio) {
                  changeValue('regraEnvio', {
                    liquidado: false,
                    campo: 'vencimento',
                    operacao: 'subtract',
                  })
                  changeValue('anexarPDF', true)
                }
              }
            }}
            value={data?.envioAutomatico ? 'automatico' : 'manual'}
            options={[
              {
                title: 'Envio Manual',
                value: 'manual',
                description:
                  'Você cria os agendamentos e os envia quando quiser.',
                icon: 'touch_app',
              },
              {
                title: 'Envio Automático',
                value: 'automatico',
                description:
                  'Você cria os agendamentos e o sistema envia os e-mails para você automaticamente.',
                icon: 'precision_manufacturing',
              },
            ]}
          />
        </Grid>

        {data?.envioAutomatico && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack gap={4}>
              <Divider />
              <TabPanel index={0} value={activeStep}>
                <TipoEnvio />
              </TabPanel>
              <TabPanel index={1} value={activeStep}>
                <BoletoAnexo />
              </TabPanel>
              <TabPanel index={2} value={activeStep}>
                <HoraEnvio validationProps={validationProps} />
              </TabPanel>
              <TabPanel index={3} value={activeStep}>
                <DataEnvio campoValidationProps={campoValidationProps} />
              </TabPanel>
              <TabPanel index={4} value={activeStep}>
                <TipoEvento
                  quantidadeDiasFormatado={quantidadeDiasFormatado}
                  operacaoValidationProps={operacaoValidationProps}
                  validationProps={validationProps}
                />
              </TabPanel>
              <TabPanel index={5} value={activeStep}>
                <Revisao quantidadeDiasFormatado={quantidadeDiasFormatado} />
              </TabPanel>

              <AgendamentoStepper activeStep={activeStep} />
            </Stack>
          </Grid>
        )}
      </Grid>
    </FormContainer>
  )
}
