import { Breakpoint, useMediaQuery, useTheme } from '@mui/material'
import { Tooltip } from '@data-c/ui'
import useStringManipulation from '@hooks/useStringManipulation'

interface EllipsisTextOptionsCellStyleProps {
  value: any
  breakpoint: Breakpoint
  length: number
}

export default function EllipsisTextOptionsCellStyle(
  props: EllipsisTextOptionsCellStyleProps,
) {
  const { value, breakpoint, length } = props

  const { toSubstring } = useStringManipulation()
  const theme = useTheme()
  const isUpLG = useMediaQuery(theme.breakpoints.up(breakpoint))
  const textCurto = toSubstring(value, length, isUpLG)

  return (
    <>
      {isUpLG ? (
        <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
      ) : (
        <Tooltip title={value}>
          <span style={{ whiteSpace: 'nowrap' }}>{textCurto}</span>
        </Tooltip>
      )}
    </>
  )
}
