import { useNavigate, useParams } from 'react-router-dom'

import { FormProvider } from '@data-c/providers'
import { PageTitle } from '@data-c/ui'

import useConvenio from '@hooks/queries/useConvenio'
import Container from '@components/Container'
import Form from './components/Form'

export default function Convenio() {
  const { id: convenioId } = useParams()
  const navigate = useNavigate()

  const { useQueryObterConvenioPorId } = useConvenio()
  const { data, isLoading, error } = useQueryObterConvenioPorId(
    convenioId || '',
  )

  function handleBack() {
    navigate('/convenios/lista')
  }

  return (
    <Container
      disablePadding
      isLoading={isLoading}
      error={error}
      customSx={{ paddingBottom: 11 }}
    >
      <FormProvider>
        <PageTitle
          title={`Convênio - ${data?.descricao || ''}`}
          onBack={handleBack}
        />
        <Form convenio={data} />
      </FormProvider>
    </Container>
  )
}
