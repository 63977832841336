import {
  Grid,
  Box,
  Typography,
  Icon,
  MenuItem,
  MenuItemProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export interface ChooseOneItemProps extends MenuItemProps {
  title?: string
  description?: string
  value?: string
  icon?: string
  selected?: boolean
}

export interface ChooseOneProps {
  options: Array<ChooseOneItemProps>
  value?: string | null
  onChoose?: (item: ChooseOneItemProps) => void
  gridItemSize?: number
}

const StyledMuiBox = styled(
  MenuItem,
  {},
)<ChooseOneItemProps>(({ theme, selected }) => ({
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  margin: theme.spacing(0.5),
  minHeight: '128px',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-wrap',
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   '&:hover': {
  //     backgroundColor: '#ffffde',
  //     cursor: 'pointer',
  //   },
}))

const ChooseOneItem = (props: ChooseOneItemProps) => {
  const { title, description, icon, ...rest } = props
  return (
    <StyledMuiBox {...rest}>
      <Box
        sx={{
          display: 'flex',
          margin: (theme) => theme.spacing(2),
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Icon fontSize="large">{icon}</Icon>
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant="subtitle2" color="primary">
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </StyledMuiBox>
  )
}

const ChooseOne = (props: ChooseOneProps) => {
  const { options, value, onChoose, gridItemSize } = props

  return (
    <Grid container>
      {options.map((o: ChooseOneItemProps) => (
        <Grid
          key={o.value}
          item
          xl={gridItemSize}
          lg={gridItemSize}
          md={gridItemSize}
          sm={gridItemSize}
          xs={12}
        >
          <ChooseOneItem
            key={o.title}
            title={o.title}
            description={o.description}
            icon={o.icon}
            value={o.value}
            selected={o.value === value}
            onClick={() => {
              if (onChoose) onChoose(o)
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

ChooseOne.defaultProps = {
  gridItemSize: 6,
}

export default ChooseOne
