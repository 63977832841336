import { useMemo } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Search as SearchIcon } from '@mui/icons-material'

import { Button, ButtonContainer, DataTable, Dialog } from '@data-c/ui'
import { useDialog } from '@data-c/hooks'
import {
  useFormApi as useDialogApi,
  useFormData as useDialogData,
} from '@data-c/providers'

import JsonViewerDialog from '@components/JsonViewerDialog'

import { BoletoConsultaObterLogsModel } from '@hooks/queries/useBoleto'
import useDates from '@hooks/useDates'

export default function Table() {
  const { toBrazilianDate } = useDates()

  const { closeForm: closeBoletoLogsDialog } = useDialogApi()
  const { isOpen: isOpenBoletoLogsDialog, formValues: data } =
    useDialogData<BoletoConsultaObterLogsModel[]>()

  const {
    isOpen: isOpenJsonViewerDialog,
    openDialog: openJsonViewerDialog,
    closeDialog: closeJsonViewerDialog,
    data: dataJsonViewerDialog,
  } = useDialog<{ title: string; json: JSON }>()

  function handleOpenJsonViewerDialog(value: string, origem: string) {
    openJsonViewerDialog({ title: origem, json: JSON.parse(value) })
  }

  const dataFormatada = useMemo(() => {
    return data?.map((item) => {
      return {
        ...item,
        dataCriacao: toBrazilianDate(item.dataCriacao),
      }
    })
  }, [])

  const columns = useMemo((): MUIDataTableColumnDef[] => {
    return [
      {
        name: 'dataCriacao',
        label: 'Data',
      },
      {
        name: 'operacaoDesc',
        label: 'Operação',
      },
      {
        name: 'bodyRequisicaoApi',
        label: 'Api/Requisição',
        options: {
          customBodyRender: (value) => {
            return (
              <Button
                startIcon={<SearchIcon />}
                variant="text"
                fullWidth
                onClick={() =>
                  handleOpenJsonViewerDialog(value, 'Api/Requisição')
                }
              >
                Visualizar
              </Button>
            )
          },
        },
      },
      {
        name: 'bodyRespostaApi',
        label: 'Api/Resposta',
        options: {
          customBodyRender: (value) => {
            return (
              <Button
                startIcon={<SearchIcon />}
                variant="text"
                fullWidth
                onClick={() =>
                  handleOpenJsonViewerDialog(value, 'Api/Resposta')
                }
              >
                Visualizar
              </Button>
            )
          },
        },
      },
      {
        name: 'bodyRequisicaoBanco',
        label: 'Banco/Requisição',
        options: {
          customBodyRender: (value) => {
            return (
              <Button
                startIcon={<SearchIcon />}
                variant="text"
                fullWidth
                onClick={() =>
                  handleOpenJsonViewerDialog(value, 'Banco/Requisição')
                }
              >
                Visualizar
              </Button>
            )
          },
        },
      },
      {
        name: 'bodyRespostaBanco',
        label: 'Banco/Resposta',
        options: {
          customBodyRender: (value) => {
            return (
              <Button
                startIcon={<SearchIcon />}
                variant="text"
                fullWidth
                onClick={() =>
                  handleOpenJsonViewerDialog(value, 'Banco/Resposta')
                }
              >
                Visualizar
              </Button>
            )
          },
        },
      },
    ]
  }, [handleOpenJsonViewerDialog, data])

  return (
    <>
      <Dialog
        title="Logs do Boleto"
        maxWidth="md"
        open={isOpenBoletoLogsDialog}
        onClose={closeBoletoLogsDialog}
        actions={
          <ButtonContainer>
            <Button variant="contained" onClick={closeBoletoLogsDialog}>
              Cancelar
            </Button>
          </ButtonContainer>
        }
      >
        <DataTable data={dataFormatada || []} columns={columns} />
      </Dialog>

      <JsonViewerDialog
        data={dataJsonViewerDialog?.json}
        title={dataJsonViewerDialog?.title}
        isOpen={isOpenJsonViewerDialog}
        onClose={closeJsonViewerDialog}
      />
    </>
  )
}
