import { Stack, TextField } from '@mui/material'

import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import { ConvenioFilters, ConvenioFilters1 } from '@hooks/queries/useConvenio'
import FilterProps from 'interfaces/FilterProps'

export default function Filter({
  onApplyFilters,
}: FilterProps<ConvenioFilters1>) {
  const { onChangeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<ConvenioFilters, ConvenioFilters1>()
  return (
    <FilterContainer
      title="Pesquisar por Convênios"
      triggerButtonProps={{ variant: 'outlined' }}
      onApplyFilters={(f) => onApplyFilters(f)}
    >
      <Stack spacing={1.5}>
        <TextField
          name="query"
          label={filterValues?.query?.label}
          value={filterValues?.query?.value}
          onChange={onChangeFilterValue}
        />
      </Stack>
    </FilterContainer>
  )
}
