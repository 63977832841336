import { Button } from '@data-c/ui'
import { Alert, Box, Typography } from '@mui/material'

interface SelectAllProps {
  total: number
  rowsSelected: any[]
  onClick: () => void
}

export default function SelectAll(props: SelectAllProps) {
  const { total, rowsSelected, onClick } = props

  return (
    <>
      <Alert severity="info">
        Você selecionou <b>{rowsSelected.length}</b> lançamento
        {rowsSelected.length > 1 ? 's' : ''} nesta página.
      </Alert>
      {total - rowsSelected.length > 0 && (
        <Box>
          <Button onClick={onClick}>
            <Typography variant="body2">
              Selecionar todos os outros <b>{total - rowsSelected.length}</b>{' '}
              lançamentos.
            </Typography>
          </Button>
        </Box>
      )}
    </>
  )
}
