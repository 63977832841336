import { InputHTMLAttributes, useState } from 'react'
import { InputState } from 'react-input-mask'
import MaskedTextField from 'components/MaskedTextField'
import { TextFieldProps } from '@mui/material'

export enum MASCARA {
  INTERMEDIARIA = '(99) 9999-99999',
  FIXO = '(99) 9999-9999',
  CELULAR = '(99) 9 9999-9999',
}

interface TelefoneTextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  inputProps?: TextFieldProps
  desabilitarNonoDigito?: boolean
}

export default function TelefoneTextField(props: TelefoneTextFieldProps) {
  const [mask, setMask] = useState(MASCARA.FIXO)

  function beforeMaskedValueChange(
    newState: InputState,
    oldState: InputState,
    userInput: string,
  ): InputState {
    let selection = newState.selection || null

    if (userInput && newState.selection && oldState.selection) {
      let newStart = newState.selection.start
      let oldStart = oldState.selection.start

      if (newStart < 13 && mask !== MASCARA.FIXO) {
        setMask(MASCARA.FIXO)
      }

      if (newStart === 13 && oldStart === 12) {
        setMask(MASCARA.FIXO)
      }

      if (newStart === 14 && oldStart === 13) {
        setMask(MASCARA.INTERMEDIARIA)
      }

      if (newStart === 15 && oldStart === 14) {
        const mascaraDeCelular = props?.desabilitarNonoDigito
          ? MASCARA.FIXO
          : MASCARA.CELULAR
        setMask(mascaraDeCelular)
        selection = { start: 16, end: 16 }
      }
    }

    const { value } = newState

    return {
      value,
      selection,
    }
  }

  return (
    <>
      <MaskedTextField
        mask={mask}
        {...props}
        beforeMaskedValueChange={beforeMaskedValueChange}
      />
    </>
  )
}
