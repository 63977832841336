import { useEffect, useState } from 'react'

import { Box, IconButton, Paper, useTheme } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import { PageTitle } from '@data-c/ui'

import usePDF from 'hooks/queries/usePDF'
import useNotification from 'hooks/useNotifications'

import Error from 'components/Displays/Error'
import Loading from 'components/Displays/Loading'
import DropDownRelatorio from 'components/Inputs/DropDownRelatorio'
import FixedContainer from 'components/FixedContainer'
import { AxiosRequestConfig } from 'axios'

interface PDFViewerProps {
  chaveId: string
  pdfIds?: string[] | (string | undefined)[]
  relatorioPath?: string
  pdfPath: string
  pdfAxiosRequestConfig?: AxiosRequestConfig<any>
  title?: string
  onClose?: () => void
  onBack?: () => void
}

export default function PDFViewer(props: PDFViewerProps) {
  const {
    chaveId,
    pdfIds,
    relatorioPath,
    pdfPath,
    pdfAxiosRequestConfig,
    title,
    onClose,
    onBack,
  } = props

  const theme = useTheme()
  const notifications = useNotification()

  const [pdfUrl, setPdfUrl] = useState('')
  const [relatorioId, setRelatorioId] = useState('')
  const [isLoadingPDF, setLoadingPDF] = useState(false)
  const [errorPDF, setErrorPDF] = useState<unknown>()

  const { useQueryObterRelatorioPorChaveId } = usePDF()
  const {
    data: relatorio,
    isLoading: isLoadingRelatorio,
    error: errorRelatorio,
  } = useQueryObterRelatorioPorChaveId(chaveId, relatorioPath)
  const { obterPDF } = usePDF()

  async function _obterPDF(relatorioId: string) {
    try {
      setLoadingPDF(true)

      const response = await obterPDF(
        pdfPath,
        {
          ...pdfAxiosRequestConfig,
          params: { ...pdfAxiosRequestConfig?.params, relatorioId },
        },
        pdfIds,
      )

      const file = new Blob([response], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)

      setPdfUrl(fileURL)
    } catch (error) {
      notifications.notifyException(error)
      setErrorPDF(error)
    } finally {
      setLoadingPDF(false)
    }
  }

  useEffect(() => {
    const relatorioId = relatorio?.data[0]?.id || ''
    setRelatorioId(relatorioId)
    if (relatorioId) {
      _obterPDF(relatorioId)
    }
  }, [relatorio?.data])

  const isLoading = isLoadingRelatorio || isLoadingPDF
  const error = errorRelatorio || errorPDF

  return (
    <Paper
      sx={{
        height: 'calc(100vh - 70px)',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <FixedContainer
        fixIn="none"
        customSx={{
          borderBottom: `solid 1px ${theme.palette.grey[300]}`,
          padding: '0 16px',
        }}
      >
        <PageTitle title={title ? title : chaveId} onBack={onBack}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
              marginTop: 2,
            }}
          >
            <DropDownRelatorio
              value={relatorioId}
              data={relatorio?.data || []}
              onChange={(e) => {
                const relatorioId = e.target.value
                setRelatorioId(relatorioId)
                _obterPDF(relatorioId)
              }}
              sx={{ width: '300px' }}
            />

            {onClose && (
              <IconButton onClick={onClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
        </PageTitle>
      </FixedContainer>

      <Box
        sx={{
          position: 'relative',
          height: '100%',
          marginTop: '78px',
        }}
      >
        {isLoading && <Loading customSx={{ zIndex: 0 }} />}
        {Boolean(error) && <Error />}
        {!isLoading && Boolean(!error) && (
          <iframe width="100%" height="100%" src={pdfUrl} />
        )}
      </Box>
    </Paper>
  )
}
