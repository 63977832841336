import { useNavigate } from 'react-router-dom'
import { Grid, TextField } from '@mui/material'

import { onlyNumbers } from '@data-c/hooks'
import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import { EmpresaCadastroModel } from '@hooks/queries/useEmpresaCadastro'
import useConvenio, { ConvenioModel } from '@hooks/queries/useConvenio'
import useValidations from '@hooks/useValidations'

import DropDownBancoCodigo, {
  DropDownBancoCodigoEnum,
} from '@components/Inputs/DropDownBancoCodigo'

import * as yup from 'yup'

const schema = yup.object().shape({
  bancoCodigo: yup.number().required('Informe o Código do Banco'),
  convenioNumero: yup
    .string()
    .required('Informe o número do convênio')
    .nullable(),
  carteiraVariacao: yup
    .string()
    .nullable()
    .when('bancoCodigo', ([bancoCodigo], schema) => {
      if (bancoCodigo === DropDownBancoCodigoEnum.BANCO_BRASIL_1) {
        return schema.required('Informe a Carteira Variação')
      }

      return schema
    }),
  agencia: yup.string().required('Informe a Agencia').nullable(),
  contaCorrente: yup.string().required('Informe a Conta Corrente').nullable(),
})

interface FormProps {
  empresa: EmpresaCadastroModel | null
}

export default function Form(props: FormProps) {
  const { empresa } = props

  const navigate = useNavigate()
  const { setValidationErrors, validationProps } = useValidations()

  const { changeValue, toggleSubmit } = useFormApi<ConvenioModel>()
  const { formValues: data } = useFormData<ConvenioModel>()

  const { useCreateOrUpdateConvenio } = useConvenio()
  const { mutateAsync: createOrUpdateConvenio } = useCreateOrUpdateConvenio()

  async function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        const convenio = await createOrUpdateConvenio({
          ...data,
          empresaId: empresa?.id || '',
        })
        if (convenio?.id) {
          navigate(`/convenios/convenio/${convenio.id}`)
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  const isBancoSicoobOuItau = [
    DropDownBancoCodigoEnum.SICOOB_756,
    DropDownBancoCodigoEnum.ITAU_341,
  ].includes(data?.bancoCodigo)
  const isBancoBrasil =
    data?.bancoCodigo === DropDownBancoCodigoEnum.BANCO_BRASIL_1

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      dialogProps={{
        title: 'Cadastro de Convênio',
        maxWidth: 'sm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="descricao"
            label="Descrição"
            value={data?.descricao || ''}
            onChange={(e) => changeValue('descricao', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
          <DropDownBancoCodigo
            name="bancoCodigo"
            required
            value={data?.bancoCodigo || ''}
            onChange={(value, valueDescription) => {
              changeValue('bancoCodigo', value)
              changeValue('bancoNomeDesc', valueDescription)
            }}
            {...validationProps('bancoCodigo')}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <TextField
            name="agencia"
            label="Agência"
            required
            value={data?.agencia || ''}
            inputProps={{ maxLength: 10 }}
            onChange={(e) =>
              changeValue('agencia', onlyNumbers(e.target.value || '') || null)
            }
            {...validationProps('agencia')}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            name="contaCorrente"
            label={isBancoSicoobOuItau ? 'Conta Corrente/DV' : 'Conta Corrente'}
            required
            value={data?.contaCorrente || ''}
            inputProps={{ maxLength: 10 }}
            onChange={(e) =>
              changeValue(
                'contaCorrente',
                onlyNumbers(e.target.value || '') || null,
              )
            }
            {...validationProps('contaCorrente')}
          />
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TextField
            name="convenioNumero"
            label="Número do Convênio"
            required
            value={data?.convenioNumero || ''}
            inputProps={{ maxLength: 20 }}
            onChange={(e) =>
              changeValue(
                'convenioNumero',
                onlyNumbers(e.target.value || '') || null,
              )
            }
            {...validationProps('convenioNumero')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="carteiraCodigo"
            label="Carteira"
            inputProps={{ maxLength: 3 }}
            value={data?.carteiraCodigo || ''}
            onChange={(e) =>
              changeValue('carteiraCodigo', e.target.value || null)
            }
            {...validationProps('carteiraCodigo')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="carteiraVariacao"
            label="Carteira Variação"
            required={isBancoBrasil}
            value={data?.carteiraVariacao || ''}
            inputProps={{ maxLength: 2 }}
            onChange={(e) =>
              changeValue('carteiraVariacao', e.target.value || null)
            }
            {...validationProps('carteiraVariacao')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
