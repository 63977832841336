import { ReactNode, createContext, useContext, useState } from 'react'
import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'

interface BoletoContextData {
  isLoading: boolean
  boleto: BoletoDetalhadoModel | null
  setBoleto: (boleto: BoletoDetalhadoModel | null) => void
  setLoading: (loading: boolean) => void
}

interface BoletoProviderProps {
  children: ReactNode
}

export const BoletoContext = createContext({} as BoletoContextData)

export default function BoletoProvider({ children }: BoletoProviderProps) {
  const [boleto, setBoleto] = useState<BoletoDetalhadoModel | null>(null)
  const [isLoading, setLoading] = useState(false)

  function handleSetLoading(loading: boolean) {
    setLoading(loading)
  }

  return (
    <BoletoContext.Provider
      value={{
        boleto,
        isLoading,
        setBoleto,
        setLoading: handleSetLoading,
      }}
    >
      {children}
    </BoletoContext.Provider>
  )
}

export const useBoletoContext = () => useContext(BoletoContext)
