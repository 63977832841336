import { useState } from 'react'
import ButtonBox from '@components/ButtonBox'
import Button from '@components/Button'
import useCredentials, { LoginDataInterface } from '@hooks/useCredentials'
import { Grid, MenuItem, TextField } from '@mui/material'

interface LicencaFormProps {
  loginData?: LoginDataInterface
  onSelecionarLicencaSuccess: () => void
}

const LicencaForm = ({
  loginData,
  onSelecionarLicencaSuccess,
}: LicencaFormProps) => {
  const [empresaId, setEmpresaId] = useState<string>('')
  const { selecionarLicenca, isSubmitting } = useCredentials()

  async function handleSelecionarLicenca() {
    const response = await selecionarLicenca(loginData, empresaId)
    if (response) {
      onSelecionarLicencaSuccess()
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
        <TextField
          select
          fullWidth
          variant="outlined"
          label="Licenças"
          size="small"
          value={empresaId}
          onChange={(e) => {
            setEmpresaId(e.target.value)
          }}
        >
          {loginData?.empresas?.map((empresa) => (
            <MenuItem value={empresa.uuid} key={empresa.uuid}>
              {empresa.nome}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
        <ButtonBox>
          <Button
            isLoading={isSubmitting}
            onClick={handleSelecionarLicenca}
            color="primary"
            variant="contained"
            size="small"
          >
            Confirmar
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}

export default LicencaForm
