import { Box, IconButton, Icon, IconButtonProps, Badge } from '@mui/material'
import { Edit as Editicon, Delete as DeleteIcon } from '@mui/icons-material'
import Tooltip from '@components/Tooltip'

interface TooltipProps {
  type: 'warning' | 'info'
  content: NonNullable<React.ReactNode>
}

interface ExtraOptionsProps {
  id: string
  icon: string
  iconButtonProps?: IconButtonProps
  tooltip?: TooltipProps
  visible?: boolean
  displayBadge?: boolean
}

interface OptionProps<T> {
  onClick(event: string, value: T): any
  value: T
  extraOptions?: Array<ExtraOptionsProps>
  displayUpdateButton?: boolean
  displayDeleteButton?: boolean
}

export const OptionStyles = () => ({
  style: {
    width: '80px',
    textAlign: 'center',
  },
})

const Options = <T extends unknown>(props: OptionProps<T>) => {
  const {
    onClick,
    value,
    extraOptions,
    displayUpdateButton,
    displayDeleteButton,
  } = props

  return (
    <Box>
      {extraOptions &&
        extraOptions.map(
          ({
            id,
            icon,
            iconButtonProps,
            tooltip,
            visible,
            displayBadge,
          }: ExtraOptionsProps) => {
            if (visible !== undefined && !visible) {
              return null
            }

            return (
              <Badge
                key={id}
                color="error"
                overlap="circular"
                badgeContent={displayBadge ? ' ' : 0}
                variant="dot"
              >
                <IconButton
                  key={id}
                  onClick={() => onClick(id, value)}
                  size="small"
                  {...iconButtonProps}
                >
                  {tooltip && (
                    <Tooltip type={tooltip.type} title={tooltip.content}>
                      <Icon fontSize="small">{icon}</Icon>
                    </Tooltip>
                  )}

                  {!tooltip && <Icon fontSize="small">{icon}</Icon>}
                </IconButton>
              </Badge>
            )
          },
        )}

      {displayUpdateButton && (
        <IconButton size="small" onClick={() => onClick('edit', value)}>
          <Tooltip
            type="info"
            title={<Box>Clique para editar este registro.</Box>}
          >
            <Editicon fontSize="small" />
          </Tooltip>
        </IconButton>
      )}

      {displayDeleteButton && (
        <IconButton size="small" onClick={() => onClick('delete', value)}>
          <Tooltip
            type="warning"
            title={<Box>Clique para remover este registro.</Box>}
          >
            <DeleteIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      )}
    </Box>
  )
}

Options.defaultProps = {
  displayUpdateButton: true,
  displayDeleteButton: true,
}

export default Options
