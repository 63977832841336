import {
  Grid,
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
  useTheme,
} from '@mui/material'

export default function Divider(props: MuiDividerProps) {
  const theme = useTheme()

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <MuiDivider
        sx={{
          '&.MuiDivider-root::before': { width: 0 },
          color: theme.palette.grey[500],
          fontWeight: 'bold',
        }}
        {...props}
      />
    </Grid>
  )
}
