import { Grid, TextField } from '@mui/material'
import { FormProvider } from '@data-c/providers'

import { DropDownTipoProtestoEnum } from '@components/Inputs/DropDownTipoProtesto'
import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import CardDynamic from '@components/CardDynamic'

import {
  AlterarBoletoProtestoModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'

interface BoletoProtestoProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoProtesto({ boleto }: BoletoProtestoProps) {
  const {
    id,
    protestoTipo,
    protestoTipoDesc,
    protestoDias,
    diasParaNegativacao,
    diasLimiteRecebimento,
    status,
    statusDesc,
  } = boleto || {}

  const dataProtesto: AlterarBoletoProtestoModel = {
    protestoTipo: protestoTipo || DropDownTipoProtestoEnum.INDEFINIDO_0,
    protestoDias,
  }

  const isBoletoIncluido = status === DropDownBoletoStatusEnum.INCLUIDO_0

  return (
    <FormProvider>
      <Form boletoId={id || ''} />
      <CardDynamic
        title="Protesto"
        data={dataProtesto}
        disabledAllChildren
        disableButton={!isBoletoIncluido}
        tooltipTitle={`Não é possível editar o Protesto de um boleto ${
          statusDesc || 'que não esteja incluído'
        }`}
        initialOpacity
        gridContainer
      >
        <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
          <TextField label="Protesto" value={protestoTipoDesc || ''} />
        </Grid>
        <Grid item xl={2} lg={4} md={4} sm={12} xs={12}>
          <TextField label="Dias Protesto" value={protestoDias || ''} />
        </Grid>
        <Grid item xl={2} lg={4} md={4} sm={12} xs={12}>
          <TextField
            label="Dias Negativação"
            value={diasParaNegativacao || ''}
          />
        </Grid>
        <Grid item xl={2} lg={4} md={4} sm={12} xs={12}>
          <TextField
            label="Dias Limite Recebimento"
            value={diasLimiteRecebimento || ''}
          />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
