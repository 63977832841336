import { useRef } from 'react'

import UnlayerEmailEditor from 'react-email-editor'

const EmailEditor = () => {
  const emailEditorRef = useRef(null)

  const exportHtml = () => {
    // emailEditorRef.current.editor.exportHtml((data) => {
    //   const { design, html } = data
    //   console.log('exportHtml', html)
    // })
  }

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  }

  const onReady = () => {
    // editor is ready
    // console.log('onReady')
  }

  return (
    <div>
      <div>
        <button onClick={exportHtml}>Export HTML</button>
      </div>

      <UnlayerEmailEditor
        options={{
          locale: 'pt-BR',
        }}
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
      />
    </div>
  )
}

export default EmailEditor
