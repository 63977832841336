import { FormProvider } from '@data-c/providers'

import { DropDownTipoDescontoEnum } from '@components/Inputs/DropDownTipoDesconto'
import OptionList from '@components/OptionsListContainer/components/OptionList'

import {
  AlterarBoletoDescontoModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'
import { OrigemType } from '../..'

interface DescontoProps {
  boleto: BoletoDetalhadoModel | null
}

export default function Desconto({ boleto }: DescontoProps) {
  const { descontoTipo, descontoValor, descontoDataExpiracao } = boleto || {}

  const dataAlterarDesconto: AlterarBoletoDescontoModel = {
    descontoTipo: descontoTipo || DropDownTipoDescontoEnum.SEM_DESCONTO_0,
    descontoValor: descontoValor || 0,
    descontoDataExpiracao,
  }

  return (
    <FormProvider>
      <OptionList
        name={OrigemType.DESCONTO}
        noRequest={{ initialData: dataAlterarDesconto }}
        form={<Form boleto={boleto} />}
      />
    </FormProvider>
  )
}
