import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import { AmbienteProps, AmbienteContext } from 'contexts/ambiente'
import { useAmbienteContext } from 'contexts/AmbienteContext/AmbienteContext'

import useCredentials from '@hooks/useCredentials'

import Menu, { MenuItemProps } from '@components/Menu'
import Header from '@components/Header'
import AmbienteNotFound from '@components/Displays/AmbienteNotFound'

const toolBarHeight = 64

interface LayoutAutenticadoProps {
  children?: React.ReactNode
}

export function menuItems(): Array<MenuItemProps> {
  const { possuiEmailDatac } = useCredentials()

  return [
    {
      id: '1',
      label: 'E-mail',
      icon: 'mail',
      visible: true,
      items: [
        {
          id: '1',
          label: 'Envio de Boletos',
          link: '/envio-boletos',
          visible: true,
        },
        {
          id: '2',
          label: 'Templates',
          link: '/templates',
          visible: true,
        },
        {
          id: '3',
          label: 'Agendamentos',
          link: '/modelos',
          visible: true,
        },
      ],
    },
    {
      id: '2',
      label: 'Boletos',
      icon: 'request_page_icon',
      link: '/boletos/lista',
      visible: possuiEmailDatac ? true : false,
    },
    {
      id: '3',
      label: 'Convênios',
      icon: 'account_balance',
      link: '/convenios/lista',
      visible: possuiEmailDatac ? true : false,
    },
    // {
    //   id: '4',
    //   label: 'Histórico de Envios',
    //   icon: 'history',
    //   link: '/log',
    // },
    // {
    //   id: '2',
    //   label: 'Config',
    //   icon: 'settings',
    //   items: [
    //     {
    //       id: '3',
    //       label: 'Modelos de E-mails',
    //       icon: 'mail',
    //       link: '/templates',
    //     },
    //   ],
    // },
  ]
}

const LayoutAutenticado = (props: LayoutAutenticadoProps) => {
  const { children } = props

  const { ambienteNotFound } = useAmbienteContext()

  const [ambiente, setAmbiente] = useState<AmbienteProps | null>(null)
  const navigate = useNavigate()
  const { isAuthenticated } = useCredentials()

  const trocarAmbiente = (ambienteNovo: AmbienteProps | null) => {
    if (ambienteNovo) {
      const ambienteAsJSON = JSON.stringify(ambienteNovo)
      localStorage.setItem(
        '@datac-emissor-boletos:ambiente-1.0.0',
        ambienteAsJSON,
      )
    } else {
      localStorage.removeItem('@datac-emissor-boletos:ambiente-1.0.0')
    }
    setAmbiente(ambienteNovo)
  }

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate('/')
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
    const storedAmbienteAsJSON = localStorage.getItem(
      '@datac-emissor-boletos:ambiente-1.0.0',
    )
    if (storedAmbienteAsJSON) {
      setAmbiente(JSON.parse(storedAmbienteAsJSON))
    }
  }, [])

  return (
    <Box
      sx={{
        height: `calc(100vh - ${toolBarHeight}px)`,
        display: 'flex',
        marginTop: `${toolBarHeight}px`,
      }}
    >
      <AmbienteContext.Provider value={{ ambiente, trocarAmbiente }}>
        <Header />
        <Menu menus={menuItems()} />
        <Box sx={{ position: 'relative', flex: '1' }}>
          {ambienteNotFound ? <AmbienteNotFound /> : children}
        </Box>
      </AmbienteContext.Provider>
    </Box>
  )
}

export default LayoutAutenticado
