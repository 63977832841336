import { Grid, TextField } from '@mui/material'
import { FormProvider } from '@data-c/providers'

import DatePicker from '@components/DatePicker'
import CardDynamic from '@components/CardDynamic'

import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'

interface BoletoInformacoesProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoInformacoes({ boleto }: BoletoInformacoesProps) {
  return (
    <FormProvider>
      <CardDynamic
        title=""
        disabledAllChildren
        initialOpacity
        gridContainer
        hiddenButton
        hiddenCollapse
      >
        <Grid item xl={4} lg={7} md={12} sm={12} xs={12}>
          <TextField label="Identificador" value={boleto?.id || ''} />
        </Grid>
        <Grid item xl={4} lg={5} md={12} sm={12} xs={12}>
          <TextField
            label="Convênio"
            value={boleto?.convenioBancoCodigoDesc || ''}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <TextField label="Origem" value={boleto?.origemDesc || ''} />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Criação"
            value={boleto?.dataCriacao || null}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Alteração"
            value={boleto?.dataAlteracao || null}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Cancelamento"
            value={boleto?.dataCancelamento || null}
            onChange={() => {}}
          />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
