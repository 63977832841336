import { useEffect, useState } from 'react'
import { isCPFOrCNPJ } from 'brazilian-values'
import { Grid, TextField } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { FormContainer } from '@data-c/ui'
import { onlyNumbers } from '@data-c/hooks'

import PessoaCadastroTransporter from '@components/Transporters/PessoaCadastroTransporter'
import CnpjCpfTextField from '@components/CnpjCpfTextField'
import CEPTextField from '@components/CEPTextField'
import AutoCompleteUF from '@components/AutoCompletes/AutoCompleteUF'
import AutoCompleteCidade from '@components/AutoCompletes/AutoCompleteCidade'
import TelefoneTextField from '@components/TelefoneTextField'

import { PessoaCadastroModel } from '@hooks/queries/usePessoaCadastro'
import { CidadeModel, UFModel } from '@hooks/queries/useCidade'
import useBoleto, { AlterarBoletoPagadorModel } from '@hooks/queries/useBoleto'
import useValidations from '@hooks/useValidations'

import * as yup from 'yup'

const schema = yup.object().shape({
  cnpjcpf: yup
    .string()
    .nullable()
    .required('Informe um CNPJ/CPF')
    .min(11, 'Informe 11 caracteres para CPF ou 14 para CNPJ')
    .test(
      'CPF/CNPJ-Validator',
      'Informe um CNPJ/CPF válido',
      (cnpjcpf: any) => {
        return isCPFOrCNPJ(cnpjcpf)
      },
    ),
  cep: yup
    .string()
    .required('Informe o cep')
    .length(8, 'O CEP deve ser composto por 8 caracteres')
    .nullable(),
  logradouro: yup.string().required('Informe o logradouro').nullable(),
  bairro: yup.string().required('Informe o bairro').nullable(),
  cidadeNome: yup.string().required('Informe o nome da cidade').nullable(),
  uf: yup.string().required('Informe o UF').nullable(),
  nome: yup.string().required('Informe o pagador').nullable(),
})

interface FormProps {
  boletoId: string
}

export default function Form({ boletoId }: FormProps) {
  const { setValidationErrors, validationProps } = useValidations()

  const { formValues: data, isOpen } = useFormData<AlterarBoletoPagadorModel>()
  const { changeValue, toggleSubmit, closeForm, changeValues } =
    useFormApi<AlterarBoletoPagadorModel>()

  const { useAlterarBoletoPagador } = useBoleto()
  const { mutateAsync: alterarBoletoPagador } = useAlterarBoletoPagador()

  const [podeBuscar, setPodeBuscar] = useState(true)

  useEffect(() => {
    setPodeBuscar(false)
  }, [isOpen])

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoPagador({
          boletoId,
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  function handleChangePessoa(pessoa: PessoaCadastroModel | null) {
    const {
      cidadeId,
      cidadeNome,
      cidadeUF,
      cidadeUFCodigoIBGE,
      documentoId,
      nome,
      logradouro,
      complemento,
      bairro,
      cep,
      telefone,
      email,
    } = pessoa || {}

    const ufModel: UFModel = {
      id: cidadeUF || '',
      codigoIBGE: cidadeUFCodigoIBGE || '',
    }

    const cidade = {
      id: cidadeId || '',
      nome: cidadeNome || '',
      uf: ufModel,
      ufId: cidadeUF || '',
    } as CidadeModel

    changeValues({
      ...data,
      pessoa,
      cnpjcpf: documentoId || '',
      uf: cidadeUF || '',
      ufModel,
      cidadeNome: cidadeNome || '',
      cidade,
      nome: nome || '',
      logradouro: logradouro || '',
      complemento,
      bairro: bairro || '',
      cep: cep || '',
      telefone,
      email,
    })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      dialogProps={{
        title: 'Alterar Pagador do Boleto',
        maxWidth: 'md',
      }}
      triggerButton={<></>}
    >
      <Grid container spacing={2}>
        <Grid item xl={7} lg={8} md={8} sm={12} xs={12}>
          <PessoaCadastroTransporter
            name="nome"
            label="Pagador"
            required
            hideBackdrop
            value={data?.pessoa || null}
            onChange={handleChangePessoa}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
          <CnpjCpfTextField
            name="cnpjcpf"
            required
            value={data?.cnpjcpf || ''}
            inputProps={{ label: 'CPF/CNPJ' }}
            podePesquisarCnpj={podeBuscar}
            onChange={(e) => {
              setPodeBuscar(true)
              changeValue('cnpjcpf', onlyNumbers(e.target.value) || '')
            }}
            onFindCnpj={(cnpj) => {
              if (cnpj) {
                const {
                  cidadeModel: cidade,
                  ufModel,
                  nomeFantasia,
                  numero,
                  ...rest
                } = cnpj

                changeValues({
                  ...data,
                  ...rest,
                  cidade,
                  cidadeNome: cidade?.nome || '',
                  uf: ufModel?.id || '',
                  ufModel,
                })
              }
            }}
            {...validationProps('cnpjcpf')}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
          <CEPTextField
            name="cep"
            required
            value={data?.cep || ''}
            inputProps={{ label: 'CEP' }}
            onChange={(e) => {
              setPodeBuscar(true)
              changeValue('cep', onlyNumbers(e.target.value) || '')
            }}
            podePesquisarCep={podeBuscar}
            onFindCep={(cep) => {
              if (cep) {
                const { cidadeModel: cidade, uf: ufModel, ...rest } = cep

                setPodeBuscar(false)
                changeValues({
                  ...data,
                  ...rest,
                  cidade,
                  cidadeNome: cidade?.nome || '',
                  ufModel,
                  uf: ufModel?.id || '',
                })
              }
            }}
            {...validationProps('cep')}
          />
        </Grid>
        <Grid item xl={6} lg={5} md={9} sm={12} xs={12}>
          <TextField
            name="logradouro"
            label="Logradouro"
            required
            value={data?.logradouro || ''}
            onChange={(e) => changeValue('logradouro', e.target.value || '')}
            {...validationProps('logradouro')}
          />
        </Grid>
        <Grid item xl={6} lg={5} md={5} sm={12} xs={12}>
          <TextField
            name="bairro"
            label="Bairro"
            required
            value={data?.bairro || ''}
            onChange={(e) => changeValue('bairro', e.target.value || '')}
            {...validationProps('bairro')}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={7} sm={12} xs={12}>
          <TextField
            name="complemento"
            label="Complemento"
            value={data?.complemento || ''}
            onChange={(e) => changeValue('complemento', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={3} xs={12}>
          <AutoCompleteUF
            name="uf"
            label="UF"
            required
            value={data?.ufModel || null}
            onChange={(_, value) => {
              changeValues({
                ...data,
                ufModel: value || null,
                uf: value?.id || '',
                cidadeNome: '',
                cidade: null,
              })
            }}
            {...validationProps('uf')}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={9} sm={9} xs={12}>
          <AutoCompleteCidade
            name="cidadeNome"
            label="Cidade"
            required
            value={data?.cidade || null}
            uf={data?.uf || ''}
            onChange={(_, value) => {
              changeValues({
                ...data,
                cidade: value || null,
                cidadeNome: value?.nome || '',
              })
            }}
            {...validationProps('cidadeNome')}
          />
        </Grid>
        <Grid item xl={4} lg={3} md={3} sm={12} xs={12}>
          <TelefoneTextField
            label="Telefone"
            name="telefone"
            value={data?.telefone || ''}
            onChange={(e) => {
              changeValue('telefone', onlyNumbers(e.target.value) || null)
            }}
          />
        </Grid>
        <Grid item xl={8} lg={9} md={9} sm={12} xs={12}>
          <TextField
            name="email"
            label="E-mail"
            value={data?.email || ''}
            onChange={(e) => changeValue('email', e.target.value || null)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
