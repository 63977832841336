import { Box, BoxProps } from '@mui/material'

const ButtonBox = (props: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
      }}
      {...props}
    />
  )
}

export default ButtonBox
