import {
  Box,
  IconButton,
  IconButtonProps,
  Tooltip as MuiTooltip,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'

interface CollapseButtonProps extends IconButtonProps {
  toCollapse: boolean
  arrowColor: string
}

export default function CollapseButton(props: CollapseButtonProps) {
  const { toCollapse, arrowColor, ...rest } = props

  const theme = useTheme()

  return (
    <MuiTooltip title={toCollapse ? 'Recolher conteúdo' : 'Exibir conteúdo'}>
      <Box>
        <IconButton
          sx={{ backgroundColor: theme.palette.background.paper }}
          {...rest}
        >
          {toCollapse ? (
            <KeyboardArrowDownIcon
              fontSize="medium"
              sx={{ color: arrowColor }}
            />
          ) : (
            <KeyboardArrowUpIcon fontSize="medium" sx={{ color: arrowColor }} />
          )}
        </IconButton>
      </Box>
    </MuiTooltip>
  )
}
