import { Grid, TextField } from '@mui/material'

import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useConvenio, {
  ConvenioCredenciaisItauModel,
} from '@hooks/queries/useConvenio'
import useValidations from '@hooks/useValidations'

import DragDropFile from '@components/DragAndDrop'

import * as yup from 'yup'

const schema = yup.object().shape({
  certificadoCRT: yup.string().required('Informe o Arquivo Certificado CRT'),
  chaveKey: yup.string().required('Informe as Chaves Privadas KEY'),
})

interface FormProps {
  convenioId?: string
}

export default function Form(props: FormProps) {
  const { convenioId } = props

  const { setValidationErrors, validationProps } = useValidations()

  const { changeValue, closeForm, toggleSubmit } =
    useFormApi<ConvenioCredenciaisItauModel>()
  const { formValues: data } = useFormData<ConvenioCredenciaisItauModel>()

  const { useAtualizarConvenioCredenciaisItau } = useConvenio()
  const { mutateAsync: atualizarConvenioCredenciaisItau } =
    useAtualizarConvenioCredenciaisItau()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await atualizarConvenioCredenciaisItau({
          convenioId: convenioId || '',
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Itaú - Configurações de Credenciais de Acesso',
        maxWidth: 'sm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="client_id"
            label="Cliente Id"
            value={data?.client_id || ''}
            onChange={(e) => changeValue('client_id', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="client_secret"
            label="Cliente Secret"
            value={data?.client_secret || ''}
            onChange={(e) =>
              changeValue('client_secret', e.target.value || null)
            }
          />
        </Grid>
        {data?.CertificadoCRTString && data?.CertificadoPrivateKeyString && (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                name="CertificadoCRTString"
                label="Certificado CRT"
                value={data?.CertificadoCRTString || ''}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                name="CertificadoPrivateKeyString"
                label="Certificado Chaves Privadas"
                value={data?.CertificadoPrivateKeyString || ''}
              />
            </Grid>
          </>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DragDropFile
            extencaoDoArquivo=".crt"
            uploadMessage="Carregar arquivo certificado CRT '*.crt'"
            onDropped={(file) => {
              changeValue('certificadoCRT', file)
            }}
            {...validationProps('certificadoCRT')}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DragDropFile
            extencaoDoArquivo=".key"
            uploadMessage="Carregar chaves privadas KEY '*.key'"
            onDropped={(file) => {
              changeValue('chaveKey', file)
            }}
            {...validationProps('chaveKey')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
