import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import useEmpresaCadastro, {
  EmpresaCadastroModel,
} from '@hooks/queries/useEmpresaCadastro'

interface AmbienteData {
  empresa: EmpresaCadastroModel | null
  currentPath: string
  ambienteNotFound: boolean
  isLoading: boolean
  isOpenMenu: boolean
  setOpenMenu: (_isOpenMenu: boolean) => void
  setCurrentPath: (path: string) => void
  definirEmpresaNoAmbiente: (empresa: EmpresaCadastroModel | null) => void
}

interface AmbienteProps {
  children: ReactNode
}

export const AmbienteContext = createContext({} as AmbienteData)

export const empresaDefaultData = {
  nome: '',
  ativo: false,
}

export default function AmbienteProvider({ children }: AmbienteProps) {
  const [isLoading, setLoading] = useState(false)
  const [isOpenMenu, setOpenMenu] = useState(true)
  const [ambienteNotFound, setAmbienteNotFound] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const [empresa, setEmpresa] = useState<EmpresaCadastroModel | null>(
    empresaDefaultData,
  )

  const { useObterEmpresaCadastroPorId } = useEmpresaCadastro()
  const { obterEmpresaCadastroPorId, isLoadingObterEmpresaCadastroPorId } =
    useObterEmpresaCadastroPorId()

  useEffect(() => {
    setLoading(isLoadingObterEmpresaCadastroPorId)
  }, [isLoadingObterEmpresaCadastroPorId])

  useEffect(() => {
    const rotasDependentesDeAmbiente = ['/boletos']
    const isRotaDependenteDoAmbiente = rotasDependentesDeAmbiente.some((rota) =>
      currentPath.startsWith(rota),
    )

    if (!empresa?.id && isRotaDependenteDoAmbiente) {
      setAmbienteNotFound(true)
    } else {
      setAmbienteNotFound(false)
    }
  }, [currentPath, empresa])

  useEffect(() => {
    async function obterPorId() {
      const empresaId = localStorage.getItem(
        'persist:@stella-hubbank:ambiente-empresaId',
      )

      if (empresaId) {
        const empresa = await obterEmpresaCadastroPorId(
          empresaId || '',
          'AMBIENTE',
        )
        if (empresa) {
          setEmpresa(empresa)
        }
      }
    }

    if (!empresa?.id) {
      obterPorId()
    }
  }, [empresa?.id])

  function definirEmpresaNoAmbiente(empresa: EmpresaCadastroModel | null) {
    if (empresa?.id) {
      localStorage.setItem(
        'persist:@stella-hubbank:ambiente-empresaId',
        empresa.id,
      )
    }
    setEmpresa(empresa)
  }

  return (
    <AmbienteContext.Provider
      value={{
        empresa,
        definirEmpresaNoAmbiente,
        ambienteNotFound,
        currentPath,
        isLoading,
        isOpenMenu,
        setCurrentPath,
        setOpenMenu: setOpenMenu,
      }}
    >
      {children}
    </AmbienteContext.Provider>
  )
}

export const useAmbienteContext = () => useContext(AmbienteContext)
