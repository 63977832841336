import { ReactNode } from 'react'
import { Box, Grid, Tooltip } from '@mui/material'

interface DisableAllChildrenProps {
  tooltipTitle?: string
  disabled: boolean
  initialOpacity?: boolean
  gridContainer?: boolean
  disableHoverListener?: boolean
  children: ReactNode
}

export default function DisableAllChildren(props: DisableAllChildrenProps) {
  const {
    tooltipTitle,
    disabled,
    initialOpacity = false,
    gridContainer = false,
    disableHoverListener = true,
    children,
  } = props

  return (
    <Tooltip title={tooltipTitle} disableHoverListener={disableHoverListener}>
      <Box sx={{ cursor: disabled ? 'not-allowed' : 'default' }}>
        <Box
          component="fieldset"
          sx={{
            padding: 0,
            pointerEvents: disabled ? 'none' : '',
            opacity: disabled && initialOpacity === false ? 0.6 : 'initial',
            border: 'none',
          }}
        >
          {gridContainer ? (
            <Grid container spacing={2}>
              {children}
            </Grid>
          ) : (
            children
          )}
        </Box>
      </Box>
    </Tooltip>
  )
}
