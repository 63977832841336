import moment from 'moment'
import _ from 'lodash'

const useUtils = () => {
  const formatCurrency = (value: string | number) => {
    if (!value) return ''

    return Number(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    })
  }

  const formatDate = (value: string, invalidDate = '') => {
    if (!value) return invalidDate
    return moment(value).format('DD/MM/YYYY')
  }

  const formatDateTime = (value: string, invalidDate = '') => {
    if (!value) return invalidDate
    return moment(value).format('DD/MM/YYYY hh:mm')
  }

  const snakeCase = (value: string) => {
    return _.snakeCase(value)
  }

  return {
    formatCurrency,
    formatDate,
    formatDateTime,
    snakeCase,
  }
}

export default useUtils
