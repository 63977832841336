import { ReactElement } from 'react'
import { CircularProgress, MenuItem, MenuItemProps, Stack } from '@mui/material'
import { useActionOptionsContext } from 'contexts/ActionOptionsContext/ActionOptionsContext'

type OptionListButtonProps<TOritem> = {
  label: string
  name: TOritem
  isLoading?: boolean
  icon?: ReactElement
} & MenuItemProps

export default function OptionListButton<TOritem>(
  props: OptionListButtonProps<TOritem>,
) {
  const { label, name, icon, isLoading, ...rest } = props

  const { setOrigem } = useActionOptionsContext()

  function handleClick() {
    setOrigem(name)
  }

  return (
    <MenuItem sx={{ m: 1 }} {...rest} onClick={handleClick}>
      <Stack direction="row" gap={1} alignItems="center">
        {icon && icon}
        {label}
        {isLoading && <CircularProgress size={12} />}
      </Stack>
    </MenuItem>
  )
}
