import { ReactNode, useMemo } from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { ButtonContainer } from '@data-c/ui'

interface CustomToolbarHeaderProps {
  countTitle?: string
  countQuantidade?: number
  customSx?: SxProps
  children?: ReactNode
}

export default function CustomToolbarHeader(props: CustomToolbarHeaderProps) {
  const { countTitle, countQuantidade, customSx, children } = props

  const flexSufixCountTitle = useMemo(() => {
    if (!countTitle) return ''

    return countQuantidade === 1
      ? `${countTitle} selecionado`
      : countQuantidade && countQuantidade > 1
      ? `${countTitle
          .split(' ')
          .map((item) => `${item}s`)
          .join('  ')} selecionados`
      : `Nenhum ${countTitle} selecionado`
  }, [countQuantidade])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: 2,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ...customSx,
      }}
    >
      <Typography variant="body1">
        {countQuantidade && countQuantidade > 0 ? countQuantidade : ''}{' '}
        {flexSufixCountTitle}
      </Typography>

      {children && <ButtonContainer>{children}</ButtonContainer>}
    </Box>
  )
}
