import { FormProvider } from '@data-c/providers'
import { ConvenioModel } from '@hooks/queries/useConvenio'
import OptionList from 'components/OptionsListContainer/components/OptionList'
import apiHubbank from 'services/api'
import Form from './components/Form'
import { OrigemType } from '../..'

interface ItauProps {
  convenio: ConvenioModel
}

export default function Itau(props: ItauProps) {
  const { convenio } = props

  return (
    <FormProvider>
      <OptionList
        name={OrigemType.ITAU}
        route="Convenio/ObterCredenciais"
        requestId={convenio?.id}
        parseResponseToJson
        api={apiHubbank}
        axiosRequestConfig={{
          params: { convenioId: convenio?.id },
        }}
        form={<Form convenioId={convenio?.id} />}
      />
    </FormProvider>
  )
}
