import { InputHTMLAttributes, useEffect } from 'react'
import { CircularProgress, InputAdornment, TextFieldProps } from '@mui/material'
import useConsultaCep, { CepModel } from 'hooks/queries/useConsultaCep'

import MaskedTextField from 'components/MaskedTextField'

interface CEPTextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  inputProps?: TextFieldProps
  value: string
  onFindCep?: (cep: CepModel) => void
  podePesquisarCep?: boolean
}

export default function CEPTextField(props: CEPTextFieldProps) {
  const { value, onFindCep, podePesquisarCep = false, ...rest } = props

  const mask = '99999-999'

  const { consultarCep, isLoading } = useConsultaCep()

  useEffect(() => {
    async function _consultarCep() {
      if (onFindCep && podePesquisarCep) {
        const cep = await consultarCep(value)
        if (cep) onFindCep(cep)
      }
    }

    if (value.length === 8) _consultarCep()
    // eslint-disable-next-line
  }, [value])

  return (
    <MaskedTextField
      mask={mask}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading ? <CircularProgress size={16} /> : <></>}
          </InputAdornment>
        ),
      }}
      value={value}
      {...rest}
    />
  )
}
