import { MouseEvent } from 'react'
import { Box, Typography, useTheme, Stack } from '@mui/material'
import { useFormApi } from '@data-c/providers'
import { CardDynamicProps } from '@components/CardDynamic'

import DeleteButton from './components/DeleteButton'
import CollapseButton from './components/CollapseButton'
import EditButton from './components/EditButton'

interface HeaderProps<T> extends Partial<CardDynamicProps<T>> {
  toCollapse: boolean
  onClick: () => void
}

export default function Header<T>(props: HeaderProps<T>) {
  const {
    title,
    tooltipTitle,
    deleteIconTitle,
    disabledDeleteIcon = false,
    hiddenButton = false,
    disableButton = false,
    data,
    toCollapse = false,
    hiddenCollapseButton = false,
    collapseOnClickHeader = true,
    iconTitle,
    cardSeverity = 'default',
    onClick,
    onClickDelete,
  } = props

  const theme = useTheme()
  const { openForm } = useFormApi()

  function handleClickDelete(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    onClickDelete && onClickDelete()
  }

  function handleClickEdit(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    openForm(data)
  }

  const borderColorMapping: Record<string, string> = {
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    primary: theme.palette.primary.main,
    default: theme.palette.grey[500],
  }

  return (
    <Box
      onClick={collapseOnClickHeader ? onClick : () => {}}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom:
          toCollapse === false
            ? `solid 1px ${
                cardSeverity !== 'default'
                  ? borderColorMapping[cardSeverity]
                  : theme.palette.grey[300]
              }`
            : 'none',
        ':hover': { cursor: collapseOnClickHeader ? 'pointer' : 'default' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginBottom: 1,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          {iconTitle}

          <Typography
            variant="body2"
            fontWeight="bold"
            fontSize="16px"
            color={borderColorMapping[cardSeverity]}
          >
            {title}
          </Typography>
        </Box>

        {hiddenButton === false && (
          <EditButton
            tooltipTitle={tooltipTitle}
            disabled={disableButton}
            onClick={handleClickEdit}
          />
        )}
      </Box>

      <Box
        sx={{
          marginBottom: 1,
          marginRight: 1,
          visibility: hiddenCollapseButton ? 'hidden' : 'initial',
        }}
      >
        <Stack title="" direction="row" gap={1}>
          {onClickDelete && (
            <DeleteButton
              deleteIconTitle={deleteIconTitle}
              disabled={disabledDeleteIcon}
              onClick={handleClickDelete}
            />
          )}

          <CollapseButton
            toCollapse={toCollapse}
            onClick={onClick}
            arrowColor={borderColorMapping[cardSeverity]}
          />
        </Stack>
      </Box>
    </Box>
  )
}
