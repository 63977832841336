import { useState } from 'react'
import {
  Box,
  // Divider,
  Typography,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  Stack,
} from '@mui/material'
import AlterarSenha from '../AlterarSenha'
import { ArrowDropDown, Logout, VpnKey as KeyIcon } from '@mui/icons-material'
import useCredentials from '@hooks/useCredentials'

const ProfileMenu = () => {
  const { logout, userLogged } = useCredentials()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [showChangePass, setShowChangePass] = useState(false)
  const menuOpen = Boolean(anchorEl)

  function handleOpen(event: any) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleLogout() {
    logout()
    // dispatch({ type: 'USER_LOGOUT' })
    // window.location.href = '/'
  }

  function handleOpenAlterarSenha() {
    setShowChangePass(!showChangePass)
  }

  return (
    <Box>
      <AlterarSenha
        email=""
        isOpen={showChangePass}
        onClose={handleOpenAlterarSenha}
      />
      <IconButton sx={{ borderRadius: 1.5 }} onClick={handleOpen}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar alt="photoURL" />

          <Typography
            variant="button"
            sx={{ color: (theme) => theme.palette.grey[900] }}
          >
            {userLogged ? userLogged.name : 'Usuário do Sistema'}
          </Typography>

          <ArrowDropDown sx={{ color: (theme) => theme.palette.grey[900] }} />
        </Stack>
      </IconButton>

      <Popover
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {/* <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Usuário do Sistema
          </Typography>
          <Typography variant="body2">080.459.786-35</Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <MenuItem onClick={handleOpenAlterarSenha} sx={{ m: 1 }}>
          <Stack direction="row" gap={1}>
            <KeyIcon />
            Alterar Senha
          </Stack>
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Stack direction="row" gap={1}>
            <Logout />
            Sair
          </Stack>
        </MenuItem>
      </Popover>
    </Box>
  )
}

export default ProfileMenu
