import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables'

import { PaginationProps } from '@hooks/usePagination'
import { Alert, LinearProgress, Stack } from '@mui/material'
import Pagination from './components/Pagination'
import SkeletonLoading from './components/SkeletonLoading'

interface DataTableProps extends Omit<MUIDataTableProps, 'title'> {
  isLoading?: boolean
  isFetching?: boolean
  tableTitle?: string
  pagination?: PaginationProps
  error?: string | null
}

const DataGridTextLabels = {
  body: {
    noMatch: 'Nenhum registro encontrado',
    toolTip: 'Ordenar',
  },
  pagination: {
    next: 'Próximo',
    previous: 'Anterior',
    rowsPerPage: 'Linhas por pág:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Procurar',
    downloadCsv: 'Download CSV',
    print: 'Imprimir',
    viewColumns: 'Colunas',
    filterTable: 'Procurar na Tabela',
  },
  filter: {
    all: 'Todos',
    title: 'Filtros',
    reset: 'Limpar',
  },
  viewColumns: {
    title: 'Visualizar Colunas',
    titleAria: 'Mostrar/Ocultar Colunas da Tabela',
  },
  selectedRows: {
    text: 'Linhas Selecionadas',
    delete: 'Remover',
    deleteAria: 'Remover Linhas Selecionadas',
  },
}

const defaultOptions: MUIDataTableOptions = {
  filterType: 'textField',
  selectableRows: 'none',
  elevation: 0,
  responsive: 'vertical',
  print: false,
  download: false,
  filter: false,
  search: false,
  viewColumns: false,
  textLabels: DataGridTextLabels,
  serverSide: true,
  rowsPerPageOptions: [15, 50, 100],
  rowsPerPage: 15,
  fixedHeader: false,
}

const DataTable = (props: DataTableProps) => {
  const {
    tableTitle,
    isLoading,
    isFetching,
    data,
    columns,
    options,
    pagination,
    error,
  } = props

  if (isLoading) return <SkeletonLoading />

  if (options) {
    options.customFooter = (
      rowCount,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      if (!pagination || isLoading) return <></>
      return (
        <Pagination
          count={pagination.totalRecords}
          page={pagination.page}
          rowsPerPage={pagination.pageSize}
          rowsPerPageOptions={
            options?.rowsPerPageOptions || defaultOptions?.rowsPerPageOptions
          }
          changeRowsPerPage={options?.onChangeRowsPerPage}
          changePage={options?.onChangePage}
          textLabels={textLabels}
        />
      )
    }
  }

  // if (!pagination || isLoading) return <></>

  return (
    <>
      {isFetching && <LinearProgress />}
      {error && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">{error}</Alert>
        </Stack>
      )}
      <MUIDataTable
        title={tableTitle}
        data={data}
        columns={columns}
        options={{ ...defaultOptions, ...options }}
      />
    </>
  )
}

export default DataTable
