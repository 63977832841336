import { useState } from 'react'
import { Transporter, TransporterProps } from '@data-c/ui'
import usePessoaCadastro, {
  PessoaCadastroModel,
} from 'hooks/queries/usePessoaCadastro'
import PessoaTable from './components/Table'

type PessoaCadastroTransporterProps = {
  hideBackdrop?: boolean
} & Omit<
  TransporterProps<PessoaCadastroModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function PessoaCadastroTransporter(
  props: PessoaCadastroTransporterProps,
) {
  const { value, hideBackdrop, ...rest } = props

  const [query, setQuery] = useState<string | null>()

  const { useQueryObterPessoasCadastro } = usePessoaCadastro()
  const { data, isLoading } = useQueryObterPessoasCadastro({
    query: query || '',
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={PessoaTable}
      label="Destinatário"
      optionKeyName="nome"
      renderValue={(value) => {
        return `${value.nome}`
      }}
      dialogProps={{
        title: 'Destinatário',
        maxWidth: 'xl',
        hideBackdrop,
      }}
      fullWidth
      value={value}
      {...rest}
    />
  )
}
