import { ReactNode } from 'react'
import { Box, SxProps } from '@mui/material'

interface AbsoluteTableContainerProps {
  children: ReactNode
  customSx?: SxProps
}

export default function AbsoluteTableContainer(
  props: AbsoluteTableContainerProps,
) {
  const { children, customSx } = props

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        width: '100%',
        padding: 3,
        paddingTop: 0,
        ...customSx,
      }}
    >
      {children}
    </Box>
  )
}
