import { ReactNode } from 'react'
import { Box, useTheme } from '@mui/material'
import FixedContainer from 'components/FixedContainer'

interface FooterProps {
  children: ReactNode
}

export default function FloatFooter(props: FooterProps) {
  const { children } = props
  const theme = useTheme()

  return (
    <FixedContainer>
      <Box
        sx={{
          paddingX: 2,
          paddingY: 3,
          border: `solid 1px ${theme.palette.grey[300]}`,
        }}
      >
        {children}
      </Box>
    </FixedContainer>
  )
}
