import { useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig } from 'axios'
import useDownload from 'hooks/useDownload'
import useNotification from 'hooks/useNotifications'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import api from 'services/api'

export interface RelatorioModel {
  id: string
  chaveId: string
  nome: string
  systemFlag: boolean
}

export async function obterRelatorioPorChaveId(
  chaveId: string,
  path?: string,
): Promise<HttpResponseInterface<RelatorioModel>> {
  const route = path ? path : 'ObterConsultaPorChaveId'

  const response = await api.get<HttpResponseInterface<RelatorioModel>>(
    `Relatorio/${route}`,
    {
      params: { chaveId },
    },
  )

  return response.data
}

export async function obterPDF(
  route: string,
  axiosRequestConfig?: AxiosRequestConfig<any>,
  ids?: string[] | (string | undefined)[],
) {
  const { relatorioId } = axiosRequestConfig?.params

  const configs:
    | AxiosRequestConfig<string[] | (string | undefined)[]>
    | undefined = {
    params: axiosRequestConfig?.params,
    headers: {
      'DC-PrintMode': 'PDF',
      'DC-PrintRelatorioId': relatorioId,
    },
    responseType: 'blob',
    ...axiosRequestConfig,
  }

  if (ids && ids?.length > 0) {
    const response = await api.post(route, ids, configs)
    return response.data
  }

  const response = await api.get(route, configs)
  return response.data
}

export function useObterPDF() {
  const { progress, start, stop, isDownloading, updateProgress } = useDownload()
  const notifications = useNotification()

  async function obterPDF(
    route: string,
    axiosRequestConfig?: AxiosRequestConfig<any>,
  ) {
    const { relatorioId } = axiosRequestConfig?.params

    try {
      start()
      const response = await api.get(route, {
        params: axiosRequestConfig?.params,
        headers: {
          'DC-PrintMode': 'PDF',
          'DC-PrintRelatorioId': relatorioId,
        },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          updateProgress(loaded, total || 0)
        },
        ...axiosRequestConfig,
      })

      return response.data
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      stop()
    }
  }

  return { obterPDF, progress, isDownloading }
}

export function useQueryObterRelatorioPorChaveId(
  chaveId: string,
  path?: string,
) {
  return useQuery<HttpResponseInterface<RelatorioModel>, AxiosError>(
    ['RELATORIO', chaveId],
    () => {
      return obterRelatorioPorChaveId(chaveId, path)
    },
  )
}

export default function usePDF() {
  return {
    useQueryObterRelatorioPorChaveId,
    useObterPDF,
    obterPDF,
  }
}
