export function flexCreateOrUpdateMessage(
  id?: string,
  gender?: 'masculino' | 'feminino',
) {
  gender = gender ?? 'masculino'

  const prefix = id ? 'alterad' : 'adicionad'
  const genderSuffix = gender === 'masculino' ? 'o' : 'a'

  return `${prefix}${genderSuffix}`
}

export function toSubstring(
  descricao: string,
  length: number,
  mediaQuery?: boolean,
) {
  if (mediaQuery) {
    return descricao
  }

  if (descricao?.length > length) {
    return descricao.substring(0, length) + '...'
  }

  return descricao
}

export function removeSpecialCharacters(value: string) {
  return value.replace(/[^\w\s]/gi, '')
}

export function singularOuPlural(text: string, condicao: number) {
  if (condicao > 1) {
    return `${text}s`
  }
  return text
}

export default function useStringManipulation() {
  return {
    toSubstring,
    flexCreateOrUpdateMessage,
    removeSpecialCharacters,
    singularOuPlural,
  }
}
