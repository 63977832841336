import {
  DatePickerProps,
  DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import 'moment/dist/locale/pt-br'

interface IDatePickerProps
  extends Omit<DatePickerProps<Moment>, 'onChange' | 'value'> {
  error?: boolean | string | null
  helperText?: string
  onChange: (date: string | null) => void
  value?: string | null
  title?: string
  required?: boolean
}

export default function DatePicker(props: IDatePickerProps) {
  const {
    value,
    title,
    error,
    onChange,
    helperText,
    required = false,
    ...rest
  } = props

  function handleChange(date: Moment | null) {
    if (date?.isValid()) {
      onChange(date?.format('yyyy-MM-DD'))
      return
    }

    if (date === null) {
      onChange(null)
      return
    }
  }

  return (
    <MuiDatePicker
      format="DD/MM/YYYY"
      value={moment(value)}
      onChange={(v: Moment | null) => handleChange(v)}
      slotProps={{
        textField: {
          error: Boolean(error),
          helperText,
          title,
          required,
        },
      }}
      {...rest}
    />
  )
}
