import { ReactNode } from 'react'
import { Tooltip } from '@data-c/ui'
import { Box, SxProps } from '@mui/material'

interface CustomTooltipProps {
  title: string
  disableHoverListener?: boolean
  children: ReactNode
  customSx?: SxProps
}

export default function CustomTooltip(props: CustomTooltipProps) {
  const { title, disableHoverListener, children, customSx } = props

  return (
    <Tooltip title={title} disableHoverListener={disableHoverListener}>
      <Box sx={{ ...customSx }}>{children}</Box>
    </Tooltip>
  )
}
