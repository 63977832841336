import { FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'
import ChooseOne from '@components/ChooseOne'
import { TemplateEmailInterface } from '@hooks/queries/useTemplateEmail'

interface TipoEventoProps {
  quantidadeDiasFormatado: string
  operacaoValidationProps: {
    helperText: string
    error: boolean
  }
  validationProps: (fieldName: string) => {
    helperText: string
    error: boolean
  }
}

const campoMaps = {
  registro: 'Criação',
  vencimento: 'Vencimento',
  pagamento: 'Pagamento',
}

export default function TipoEvento(props: TipoEventoProps) {
  const { quantidadeDiasFormatado, operacaoValidationProps, validationProps } =
    props

  const { changeValue } = useFormApi<TemplateEmailInterface>()
  const { formValues: data } = useFormData<TemplateEmailInterface>()

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ChooseOne
          gridItemSize={4}
          onChoose={(item) => {
            changeValue('regraEnvio', {
              ...data.regraEnvio,
              operacao: item?.value || '',
            })
          }}
          value={data?.regraEnvio?.operacao}
          options={[
            {
              title: 'No dia',
              value: 'now',
              description: `O envio ocorre na data de ${
                campoMaps[data?.regraEnvio?.campo || 'registro']
              }`,
              icon: 'today',
            },
            {
              title: 'Antes',
              value: 'subtract',
              description: data.quantidadeDias
                ? `O envio ocorre ${quantidadeDiasFormatado} dia${
                    (data?.quantidadeDias || 0) > 1 ? 's' : ''
                  } antes da data de ${
                    campoMaps[data?.regraEnvio?.campo || 'registro']
                  }`
                : 'Configure os dias para obter um resultado',
              icon: 'schedule',
            },
            {
              title: 'Depois',
              value: 'add',
              description: data.quantidadeDias
                ? `O envio ocorre ${quantidadeDiasFormatado} dia${
                    (data?.quantidadeDias || 0) > 1 ? 's' : ''
                  } depois da data de ${
                    campoMaps[data?.regraEnvio?.campo || 'registro']
                  }`
                : 'Configure os dias para obter um resultado',
              icon: 'schedule',
            },
          ]}
        />
        {operacaoValidationProps.error && (
          <FormControl error>
            <FormHelperText id="opcomponent-error-text">
              {operacaoValidationProps.helperText}
            </FormHelperText>
          </FormControl>
        )}
      </Grid>

      {data?.regraEnvio?.operacao !== 'now' && (
        <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
          <TextField
            name="quantidadeDias"
            label="Dias"
            required
            value={data?.quantidadeDias || ''}
            onChange={(e) => {
              changeValue('quantidadeDias', parseInt(e.target.value))
            }}
            {...validationProps('quantidadeDias')}
          />
        </Grid>
      )}
    </Grid>
  )
}
