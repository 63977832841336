import { Switch, useTheme } from '@mui/material'

interface ActiveSwitchProps<T> {
  data: T[]
  value: any
  title?: string
  rowIndex: number
  disabled?: boolean
  onChange: (event: 'update_ativo', _data: T) => void
}

export default function ActiveSwitch<T>(props: ActiveSwitchProps<T>) {
  const { data, value, title, rowIndex, disabled, onChange } = props

  const theme = useTheme()

  function handleChange(checked: boolean) {
    const _data = data[rowIndex]
    if (_data) {
      onChange('update_ativo', {
        ..._data,
        ativo: checked,
      })
    }
  }

  return (
    <Switch
      title={title ? title : value ? 'Desativar' : 'Ativar'}
      size="small"
      color="success"
      checked={Boolean(value)}
      disabled={disabled}
      onChange={(e) => {
        handleChange(e.target.checked)
      }}
      sx={{
        '& .MuiSwitch-track': {
          backgroundColor: theme.palette.error.dark,
        },
        '& .MuiSwitch-switchBase': {
          color: Boolean(value) ? '' : theme.palette.error.dark,
        },
      }}
    />
  )
}
