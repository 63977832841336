import { useState } from 'react'
import { Transporter, TransporterProps } from '@data-c/ui'
import ModeloTable from 'pages/Modelos/components/Table'
import useTemplateEmail, {
  TemplateEmailInterface,
} from '@hooks/queries/useTemplateEmail'

type AgendamentoTransporterProps = {
  hideBackdrop?: boolean
  apenasAtivos?: boolean
} & Omit<
  TransporterProps<TemplateEmailInterface>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function AgendamentoTransporter(
  props: AgendamentoTransporterProps,
) {
  const { value, hideBackdrop = false, apenasAtivos = false, ...rest } = props

  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useTemplateEmail()

  const { data, isLoading: isLoadingObterTemplateEmail } = useQuery({
    query: query || '',
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  const agendamentosAtivos = data?.data?.filter(
    (modelo) => modelo?.ativo === true,
  )

  return (
    <Transporter
      onSearch={handleSearch}
      options={agendamentosAtivos || []}
      isLoading={isLoadingObterTemplateEmail}
      table={ModeloTable}
      label="Agendamentos"
      renderValue={(value) => `${value.nome}`}
      dialogProps={{ hideBackdrop, title: 'Agendamentos', maxWidth: 'md' }}
      value={value}
      {...rest}
    />
  )
}
