import { useState } from 'react'

export default function useDownload() {
  const [downloadManager, setDownloadManager] = useState({
    error: null,
    loadedd: 0,
    total: 0,
    progress: 0,
    isDownloading: false,
  })

  function start() {
    setDownloadManager((oldState) => ({
      ...oldState,
      isDownloading: true,
      progress: 0,
    }))
  }

  function stop() {
    setDownloadManager((oldState) => ({
      ...oldState,
      isDownloading: false,
      progress: 100,
    }))
  }

  function updateProgress(loaded: number, total: number) {
    const progress = (loaded / total) * 100

    setDownloadManager((oldState) => ({
      ...oldState,
      loaded,
      total,
      progress,
    }))
  }

  return {
    updateProgress,
    start,
    stop,
    progress: downloadManager.progress,
    total: downloadManager.total,
    isDownloading: downloadManager.isDownloading,
  }
}
