import { Stack } from '@mui/material'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import EmptyData from '@components/EmptyData'

interface JsonViewerDialogProps {
  title?: string
  data?: JSON
  isOpen: boolean
  onClose: () => void
}

export default function JsonViewerDialog(props: JsonViewerDialogProps) {
  const { title, data, isOpen, onClose } = props

  return (
    <Dialog
      title={title || 'Visualizador de JSON'}
      maxWidth="md"
      hideBackdrop
      open={isOpen}
      onClose={onClose}
      actions={
        <ButtonContainer>
          <Button variant="contained" onClick={onClose}>
            Cancelar
          </Button>
        </ButtonContainer>
      }
    >
      {data ? (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      ) : (
        <Stack
          height="250px"
          position="relative"
          sx={{ backgroundColor: 'red' }}
        >
          <EmptyData description="Nenhum registro encontrado" />
        </Stack>
      )}
    </Dialog>
  )
}
