import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import UnlayerEmailEditor from 'react-email-editor'

import CloseIcon from '@mui/icons-material/Close'
import { TextField, Box, Popover, Snackbar, IconButton } from '@mui/material'

import useTemplateEmail, {
  AlterarEmailAgendamentoCorpoModel,
  TemplateEmailInterface,
} from '@hooks/queries/useTemplateEmail'
import useValidations from '@hooks/useValidations'
import { LayoutEmailInterface } from '@hooks/queries/useLayoutEmail'

import Button from '@components/Button'
import { DialogContent } from '@components/Dialog'

import Templates from './components/Templates'

import moment from 'moment'
import * as yup from 'yup'
import { useForm } from '@data-c/hooks'

const schema = yup.object().shape({
  tituloEmail: yup.string().required('Informe o título do e-mail').nullable(),
})

interface FormModeloEmailProps {
  data: TemplateEmailInterface
}

export default function FormModeloEmail(props: FormModeloEmailProps) {
  const { data: _data } = props

  const [isReady, setReady] = useState<boolean>(false)
  const [oldDesign, setOldDesign] = useState(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { validationProps, setValidationErrors } = useValidations()
  const { useAlterarEmailAgendamentoCorpo } = useTemplateEmail()
  const { mutateAsync: alterarEmailAgendamentoCorpo, isLoading } =
    useAlterarEmailAgendamentoCorpo()

  const { data, changeValue, handleChange } = useForm(_data)

  useEffect(() => {
    if (isReady) {
      const parsedDesign = data?.designEmail
        ? JSON.parse(data?.designEmail)
        : null

      //@ts-ignore
      emailEditorRef.current.editor.loadDesign(parsedDesign)
    }
  }, [isReady, data])

  const emailEditorRef = useRef(null)

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        if (emailEditorRef.current !== null) {
          //@ts-ignore
          emailEditorRef.current.editor.exportHtml(
            async (templateEmail: any) => {
              const { design: jsonDesign, html } = templateEmail

              data.designEmail = JSON.stringify(jsonDesign)
              data.corpoEmail = html

              const dataEmailAgendamentoCorpo: AlterarEmailAgendamentoCorpoModel =
                {
                  corpoEmail: html,
                  designEmail: JSON.stringify(jsonDesign),
                  tituloEmail: data?.tituloEmail,
                }

              await alterarEmailAgendamentoCorpo({
                agendamentoId: data?.id || '',
                data: dataEmailAgendamentoCorpo,
              })
            },
          )
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  const onReady = () => {
    setReady(true)
  }

  const sampleDates: {
    dataAtual: string
    vencimento: string
    emissao: string
    pagamento: string
  } = useMemo(() => {
    const currentDate = moment().format('DD/MM/YYYY')
    const vencimentoDate = moment().add(15, 'days').format('DD/MM/YYYY')
    const pagamentoDate = moment().add(10, 'days').format('DD/MM/YYYY')
    const samples = {
      dataAtual: currentDate,
      vencimento: vencimentoDate,
      emissao: currentDate,
      pagamento: pagamentoDate,
    }
    return samples
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleCarregarTemplate = useCallback(
    (template: LayoutEmailInterface) => {
      setOldDesign(data?.designEmail)
      changeValue('designEmail', template.design)
    },
    [changeValue, data],
  )

  const undoTemplate = useMemo(() => {
    return (
      <React.Fragment>
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            changeValue('designEmail', oldDesign)
            setOldDesign(null)
          }}
        >
          Desfazer
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="secondary"
          onClick={() => {
            setOldDesign(null)
          }}
        >
          <CloseIcon fontSize="small" color="secondary" />
        </IconButton>
      </React.Fragment>
    )
  }, [setOldDesign, changeValue, oldDesign])

  return (
    <>
      <DialogContent
        sx={{ height: 'calc(100% - 66px)', padding: `2px 2px 2px` }}
      >
        {isReady && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
            paddingRight="16px"
            paddingBottom="16px"
            paddingTop="4px"
          >
            <Box
              sx={{
                width: '700px',
                display: 'flex',
                ml: (theme) => theme.spacing(2),
              }}
            >
              <TextField
                value={data?.tituloEmail || ''}
                onChange={handleChange}
                label="Título do E-mail"
                name="tituloEmail"
                {...validationProps('tituloEmail')}
              />
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                onClick={handleClick}
                sx={{ marginRight: '16px' }}
              >
                Carregar dos Templates
              </Button>

              <Button
                size="small"
                variant="contained"
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Salvar Modelo
              </Button>
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
          <UnlayerEmailEditor
            options={{
              mergeTags: [
                {
                  name: 'Título do E-mail',
                  value: '{{titulo}}',
                  sample: 'Este é o título do e-mail',
                },
                {
                  name: 'Nome do Pagador (Cliente)',
                  value: '{{nomeCliente}}',
                  sample: 'Pagador LTDA',
                },
                {
                  name: 'Valor do Documento',
                  value: '{{valorDocumento}}',
                  sample: 'R$ 5.098,70',
                },
                {
                  name: 'Data de Vencimento',
                  value: '{{vencimento}}',
                  sample: sampleDates.vencimento,
                },
                {
                  name: 'Data de Emissão',
                  value: '{{emissao}}',
                  sample: sampleDates.emissao,
                },
                {
                  name: 'Data de Pagamento',
                  value: '{{pagamento}}',
                  sample: sampleDates.pagamento,
                },
              ],
              locale: 'pt-BR',
            }}
            projectId={99952}
            ref={emailEditorRef}
            // onLoad={onLoad}
            onReady={onReady}
          />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Templates
            onClick={handleCarregarTemplate}
            onAfterClick={() => {
              setAnchorEl(null)
            }}
          />
        </Popover>
        <Snackbar
          autoHideDuration={6000}
          open={Boolean(oldDesign)}
          onClose={() => {
            setOldDesign(null)
          }}
          action={undoTemplate}
          message="Template aplicado"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
      </DialogContent>
    </>
  )
}

FormModeloEmail.defaultProps = {
  data: {},
}
