import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { PageTitle } from '@data-c/ui'
import { useDialog } from '@data-c/hooks'

import useTemplateEmail, {
  TemplateEmailInterface,
} from '@hooks/queries/useTemplateEmail'

import Button from '@components/Button'
import Delete from '@components/Delete'
import Dialog from '@components/Dialog'
import AbsoluteTableContainer from '@components/AbsoluteTableContainer'

import Form from './components/Form'
import Table from './components/Table'
import FormModeloEmail from './components/FormModeloEmail'
import FormInitial from './components/FormInitial'

export default function Modelos() {
  const { useDelete } = useTemplateEmail()
  const { mutateAsync: deleteTemplate } = useDelete()

  const {
    data: dataTemplateEmail,
    openDialog: openTemplateEmail,
    isOpen: isOpenTemplateEmail,
    closeDialog: closeTemplateEmail,
  } = useDialog<TemplateEmailInterface>()

  const {
    openDialog: openInitialForm,
    isOpen: isOpenInitialForm,
    closeDialog: closeInitialForm,
  } = useDialog<TemplateEmailInterface>()

  function handleOpenTemplateEmailDialog(data: TemplateEmailInterface) {
    openTemplateEmail(data)
  }

  return (
    <Container>
      <FormProvider initialFormValues={{ horaEnvio: 0 }}>
        <PageTitle title="Agendamentos">
          <Button
            variant="contained"
            onClick={() => {
              openInitialForm({
                nome: '',
                identificador: '',
                envioAutomatico: true,
                ativo: true,
              })
            }}
          >
            Novo Agendamento
          </Button>
        </PageTitle>

        <AbsoluteTableContainer>
          <Table onOpenTemplateEmail={handleOpenTemplateEmailDialog} />
        </AbsoluteTableContainer>

        <Delete onDelete={deleteTemplate} />
        <Form />

        <FormInitial isOpen={isOpenInitialForm} onClose={closeInitialForm} />
      </FormProvider>

      <Dialog
        title="Editar Agendamento"
        open={isOpenTemplateEmail}
        onClose={closeTemplateEmail}
        fullScreen
      >
        <FormModeloEmail data={dataTemplateEmail} />
      </Dialog>
    </Container>
  )
}
