import { useState } from 'react'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import { DropDownTipoDescontoEnum } from '@components/Inputs/DropDownTipoDesconto'
import { DropDownTipoJurosEnum } from '@components/Inputs/DropDownTipoJuros'
import { DropDownTipoMultaEnum } from '@components/Inputs/DropDownTipoMulta'
import Container from '@components/Container'

import { useAmbienteContext } from 'contexts/AmbienteContext/AmbienteContext'

import { BoletoFilters1, boletofilters } from '@hooks/queries/useBoleto'
import useDates from '@hooks/useDates'

import Filter from './components/Filter'
import Table from './components/Table'
import Form from './components/Form'

export default function Boletos() {
  const { empresa } = useAmbienteContext()
  const { getCurrentDate } = useDates()
  const [appliedFilters, setFilters] = useState<BoletoFilters1>(
    {} as BoletoFilters1,
  )

  return (
    <Container>
      <FormProvider
        initialFormValues={{
          dataEmissao: getCurrentDate().format('yyyy-MM-DD'),
          dataVencimento: getCurrentDate().format('yyyy-MM-DD'),
          descontoTipo: DropDownTipoDescontoEnum.DEFINIDO_PELO_CONVENIO_99,
          jurosTipo: DropDownTipoJurosEnum.DEFINIDO_PELO_CONVENIO_99,
          multaTipo: DropDownTipoMultaEnum.DEFINIDO_PELO_CONVENIO_99,
        }}
      >
        <PageTitle title="Boletos">
          <ButtonContainer>
            <FilterProvider
              filterValues={boletofilters}
              storagePath="@storage:boletos-stella-hubbank"
            >
              <Filter
                onApplyFilters={(_appliedFilters) => {
                  setFilters(_appliedFilters)
                }}
              />
            </FilterProvider>
            <Form />
          </ButtonContainer>
        </PageTitle>
        <Table filters={{ ...appliedFilters, empresaId: empresa?.id || '' }} />
      </FormProvider>
    </Container>
  )
}
