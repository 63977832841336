import { Grid, TextField } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { Checkbox, CurrencyTextField } from '@data-c/ui'

import DatePicker from '@components/DatePicker'
import CardDynamic from '@components/CardDynamic'

import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'

interface BoletoOutrasInformacoesProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoOutrasInformacoes({
  boleto,
}: BoletoOutrasInformacoesProps) {
  return (
    <FormProvider>
      <CardDynamic
        title="Outras Informações"
        disabledAllChildren
        initialOpacity
        gridContainer
        hiddenButton
      >
        <Grid item xl={2} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Pagamento"
            value={boleto?.dataPagamento || ''}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={2} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Crédito"
            value={boleto?.dataCredito || ''}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={2} lg={4} md={6} sm={12} xs={12}>
          <CurrencyTextField
            label="Valor Atual"
            value={boleto?.valorAtual || ''}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={6} sm={12} xs={12}>
          <CurrencyTextField
            label="Multa Cobrada"
            value={boleto?.valorMultaCobrada || ''}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={6} sm={12} xs={12}>
          <CurrencyTextField
            label="Desconto Concedido"
            value={boleto?.valorDescontoConcedido || ''}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={6} sm={12} xs={12}>
          <CurrencyTextField
            label="Valor Pago"
            value={boleto?.valorPago || ''}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={12} sm={12} xs={12}>
          <DatePicker
            label="Data Tarifa"
            value={boleto?.dataTarifa || ''}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={10} lg={12} md={12} sm={12} xs={12}>
          <TextField label="Código Barras" value={boleto?.codigoBarras || ''} />
        </Grid>
        <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Linha Digitável"
            value={boleto?.linhaDigitavel || ''}
          />
        </Grid>
        <Grid item xl={2} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Movimento"
            value={boleto?.dataMovimento || ''}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <TextField
            label="Tipo Movimento"
            value={boleto?.tipoMovimentoDesc || ''}
          />
        </Grid>
        <Grid item xl={12} lg={4} md={12} sm={12} xs={12}>
          <Checkbox label="Incluir PIX" value={boleto?.incluirPix} />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
