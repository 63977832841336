import { Stack, TextField } from '@mui/material'

import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import { ConvenioResumoModel } from '@hooks/queries/useConvenio'
import { dateEndOfMonth, dateStartOfMonth } from '@hooks/useDates'
import { BoletoFilters, BoletoFilters1 } from '@hooks/queries/useBoleto'
import useUtils from '@hooks/useUtils'

import DropDownBoletoTipoData, {
  boletoTipoDataReverseMapping,
} from '@components/Inputs/DropDownBoletoTipoData'
import DatePicker from '@components/DatePicker'
import ConvenioTransporter from '@components/Transporters/ConvenioTransporter'

import FilterProps from 'interfaces/FilterProps'

export function formatarFiltros(filters?: BoletoFilters1): BoletoFilters1 {
  return {
    ...filters,
    tipoData: filters?.tipoData || 0,
    dataFim: filters?.dataFim || dateEndOfMonth(),
    dataInicio: filters?.dataInicio || dateStartOfMonth(),
    convenioId: filters?.convenio?.id || null,
  }
}

export default function Filter(props: FilterProps<BoletoFilters1>) {
  const { onApplyFilters } = props

  const { changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<BoletoFilters, BoletoFilters1>()
  const { formatDate } = useUtils()

  return (
    <FilterContainer
      title="Opções de Filtro"
      triggerButtonProps={{ variant: 'outlined' }}
      onApplyFilters={onApplyFilters}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Convênio') {
          return `${filterLabel} - ${filterValue?.convenioDesc || ''}`
        }

        if (filterLabel === 'Data Inicial') {
          return `${filterLabel} - ${formatDate(filterValue || '')}`
        }

        if (filterLabel === 'Data Final') {
          return `${filterLabel} - ${formatDate(filterValue || '')}`
        }

        if (filterLabel === 'Tipo de Data') {
          const newFilterValue = boletoTipoDataReverseMapping[filterValue]
          return `${filterLabel} - ${newFilterValue}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <DropDownBoletoTipoData
          value={filterValues?.tipoData?.value}
          onChange={(value) => {
            changeFilterValue('tipoData', value)
          }}
        />
        <Stack flexDirection="row" gap={1}>
          <DatePicker
            label={filterValues?.dataInicio?.label}
            onChange={(dataInicio) => {
              changeFilterValue('dataInicio', dataInicio)
            }}
            value={filterValues?.dataInicio?.value}
          />
          <DatePicker
            label={filterValues?.dataFim?.label}
            onChange={(dataFim) => {
              changeFilterValue('dataFim', dataFim)
            }}
            value={filterValues?.dataFim?.value}
          />
        </Stack>

        <TextField
          name="pagadorConteudo"
          label={filterValues?.pagadorConteudo?.label}
          value={filterValues?.pagadorConteudo?.value}
          onChange={(e) => {
            changeFilterValue('pagadorConteudo', e.target.value || null)
          }}
        />

        <ConvenioTransporter
          name="convenio"
          hideBackdrop
          apenasAtivos
          label={filterValues?.convenio?.label}
          value={filterValues?.convenio?.value || null}
          onChange={(value: ConvenioResumoModel | null) => {
            changeFilterValue('convenio', value)
          }}
        />
      </Stack>
    </FilterContainer>
  )
}
