import { useCallback, useEffect, useMemo, useState } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'

import {
  CurrencyCellStyle,
  DataTable,
  DataTableOptions as Options,
} from '@data-c/ui'
import { findData } from '@data-c/hooks'

import { EmailConsultaInterface } from '@hooks/queries/useEnvioBoletos'
import useTableCustomCell from '@hooks/useTableCustomCell'

interface TableProps {
  boletosJaEnviados?: EmailConsultaInterface[]
  onRemoveBoletoJaEnviado: (boletoJaEnviado: EmailConsultaInterface) => void
}

export default function Table(props: TableProps) {
  const { boletosJaEnviados, onRemoveBoletoJaEnviado } = props
  const { whiteSpace, dateOptionsCellStyle, floatOptionsCellStyle } =
    useTableCustomCell()

  const [data, setData] = useState<EmailConsultaInterface[]>([])

  useEffect(() => {
    if (boletosJaEnviados) {
      setData(boletosJaEnviados)
    }
  }, [boletosJaEnviados])

  const handleClickItem = useCallback(
    async (event: 'remove', _data: EmailConsultaInterface) => {
      if (event === 'remove') {
        const filteredData = data.filter((d) => d?.id !== _data?.id)
        setData(filteredData)
        onRemoveBoletoJaEnviado(_data)
      }
    },
    [onRemoveBoletoJaEnviado, data],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'empresaNome',
        label: 'Empresa',
        options: {
          setCellProps: whiteSpace().setCellProps,
        },
      },
      {
        name: 'documentoSistema',
        label: 'Documento',
      },
      {
        name: 'pagadorNome',
        label: 'Cliente',
        options: {
          setCellProps: whiteSpace().setCellProps,
        },
      },
      {
        name: 'pagadorEmail',
        label: 'E-mail de Cobrança',
        options: {
          ...whiteSpace(),
        },
      },
      {
        name: 'emissao',
        label: 'Emissão',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'vencimento',
        label: 'Vencimento',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'valor',
        label: 'Valor Título',
        options: {
          setCellHeaderProps: whiteSpace().setCellHeaderProps,
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'id',
        label: 'Opções',
        options: {
          ...floatOptionsCellStyle(),
          customBodyRender: (value) => {
            const _data = findData(data || [], value, 'id')
            return (
              <Options
                displayUpdateButton={false}
                displayDeleteButton={false}
                value={_data}
                onClick={handleClickItem}
                extraOptions={[
                  {
                    id: 'remove',
                    icon: 'close',
                    visible: true,
                    iconButtonProps: {
                      title: 'Remover boleto do envio',
                      color: 'error',
                    },
                  },
                ]}
              />
            )
          },
        },
      },
    ],
    [data, onRemoveBoletoJaEnviado],
  )

  return <DataTable columns={columns} data={data || []} />
}
