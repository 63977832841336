import { useEffect, useState } from 'react'

import { isCPFOrCNPJ } from 'brazilian-values'
import { Grid, TextField } from '@mui/material'

import { onlyNumbers } from '@data-c/hooks'
import { Button, ButtonContainer, Checkbox, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import { CnpjModel } from '@hooks/queries/useConsultaCnpj'
import { CepModel } from '@hooks/queries/useConsultaCep'
import useCliente, { ClienteModel } from '@hooks/queries/useClientes'
import useValidations from '@hooks/useValidations'

import InscricaoEstadualTextField from '@components/InscricaoEstadualTextField'
import CEPTextField from '@components/CEPTextField'
import AutoCompleteUF from '@components/AutoCompletes/AutoCompleteUF'
import AutoCompleteCidade from '@components/AutoCompletes/AutoCompleteCidade'
import CnpjCpfTextField from '@components/CnpjCpfTextField'
import MaskedTextField from '@components/MaskedTextField'
import DropDownIndicadorIE, {
  IndicadorIEEnum,
} from '@components/Inputs/DropDownIndicadorIE'

import * as yup from 'yup'

const schema = yup.object().shape({
  documentoId: yup
    .string()
    .required('Informe um CNPJ/CPF')
    .min(11, 'Informe 11 caracteres para CPF ou 14 para CNPJ')
    .test(
      'CPF/CNPJ-Validator',
      'Informe um CNPJ/CPF válido',
      (documentoId: any) => {
        return isCPFOrCNPJ(documentoId)
      },
    ),
  nome: yup.string().required('Informe o nome'),
  cep: yup
    .string()
    .required('Informe o CEP')
    .length(8, 'O CEP deve ser composto por 8 caracteres'),
  cidadeUF: yup.string().required('Informe o UF'),
  cidadeId: yup.string().required('Informe a Cidade'),
})

export default function Form() {
  const {
    closeForm,
    toggleSubmit,
    changeValue,
    changeValues,
    onChangeFormValue,
  } = useFormApi<ClienteModel>()
  const { formValues: data, isSubmitting, isOpen } = useFormData<ClienteModel>()

  const { setValidationErrors, validationProps } = useValidations()
  const { useCreateOrUpdateCliente } = useCliente()
  const { mutateAsync: createOrUpdateCliente } = useCreateOrUpdateCliente()

  const [podeBuscar, setPodeBuscar] = useState(true)

  useEffect(() => {
    if (isOpen === false) {
      handleCloseForm()
    }
  }, [isOpen])

  useEffect(() => {
    setPodeBuscar(false)
  }, [isOpen])

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        const inscEstadual =
          data?.inscEstadual === 'ISENTO' || data?.inscEstadual?.length === 0
            ? null
            : data?.inscEstadual

        await createOrUpdateCliente({ ...data, inscEstadual })
        handleCloseForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  function handleCloseForm() {
    setPodeBuscar(true)
    closeForm()
  }

  return (
    <FormContainer
      triggerButton="none"
      dialogProps={{
        title: 'Cadastro de Cliente',
        maxWidth: 'sm',
      }}
      actions={
        <ButtonContainer>
          <Button color="error" onClick={handleCloseForm}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </ButtonContainer>
      }
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            disabled
            name="codigo"
            label="Código"
            value={data?.codigo || ''}
          />
        </Grid>
        <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
          <CnpjCpfTextField
            name="documentoId"
            required
            value={data?.documentoId || ''}
            onChange={(e) => {
              setPodeBuscar(true)
              changeValue('documentoId', onlyNumbers(e.target.value))
            }}
            inputProps={{ label: 'CPF/CNPJ' }}
            podePesquisarCnpj={podeBuscar}
            onFindCnpj={(cnpj: CnpjModel) => {
              if (cnpj) {
                changeValues({
                  ...data,
                  cep: cnpj.cep,
                  nome: cnpj.nome,
                  email: cnpj.email,
                  logradouro: cnpj.logradouro,
                  complemento: cnpj.complemento,
                  numero: cnpj.numero,
                  bairro: cnpj.bairro,
                  nomeFantasia: cnpj.nomeFantasia,
                  cidadeModel: cnpj?.cidadeModel || null,
                  cidadeId: cnpj?.cidadeModel?.id || '',
                  cidadeNome: cnpj?.cidadeModel?.nome || '',
                  ufModel: cnpj?.ufModel || null,
                  cidadeUFCodigoIBGE: cnpj?.ufModel?.codigoIBGE || '',
                  cidadeUF: cnpj?.ufModel?.id || '',
                  paisId: cnpj?.cidadeModel?.paisId || '',
                })
              }
            }}
            {...validationProps('documentoId')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <InscricaoEstadualTextField
            name="inscEstadual"
            label="Inscrição Estadual"
            value={data?.inscEstadual || null}
            isentoIE={
              data?.inscEstadualId ===
              IndicadorIEEnum.CONTRIBUINTE_ISENTO_INSCRICAO_2
            }
            onChange={(inscEstadual) => {
              if (inscEstadual === 'ISENTO') {
                changeValues({
                  ...data,
                  inscEstadual: 'ISENTO',
                  inscEstadualId:
                    IndicadorIEEnum.CONTRIBUINTE_ISENTO_INSCRICAO_2,
                })

                return
              }

              changeValues({
                ...data,
                inscEstadual,
                inscEstadualId: IndicadorIEEnum.NAO_INFORMADO_0,
              })
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DropDownIndicadorIE
            name="inscEstadualId"
            label="Indicador Insc. Estadual"
            value={data?.inscEstadualId || ''}
            disabled={data?.inscEstadual === 'ISENTO'}
            onChange={(e) => {
              const inscEstadualId = e.target.value
              changeValue('inscEstadualId', inscEstadualId || null)

              if (
                inscEstadualId ===
                IndicadorIEEnum.CONTRIBUINTE_ISENTO_INSCRICAO_2
              ) {
                changeValue('inscEstadual', 'ISENTO')
              }
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="nome"
            label="Nome"
            required
            inputProps={{ maxLength: 60 }}
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="nomeFantasia"
            label="Nome Fantasia"
            inputProps={{ maxLength: 60 }}
            value={data?.nomeFantasia || ''}
            onChange={(e) =>
              changeValue('nomeFantasia', e.target.value || null)
            }
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <CEPTextField
            name="cep"
            required
            inputProps={{ label: 'CEP' }}
            value={data?.cep || ''}
            onChange={(e) => {
              setPodeBuscar(true)
              changeValue('cep', onlyNumbers(e.target.value) || null)
            }}
            podePesquisarCep={podeBuscar}
            onFindCep={(cep: CepModel) => {
              if (cep) {
                setPodeBuscar(false)
                changeValues({
                  ...data,
                  logradouro: cep.logradouro,
                  bairro: cep.bairro,
                  complemento: cep?.complemento || '',
                  cidadeModel: cep?.cidadeModel,
                  cidadeId: cep?.cidadeModel?.id || '',
                  cidadeNome: cep?.cidadeModel?.nome || '',
                  ufModel: cep?.uf,
                  cidadeUFCodigoIBGE: cep?.uf?.codigoIBGE || '',
                  cidadeUF: cep?.uf?.id || '',
                  paisId: cep?.cidadeModel?.paisId || '',
                })
              }
            }}
            {...validationProps('cep')}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={8} xs={12}>
          <AutoCompleteUF
            name="cidadeUF"
            label="UF"
            required
            value={data?.ufModel || null}
            onChange={(_, value) => {
              const cidadeUF = value?.id
              const ufModel = value || null
              const cidadeUFCodigoIBGE = value?.codigoIBGE || ''

              changeValues({
                ...data,
                ufModel,
                cidadeUF,
                cidadeUFCodigoIBGE,
                cidadeId: '',
                cidadeModel: null,
              })
            }}
            {...validationProps('cidadeUF')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <AutoCompleteCidade
            name="cidadeId"
            label="Cidade"
            required
            value={data?.cidadeModel || null}
            uf={data?.cidadeUF || ''}
            onChange={(_, value) => {
              const cidadeId = value?.id || ''
              const cidadeNome = value?.nome || ''
              const cidadeModel = value || null
              const paisId = value?.paisId || ''

              changeValues({
                ...data,
                cidadeId,
                cidadeNome,
                cidadeModel,
                paisId,
              })
            }}
            {...validationProps('cidadeId')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="bairro"
            label="Bairro"
            inputProps={{ maxLength: 60 }}
            value={data?.bairro || ''}
            onChange={(e) => changeValue('bairro', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="logradouro"
            label="Logradouro"
            value={data?.logradouro || ''}
            inputProps={{ maxLength: 40 }}
            onChange={(e) => changeValue('logradouro', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <TextField
            name="numero"
            label="Número"
            inputProps={{ maxLength: 60 }}
            value={data?.numero || ''}
            onChange={(e) => changeValue('numero', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <TextField
            name="complemento"
            label="Complemento"
            inputProps={{ maxLength: 60 }}
            value={data?.complemento || ''}
            onChange={(e) => changeValue('complemento', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MaskedTextField
            name="telefone"
            mask="(99) 9999-9999"
            inputProps={{ label: 'Telefone' }}
            maxLength={14}
            value={data?.telefone || ''}
            onChange={(e) => {
              changeValue('telefone', onlyNumbers(e.target.value) || null)
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={8} xs={12}>
          <TextField
            name="email"
            label="E-mail"
            inputProps={{ maxLength: 200 }}
            value={data?.email || ''}
            onChange={(e) => changeValue('email', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <Checkbox
            label="Ativo"
            name="ativo"
            checked={Boolean(data?.ativo)}
            onChange={onChangeFormValue}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
