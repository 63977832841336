import BoletoProvider from 'contexts/BoletoContext/BoletoContext'
import Boleto from './components/Boleto'

export default function BoletoProviderPage() {
  return (
    <BoletoProvider>
      <Boleto />
    </BoletoProvider>
  )
}
