import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FilterOptions } from '@data-c/providers'

import { dateEndOfMonth, dateStartOfMonth } from '@hooks/useDates'
import useNotification from '@hooks/useNotifications'

import ErrorInterface from 'interfaces/ErrorInterface'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'

import api from 'services/api'

import { ConvenioResumoModel } from './useConvenio'
import { PessoaCadastroModel } from './usePessoaCadastro'
import { CidadeModel, UFModel } from './useCidade'

export interface BoletoDetalhadoModel {
  id?: string
  shortId?: string | null
  origem: number
  origemDesc?: string | null
  status: number
  statusDesc?: string | null
  dataCriacao: string
  dataAlteracao: string
  dataRegistro?: string | null
  empresaId: string
  convenioId: string
  convenioBancoCodigo: number
  convenioBancoCodigoDesc?: string | null
  carteiraCodigo?: string | null
  carteiraVariacao?: string | null
  convenioAgencia: number
  convenioContaCorrente: number
  convenioNumero?: string | null
  diasParaNegativacao: number
  diasLimiteRecebimento: number
  permiteRecebimentoParcial: boolean
  mensagemOcorrencia?: string | null
  mensagemJurosMulta?: string | null
  beneficiarioFinalCNPJCPF?: string | null
  beneficiarioFinalNome?: string | null
  beneficiarioFinalCEP?: string | null
  beneficiarioFinalLogradouro?: string | null
  beneficiarioFinalBairro?: string | null
  beneficiarioFinalCidadeNome?: string | null
  beneficiarioFinalUF?: string | null
  pagadorCNPJCPF?: string | null
  pagadorNome?: string | null
  pagadorLogradouro?: string | null
  pagadorComplemento?: string | null
  pagadorBairro?: string | null
  pagadorCidadeNome?: string | null
  pagadorUF?: string | null
  pagadorCEP?: string | null
  pagadorTelefone?: string | null
  pagadorEmail?: string | null
  nossoNumero?: string | null
  nossoNumeroSequencial: number
  documentoSistema?: string | null
  dataEmissao: string
  dataVencimento?: string | null
  dataPagamento?: string | null
  dataAntecipacao?: string | null
  dataAntecipacaoCancelamento: string
  valorTitulo: number
  valorAbatimento: number
  multaTipo: number
  multaTipoDesc?: string | null
  multaValor?: number | null
  jurosTipo: number
  jurosTipoDesc?: string | null
  jurosValor?: number | null
  descontoTipo: number
  descontoTipoDesc?: string | null
  descontoDataExpiracao?: string | null
  descontoValor: number
  protestoTipo: number
  protestoTipoDesc?: string | null
  protestoDias?: number | null
  codigoBarras?: string | null
  linhaDigitavel?: string | null
  pixQrCode?: string | null
  incluirPix: boolean
  dataMovimento?: string | null
  tipoMovimento: number
  tipoMovimentoDesc?: string | null
  dataCredito?: string | null
  valorAtual?: number | null
  valorMultaCobrada?: number | null
  valorDescontoConcedido?: number | null
  valorPago?: number | null
  valorTarifa?: number | null
  dataTarifa?: string | null
  dataCancelamento?: string | null
}

export interface BoletoFilters {
  dataInicio: FilterOptions<string>
  dataFim: FilterOptions<string>
  empresaId?: FilterOptions<string>
  pagadorConteudo?: FilterOptions<string>
  convenio?: FilterOptions<ConvenioResumoModel | null>
  convenioId?: FilterOptions<string | null>
  tipoData: FilterOptions<number>
}

export interface BoletoFilters1 {
  dataInicio: string
  dataFim: string
  empresaId?: string
  pagadorConteudo?: string | null
  convenio?: ConvenioResumoModel | null
  convenioId?: string | null
  tipoData: number
}

export interface BoletoInclusaoModel {
  id?: string
  convenioId: string
  convenio?: ConvenioResumoModel | null
  pagador?: PagadorProps | null
  pessoaCadastro?: PessoaCadastroModel | null
  dataEmissao: string
  dataVencimento: string
  documentoSistema?: string | null
  valorTitulo: number
  descontoTipo: number
  descontoDataExpiracao?: string | null
  descontoValor: number
  multaTipo: number
  multaValor?: number | null
  jurosTipo: number
  jurosValor?: number | null
  protestoTipo: number
  protestoDias?: number | null
  mensagemOcorrencia?: string | null
}

interface PagadorProps {
  cnpjcpf: string
  nome: string
  logradouro: string
  complemento?: string | null
  bairro: string
  cidadeNome: string
  cidade?: CidadeModel | null
  uf: string
  ufModel?: UFModel | null
  cep: string
  telefone?: string | null
  email?: string | null
}

export interface AlterarBoletoIdenfiticacaoModel {
  dataEmissao: string
  dataVencimento: string
  documentoSistema?: string | null
  diasParaNegativacao: number
  diasLimiteRecebimento: number
  permiteRecebimentoParcial: boolean
  nossoNumeroSequencial: number
  carteiraCodigo?: string | null
  carteiraVariacao?: string | null
}

export interface AlterarBoletoValoresModel {
  valorTitulo: number
  descontoTipo: number
  descontoDataExpiracao?: string | null
  descontoValor: number
  multaTipo: number
  multaValor?: number | null
  jurosTipo: number
  jurosValor?: number | null
}

export interface AlterarBoletoBeneficiarioModel {
  beneficiarioNome?: string | null
  pessoa?: PessoaCadastroModel | null
  beneficiarioCNPJCPF?: string | null
}

export interface AlterarBoletoPagadorModel extends PagadorProps {
  pessoa?: PessoaCadastroModel | null
}

export interface AlterarBoletoProtestoModel {
  protestoTipo: number
  protestoDias?: number | null
}

export interface AlterarBoletoMensagemParams {
  boletoId: string
  novaMensagem: string
}

export interface AlterarBoletoDataVencimentoParams {
  boletoId: string
  novaDataVencimento: string
}

export interface AlterarBoletoDataLimitePagamentoParams {
  boletoId: string
  novaDataLimitePagamento: string
}

export interface AlterarBoletoAbatimentoParams {
  boletoId: string
  abatimento: number
}

export interface AlterarBoletoJurosModel {
  jurosTipo: number
  jurosValor?: number | null
}

export interface AlterarBoletoMultaModel {
  multaTipo: number
  multaValor?: number | null
}

export interface AlterarBoletoDescontoModel {
  descontoTipo: number
  descontoDataExpiracao?: string | null
  descontoValor: number
}

export interface AlterarBoletoPagadorEnderecoModel {
  logradouro?: string | null
  complemento?: string | null
  bairro?: string | null
  cidade?: CidadeModel | null
  cidadeNome?: string | null
  uf?: string | null
  ufModel?: UFModel | null
  cep?: string | null
  telefone?: string | null
  email?: string | null
}

export interface BoletoConsultaObterLogsModel {
  id: string
  boletoId: string
  dataCriacao: string
  operacao: number
  operacaoDesc?: string | null
  bodyRequisicaoApi?: string | null
  bodyRequisicaoBanco?: string | null
  bodyRespostaBanco?: string | null
  bodyRespostaApi?: string | null
}

export interface BoletoConsultaObterMovimentacaoModel {
  id: string
  boletoId: string
  tipoMovimento: number
  tipoMovimentoDesc?: string | null
  dataCriacao: string
  dataAlteracao: string
  dataSituacao: string
  valorTarifa?: number | null
  retornoBancoJson?: string | null
}

export const boletofilters: BoletoFilters = {
  pagadorConteudo: {
    label: 'Pagador (Nome, CNPJ/CPF)',
    value: '',
  },
  dataInicio: {
    label: 'Data Inicial',
    value: dateStartOfMonth(),
  },
  dataFim: {
    label: 'Data Final',
    value: dateEndOfMonth(),
  },
  empresaId: {
    label: 'Empresa',
  },
  convenio: {
    label: 'Convênio',
  },
  convenioId: {
    label: 'Convênio',
  },
  tipoData: {
    label: 'Tipo de Data',
    value: 0,
  },
}

export async function obterBoletos(
  filters: BoletoFilters1,
  params?: QueryParamsInterface,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const paginationProps = params?.pagination || { page: 1, pageSize: 20 }
  const sortProps = params?.sort || {
    column: 'dataAlteracao',
    direction: 'asc',
  }

  const { page, pageSize } = paginationProps
  const { column, direction } = sortProps

  delete filters.convenio

  const response = await api.post('BoletoConsulta/Consultar', filters, {
    headers: {
      'Resposta-Compactada': 'Nunca',
      'DC-Page': page,
      'DC-PageSize': pageSize,
      'DC-Ordenation-Fields': `${column} ${direction}`,
    },
  })
  return response.data
}

export async function obterBoletoPorId(
  id: string,
): Promise<BoletoDetalhadoModel> {
  const response = await api.get(`BoletoConsulta/${id}`)
  return response.data.data
}

export async function incluirBoleto(
  data: BoletoInclusaoModel,
): Promise<BoletoDetalhadoModel> {
  delete data?.convenio
  delete data?.pessoaCadastro
  delete data?.pagador?.cidade
  delete data?.pagador?.ufModel

  const response = await api.post('BoletoInclusao/Incluir', data)
  return response?.data?.data
}

async function alterarBoletoIdenfiticacao(
  boletoId: string,
  data: AlterarBoletoIdenfiticacaoModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const response = await api.put(`BoletoInclusao/AlterarIdentificacao`, data, {
    params: { boletoId },
  })
  return response.data
}

async function alterarBoletoValores(
  boletoId: string,
  data: AlterarBoletoValoresModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const response = await api.put(`BoletoInclusao/AlterarValores`, data, {
    params: { boletoId },
  })
  return response.data
}

async function alterarBoletoBeneficiario(
  boletoId: string,
  data: AlterarBoletoBeneficiarioModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  delete data.pessoa

  const response = await api.put(`BoletoInclusao/AlterarBeneficiario`, data, {
    params: { boletoId },
  })
  return response.data
}

async function alterarBoletoProtesto(
  boletoId: string,
  data: AlterarBoletoProtestoModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const response = await api.put(`BoletoInclusao/AlterarProtesto`, data, {
    params: { boletoId },
  })
  return response.data
}

async function alterarBoletoMensagem(
  data: AlterarBoletoMensagemParams,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const { boletoId, novaMensagem } = data

  const response = await api.put(
    `BoletoInclusao/AlterarMensagem`,
    {},
    {
      params: { boletoId, novaMensagem },
    },
  )
  return response.data
}

async function alterarBoletoDataVencimento(
  data: AlterarBoletoDataVencimentoParams,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const { boletoId, novaDataVencimento } = data

  const response = await api.post(
    'BoletoAlteracao/AlterarVencimento',
    {},
    {
      params: { boletoId, novaDataVencimento },
    },
  )
  return response.data
}

async function alterarBoletoDataLimitePagamento(
  data: AlterarBoletoDataLimitePagamentoParams,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const { boletoId, novaDataLimitePagamento } = data

  const response = await api.post(
    'BoletoAlteracao/AlterarDataLimitePagamento',
    {},
    {
      params: { boletoId, novaDataLimitePagamento },
    },
  )
  return response.data
}

async function alterarBoletoAbatimento(
  data: AlterarBoletoAbatimentoParams,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const { boletoId, abatimento } = data

  const response = await api.post(
    'BoletoAlteracao/AlterarAbatimento',
    {},
    {
      params: { boletoId, abatimento },
    },
  )
  return response.data
}

async function alterarBoletoJuros(
  boletoId: string,
  data: AlterarBoletoJurosModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const response = await api.post('BoletoAlteracao/AlterarJuros', data, {
    params: { boletoId },
  })
  return response.data
}

async function alterarBoletoMulta(
  boletoId: string,
  data: AlterarBoletoMultaModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const response = await api.post('BoletoAlteracao/AlterarMulta', data, {
    params: { boletoId },
  })
  return response.data
}

async function alterarBoletoDesconto(
  boletoId: string,
  data: AlterarBoletoDescontoModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const response = await api.post('BoletoAlteracao/AlterarDesconto', data, {
    params: { boletoId },
  })
  return response.data
}

async function alterarBoletoPagadorEndereco(
  boletoId: string,
  data: AlterarBoletoPagadorEnderecoModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  delete data.cidade
  delete data.ufModel

  const response = await api.post(
    'BoletoAlteracao/AlterarPagadorEndereco',
    data,
    {
      params: { boletoId },
    },
  )
  return response.data
}

async function cancelarBoleto(
  boletoId: string,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  const response = await api.post(
    'BoletoAlteracao/Cancelar',
    {},
    { params: { boletoId } },
  )
  return response.data
}

async function alterarBoletoPagador(
  boletoId: string,
  data: AlterarBoletoPagadorModel,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  delete data.cidade
  delete data.ufModel
  delete data.pessoa

  const response = await api.put(`BoletoInclusao/AlterarPagador`, data, {
    params: { boletoId },
  })
  return response.data
}

async function registrarBoleto(
  data: BoletoInclusaoModel,
): Promise<BoletoDetalhadoModel> {
  delete data?.convenio
  delete data?.pessoaCadastro
  delete data?.pagador?.cidade
  delete data?.pagador?.ufModel

  const response = await api.post('BoletoRegistro/Registrar', data)
  return response.data
}

export function useQueryObterBoletos(
  filters: BoletoFilters1,
  params: QueryParamsInterface,
) {
  return useQuery<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['BOLETOS', filters, params], () => {
    return obterBoletos(filters, params)
  })
}

export function useQueryObterBoletoPorId(id: string) {
  return useQuery<
    BoletoDetalhadoModel,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['BOLETOS', id], () => obterBoletoPorId(id))
}

export function useObterBoletoPorId() {
  const [isLoading, setLoading] = useState(false)
  const notifications = useNotification()

  async function obterBoletoPorId(
    boletoId: string,
  ): Promise<BoletoDetalhadoModel | null> {
    try {
      setLoading(true)
      const response = await api.get(`BoletoConsulta/${boletoId}`)
      if (response?.data?.data) {
        return response.data.data
      }
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }

    return null
  }

  return { obterBoletoPorId, isLoadingObterBoletoPorId: isLoading }
}

export function useObterBoletoDoBanco() {
  const [isLoading, setLoading] = useState(false)
  const notifications = useNotification()

  async function obterBoletoDoBanco(
    boletoId: string,
  ): Promise<BoletoDetalhadoModel | null> {
    try {
      setLoading(true)
      const response = await api.get(
        'BoletoConsultaBanco/ConsultarIndividual',
        {
          params: { boletoId },
        },
      )
      if (response?.data?.data) {
        return response.data.data
      }
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }

    return null
  }

  return { obterBoletoDoBanco, isLoadingObterBoletoDoBanco: isLoading }
}

export function useIncluirBoleto() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<BoletoDetalhadoModel, AxiosError, BoletoInclusaoModel>(
    (data) => incluirBoleto(data),
    {
      onSuccess() {
        notifications.notifySuccess('Boleto incluído com sucesso!')
        queryClient.invalidateQueries(['BOLETOS'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useAlterarBoletoIdenfiticacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoIdenfiticacaoModel
    }
  >(({ boletoId, data }) => alterarBoletoIdenfiticacao(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess(
        'Identificação do boleto alterada com sucesso!',
      )
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoValores() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoValoresModel
    }
  >(({ boletoId, data }) => alterarBoletoValores(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess('Valores do boleto alterados com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoBeneficiario() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoBeneficiarioModel
    }
  >(({ boletoId, data }) => alterarBoletoBeneficiario(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess(
        'Beneficiário do boleto alterado com sucesso!',
      )
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoPagador() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoPagadorModel
    }
  >(({ boletoId, data }) => alterarBoletoPagador(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess('Pagador do boleto alterado com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoProtesto() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoProtestoModel
    }
  >(({ boletoId, data }) => alterarBoletoProtesto(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess('Protesto do boleto alterado com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoMensagem() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    AlterarBoletoMensagemParams
  >((params) => alterarBoletoMensagem(params), {
    onSuccess: () => {
      notifications.notifySuccess('Mensagem do boleto alterada com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoDataVencimento() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    AlterarBoletoDataVencimentoParams
  >((params) => alterarBoletoDataVencimento(params), {
    onSuccess: () => {
      notifications.notifySuccess(
        'Data de Vencimento do boleto alterada com sucesso!',
      )
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoDataLimitePagamento() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    AlterarBoletoDataLimitePagamentoParams
  >((params) => alterarBoletoDataLimitePagamento(params), {
    onSuccess: () => {
      notifications.notifySuccess(
        'Data Limite de Pagamento do boleto alterada com sucesso!',
      )
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoAbatimento() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    AlterarBoletoAbatimentoParams
  >((params) => alterarBoletoAbatimento(params), {
    onSuccess: () => {
      notifications.notifySuccess('Abatimento do boleto alterado com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoJuros() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoJurosModel
    }
  >(({ boletoId, data }) => alterarBoletoJuros(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess('Juros do boleto alterado com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoMulta() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoMultaModel
    }
  >(({ boletoId, data }) => alterarBoletoMulta(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess('Multa do boleto alterado com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoDesconto() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoDescontoModel
    }
  >(({ boletoId, data }) => alterarBoletoDesconto(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess('Desconto do boleto alterado com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarBoletoPagadorEndereco() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    {
      boletoId: string
      data: AlterarBoletoPagadorEnderecoModel
    }
  >(({ boletoId, data }) => alterarBoletoPagadorEndereco(boletoId, data), {
    onSuccess: () => {
      notifications.notifySuccess(
        'Endereço do pagador do boleto alterado com sucesso!',
      )
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useCancelarBoleto() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface>,
    { boletoId: string }
  >(({ boletoId }) => cancelarBoleto(boletoId), {
    onSuccess: () => {
      notifications.notifySuccess('Boleto cancelado com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useRegistrarBoleto() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    BoletoDetalhadoModel,
    AxiosError<ErrorInterface>,
    BoletoInclusaoModel
  >((data) => registrarBoleto(data), {
    onSuccess: () => {
      notifications.notifySuccess('Boleto registrado com sucesso!')
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export default function useBoleto() {
  return {
    useQueryObterBoletos,
    useQueryObterBoletoPorId,
    useObterBoletoPorId,
    useObterBoletoDoBanco,
    useIncluirBoleto,
    useAlterarBoletoIdenfiticacao,
    useAlterarBoletoValores,
    useAlterarBoletoBeneficiario,
    useAlterarBoletoPagador,
    useAlterarBoletoProtesto,
    useAlterarBoletoMensagem,
    useAlterarBoletoDataVencimento,
    useAlterarBoletoDataLimitePagamento,
    useAlterarBoletoAbatimento,
    useAlterarBoletoJuros,
    useAlterarBoletoMulta,
    useAlterarBoletoDesconto,
    useAlterarBoletoPagadorEndereco,
    useCancelarBoleto,
    useRegistrarBoleto,
  }
}
