import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import Acesso from './pages/Acesso'
import Home from './pages/Home'
import LayoutAutenticado from './pages/Layouts/LayoutAutenticado'
import LayoutNaoAutenticado from './pages/Layouts/LayoutNaoAutenticado'
import EmailEditor from 'pages/EmailEditor'
import Modelos from 'pages/Modelos'
import Templates from 'pages/Templates'
import EnvioBoletos from 'pages/EnvioBoletos'
import LogEnvio from 'pages/LogEnvio'
import Convenios from 'pages/Convenios'
import Boletos from 'pages/Boletos'
import Convenio from 'pages/Convenio'
import Boleto from 'pages/Boleto'

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <LayoutNaoAutenticado>
              <Acesso />
            </LayoutNaoAutenticado>
          }
        />
        <Route
          path="/home"
          element={
            <LayoutAutenticado>
              <Home />
            </LayoutAutenticado>
          }
        />

        <Route
          path="/boletos"
          element={
            <LayoutAutenticado>
              <Outlet />
            </LayoutAutenticado>
          }
        >
          <Route path="lista" element={<Boletos />} />
          <Route path="boleto/:id" element={<Boleto />} />
        </Route>

        <Route
          path="/envio-boletos"
          element={
            <LayoutAutenticado>
              <EnvioBoletos />
            </LayoutAutenticado>
          }
        />
        <Route
          path="/modelos"
          element={
            <LayoutAutenticado>
              <Modelos />
            </LayoutAutenticado>
          }
        />
        <Route
          path="/templates"
          element={
            <LayoutAutenticado>
              <Templates />
            </LayoutAutenticado>
          }
        />
        <Route
          path="/convenios"
          element={
            <LayoutAutenticado>
              <Outlet />
            </LayoutAutenticado>
          }
        >
          <Route path="lista" element={<Convenios />} />
          <Route path="convenio/:id" element={<Convenio />} />
        </Route>
        <Route
          path="/editor"
          element={
            <LayoutAutenticado>
              <EmailEditor />
            </LayoutAutenticado>
          }
        />
        <Route
          path="/log"
          element={
            <LayoutAutenticado>
              <LogEnvio />
            </LayoutAutenticado>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
