import { Stack, Typography } from '@mui/material'
import { BackupOutlined as BackupOutlinedIcon } from '@mui/icons-material'

import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { formatCurrency, useDialog } from '@data-c/hooks'

import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import CardInfo from '@components/CardInfo'
import Divider from '@components/Divider'
import CustomTooltip from '@components/CustomTooltip'

import useBoleto, {
  BoletoDetalhadoModel,
  BoletoInclusaoModel,
} from '@hooks/queries/useBoleto'
import useDates from '@hooks/useDates'

interface BoletoRegistroProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoRegistro({ boleto }: BoletoRegistroProps) {
  const { toBrazilianDate } = useDates()

  const { useRegistrarBoleto } = useBoleto()
  const { mutateAsync: registrarBoleto, isLoading } = useRegistrarBoleto()

  const {
    isOpen: isOpenRegistrarBoletoDialog,
    openDialog: openRegistrarBoletoDialog,
    closeDialog: closeRegistrarBoletoDialog,
  } = useDialog()

  async function handleRegistrarBoleto() {
    if (boleto) {
      const {
        id,
        convenioId,
        dataEmissao,
        dataVencimento,
        descontoTipo,
        descontoValor,
        jurosTipo,
        multaTipo,
        protestoTipo,
        valorTitulo,
        descontoDataExpiracao,
        documentoSistema,
        jurosValor,
        mensagemOcorrencia,
        multaValor,
        protestoDias,
        pagadorBairro,
        pagadorCEP,
        pagadorCidadeNome,
        pagadorCNPJCPF,
        pagadorLogradouro,
        pagadorNome,
        pagadorUF,
        pagadorComplemento,
        pagadorEmail,
        pagadorTelefone,
      } = boleto

      const dataRegistrarBoleto: BoletoInclusaoModel = {
        id: id || '',
        convenioId,
        dataEmissao,
        dataVencimento: dataVencimento || '',
        descontoTipo,
        descontoValor,
        jurosTipo,
        multaTipo,
        pagador: {
          bairro: pagadorBairro || '',
          cep: pagadorCEP || '',
          cidadeNome: pagadorCidadeNome || '',
          cnpjcpf: pagadorCNPJCPF || '',
          logradouro: pagadorLogradouro || '',
          nome: pagadorNome || '',
          uf: pagadorUF || '',
          complemento: pagadorComplemento,
          email: pagadorEmail,
          telefone: pagadorTelefone,
        },
        protestoTipo,
        valorTitulo,
        descontoDataExpiracao,
        documentoSistema,
        jurosValor,
        mensagemOcorrencia,
        multaValor,
        protestoDias,
      }
      await registrarBoleto(dataRegistrarBoleto)
      handleCloseRegistrarBoletoDialog()
    }
  }

  function handleCloseRegistrarBoletoDialog() {
    closeRegistrarBoletoDialog()
  }

  const dataVencimentoFormatada = toBrazilianDate(boleto?.dataVencimento || '')
  const valorTituloFormatado = formatCurrency(boleto?.valorTitulo || '')
  const isBoletoIncluido =
    boleto?.status === DropDownBoletoStatusEnum.INCLUIDO_0

  return (
    <>
      <CustomTooltip
        title="Realiza o registro do boleto junto ao banco (Habilitado somente quando o boleto está incluído)"
        disableHoverListener={isBoletoIncluido}
      >
        <Button
          title="Realiza o registro do boleto junto ao banco"
          variant="contained"
          disabled={!isBoletoIncluido}
          startIcon={<BackupOutlinedIcon />}
          onClick={openRegistrarBoletoDialog}
        >
          Registrar
        </Button>
      </CustomTooltip>

      <Dialog
        title="Registrar Boleto?"
        type="info"
        maxWidth="xs"
        open={isOpenRegistrarBoletoDialog}
        onClose={handleCloseRegistrarBoletoDialog}
        actions={
          <ButtonContainer>
            <Button color="error" onClick={handleCloseRegistrarBoletoDialog}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              isLoading={isLoading}
              onClick={handleRegistrarBoleto}
            >
              Sim, registrar boleto
            </Button>
          </ButtonContainer>
        }
      >
        <Stack gap={1}>
          <Typography variant="h5" fontWeight="bold">
            Realiza o registro do boleto junto ao banco
          </Typography>

          <Divider />

          <Stack>
            <CardInfo
              title="Vencimento"
              subtitle={dataVencimentoFormatada}
              direction="horizontal"
            />
            <CardInfo
              title="Valor Título"
              subtitle={valorTituloFormatado}
              direction="horizontal"
            />
            <CardInfo
              title="Pagador"
              subtitle={boleto?.pagadorNome}
              direction="horizontal"
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}
