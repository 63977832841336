import { Grid, TextField } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { FormContainer } from '@data-c/ui'

import useBoleto, {
  AlterarBoletoMensagemParams,
} from '@hooks/queries/useBoleto'

export default function Form() {
  const { formValues: data } = useFormData<AlterarBoletoMensagemParams>()
  const { changeValue, toggleSubmit, closeForm } =
    useFormApi<AlterarBoletoMensagemParams>()

  const { useAlterarBoletoMensagem } = useBoleto()
  const { mutateAsync: alterarBoletoMensagem } = useAlterarBoletoMensagem()

  async function handleSubmit() {
    toggleSubmit(true)
    await alterarBoletoMensagem(data).finally(() => toggleSubmit(false))
    closeForm()
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      dialogProps={{
        title: 'Alterar Mensagem do Boleto',
        maxWidth: 'sm',
      }}
      triggerButton={<></>}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="novaMensagem"
            label="Mensagem (Instrução)"
            value={data?.novaMensagem || ''}
            multiline
            rows={4}
            onChange={(e) => changeValue('novaMensagem', e.target.value || '')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
