import { useMemo, useCallback } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { TransportableDataTableProps, findData } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'
import { DataTable } from '@data-c/ui'

import Options from '@components/DataTable/components/Options'
import ActiveSwitch from '@components/ActiveSwitch'

import useTemplateEmail, {
  RegraEnvioInterface,
  RegraEnvioOperacao,
  TemplateEmailInterface,
  tipoDataReverseMapping,
} from '@hooks/queries/useTemplateEmail'
import useTableCustomCell from '@hooks/useTableCustomCell'

import DataTableProps from 'interfaces/DataTableProps'

function definirOperacao(quantidadeDias: number) {
  const operacao: RegraEnvioOperacao =
    quantidadeDias > 0 ? 'subtract' : quantidadeDias < 0 ? 'add' : 'now'
  return operacao
}

const buildDisparoText = (data: TemplateEmailInterface) => {
  if (!data.envioAutomatico) {
    return 'Envio manual'
  }

  const { tipoData, quantidadeDias } = data

  const campoMaps = {
    registro: 'Registro',
    vencimento: 'Vencimento',
    pagamento: 'Pagamento',
  }
  const campo = tipoDataReverseMapping[tipoData || 0]
  const operacaoDefinida = definirOperacao(quantidadeDias || 0)

  if (quantidadeDias === undefined || quantidadeDias === null) {
    return 'Configuração inválida'
  }

  let operacao = ''

  if (operacaoDefinida === 'now') {
    operacao = `na data de ${campoMaps[campo || 'registro']}`
  } else {
    operacao = `${(quantidadeDias || '---').toString().replace(/-/g, '')} dia${
      (quantidadeDias || 0) > 1 ? 's' : ''
    } ${operacaoDefinida === 'subtract' ? 'antes' : 'depois'} da data de ${
      campoMaps[campo || 'registro']
    }`
  }

  return `O envio dos e-mails irá ocorrer ${operacao} de cada boleto às ${data.horaEnvio}h`
}

interface TableProps
  extends DataTableProps<TemplateEmailInterface, TemplateEmailInterface>,
    TransportableDataTableProps<TemplateEmailInterface> {
  onOpenTemplateEmail?: (data: TemplateEmailInterface) => void
}

export default function Table(props: TableProps) {
  const { onOpenTemplateEmail, enableTransporter, onTransport, query } = props

  const { floatOptionsCellStyle, ativoOptionsCellStyle, whiteSpace } =
    useTableCustomCell()

  const { useQuery, useObterTemplatePorId, useAlterarEmailAgendamentoStatus } =
    useTemplateEmail()
  const { obterTemplatePorId, isLoadingObterTemplatePorId } =
    useObterTemplatePorId()
  const {
    data,
    isLoading: isLoadingObterTemplateEmail,
    isFetching: isFetchingObterTemplateEmail,
  } = useQuery({ query: query || '' })
  const {
    mutateAsync: alterarEmailAgendamentoStatus,
    isLoading: isLoadingAlterarEmailAgendamentoStatus,
  } = useAlterarEmailAgendamentoStatus()

  const { openForm: opemFormRegrasEnvio, openConfirm } =
    useFormApi<TemplateEmailInterface>()

  const handleClickItem = useCallback(
    async (
      event:
        | 'regras_envio'
        | 'template_email'
        | 'delete'
        | 'transport'
        | 'update_ativo',
      data: TemplateEmailInterface,
    ) => {
      switch (event) {
        case 'regras_envio':
          const responseRegraEnvio = await obterTemplatePorId(data?.id || '')

          if (responseRegraEnvio) {
            const { tipoData, somenteBoletosLiquidados } = responseRegraEnvio
            const campo = tipoDataReverseMapping[tipoData || 0]
            const operacao = definirOperacao(
              responseRegraEnvio?.quantidadeDias || 0,
            )

            const regraEnvio: RegraEnvioInterface = {
              campo,
              liquidado: Boolean(somenteBoletosLiquidados),
              operacao,
            }

            opemFormRegrasEnvio({
              ...responseRegraEnvio,
              regraEnvio,
              quantidadeDias: Math.abs(responseRegraEnvio?.quantidadeDias || 0),
            })
          }

          break
        case 'template_email':
          const responseTemplateEmail = await obterTemplatePorId(data?.id || '')
          if (responseTemplateEmail) {
            onOpenTemplateEmail && onOpenTemplateEmail(responseTemplateEmail)
          }
          break
        case 'transport':
          onTransport && onTransport(data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'update_ativo': {
          alterarEmailAgendamentoStatus({
            agendamentoId: data?.id || '',
            ativo: data?.ativo,
          })
          break
        }
      }
    },
    [
      opemFormRegrasEnvio,
      onOpenTemplateEmail,
      openConfirm,
      obterTemplatePorId,
      onTransport,
    ],
  )

  const templates =
    data?.data?.map((d) => ({
      ...d,
      disparo: buildDisparoText(d),
      horaEnvio: d.envioAutomatico ? `${d.horaEnvio}h` : '---',
    })) || []

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'identificador',
        label: 'Id',
        options: {
          setCellProps: whiteSpace().setCellProps,
        },
      },
      {
        name: 'nome',
        label: 'Agendamento',
        options: {
          setCellProps: whiteSpace().setCellProps,
        },
      },
      {
        name: 'disparo',
        label: 'Disparo do envio',
        options: {
          ...whiteSpace(),
        },
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: ativoOptionsCellStyle,
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            return (
              <ActiveSwitch
                data={data?.data || []}
                value={value}
                disabled={isLoadingAlterarEmailAgendamentoStatus}
                rowIndex={rowIndex}
                onChange={handleClickItem}
              />
            )
          },
        },
      },
      {
        name: 'id',
        label: 'Opções',
        options: {
          ...floatOptionsCellStyle(),
          customBodyRender: (value) => {
            const _data = findData(data?.data || [], value, 'id')
            return (
              <Options
                displayUpdateButton={false}
                displayDeleteButton={!enableTransporter}
                extraOptions={[
                  {
                    id: 'regras_envio',
                    icon: 'add_alarm',
                    visible: !enableTransporter,
                    iconButtonProps: { title: 'Alterar agendamento' },
                  },
                  {
                    id: 'template_email',
                    icon: 'receipt',
                    visible: !enableTransporter,
                    displayBadge: !_data?.corpoEmail && !_data?.tituloEmail,
                    iconButtonProps: { title: 'Editar agendamento' },
                  },
                  {
                    id: 'transport',
                    icon: 'file_download',
                    visible: enableTransporter === true,
                    iconButtonProps: { color: 'primary' },
                  },
                ]}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [handleClickItem, data?.data, enableTransporter],
  )

  const isFetching =
    isLoadingObterTemplateEmail ||
    isLoadingObterTemplatePorId ||
    isLoadingAlterarEmailAgendamentoStatus ||
    isFetchingObterTemplateEmail

  return (
    <DataTable
      data={templates}
      isLoading={isLoadingObterTemplateEmail}
      isFetching={isFetching}
      columns={columns}
    />
  )
}
