import { CSSProperties } from 'react'
import { CircularProgress, Stack } from '@mui/material'

interface LoadingProps {
  customSx?: CSSProperties
}

export default function Loading(props: LoadingProps) {
  const { customSx } = props

  return (
    <Stack
      sx={{
        position: 'absolute',
        zIndex: '99',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgb(0 0 0 / 50%)',
        ...customSx,
      }}
    >
      <CircularProgress />
    </Stack>
  )
}
