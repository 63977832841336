import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownTipoCobrancaTarifaEnum {
  RETORNADA_PELO_BANCO_0 = 0,
  INFORMAR_NO_REGISTRO_1 = 1,
  INFORMAR_NA_LIQUIDACAO_2 = 2,
}

const menuItems = [
  {
    value: DropDownTipoCobrancaTarifaEnum.RETORNADA_PELO_BANCO_0,
    label: '0 - Retornada pelo banco',
  },
  {
    value: DropDownTipoCobrancaTarifaEnum.INFORMAR_NO_REGISTRO_1,
    label: '1 - Informar no registro',
  },
  {
    value: DropDownTipoCobrancaTarifaEnum.INFORMAR_NA_LIQUIDACAO_2,
    label: '2 - Informar na liquidação',
  },
]

export default function DropDownTipoCobrancaTarifa(
  props: DynamicDropDownProps,
) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="Tipo de Cobrança de Tarifa"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
