import { Stack, Typography } from '@mui/material'
import { CloseOutlined as CloseOutlinedIcon } from '@mui/icons-material'

import { formatCurrency, useDialog } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'

import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import CardInfo from '@components/CardInfo'
import Divider from '@components/Divider'
import CustomTooltip from '@components/CustomTooltip'

import useBoleto, { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'
import useDates from '@hooks/useDates'

interface BoletoCancelamentoProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoCancelamento({
  boleto,
}: BoletoCancelamentoProps) {
  const { toBrazilianDate } = useDates()
  const {
    isOpen: isOpenBoletoCancelamentoDialog,
    openDialog: openBoletoCancelamentoDialog,
    closeDialog: closeBoletoCancelamentoDialog,
  } = useDialog()

  const { useCancelarBoleto } = useBoleto()
  const { mutateAsync: cancelarBoleto, isLoading } = useCancelarBoleto()

  async function handleCancelarBoleto() {
    await cancelarBoleto({ boletoId: boleto?.id || '' })
    handleCloseBoletoCancelamentoDialog()
  }

  const dataVencimentoFormatada = toBrazilianDate(boleto?.dataVencimento || '')
  const valorTituloFormatado = formatCurrency(boleto?.valorTitulo || '')
  const isBoletoRegistrado =
    boleto?.status === DropDownBoletoStatusEnum.REGISTRADO_1

  function handleCloseBoletoCancelamentoDialog() {
    closeBoletoCancelamentoDialog()
  }

  return (
    <>
      <CustomTooltip
        title="Realiza o cancelamento(baixa) do boleto junto ao banco (Habilitado somente quando o boleto está registrado)"
        disableHoverListener={isBoletoRegistrado}
      >
        <Button
          title="Realiza o cancelamento(baixa) do boleto junto ao banco"
          variant="contained"
          disabled={!isBoletoRegistrado}
          startIcon={<CloseOutlinedIcon />}
          onClick={openBoletoCancelamentoDialog}
        >
          Cancelar
        </Button>
      </CustomTooltip>

      <Dialog
        title="Cancelar Boleto?"
        type="error"
        maxWidth="xs"
        open={isOpenBoletoCancelamentoDialog}
        onClose={handleCloseBoletoCancelamentoDialog}
        actions={
          <ButtonContainer>
            <Button onClick={handleCloseBoletoCancelamentoDialog}>
              Voltar
            </Button>
            <Button
              variant="contained"
              color="error"
              isLoading={isLoading}
              onClick={handleCancelarBoleto}
            >
              Sim, cancelar boleto
            </Button>
          </ButtonContainer>
        }
      >
        <Stack gap={1}>
          <Typography variant="h5" fontWeight="bold">
            Atenção! Ao cancelar um boleto, você não poderá reverter o
            cancelamento!
          </Typography>

          <Divider />

          <Stack>
            <CardInfo
              title="Nosso número"
              subtitle={boleto?.nossoNumero}
              direction="horizontal"
            />
            <CardInfo
              title="Vencimento"
              subtitle={dataVencimentoFormatada}
              direction="horizontal"
            />
            <CardInfo
              title="Valor Título"
              subtitle={valorTituloFormatado}
              direction="horizontal"
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}
