import { TextField } from '@mui/material'

import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { useForm } from '@data-c/hooks'

import useValidations from '@hooks/useValidations'
import { LayoutEmailInterface, useSubmit } from '@hooks/queries/useLayoutEmail'
import * as yup from 'yup'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome do template').nullable(),
  // corpoEmail: yup.string().required('Informe o corpo do e-mail'),
})

const defaultData = {
  nome: '',
}

interface FormInitialProps {
  isOpen: boolean
  onClose: () => void
}

export default function FormInitial(props: FormInitialProps) {
  const { isOpen, onClose } = props

  const { openForm, toggleSubmit } = useFormApi<LayoutEmailInterface>()
  const { data, changeValue } = useForm(defaultData)

  const { validationProps, setValidationErrors } = useValidations()
  const { mutateAsync: salvarLayout, isLoading } = useSubmit()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        const response = await salvarLayout(data)
        openForm(response)
        handleClose()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  function handleClose() {
    onClose()
    changeValue('nome', '')
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      title="Adicionar Template"
      actions={
        <ButtonContainer>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            variant="contained"
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Adicionar
          </Button>
        </ButtonContainer>
      }
    >
      <TextField
        name="nome"
        label="Nome do Template"
        value={data?.nome || ''}
        inputProps={{ maxLength: 40 }}
        onChange={(e) => {
          const nome = e.target.value
          changeValue('nome', nome)
        }}
        {...validationProps('nome')}
      />
    </Dialog>
  )
}
