import { Grid, TextField } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { FormContainer } from '@data-c/ui'
import { onlyNumbers } from '@data-c/hooks'

import DropDownTipoProtesto, {
  DropDownTipoProtestoEnum,
} from '@components/Inputs/DropDownTipoProtesto'

import useBoleto, { AlterarBoletoProtestoModel } from '@hooks/queries/useBoleto'
import useValidations from 'hooks/useValidations'

import * as yup from 'yup'

const isProtestoTipo = [
  DropDownTipoProtestoEnum.NÃO_PROTESTAR_3,
  DropDownTipoProtestoEnum.DEFINIDO_PELO_CONVENIO_99,
]

const schema = yup.object().shape({
  protestoDias: yup
    .string()
    .when('protestoTipo', ([protestoTipo], schema) => {
      if (!isProtestoTipo.includes(protestoTipo)) {
        return schema.required('Informe o Dias protesto')
      }
      return schema
    })
    .nullable(),
})

interface FormProps {
  boletoId: string
}

export default function Form({ boletoId }: FormProps) {
  const { setValidationErrors, validationProps } = useValidations()

  const { formValues: data } = useFormData<AlterarBoletoProtestoModel>()
  const { changeValue, toggleSubmit, closeForm } =
    useFormApi<AlterarBoletoProtestoModel>()

  const { useAlterarBoletoProtesto } = useBoleto()
  const { mutateAsync: alterarBoletoProtesto } = useAlterarBoletoProtesto()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoProtesto({
          boletoId,
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      dialogProps={{
        title: 'Alterar Protesto do Boleto',
        maxWidth: 'sm',
      }}
      triggerButton={<></>}
    >
      <Grid container spacing={2}>
        <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
          <DropDownTipoProtesto
            name="protestoTipo"
            value={String(data?.protestoTipo) || ''}
            onChange={(value, valueDescription) => {
              changeValue('protestoTipo', value)
              changeValue('protestoTipoDesc', valueDescription)
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <TextField
            name="protestoDias"
            label="Dias Protesto"
            required={!isProtestoTipo.includes(data?.protestoTipo)}
            value={data?.protestoDias || ''}
            inputProps={{ maxLength: 9 }}
            onChange={(e) =>
              changeValue('protestoDias', onlyNumbers(e.target.value) || null)
            }
            {...validationProps('protestoDias')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
