import { useFormData } from '@data-c/providers'
import { Button } from '@data-c/ui'

import { useActionOptionsContext } from 'contexts/ActionOptionsContext/ActionOptionsContext'
import { ConvenioModel } from '@hooks/queries/useConvenio'

import { DropDownBancoCodigoEnum } from '@components/Inputs/DropDownBancoCodigo'
import CustomTooltip from '@components/CustomTooltip'

import BancoBrasil from './components/BancoBrasil'
import Sicoob from './components/Sicoob'
import Itau from './components/Itau'

export enum OrigemType {
  BANCO_DO_BRASIL = 'BANCO_DO_BRASIL',
  SICOOB = 'SICOOB',
  ITAU = 'ITAU',
}

export default function ActionsOptions() {
  const { setOrigem, isLoading: isLoadingObterCredenciais } =
    useActionOptionsContext()

  function handleClick(bancoCodigo: DropDownBancoCodigoEnum) {
    const mapping: Record<number, string> = {
      [DropDownBancoCodigoEnum.BANCO_BRASIL_1]: OrigemType.BANCO_DO_BRASIL,
      [DropDownBancoCodigoEnum.SICOOB_756]: OrigemType.SICOOB,
      [DropDownBancoCodigoEnum.ITAU_341]: OrigemType.ITAU,
    }
    setOrigem(mapping[bancoCodigo])
  }

  const { formValues: convenio } = useFormData<ConvenioModel>()
  const isConvenioAtivo = convenio?.ativo

  return (
    <>
      <CustomTooltip
        title="Habilitado somente quando o convênio está desativado"
        disableHoverListener={!isConvenioAtivo}
      >
        <Button
          disabled={isConvenioAtivo}
          isLoading={isLoadingObterCredenciais}
          onClick={() => handleClick(convenio?.bancoCodigo)}
        >
          Credenciais de Acesso
        </Button>
      </CustomTooltip>

      <BancoBrasil convenio={convenio} />
      <Sicoob convenio={convenio} />
      <Itau convenio={convenio} />
    </>
  )
}
