import { useMemo } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Dialog } from '@data-c/ui'

import { agendamentoTipoDataMapping } from '@components/Inputs/DropDownAgendamentoTipoData'
import {
  agendamentoStatusColorMapping,
  agendamentoStatusMapping,
} from '@components/Inputs/DropDownAgendamentoStatus'
import FlagWithDescription from '@components/Displays/FlagWithDescription'
import ResponsiveDataTable from '@components/ResponsiveDataTable'

import { AgendamentoStatusModel } from '@hooks/queries/useEnvioBoletos'
import useTableCustomCell from '@hooks/useTableCustomCell'
import useDates from '@hooks/useDates'

interface BoletoLogEnvioDialogProps {
  data: AgendamentoStatusModel[]
  isOpen: boolean
  onClose: () => void
}

export default function BoletoLogEnvioDialog(props: BoletoLogEnvioDialogProps) {
  const { data, isOpen, onClose } = props

  const { toBrazilianDateTime } = useDates()
  const { dateOptionsCellStyle, whiteSpace, ativoOptionsCellStyle } =
    useTableCustomCell()

  const dataFormatada = useMemo(() => {
    return data?.map((item) => {
      return {
        ...item,
        data: toBrazilianDateTime(item?.data || ''),
        statusFormatado: {
          status: item?.status,
          statusDescricao: agendamentoStatusMapping[item?.status],
        },
        agendamentoTipoData:
          agendamentoTipoDataMapping[item?.agendamentoTipoData],
      }
    })
  }, [data])

  const columns = useMemo((): MUIDataTableColumnDef[] => {
    return [
      {
        name: 'statusFormatado',
        label: 'Status',
        options: {
          setCellProps: ativoOptionsCellStyle,
          customBodyRender: (value: {
            statusDescricao: string
            status: number
          }) => {
            return (
              <FlagWithDescription
                flagDescription={value.statusDescricao}
                mappingColor={agendamentoStatusColorMapping[value.status]}
              />
            )
          },
        },
      },
      {
        name: 'agendamentoEnvioAutomatico',
        label: 'Tipo de Envio',
        options: {
          setCellProps: ativoOptionsCellStyle,
          customBodyRender: (value) => {
            return (
              <FlagWithDescription
                flagDescription={value ? 'Envio automático' : 'Envio manual'}
                color={value ? 'primary' : 'warning'}
              />
            )
          },
        },
      },
      {
        name: 'agendamentoNome',
        label: 'Nome',
        options: {
          setCellProps: whiteSpace().setCellProps,
        },
      },
      {
        name: 'data',
        label: 'Data',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'agendamentoTipoData',
        label: 'Tipo Data',
        options: {
          ...whiteSpace(),
        },
      },
      {
        name: 'mensagemErro',
        label: 'Mensagem de Erro',
        options: {
          ...whiteSpace(),
        },
      },
    ]
  }, [data])

  return (
    <Dialog
      title="Log de Envios"
      maxWidth="lg"
      open={isOpen}
      onClose={onClose}
      actions="none"
    >
      <ResponsiveDataTable data={dataFormatada || []} columns={columns} />
    </Dialog>
  )
}
