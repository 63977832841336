import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'
import DynamicMenuItem from '@components/DynamicMenuItem'

export enum DropDownTipoDescontoEnum {
  SEM_DESCONTO_0 = 0,
  VALOR_FIXO_ATE_DATA_INFORMADA_1 = 1,
  PERCENTUAL_ATE_DATA_INFORMADA_2 = 2,
  POR_DIA_ANTECIPACAO_3 = 3,
  DEFINIDO_PELO_CONVENIO_99 = 99,
}

const menuItems = [
  {
    value: DropDownTipoDescontoEnum.SEM_DESCONTO_0,
    label: '00 - Sem Desconto',
  },
  {
    value: DropDownTipoDescontoEnum.VALOR_FIXO_ATE_DATA_INFORMADA_1,
    label: '01 - Valor fixo até a data informada',
  },
  {
    value: DropDownTipoDescontoEnum.PERCENTUAL_ATE_DATA_INFORMADA_2,
    label: '02 - Percentual até a data informada',
  },
  {
    value: DropDownTipoDescontoEnum.POR_DIA_ANTECIPACAO_3,
    label: '03 - Por dia de antecipação',
  },
  {
    value: DropDownTipoDescontoEnum.DEFINIDO_PELO_CONVENIO_99,
    label: '99 - Definido pelo convênio',
  },
]

export default function DropDownTipoDesconto(props: DynamicDropDownProps) {
  const { onChange, tooltipTitle, disabled, optionToDisabled, ...rest } = props

  return (
    <DynamicDropDown
      label="Tipo de Desconto"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <DynamicMenuItem
          key={item.value}
          value={item.value}
          disabled={optionToDisabled?.includes(item.value) || disabled}
          tooltipTitle={tooltipTitle}
        >
          {item.label}
        </DynamicMenuItem>
      ))}
    </DynamicDropDown>
  )
}
