import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { Stack, Typography } from '@mui/material'
import { EmailConsultaInterface } from '@hooks/queries/useEnvioBoletos'
import Table from './components/Table'

interface BoletosJaEnviadosDialogProps {
  boletosJaEnviados?: EmailConsultaInterface[]
  isLoading: boolean
  isOpen: boolean
  disabled?: boolean
  onClose: () => void
  onConfirm: () => void
  onRemoveBoletoJaEnviado: (boletoJaEnviado: EmailConsultaInterface) => void
}

export default function BoletosJaEnviadosDialog(
  props: BoletosJaEnviadosDialogProps,
) {
  const {
    boletosJaEnviados,
    isOpen,
    disabled = false,
    isLoading = false,
    onClose,
    onConfirm,
    onRemoveBoletoJaEnviado,
  } = props

  const descricao =
    boletosJaEnviados?.length === 1
      ? `Notamos que o boleto listado abaixo já foi enviado
    anteriormente. Caso tenha selecionado esse boleto por engano,
    você pode removê-lo e prosseguir com o envio normalmente.`
      : `Notamos que os boletos listados abaixo já foram enviados
    anteriormente. Caso tenha selecionado algum desses boletos por engano,
    você pode removê-los e prosseguir com o envio normalmente.`

  return (
    <Dialog
      title="Reenviar boletos?"
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      actions={
        <ButtonContainer>
          <Button color="error" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            isLoading={isLoading}
            disabled={disabled}
            onClick={onConfirm}
          >
            Enviar
          </Button>
        </ButtonContainer>
      }
    >
      <Stack gap={2} title="">
        <Typography variant="h5">{descricao}</Typography>
        <Table
          boletosJaEnviados={boletosJaEnviados}
          onRemoveBoletoJaEnviado={onRemoveBoletoJaEnviado}
        />
      </Stack>
    </Dialog>
  )
}
