import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownIndicadorAceiteTituloVencidoEnum {
  NAO_INFORMAR_0 = 0,
  ACEITO_ATE_DIAS_LIMITE_RECEBIMENTO_1 = 1,
  NAO_ACEITO_APOS_VENCIMENTO_2 = 2,
}

const menuItems = [
  {
    value: DropDownIndicadorAceiteTituloVencidoEnum.NAO_INFORMAR_0,
    label: '0 - Não informar',
  },
  {
    value:
      DropDownIndicadorAceiteTituloVencidoEnum.ACEITO_ATE_DIAS_LIMITE_RECEBIMENTO_1,
    label: '1 - Aceito até dias do limite',
  },
  {
    value:
      DropDownIndicadorAceiteTituloVencidoEnum.NAO_ACEITO_APOS_VENCIMENTO_2,
    label: '2 - Não aceito após vencimento',
  },
]

export default function DropDownIndicadorAceiteTituloVencido(
  props: DynamicDropDownProps,
) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="Indicador de Aceite Título vencido"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={String(item.value)}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
