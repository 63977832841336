import {
  TableFooter,
  TableRow,
  TableCell,
  Select,
  Pagination,
} from '@mui/material'
import { useMemo } from 'react'

const PaginationFooter = (props: any) => {
  const {
    count: _count,
    rowsPerPage,
    page,
    changePage,
    changeRowsPerPage,
    rowsPerPageOptions,
  } = props

  const countOfPages = useMemo(() => {
    return Math.max(0, Math.ceil(_count / rowsPerPage))
  }, [_count, rowsPerPage])

  return (
    <TableFooter>
      <TableRow>
        <TableCell
          colSpan={1000}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '12px 12px 12px 12px',
          }}
        >
          <Select
            size="small"
            value={rowsPerPage}
            native
            onChange={(e) => {
              changeRowsPerPage(e.target.value)
            }}
          >
            {rowsPerPageOptions.map((option: string) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Pagination
            count={countOfPages}
            showFirstButton
            showLastButton
            onChange={(_, _page) => {
              changePage(_page)
            }}
            page={page}
            color="secondary"
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}

export default PaginationFooter
