import { FormProvider } from '@data-c/providers'

import { DropDownTipoJurosEnum } from '@components/Inputs/DropDownTipoJuros'
import OptionList from '@components/OptionsListContainer/components/OptionList'

import {
  AlterarBoletoJurosModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'
import { OrigemType } from '../..'

interface JurosProps {
  boleto: BoletoDetalhadoModel | null
}

export default function Juros({ boleto }: JurosProps) {
  const { jurosTipo, jurosValor } = boleto || {}

  const dataAlterarJuros: AlterarBoletoJurosModel = {
    jurosTipo: jurosTipo || DropDownTipoJurosEnum.ISENTO_0,
    jurosValor,
  }

  return (
    <FormProvider>
      <OptionList
        name={OrigemType.JUROS}
        noRequest={{ initialData: dataAlterarJuros }}
        form={<Form boleto={boleto} />}
      />
    </FormProvider>
  )
}
