import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotifications'

import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import ErrorInterface from 'interfaces/ErrorInterface'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'

import apiCadastro from 'services/api-cadastro'

import { PaisModel } from './usePais'
import { CidadeModel, UFModel } from './useCidade'

export interface PessoaCadastroModel {
  id?: string
  codigo?: number | null
  nome: string
  nomeFantasia?: string | null
  logradouro?: string | null
  bairro?: string | null
  cidadeId: string
  cidadeNome?: string | null
  cidadeModel?: CidadeModel | null
  cidadeUF: string
  ufModel?: UFModel | null
  documentoId: string
  numero?: string | null
  complemento?: string | null
  cidadeUFCodigoIBGE?: string | null
  paisId?: string | null
  paisNome?: string | null
  paisModel?: PaisModel | null
  cep?: string | null
  telefone?: string | null
  inscEstadual?: string | null
  inscEstadualId?: number | string | null
  email?: string | null
  ativo?: boolean
}

export async function removerPessoaCadastro(
  data: PessoaCadastroModel,
): Promise<void> {
  const response = await apiCadastro.delete(`Pessoa/${data.id}`)
  return response.data
}

export async function obterPessoasCadastro(
  params?: QueryParamsInterface,
): Promise<HttpResponseInterface<PessoaCadastroModel>> {
  let paginationProps = params?.pagination

  if (!paginationProps) {
    paginationProps = {
      page: 1,
      pageSize: 20,
    }
  }

  const { page, pageSize } = paginationProps

  const response = await apiCadastro.get(
    `Pessoa/Pesquisar/${params?.query || ''}`,
    {
      headers: {
        'Resposta-Compactada': 'Nunca',
        'DC-Page': page,
        'DC-PageSize': pageSize,
      },
    },
  )

  return response.data
}

export function useQueryObterPessoasCadastro(params?: QueryParamsInterface) {
  return useQuery<
    HttpResponseInterface<PessoaCadastroModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['PESSOAS_CADASTRO', params], () => {
    return obterPessoasCadastro(params)
  })
}

export function useRemoverPessoaCadastro() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<void, AxiosError, PessoaCadastroModel>(
    (data) => removerPessoaCadastro(data),
    {
      onSuccess() {
        notifications.notifySuccess('Pessoa excluída com sucesso!')
        queryClient.invalidateQueries(['PESSOA_CADASTRO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function usePessoaCadastro() {
  return {
    useQueryObterPessoasCadastro,
    useRemoverPessoaCadastro,
  }
}
