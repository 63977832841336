import { useFormApi, useFormData } from '@data-c/providers'
import { Button, ButtonContainer } from '@data-c/ui'

import CustomTooltip from '@components/CustomTooltip'
import { BoletoInclusaoModel } from '@hooks/queries/useBoleto'

interface ActionsProps {
  onSubmit: (origem: 'INCLUIR' | 'REGISTRAR') => void
}

export default function Actions(props: ActionsProps) {
  const { onSubmit } = props

  const { closeForm } = useFormApi<BoletoInclusaoModel>()
  const { isSubmitting } = useFormData<BoletoInclusaoModel>()

  return (
    <ButtonContainer
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
    >
      <CustomTooltip title="Faz a inclusão do boleto no sistema e já realiza a transmissão para o banco">
        <Button variant="contained" onClick={() => onSubmit('REGISTRAR')}>
          Registrar
        </Button>
      </CustomTooltip>
      <ButtonContainer>
        <Button color="error" onClick={closeForm}>
          Cancelar
        </Button>
        <CustomTooltip title="Faz a inclusão do boleto no sistema e abre as opções de detalhamento (não efetua registro)">
          <Button
            title=""
            variant="contained"
            isLoading={isSubmitting}
            onClick={() => onSubmit('INCLUIR')}
          >
            Incluir e Detalhar
          </Button>
        </CustomTooltip>
      </ButtonContainer>
    </ButtonContainer>
  )
}
