import { useState } from 'react'
import { Grid, TextField } from '@mui/material'

import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import { onlyNumbers } from '@data-c/hooks'

import AutoCompleteUF from '@components/AutoCompletes/AutoCompleteUF'
import AutoCompleteCidade from '@components/AutoCompletes/AutoCompleteCidade'
import TelefoneTextField from '@components/TelefoneTextField'
import CEPTextField from '@components/CEPTextField'

import useBoleto, {
  AlterarBoletoPagadorEnderecoModel,
} from '@hooks/queries/useBoleto'
import useValidations from '@hooks/useValidations'

import * as yup from 'yup'

const schema = yup.object().shape({
  cep: yup
    .string()
    .length(8, 'O CEP deve ser composto por 8 caracteres')
    .nullable(),
})

interface FormProps {
  boletoId: string
}

export default function Form({ boletoId }: FormProps) {
  const { setValidationErrors, validationProps } = useValidations()

  const { changeValue, closeForm, toggleSubmit, changeValues } =
    useFormApi<AlterarBoletoPagadorEnderecoModel>()
  const { formValues: data } = useFormData<AlterarBoletoPagadorEnderecoModel>()

  const { useAlterarBoletoPagadorEndereco } = useBoleto()
  const { mutateAsync: alterarBoletoPagadorEndereco } =
    useAlterarBoletoPagadorEndereco()

  const [podeBuscar, setPodeBuscar] = useState(false)

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoPagadorEndereco({
          boletoId,
          data,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Alterar Endereço do Pagador',
        maxWidth: 'md',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <CEPTextField
            name="cep"
            required
            value={data?.cep || ''}
            inputProps={{ label: 'CEP' }}
            onChange={(e) => {
              setPodeBuscar(true)
              changeValue('cep', onlyNumbers(e.target.value) || null)
            }}
            podePesquisarCep={podeBuscar}
            onFindCep={(cep) => {
              if (cep) {
                const { cidadeModel: cidade, uf: ufModel, ...rest } = cep

                setPodeBuscar(false)
                changeValues({
                  ...data,
                  ...rest,
                  cidade,
                  cidadeNome: cidade?.nome || '',
                  ufModel,
                  uf: ufModel?.id || '',
                })
              }
            }}
            {...validationProps('cep')}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={9} xs={12}>
          <TextField
            name="logradouro"
            label="Logradouro"
            required
            value={data?.logradouro || ''}
            onChange={(e) => changeValue('logradouro', e.target.value || '')}
            {...validationProps('logradouro')}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
          <TextField
            name="bairro"
            label="Bairro"
            required
            value={data?.bairro || ''}
            onChange={(e) => changeValue('bairro', e.target.value || '')}
            {...validationProps('bairro')}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
          <TextField
            name="complemento"
            label="Complemento"
            value={data?.complemento || ''}
            onChange={(e) => changeValue('complemento', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <AutoCompleteUF
            name="uf"
            label="UF"
            required
            value={data?.ufModel || null}
            onChange={(_, value) => {
              changeValues({
                ...data,
                ufModel: value || null,
                uf: value?.id || '',
                cidadeNome: '',
                cidade: null,
              })
            }}
            {...validationProps('uf')}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={9} xs={12}>
          <AutoCompleteCidade
            name="cidadeNome"
            label="Cidade"
            required
            value={data?.cidade || null}
            uf={data?.uf || ''}
            onChange={(_, value) => {
              changeValues({
                ...data,
                cidade: value || null,
                cidadeNome: value?.nome || '',
              })
            }}
            {...validationProps('cidadeNome')}
          />
        </Grid>
        <Grid item xl={4} lg={3} md={3} sm={5} xs={12}>
          <TelefoneTextField
            label="Telefone"
            name="telefone"
            value={data?.telefone || ''}
            onChange={(e) => {
              changeValue('telefone', onlyNumbers(e.target.value) || null)
            }}
          />
        </Grid>
        <Grid item xl={8} lg={9} md={9} sm={7} xs={12}>
          <TextField
            name="email"
            label="E-mail"
            value={data?.email || ''}
            onChange={(e) => changeValue('email', e.target.value || null)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
