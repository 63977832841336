import ActionOptionsProvider from 'contexts/ActionOptionsContext/ActionOptionsContext'
import ActionsOptions from './components/ActionsOptions'

export default function BoletoAlteracoes() {
  return (
    <ActionOptionsProvider>
      <ActionsOptions />
    </ActionOptionsProvider>
  )
}
