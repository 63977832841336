import { Grid, Stepper, Step, StepLabel } from '@mui/material'

interface AgendamentoStepperProps {
  activeStep: number
}

export default function AgendamentoStepper({
  activeStep,
}: AgendamentoStepperProps) {
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>
            Vamos enviar os boletos em aberto ou os liquidados (pagos)?
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            Você quer anexar o arquivo PDF do boleto ao e-mail?
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            Qual a hora do dia o envio dos e-mails deve ocorrer?
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            A partir de qual data você quer realizar o envio dos e-mails?
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            Quando o evento de envio dos e-mails deve ocorrer?.
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>Veja se está tudo certinho e clique em salvar!</StepLabel>
        </Step>
      </Stepper>
    </Grid>
  )
}
