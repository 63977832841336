import { Grid, TextField } from '@mui/material'

import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useConvenio, {
  ConvenioCredenciaisBancoBrasilModel,
} from '@hooks/queries/useConvenio'

interface FormProps {
  convenioId?: string
}

export default function Form(props: FormProps) {
  const { convenioId } = props

  const { changeValue, closeForm, toggleSubmit } =
    useFormApi<ConvenioCredenciaisBancoBrasilModel>()
  const { formValues: data } =
    useFormData<ConvenioCredenciaisBancoBrasilModel>()

  const { useAtualizarConvenioCredenciaisBancoBrasil } = useConvenio()
  const { mutateAsync: atualizarConvenioCredenciaisBancoBrasil } =
    useAtualizarConvenioCredenciaisBancoBrasil()

  async function handleSubmit() {
    toggleSubmit(true)
    await atualizarConvenioCredenciaisBancoBrasil({
      convenioId: convenioId || '',
      data,
    }).finally(() => toggleSubmit(false))
    closeForm()
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Banco do Brasil - Configurações de Credenciais de Acesso',
        maxWidth: 'sm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="developer_application_key"
            label="Developer Application Key"
            value={data?.developer_application_key || ''}
            onChange={(e) =>
              changeValue('developer_application_key', e.target.value || null)
            }
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="cliente_id"
            label="Cliente Id"
            value={data?.cliente_id || ''}
            onChange={(e) => changeValue('cliente_id', e.target.value || null)}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="cliente_secret"
            label="Cliente Secret"
            value={data?.cliente_secret || ''}
            onChange={(e) =>
              changeValue('cliente_secret', e.target.value || null)
            }
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="AuthorizationBasic"
            label="Basic"
            multiline
            rows={4}
            value={data?.AuthorizationBasic || ''}
            onChange={(e) =>
              changeValue('AuthorizationBasic', e.target.value || null)
            }
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
