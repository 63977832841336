import { Grid, TextField } from '@mui/material'

import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useConvenio, {
  ConvenioCredenciaisSicoobModel,
} from '@hooks/queries/useConvenio'

interface FormProps {
  convenioId?: string
}

export default function Form(props: FormProps) {
  const { convenioId } = props

  const { changeValue, closeForm, toggleSubmit } =
    useFormApi<ConvenioCredenciaisSicoobModel>()
  const { formValues: data } = useFormData<ConvenioCredenciaisSicoobModel>()

  const { useAtualizarConvenioCredenciaisSicoob } = useConvenio()
  const { mutateAsync: atualizarConvenioCredenciaisSicoob } =
    useAtualizarConvenioCredenciaisSicoob()

  async function handleSubmit() {
    toggleSubmit(true)
    await atualizarConvenioCredenciaisSicoob({
      convenioId: convenioId || '',
      data,
    }).finally(() => toggleSubmit(false))
    closeForm()
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Sicoob - Configurações de Credenciais de Acesso',
        maxWidth: 'sm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="cliente_id"
            label="Cliente Id"
            value={data?.cliente_id || ''}
            onChange={(e) => changeValue('cliente_id', e.target.value || null)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
