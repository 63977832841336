import { useState, useCallback } from 'react'
import { Grid } from '@mui/material'
import Dialog from '@components/Dialog'
import DialogActions from '@components/Dialog/DialogActions'
import Button from '@components/Button'
import PasswordTextField from '@components/PasswordTextField'
import useNotification from '@hooks/useNotifications'
import DialogContent from '@components/Dialog/DialogContent'
import useCredentials from '@hooks/useCredentials'
import useValidations from '@hooks/useValidations'
import * as yup from 'yup'

interface AlterarSenhaProps {
  email: string
  isOpen: boolean
  onClose: () => void
}

const validationSchema = yup.object().shape({
  pass: yup.string().required('Informe a Senha Atual'),
  newPass: yup.string().required('Informe a Nova Senha'),
})

const AlterarSenha = (props: AlterarSenhaProps) => {
  const { isOpen, onClose } = props
  const { alterarSenha, isSubmitting } = useCredentials()
  const { validationProps, setValidationErrors } = useValidations()

  const [passwordForm, setPasswordForm] = useState({
    pass: '',
    newPass: '',
  })

  const notification = useNotification()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleAlterarSenha = useCallback(async () => {
    setValidationErrors(null)
    validationSchema
      .validate(passwordForm, { abortEarly: false })
      .then(async () => {
        const response = await alterarSenha(
          passwordForm.pass,
          passwordForm.newPass,
        )

        if (response) {
          notification.notifySuccess('A sua senha foi alterada')
          handleClose()
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }, [
    passwordForm,
    handleClose,
    alterarSenha,
    notification,
    setValidationErrors,
  ])

  return (
    <Dialog open={isOpen} onClose={handleClose} title="Alterar Senha">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <PasswordTextField
              label="Senha Atual"
              name="pass"
              value={passwordForm?.pass || ''}
              onChange={(e) =>
                setPasswordForm((oldState) => ({
                  ...oldState,
                  pass: e.target.value,
                }))
              }
              {...validationProps('pass')}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <PasswordTextField
              label="Nova Senha"
              name="newPass"
              value={passwordForm?.newPass || ''}
              onChange={(e) =>
                setPasswordForm((oldState) => ({
                  ...oldState,
                  newPass: e.target.value,
                }))
              }
              {...validationProps('newPass')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAlterarSenha}
          isLoading={isSubmitting}
        >
          Alterar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlterarSenha
