import axios, { AxiosRequestConfig } from 'axios'
import { getToken } from 'services/credentials'

const api = axios.create({
  // baseURL: process.env.REACT_APP_API_URL
  baseURL: `${import.meta.env.VITE_API_CADASTRO_URL}`,
})

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  let conf: AxiosRequestConfig = config
  if (conf.headers === undefined) {
    conf.headers = {}
  }

  const token = getToken()

  const temAuthorizationHeader = Boolean(conf.headers.Authorization)
  if (token && token !== 'undefined' && !temAuthorizationHeader) {
    conf.headers.Authorization = `Bearer ${token}`
  }

  conf.headers['Content-Type'] = 'application/json;charset=UTF-8'
  conf.headers['Resposta-Compactada'] = 'Nunca'
  conf.headers['Access-Control-Allow-Origin'] = '*'
  conf.headers['Access-Control-Allow-Headers'] =
    'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept'
  conf.headers['Access-Control-Allow-Methods'] =
    'GET, POST, OPTIONS, PUT, PATCH, DELETE'
  conf.headers['Access-Control-Allow-Credentials'] = true
  conf.headers['Allow'] = 'GET, POST, PUT, DELETE, OPTIONS, HEAD'
  return conf
})

export default api
