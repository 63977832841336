import { useMemo } from 'react'
import { Alert, AlertTitle } from '@mui/material'
import { useFormData } from '@data-c/providers'
import { TemplateEmailInterface } from '@hooks/queries/useTemplateEmail'

interface RevisaoProps {
  quantidadeDiasFormatado: string
}

export default function Revisao({ quantidadeDiasFormatado }: RevisaoProps) {
  const { formValues: data } = useFormData<TemplateEmailInterface>()

  const campoMaps = {
    registro: 'Criação',
    vencimento: 'Vencimento',
    pagamento: 'Pagamento',
  }

  const mensagemDeRevisao = useMemo(() => {
    let operacao = ''
    if (data.regraEnvio?.operacao === 'now') {
      operacao = `na data de ${campoMaps[data.regraEnvio.campo || 'registro']}`
    } else {
      operacao = `${quantidadeDiasFormatado} dia${
        (data?.quantidadeDias || 0) > 1 ? 's' : ''
      } ${
        data.regraEnvio?.operacao === 'subtract' ? 'antes' : 'depois'
      } da data de ${campoMaps[data?.regraEnvio?.campo || 'registro']}`
    }

    return `O envio dos e-mails irá ocorrer ${operacao} de cada boleto às ${data.horaEnvio}h`
  }, [data, quantidadeDiasFormatado])

  return (
    <Alert severity="info">
      <AlertTitle>Revise as configurações</AlertTitle>
      {mensagemDeRevisao}
    </Alert>
  )
}
