import { useFormData } from '@data-c/providers'
import { Button, ButtonContainer } from '@data-c/ui'

import { ConvenioModel } from '@hooks/queries/useConvenio'
import CustomTooltip from '@components/CustomTooltip'
import ActionOptionsProvider from 'contexts/ActionOptionsContext/ActionOptionsContext'
import ActionsOptions from './components/ActionsOptions'
import FloatFooter from '@components/FloatFooter'

interface ActionsProps {
  onSubmit: () => void
}

export default function Actions(props: ActionsProps) {
  const { onSubmit } = props

  const { formValues: convenio, isSubmitting } = useFormData<ConvenioModel>()
  const isConvenioAtivo = convenio?.ativo

  return (
    <FloatFooter>
      <ButtonContainer width="100%">
        <ActionOptionsProvider>
          <ActionsOptions />
        </ActionOptionsProvider>

        <CustomTooltip
          title="Habilitado somente quando o convênio está desativado"
          disableHoverListener={!isConvenioAtivo}
        >
          <Button
            variant="contained"
            disabled={isConvenioAtivo}
            isLoading={isSubmitting}
            onClick={onSubmit}
          >
            Salvar
          </Button>
        </CustomTooltip>
      </ButtonContainer>
    </FloatFooter>
  )
}
