import { useEffect, useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { AutoCompleteProps } from 'hooks/useAutocomplete'
import useConvenio, { ConvenioResumoModel } from '@hooks/queries/useConvenio'

interface AutoCompleteConvenioProps
  extends AutoCompleteProps<ConvenioResumoModel> {
  empresaId?: string
}

export default function AutoCompleteConvenio(props: AutoCompleteConvenioProps) {
  const { onChange, value, autoFocus, empresaId, disabled } = props

  const [options, setOptions] = useState<ConvenioResumoModel[]>([])

  const { useQueryObterConvenio, useObterConvenioPorEmpresaId } = useConvenio()
  const { obterConvenioPorEmpresaId, isLoadingObterConvenioPorEmpresaId } =
    useObterConvenioPorEmpresaId()
  const { data } = useQueryObterConvenio()

  useEffect(() => {
    setConveniosAtivos()
  }, [data?.data])

  useEffect(() => {
    async function obterPorEmpresaId(empresaId: string) {
      const convenio = await obterConvenioPorEmpresaId(empresaId)
      setOptions(convenio?.data || [])
    }

    if (empresaId) {
      obterPorEmpresaId(empresaId)
    }
    setOptions([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId])

  function setConveniosAtivos() {
    if (!empresaId) {
      const conveniosAtivos = data?.data?.filter(
        (convenio) => convenio.ativo === true,
      )
      setOptions(conveniosAtivos || [])
    }
  }

  function handleOpen() {
    setConveniosAtivos()
  }

  return (
    <Autocomplete
      onOpen={handleOpen}
      value={value || null}
      disablePortal
      disabled={disabled}
      options={options || []}
      onChange={onChange}
      getOptionLabel={(convenio) => {
        return `${convenio.convenioDesc}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          value={value}
          label="Convênio"
          autoFocus={autoFocus}
        />
      )}
      loadingText="Carregando..."
      loading={isLoadingObterConvenioPorEmpresaId}
    />
  )
}
