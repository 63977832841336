import { ChangeEvent } from 'react'
import { TextField, TextFieldProps } from '@mui/material'

export type DynamicDropDownProps = {
  tooltipTitle?: string
  disabled?: boolean
  optionToDisabled?: number[]
  onChange: (value?: number | string, valueDescription?: string) => void
} & Omit<TextFieldProps, 'onChange'>

type TDynamicDropDownProps<T> = {
  menuItems: T[]
  onChange: (value?: number, valueDescription?: string) => void
} & DynamicDropDownProps

export default function DynamicDropDown<
  T extends { value: string | number; label: string },
>(props: TDynamicDropDownProps<T>) {
  const { onChange, children, menuItems, ...rest } = props

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value)
    const valueDescription = menuItems.find(
      (item) => item.value === value,
    )?.label

    onChange(value === 0 ? String(value) : value || undefined, valueDescription)
  }

  return (
    <TextField select onChange={handleChange} {...rest}>
      {children}
    </TextField>
  )
}
