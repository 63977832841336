import { Grid, TextField } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { CurrencyTextField } from '@data-c/ui'

import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import { DropDownTipoDescontoEnum } from '@components/Inputs/DropDownTipoDesconto'
import { DropDownTipoJurosEnum } from '@components/Inputs/DropDownTipoJuros'
import { DropDownTipoMultaEnum } from '@components/Inputs/DropDownTipoMulta'
import CardDynamic from '@components/CardDynamic'

import {
  AlterarBoletoValoresModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'

interface BoletoValoresProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoValores({ boleto }: BoletoValoresProps) {
  const {
    id,
    valorTitulo,
    valorAbatimento,
    multaTipo,
    multaTipoDesc,
    multaValor,
    jurosTipo,
    jurosTipoDesc,
    jurosValor,
    descontoTipo,
    descontoTipoDesc,
    descontoValor,
    descontoDataExpiracao,
    status,
    statusDesc,
  } = boleto || {}

  const dataValores: AlterarBoletoValoresModel = {
    descontoTipo: descontoTipo || DropDownTipoDescontoEnum.SEM_DESCONTO_0,
    descontoValor: descontoValor || 0,
    jurosTipo: jurosTipo || DropDownTipoJurosEnum.ISENTO_0,
    multaTipo: multaTipo || DropDownTipoMultaEnum.ISENTO_0,
    valorTitulo: valorTitulo || 0,
    descontoDataExpiracao,
    jurosValor,
    multaValor,
  }

  const isBoletoIncluido = status === DropDownBoletoStatusEnum.INCLUIDO_0

  return (
    <FormProvider>
      <Form boletoId={id || ''} />
      <CardDynamic
        title="Valores"
        data={dataValores}
        disabledAllChildren
        disableButton={!isBoletoIncluido}
        tooltipTitle={`Não é possível editar os Valores de um boleto ${
          statusDesc || 'que não esteja incluído'
        }`}
        initialOpacity
        gridContainer
      >
        <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
          <CurrencyTextField label="Valor Título" value={valorTitulo || ''} />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
          <CurrencyTextField
            label="Valor de Abatimento"
            value={valorAbatimento || ''}
          />
        </Grid>
        <Grid item xl={6} lg={5} md={6} sm={12} xs={12}>
          <TextField label="Tipo Desconto" value={descontoTipoDesc || ''} />
        </Grid>
        <Grid item xl={2} lg={3} md={6} sm={12} xs={12}>
          <CurrencyTextField
            label="Valor Desconto"
            value={descontoValor || ''}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <TextField label="Tipo Juros" value={jurosTipoDesc || ''} />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
          <CurrencyTextField label="Valor Juros" value={jurosValor || ''} />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <TextField label="Tipo Multa" value={multaTipoDesc || ''} />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
          <CurrencyTextField label="Valor Multa" value={multaValor || ''} />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
