import { Grid } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'
import ChooseOne from '@components/ChooseOne'
import { TemplateEmailInterface } from '@hooks/queries/useTemplateEmail'

export default function TipoEnvio() {
  const { changeValue } = useFormApi<TemplateEmailInterface>()
  const { formValues: data } = useFormData<TemplateEmailInterface>()

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <ChooseOne
        onChoose={(item) => {
          const value = item.value
          const isLiquidado = value === 'liquidado'
          changeValue('somenteBoletosLiquidados', isLiquidado)
          changeValue('regraEnvio', {
            ...data?.regraEnvio,
            liquidado: isLiquidado,
          })
        }}
        value={data?.regraEnvio?.liquidado ? 'liquidado' : 'aberto'}
        options={[
          {
            title: 'Apenas Boletos em Aberto',
            value: 'aberto',
            description:
              'Apenas os boletos em aberto serão considerados no envio do e-mail.',
            icon: 'credit_card_off',
          },
          {
            title: 'Apenas Boletos Liquidados',
            value: 'liquidado',
            description:
              'Apenas os boletos liquidados serão considerados no envio do e-mail.',
            icon: 'credit_score',
          },
        ]}
      />
    </Grid>
  )
}
