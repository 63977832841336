import { ReactElement, ReactNode, useState } from 'react'
import { Box, Collapse, Grid, Stack, SxProps, useTheme } from '@mui/material'
import DisableAllChildren from 'components/DisableAllChildren'
import Header from './components/Header'

export interface CardDynamicProps<T> {
  title: string
  initialCollapse?: boolean
  tooltipTitle?: string
  deleteIconTitle?: string
  disabledDeleteIcon?: boolean
  hiddenButton?: boolean
  disableButton?: boolean
  disabledAllChildren?: boolean
  hiddenCollapse?: boolean
  hiddenCollapseButton?: boolean
  collapseOnClickHeader?: boolean
  initialOpacity?: boolean
  heightAfterCollapse?: number
  data?: T
  gridContainer?: boolean
  containerSx?: SxProps
  onClickDelete?: () => void
  iconTitle?: ReactElement
  cardSeverity?: 'success' | 'error' | 'warning' | 'primary' | 'default'
  children: ReactNode
}

export default function CardDynamic<T>(props: CardDynamicProps<T>) {
  const {
    hiddenCollapse = false,
    disabledAllChildren = false,
    initialOpacity = false,
    gridContainer = false,
    initialCollapse = true,
    heightAfterCollapse,
    containerSx,
    children,
    ...rest
  } = props

  const [toCollapse, setToCollapse] = useState(initialCollapse)

  const theme = useTheme()
  const isHeightAfterCollapse = !!heightAfterCollapse

  return (
    <Stack>
      {!hiddenCollapse && (
        <Header
          toCollapse={toCollapse}
          onClick={() => setToCollapse(!toCollapse)}
          {...rest}
        />
      )}

      <Collapse
        style={{
          visibility:
            isHeightAfterCollapse && toCollapse
              ? 'initial'
              : isHeightAfterCollapse && !toCollapse
              ? 'initial'
              : 'inherit',
          minHeight:
            isHeightAfterCollapse && toCollapse
              ? ''
              : isHeightAfterCollapse && !toCollapse
              ? heightAfterCollapse
              : '',
        }}
        in={toCollapse}
      >
        <Box>
          <DisableAllChildren
            disabled={disabledAllChildren}
            initialOpacity={initialOpacity}
          >
            <Box
              sx={{
                border: `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: '4px',
                padding: 2,
                height: '100%',
                ...containerSx,
              }}
            >
              {gridContainer ? (
                <Grid container spacing={2}>
                  {children}
                </Grid>
              ) : (
                children
              )}
            </Box>
          </DisableAllChildren>
        </Box>
      </Collapse>
    </Stack>
  )
}
