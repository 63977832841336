import { Grid, TextField } from '@mui/material'
import { FormProvider } from '@data-c/providers'

import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import DatePicker from '@components/DatePicker'
import CardDynamic from '@components/CardDynamic'

import {
  AlterarBoletoIdenfiticacaoModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'

interface BoletoIdentificacaoProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoIdentificacao({
  boleto,
}: BoletoIdentificacaoProps) {
  const {
    id,
    dataEmissao,
    documentoSistema,
    carteiraCodigo,
    carteiraVariacao,
    descontoDataExpiracao,
    dataVencimento,
    diasLimiteRecebimento,
    diasParaNegativacao,
    nossoNumeroSequencial,
    permiteRecebimentoParcial,
    dataRegistro,
    status,
    statusDesc,
  } = boleto || {}

  const dataIdentificacao: AlterarBoletoIdenfiticacaoModel = {
    dataEmissao: dataEmissao || '',
    dataVencimento: dataVencimento || '',
    diasLimiteRecebimento: diasLimiteRecebimento || 0,
    diasParaNegativacao: diasParaNegativacao || 0,
    nossoNumeroSequencial: nossoNumeroSequencial || 0,
    permiteRecebimentoParcial: permiteRecebimentoParcial || false,
    carteiraCodigo,
    carteiraVariacao,
    documentoSistema,
  }

  const isBoletoIncluido = status === DropDownBoletoStatusEnum.INCLUIDO_0

  return (
    <FormProvider>
      <Form boletoId={id || ''} />
      <CardDynamic
        title="Identificação"
        data={dataIdentificacao}
        disableButton={!isBoletoIncluido}
        tooltipTitle={`Não é possível editar a Identificação de um boleto ${
          statusDesc || 'que não esteja incluído'
        }`}
        disabledAllChildren
        initialOpacity
        gridContainer
      >
        <Grid item xl={2} lg={3} md={5} sm={12} xs={12}>
          <DatePicker
            label="Data Emissão"
            value={dataEmissao || null}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={3} lg={6} md={7} sm={12} xs={12}>
          <TextField label="Documento" value={documentoSistema || ''} />
        </Grid>
        <Grid item xl={2} lg={3} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Registro"
            value={dataRegistro || null}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={1} lg={4} md={6} sm={12} xs={12}>
          <TextField label="Carteira" value={carteiraCodigo || ''} />
        </Grid>
        <Grid item xl={1} lg={4} md={6} sm={12} xs={12}>
          <TextField label="Variação" value={carteiraVariacao || ''} />
        </Grid>
        <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Expiração"
            value={descontoDataExpiracao || null}
            onChange={() => {}}
          />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
