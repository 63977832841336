import api from 'services/api-cadastro'

interface PaisProps {
  id: string
  nome: string
}

export interface UFModel {
  id: string
  nome?: string
  codigoIBGE: string
}

export interface CidadeModel {
  id: string
  nome: string
  ufId: string
  paisId: string
  paisNome: string
  pais: PaisProps
  uf: UFModel
}

export async function obterCidades(query: string, uf?: string | null) {
  const route =
    uf?.length === 0
      ? `Cidade/Pesquisar/${query}`
      : `Cidade/PesquisarPorUF/${uf}/${query}`

  const response = await api.get(route)
  return response
}

export default function useCidades() {
  return {
    obterCidades,
  }
}
