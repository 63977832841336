import { useMemo } from 'react'
import { LogsModel, useQuery } from '@hooks/queries/useLogsEnvio'
import { MUIDataTableColumnDef } from 'mui-datatables'
import DataTable from '@components/DataTable'
import useDates from '@hooks/useDates'
import LogDetails from './components/LogDetails'
import { TableCell, TableRow } from '@mui/material'

const Table = () => {
  const { getCurrentDate, getFirstDateOfCurrentMonth, toBrazilianDateTime } =
    useDates()

  const currentDate = useMemo(() => {
    return getCurrentDate().format('YYYY-MM-DD')
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  const firsDateOfCurrentMonth = useMemo(() => {
    return getFirstDateOfCurrentMonth().format('YYYY-MM-DD')
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  const { data, isLoading, isFetching, error } = useQuery({
    params: {
      inicio: firsDateOfCurrentMonth,
      fim: currentDate,
    },
  })

  const logs: Array<any> = Array.isArray(data)
    ? data?.map((d: LogsModel) => {
        return {
          ...d,
          createdAt: toBrazilianDateTime(d.createdAt),
          enviados: d.enviados?.length || 0,
          naoEnviados: d.naoEnviados?.length || 0,
          templateNome: d.template?.nome || '',
        }
      })
    : []

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'createdAt',
        label: 'Data',
        options: {
          setCellProps: () => ({
            style: {
              width: '200px',
            },
          }),
        },
      },
      {
        name: 'templateNome',
        label: 'Template / Nome',
      },
      {
        name: 'enviados',
        label: 'Enviados',
        options: {
          setCellProps: () => ({
            style: {
              width: '30px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'naoEnviados',
        label: 'Não Enviados',
        options: {
          setCellProps: () => ({
            style: {
              width: '30px',
              textAlign: 'right',
            },
          }),
        },
      },
    ],
    [],
  )

  return (
    <DataTable
      data={logs || []}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      error={error?.message}
      options={{
        pagination: false,
        expandableRows: true,
        expandableRowsHeader: false,
        renderExpandableRow: (_, rowMeta) => {
          const { dataIndex } = rowMeta
          let expandableData: LogsModel | null = null

          if (data) {
            expandableData = data[dataIndex]
          }

          return (
            <TableRow>
              <TableCell colSpan={6}>
                <LogDetails
                  enviados={expandableData?.enviados || []}
                  naoEnviados={expandableData?.naoEnviados || []}
                />
              </TableCell>
            </TableRow>
          )
        },
      }}
    />
  )
}

export default Table
