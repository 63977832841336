import { ReactNode } from 'react'
import {
  Container as MuiContainer,
  ContainerProps as MuiContainerProps,
  Stack,
  SxProps,
} from '@mui/material'

import Error from 'components/Displays/Error'
import Loading from 'components/Displays/Loading'

interface ContainerProps {
  disablePadding?: boolean
  isLoading?: boolean
  error?: any
  customSx?: SxProps
  children: ReactNode
  containerProps?: MuiContainerProps
}

export default function Container(props: ContainerProps) {
  const {
    disablePadding = true,
    isLoading = false,
    error,
    customSx,
    children,
    containerProps,
  } = props

  return (
    <MuiContainer
      {...containerProps}
      sx={{ position: isLoading ? 'unset' : 'absolute' }}
    >
      <Stack padding={disablePadding ? 0 : 2} sx={{ ...customSx }}>
        {isLoading && <Loading />}
        {Boolean(error) && <Error />}
        {!isLoading && Boolean(!error) && <>{children}</>}
      </Stack>
    </MuiContainer>
  )
}
