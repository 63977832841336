import { FileDownloadOutlined as FileDownloadOutlinedIcon } from '@mui/icons-material'

import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { useDialog } from '@data-c/hooks'

import { useBoletoContext } from 'contexts/BoletoContext/BoletoContext'
import useBoleto from '@hooks/queries/useBoleto'

export default function BoletoConsulta() {
  const { boleto, setBoleto } = useBoletoContext()
  const {
    isOpen: isOpenBoletoConsultaDialog,
    openDialog: openBoletoConsultaDialog,
    closeDialog: closeBoletoConsultaDialog,
  } = useDialog()

  const { useObterBoletoDoBanco } = useBoleto()
  const { obterBoletoDoBanco, isLoadingObterBoletoDoBanco } =
    useObterBoletoDoBanco()

  async function handleObterBoletoDoBanco() {
    const boletoDoBanco = await obterBoletoDoBanco(boleto?.id || '')
    if (boletoDoBanco) {
      setBoleto(boletoDoBanco)
      handleCloseBoletoConsultaDialog()
    }
  }

  function handleCloseBoletoConsultaDialog() {
    closeBoletoConsultaDialog()
  }

  return (
    <>
      <Button
        title="Realiza a consulta do boleto junto ao banco"
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={openBoletoConsultaDialog}
      >
        Consultar
      </Button>

      <Dialog
        title="Realizar Consulta do Boleto?"
        type="info"
        maxWidth="xs"
        open={isOpenBoletoConsultaDialog}
        onClose={handleCloseBoletoConsultaDialog}
        actions={
          <ButtonContainer>
            <Button color="error" onClick={handleCloseBoletoConsultaDialog}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              isLoading={isLoadingObterBoletoDoBanco}
              onClick={handleObterBoletoDoBanco}
            >
              Sim, Realizar consulta
            </Button>
          </ButtonContainer>
        }
      >
        Ao realizar a consulta do boleto, o sistema irá atualizar as informações
        do boleto atual com as informações retornadas pelo banco.
      </Dialog>
    </>
  )
}
