import { Stack, Typography } from '@mui/material'
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  Error as ErrorIcon,
} from '@mui/icons-material'

interface NomeArquivoSelecionadoProps {
  nomeArquivoSelecionado: string
  messageError: string
}

export default function NomeArquivoSelecionado(
  props: NomeArquivoSelecionadoProps,
) {
  const { nomeArquivoSelecionado, messageError } = props

  return (
    <Stack flexDirection="row" gap={1}>
      {messageError.length > 0 ? (
        <ErrorIcon color="error" />
      ) : (
        <CheckCircleRoundedIcon color="success" />
      )}
      <Typography variant="body2">{nomeArquivoSelecionado}</Typography>
    </Stack>
  )
}
