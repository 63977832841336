import { FormProvider as DialogProvider } from '@data-c/providers'
import OptionList from '@components/OptionsListContainer/components/OptionList'
import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'
import apiHubbank from 'services/api'
import Table from './components/Table'
import { OrigemType } from '../..'

interface BoletoLogsProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoLogs({ boleto }: BoletoLogsProps) {
  const { id } = boleto || {}

  return (
    <DialogProvider>
      <OptionList
        name={OrigemType.VISUALIZAR_LOGS}
        api={apiHubbank}
        route="BoletoConsulta/ObterLogs"
        requestId={id}
        axiosRequestConfig={{ params: { boletoId: id || '' } }}
        component={<Table />}
      />
    </DialogProvider>
  )
}
