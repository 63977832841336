import { useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import useNotification from 'hooks/useNotifications'
import { AutoCompleteProps } from 'hooks/useAutocomplete'
import useEmpresa, { EmpresaModel } from 'hooks/queries/useEmpresa'

export default function AutoCompleteEmpresas(
  props: AutoCompleteProps<EmpresaModel>,
) {
  const { onChange, value, autoFocus } = props

  const [options, setOptions] = useState<EmpresaModel[]>([])
  const [isLoading, setLoading] = useState(false)

  const notifications = useNotification()
  const { obterEmpresas } = useEmpresa()

  async function handleObterEmpresas() {
    if (options?.length > 0) return
    setLoading(true)
    try {
      const empresas = await obterEmpresas('')

      setOptions(empresas.data)
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Autocomplete
      value={value || null}
      disablePortal
      onOpen={handleObterEmpresas}
      options={options || []}
      onChange={onChange}
      getOptionLabel={(empresa: EmpresaModel) => {
        return `${empresa.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          value={value}
          label="Empresa"
          autoFocus={autoFocus}
        />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
