import { Stack, TextField } from '@mui/material'

import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import { EmpresaModel } from '@hooks/queries/useEmpresa'
import {
  EnvioBoletoFilters,
  EnvioBoletoFilters1,
} from '@hooks/queries/useEnvioBoletos'
import { ConvenioResumoModel } from '@hooks/queries/useConvenio'
import { tipoDataReverseMapping } from '@hooks/queries/useTemplateEmail'
import { dateEndOfMonth, dateStartOfMonth } from '@hooks/useDates'
import useUtils from '@hooks/useUtils'

import AutoCompleteEmpresas from '@components/AutoCompletes/AutoCompleteEmpresa'
import DatePicker from '@components/DatePicker'
import DropDownTipoData from '@components/Inputs/DropDownTipoData'
import AutoCompleteConvenio from '@components/AutoCompletes/AutoCompleteConvenio'

import FilterProps from 'interfaces/FilterProps'

export function formatarFiltros(
  filters?: EnvioBoletoFilters1,
): EnvioBoletoFilters1 {
  return {
    ...filters,
    tipoData: filters?.tipoData || tipoDataReverseMapping[0],
    dataFim: filters?.dataFim || dateEndOfMonth(),
    dataInicio: filters?.dataInicio || dateStartOfMonth(),
    empresaId: filters?.empresa?.id || null,
    convenioId: filters?.convenio?.id || null,
  }
}

export default function Filter({
  onApplyFilters,
}: FilterProps<EnvioBoletoFilters1>) {
  const { changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<
    EnvioBoletoFilters,
    EnvioBoletoFilters1
  >()
  const { formatDate } = useUtils()

  return (
    <FilterContainer
      title="Opções de Filtro"
      triggerButtonProps={{ variant: 'outlined' }}
      onApplyFilters={onApplyFilters}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Empresa') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Convênio') {
          return `${filterLabel} - ${filterValue?.convenioDesc || ''}`
        }

        if (filterLabel === 'Data Inicial') {
          return `${filterLabel} - ${formatDate(filterValue || '')}`
        }

        if (filterLabel === 'Data Final') {
          return `${filterLabel} - ${formatDate(filterValue || '')}`
        }

        if (filterLabel === 'Tipo de Data') {
          const newFilterValue = tipoDataReverseMapping[filterValue]
          return `${filterLabel} - ${newFilterValue}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <DropDownTipoData
          value={filterValues?.tipoData?.value}
          onChange={(e) => {
            const tipoData = e.target.value
            changeFilterValue('tipoData', tipoData)
          }}
        />
        <Stack flexDirection="row" gap={1}>
          <DatePicker
            label={filterValues?.dataInicio?.label}
            onChange={(dataInicio) => {
              changeFilterValue('dataInicio', dataInicio)
            }}
            value={filterValues?.dataInicio?.value}
          />
          <DatePicker
            label={filterValues?.dataFim?.label}
            onChange={(dataFim) => {
              changeFilterValue('dataFim', dataFim)
            }}
            value={filterValues?.dataFim?.value}
          />
        </Stack>
        <TextField
          name="pagadorConteudo"
          label={filterValues?.pagadorConteudo?.label}
          value={filterValues?.pagadorConteudo?.value}
          onChange={(e) => {
            changeFilterValue('pagadorConteudo', e.target.value || null)
          }}
        />
        <AutoCompleteEmpresas
          label={filterValues?.empresa?.label}
          name="empresa"
          value={filterValues.empresa?.value || null}
          onChange={(_, value: EmpresaModel | null) => {
            changeFilterValue('empresa', value)
            changeFilterValue('convenio', null)
          }}
        />

        <AutoCompleteConvenio
          label={filterValues?.convenio?.label}
          empresaId={filterValues?.empresa?.value?.id || ''}
          disabled={!filterValues?.empresa?.value?.id}
          name="convenio"
          value={filterValues?.convenio?.value || null}
          onChange={(_, value: ConvenioResumoModel | null) => {
            changeFilterValue('convenio', value)
          }}
        />
      </Stack>
    </FilterContainer>
  )
}
