import { useState } from 'react'
import { normalizeText, onlyNumbers } from '@data-c/hooks'
import useCidades, { CidadeModel, UFModel } from './useCidade'
import api from 'services/api-cnpj'
import { isCPFOrCNPJ } from 'brazilian-values'

function encontrarCidadePorNome(
  cidades: CidadeModel[],
  nomeCidade: string,
): CidadeModel | undefined {
  const normalizedNomeCidade = normalizeText(nomeCidade).toLowerCase()

  return cidades.find(
    (cidade) =>
      normalizeText(cidade.nome).toLowerCase() === normalizedNomeCidade,
  )
}

export interface ApiCnpjModel {
  cnpj: string
  cep: string
  nome: string
  email: string
  fantasia: string
  logradouro: string
  complemento: string
  numero: string
  telefone: string
  uf: string
  bairro: string
  municipio: string
}

export interface CnpjModel {
  cep: string
  nome: string
  email: string
  nomeFantasia: string
  logradouro: string
  complemento: string
  numero: string
  ufModel?: UFModel
  bairro: string
  cidadeModel?: CidadeModel
}

export async function consultarCnpjNaApiViaCnpj(
  cnpj: string,
): Promise<ApiCnpjModel> {
  const response = await api.get<{ body: ApiCnpjModel }>(
    `/consultacnpj?cnpj=${cnpj}`,
  )
  return response.data.body
}

export default function useConsultaCnpj() {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const { obterCidades } = useCidades()

  async function _consultarCnpj(cnpj: string): Promise<CnpjModel | undefined> {
    try {
      setLoading(true)

      const isCNPJValido = isCPFOrCNPJ(cnpj)
      if (!isCNPJValido) return

      const data = await consultarCnpjNaApiViaCnpj(cnpj)

      if (!data.uf) return
      const cidades = await obterCidades('', data.uf)
      const cidade = encontrarCidadePorNome(cidades?.data?.data, data.municipio)

      const {
        cep,
        nome,
        email,
        fantasia,
        logradouro,
        complemento,
        numero,
        bairro,
      } = data

      const cepSemMascara = onlyNumbers(cep)

      return {
        cep: cepSemMascara || '',
        nome,
        email,
        logradouro,
        complemento,
        numero,
        bairro,
        cidadeModel: cidade,
        ufModel: cidade?.uf,
        nomeFantasia: fantasia,
      }
    } catch (err) {
      setError('Não foi possível carregar o cep')
    } finally {
      setLoading(false)
    }
  }

  return {
    consultarCnpj: _consultarCnpj,
    isLoading,
    error,
  }
}
