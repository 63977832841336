import DynamicDropDown, {
  DynamicDropDownProps,
} from '@components/Inputs/DynamicDropDown'
import { MenuItem } from '@mui/material'

export enum DropDownBoletoStatusEnum {
  INCLUIDO_0 = 0,
  REGISTRADO_1 = 1,
  ANTECIPADO_2 = 2,
  PAGO_3 = 3,
  CANCELADO_4 = 4,
}

export const boletoStatusMapping: Record<number, string> = {
  0: 'Incluído',
  1: 'Registrado',
  2: 'Antecipado',
  3: 'Pago',
  4: 'Cancelado',
}

export const boletoStatusMappingColors: Record<
  number,
  'error' | 'success' | 'warning' | 'primary' | 'info'
> = {
  0: 'info',
  2: 'warning',
  3: 'primary',
  1: 'success',
  4: 'error',
}

const menuItems = [
  {
    value: DropDownBoletoStatusEnum.INCLUIDO_0,
    label: '0 - Incluído',
  },
  {
    value: DropDownBoletoStatusEnum.REGISTRADO_1,
    label: '1 - Registrado',
  },
  {
    value: DropDownBoletoStatusEnum.ANTECIPADO_2,
    label: '2 - Antecipado',
  },
  {
    value: DropDownBoletoStatusEnum.PAGO_3,
    label: '3 - Pago',
  },
  {
    value: DropDownBoletoStatusEnum.CANCELADO_4,
    label: '4 - Cancelado',
  },
]

export default function DropDownBoletoStatus(props: DynamicDropDownProps) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown menuItems={menuItems} onChange={onChange} {...rest}>
      {menuItems.map((item) => (
        <MenuItem value={item.value}>{item.label}</MenuItem>
      ))}
    </DynamicDropDown>
  )
}
