import { Box, Skeleton } from '@mui/material'

const SkeletonLoading = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton variant="rectangular" height={300} />
    </Box>
  )
}

export default SkeletonLoading
