import {
  TextField,
  TextFieldProps,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useState } from 'react'

const PasswordTextField = (props: TextFieldProps) => {
  const [isVisible, switchVisible] = useState<Boolean>(false)

  const handleSwitchVisible = () => {
    switchVisible(!isVisible)
  }

  return (
    <TextField
      type={isVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleSwitchVisible}
            >
              {isVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

export default PasswordTextField
