import { Box } from '@mui/material'

import ClearSelection from './components/ClearSelection'
import SelectAll from './components/SelectAll'
import SelecaoDeAgendamento from './components/SelecaoDeAgendamento'

interface SelectHeaderProps {
  rowsSelected: any[]
  total: number
  allSelected: boolean
  onSelectAll: () => void
  onClearSelection: () => void
  onSendSelected: (modeloId: string) => void
  sending: boolean
}

export default function SelectHeader(props: SelectHeaderProps) {
  const {
    rowsSelected,
    total,
    allSelected,
    onSelectAll,
    onClearSelection,
    onSendSelected,
    sending,
  } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'rows',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'rows',
            alignItems: 'center',
            alignContent: 'center',
            '& > *': {
              mr: 1,
            },
          }}
        >
          {allSelected && (
            <ClearSelection total={total} onClick={onClearSelection} />
          )}

          {!allSelected && (
            <SelectAll
              total={total}
              rowsSelected={rowsSelected}
              onClick={onSelectAll}
            />
          )}
        </Box>

        <SelecaoDeAgendamento
          onClick={(modeloId) => onSendSelected(modeloId)}
          sending={sending}
        />
      </Box>
    </Box>
  )
}

SelectHeader.defaultProps = {
  rowsSelected: [],
  allSelected: false,
}
