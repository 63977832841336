import { Box } from '@mui/material'
import React from 'react'

// import { Container } from './styles';

interface DialogActionsProps {
  children: React.ReactNode
}

const DialogActions = ({ children }: DialogActionsProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'flex-end',
      margin: (theme) => theme.spacing(1.5),
    }}
  >
    {children}
  </Box>
)

export default DialogActions
