import { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { DataTable, DataTableOptions as Options, Flag } from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { TransportableDataTableProps, findData } from '@data-c/hooks'

import EllipsisTextOptionsCellStyle from '@components/EllipsisTextOptionsCellStyle'

import useConvenio, {
  ConvenioModel,
  ConvenioFilters1,
  ConvenioResumoModel,
} from '@hooks/queries/useConvenio'
import useDates from '@hooks/useDates'
import useTableCustomCell from '@hooks/useTableCustomCell'

import DataTableProps from 'interfaces/DataTableProps'

interface TableProps
  extends DataTableProps<ConvenioResumoModel, ConvenioFilters1>,
    TransportableDataTableProps<ConvenioResumoModel> {}

export default function Table(props: TableProps) {
  const {
    filters,
    query: transporterQuery,
    onTransport,
    enableTransporter,
  } = props

  const query = filters?.query ? filters.query : transporterQuery

  const { toBrazilianDate } = useDates()
  const {
    floatOptionsCellStyle,
    dateOptionsCellStyle,
    cpfCnpjOptionsCellStyle,
  } = useTableCustomCell()

  const navigate = useNavigate()

  const { openConfirm } = useFormApi<ConvenioModel>()
  const { useQueryObterConvenio, useObterConvenioPorId } = useConvenio()
  const { obterConvenioPorId, isLoadingObterConvenioPorId } =
    useObterConvenioPorId()
  const {
    data,
    isLoading: isLoadingObterConvenio,
    isFetching: isFetchingObterConvenio,
    error,
  } = useQueryObterConvenio({ query: query || '' })

  const dataFormatada = useMemo(
    () =>
      data?.data?.map((item) => {
        return {
          ...item,
          empresaCNPJCPF: formatToCPFOrCNPJ(item?.empresaCNPJCPF || ''),
          dataConsultaUltimoMovimento: toBrazilianDate(
            item?.dataConsultaUltimoMovimento || '',
          ),
        }
      }),
    [data?.data],
  )

  const handleClickItem = useCallback(
    async (
      event: 'edit' | 'delete' | 'transport',
      data: ConvenioResumoModel,
    ) => {
      const convenio = await obterConvenioPorId(data?.id || '')
      if (convenio) {
        switch (event) {
          case 'edit':
            navigate(`/convenios/convenio/${convenio?.id}`)
            break
          case 'delete':
            openConfirm(convenio)
            break
          case 'transport':
            onTransport && onTransport(data)
            break
        }
      }
    },
    [openConfirm, obterConvenioPorId, navigate, onTransport],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'descricao',
        label: 'Descrição',
        options: {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'empresaCNPJCPF',
        label: 'CNPJ/CPF',
        options: {
          setCellProps: cpfCnpjOptionsCellStyle,
        },
      },
      {
        name: 'empresaNome',
        label: 'Empresa',
        options: {
          customBodyRender: (value) => {
            return (
              <EllipsisTextOptionsCellStyle
                value={value}
                breakpoint="lg"
                length={25}
              />
            )
          },
        },
      },
      {
        name: 'convenioNumero',
        label: 'Número',
        options: {
          setCellProps: () => ({
            style: {
              width: '140px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'carteiraCodigo',
        label: 'Carteira',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'dataConsultaUltimoMovimento',
        label: 'Última Consulta',
        options: {
          setCellProps: dateOptionsCellStyle,
          setCellHeaderProps: () => ({ style: { whiteSpace: 'nowrap' } }),
        },
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '56px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return (
              <Flag
                isFlagged={true}
                type={value === true ? 'success' : 'error'}
              />
            )
          },
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          ...floatOptionsCellStyle(),
          customBodyRender: (value) => {
            const _data = findData<ConvenioResumoModel>(
              data?.data || [],
              value,
              'id',
            )

            const enableTransporterApenasConveniosAtivos =
              enableTransporter && _data?.ativo === true

            return (
              <Options
                displayUpdateButton={!enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayTransporterButton={
                  enableTransporterApenasConveniosAtivos
                }
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem, enableTransporter],
  )

  const isLoading = isLoadingObterConvenio || isLoadingObterConvenioPorId
  const isFetching = isFetchingObterConvenio || isLoadingObterConvenioPorId

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={dataFormatada || []}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  )
}
