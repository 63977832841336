import { MenuItem, MenuItemProps } from '@mui/material'
import DisableAllChildren from '@components/DisableAllChildren'

interface DynamicMenuItemProps extends MenuItemProps {
  tooltipTitle?: string
}

export default function DynamicMenuItem(props: DynamicMenuItemProps) {
  const { children, disabled = false, tooltipTitle, ...rest } = props

  return (
    <DisableAllChildren
      disabled={disabled}
      tooltipTitle={tooltipTitle}
      disableHoverListener={!disabled}
    >
      <MenuItem {...rest}>{children}</MenuItem>
    </DisableAllChildren>
  )
}
