export enum DropDownAgendamentoTipoDataEnum {
  VENCIMENTO_0 = 0,
  PAGAMENTO_1 = 1,
  REGISTRO_2 = 2,
}

export const agendamentoTipoDataMapping: Record<number, string> = {
  [DropDownAgendamentoTipoDataEnum.VENCIMENTO_0]: 'Vencimento',
  [DropDownAgendamentoTipoDataEnum.PAGAMENTO_1]: 'Pagamento',
  [DropDownAgendamentoTipoDataEnum.REGISTRO_2]: 'Registro',
}
