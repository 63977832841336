import { useFormApi, useFormData } from '@data-c/providers'
import { TemplateEmailInterface } from '@hooks/queries/useTemplateEmail'
import { Box, FormControlLabel, Grid, Switch } from '@mui/material'

export default function BoletoAnexo() {
  const { onChangeFormValue } = useFormApi<TemplateEmailInterface>()
  const { formValues: data } = useFormData<TemplateEmailInterface>()

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <FormControlLabel
          label="Anexar boleto"
          labelPlacement="bottom"
          control={
            <Switch
              name="anexarPDF"
              size="medium"
              checked={data?.anexarPDF || false}
              onChange={onChangeFormValue}
              type="checkbox"
            />
          }
        />
      </Box>
    </Grid>
  )
}
