import { FormProvider } from '@data-c/providers'

import { DropDownTipoMultaEnum } from '@components/Inputs/DropDownTipoMulta'
import OptionList from '@components/OptionsListContainer/components/OptionList'

import {
  AlterarBoletoMultaModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import Form from './components/Form'
import { OrigemType } from '../..'

interface MultaProps {
  boleto: BoletoDetalhadoModel | null
}

export default function Multa({ boleto }: MultaProps) {
  const { multaTipo, multaValor } = boleto || {}

  const dataAlterarMulta: AlterarBoletoMultaModel = {
    multaTipo: multaTipo || DropDownTipoMultaEnum.ISENTO_0,
    multaValor,
  }

  return (
    <FormProvider>
      <OptionList
        name={OrigemType.MULTA}
        noRequest={{ initialData: dataAlterarMulta }}
        form={<Form boleto={boleto} />}
      />
    </FormProvider>
  )
}
