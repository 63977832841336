import React from 'react'
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from '@mui/material'

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean
}

const Button = (props: ButtonProps) => {
  const { isLoading, children, ...rest } = props

  return (
    <MuiButton
      size="small"
      {...rest}
      disabled={isLoading ? true : rest.disabled}
    >
      {isLoading ? <CircularProgress color="secondary" size={22} /> : children}
    </MuiButton>
  )
}

export default Button
