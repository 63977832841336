import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from '@mui/material'
import { useAmbienteContext } from 'contexts/AmbienteContext/AmbienteContext'
import { EmpresaCadastroModel } from '@hooks/queries/useEmpresaCadastro'
import AutoCompleteEmpresaCadastro from '@components/AutoCompletes/AutoCompleteEmpresaCadastro'

export default function SelecaoAmbiente() {
  const { empresa, definirEmpresaNoAmbiente, isLoading, setCurrentPath } =
    useAmbienteContext()

  const { pathname } = useLocation()

  useEffect(() => {
    setCurrentPath(pathname)
  }, [pathname])

  return (
    <Box sx={{ width: '400px' }}>
      <AutoCompleteEmpresaCadastro
        value={empresa}
        isLoading={isLoading}
        onChange={(_, value: EmpresaCadastroModel | null) => {
          if (value === null) return
          definirEmpresaNoAmbiente(value)
        }}
      />
    </Box>
  )
}
