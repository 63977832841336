import { useState } from 'react'
import { Transporter, TransporterProps } from '@data-c/ui'
import useConvenio, { ConvenioResumoModel } from '@hooks/queries/useConvenio'
import ConvenioTable from 'pages/Convenios/components/Table'

type ConvenioTransporterProps = {
  hideBackdrop?: boolean
  apenasAtivos?: boolean
} & Omit<
  TransporterProps<ConvenioResumoModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function ConvenioTransporter(props: ConvenioTransporterProps) {
  const { value, hideBackdrop = false, apenasAtivos = false, ...rest } = props

  const [query, setQuery] = useState<string | null>()
  const { useQueryObterConvenio } = useConvenio()

  const { data, isLoading } = useQueryObterConvenio({
    query: query || '',
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  const convenios = apenasAtivos
    ? data?.data.filter((convenio) => convenio.ativo === true)
    : data?.data

  return (
    <Transporter
      onSearch={handleSearch}
      options={convenios || []}
      isLoading={isLoading}
      table={ConvenioTable}
      label="Convênios"
      renderValue={(value) => `${value.descricao}`}
      dialogProps={{ hideBackdrop, title: 'Convênios', maxWidth: 'lg' }}
      value={value}
      {...rest}
    />
  )
}
