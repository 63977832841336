import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownBancoCodigoEnum {
  BANCO_BRASIL_1 = 1,
  SANTANDER_33 = 33,
  INTER_77 = 77,
  CAIXA_ECONOMICA_104 = 104,
  BRADESCO_237 = 237,
  ITAU_341 = 341,
  SICREDI_748 = 748,
  SICOOB_756 = 756,
}

const menuItems = [
  {
    value: DropDownBancoCodigoEnum.BANCO_BRASIL_1,
    label: '001 - Banco do Brasil',
  },
  {
    value: DropDownBancoCodigoEnum.SICOOB_756,
    label: '756 - Sicoob',
  },
  {
    value: DropDownBancoCodigoEnum.ITAU_341,
    label: '341 - Itaú',
  },
  {
    value: DropDownBancoCodigoEnum.SICREDI_748,
    label: '748 - Sicredi',
  },
  {
    value: DropDownBancoCodigoEnum.BRADESCO_237,
    label: '237 - Bradesco',
  },
  {
    value: DropDownBancoCodigoEnum.CAIXA_ECONOMICA_104,
    label: '104 - Caixa Econômica Federal',
  },
  {
    value: DropDownBancoCodigoEnum.SANTANDER_33,
    label: '033 - Santander',
  },
  {
    value: DropDownBancoCodigoEnum.INTER_77,
    label: '077 - Banco Inter',
  },
]

export default function DropDownBancoCodigo(props: DynamicDropDownProps) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="Código do Banco"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
