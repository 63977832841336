import { formatToGenericPhone } from 'brazilian-values'
import { Grid, TextField } from '@mui/material'
import { FormProvider } from '@data-c/providers'

import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import CnpjCpfTextField from '@components/CnpjCpfTextField'
import CardDynamic from '@components/CardDynamic'

import {
  AlterarBoletoPagadorModel,
  BoletoDetalhadoModel,
} from '@hooks/queries/useBoleto'
import { CidadeModel, UFModel } from '@hooks/queries/useCidade'
import { PessoaCadastroModel } from '@hooks/queries/usePessoaCadastro'

import Form from './components/Form'

interface BoletoPagadorProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoPagador({ boleto }: BoletoPagadorProps) {
  const {
    id,
    pagadorNome,
    pagadorCNPJCPF,
    pagadorLogradouro,
    pagadorBairro,
    pagadorComplemento,
    pagadorUF,
    pagadorCidadeNome,
    pagadorCEP,
    pagadorTelefone,
    pagadorEmail,
    status,
    statusDesc,
  } = boleto || {}

  const ufModel = {
    id: pagadorUF || '',
  } as UFModel

  const cidade = {
    nome: pagadorCidadeNome || '',
    uf: ufModel,
    ufId: pagadorUF || '',
  } as CidadeModel

  const pessoa = {
    nome: pagadorNome || '',
  } as PessoaCadastroModel

  const dataPagador: AlterarBoletoPagadorModel = {
    nome: pagadorNome || '',
    cnpjcpf: pagadorCNPJCPF || '',
    logradouro: pagadorLogradouro || '',
    bairro: pagadorBairro || '',
    complemento: pagadorComplemento,
    uf: pagadorUF || '',
    ufModel,
    cidade,
    cidadeNome: pagadorCidadeNome || '',
    cep: pagadorCEP || '',
    telefone: pagadorTelefone,
    email: pagadorEmail,
    pessoa,
  }

  const pagadorTelefoneFormatado = formatToGenericPhone(pagadorTelefone || '')
  const isBoletoIncluido = status === DropDownBoletoStatusEnum.INCLUIDO_0

  return (
    <FormProvider>
      <Form boletoId={id || ''} />
      <CardDynamic
        title="Pagador"
        data={dataPagador}
        disabledAllChildren
        disableButton={!isBoletoIncluido}
        tooltipTitle={`Não é possível editar o Pagador de um boleto ${
          statusDesc || 'que não esteja incluído'
        }`}
        initialOpacity
        gridContainer
      >
        <Grid item xl={7} lg={9} md={12} sm={12} xs={12}>
          <TextField label="Pagador" value={pagadorNome || ''} />
        </Grid>
        <Grid item xl={3} lg={3} md={8} sm={12} xs={12}>
          <CnpjCpfTextField
            inputProps={{ label: 'CNPJ/CPF' }}
            value={pagadorCNPJCPF || ''}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
          <TextField label="CEP" value={pagadorCEP || ''} />
        </Grid>
        <Grid item xl={4} lg={5} md={7} sm={12} xs={12}>
          <TextField label="Logradouro" value={pagadorLogradouro || ''} />
        </Grid>
        <Grid item xl={3} lg={5} md={5} sm={12} xs={12}>
          <TextField label="Bairro" value={pagadorBairro || ''} />
        </Grid>
        <Grid item xl={5} lg={4} md={12} sm={12} xs={12}>
          <TextField label="Complemento" value={pagadorComplemento || ''} />
        </Grid>
        <Grid item xl={1} lg={2} md={3} sm={12} xs={12}>
          <TextField label="UF" value={pagadorUF || ''} />
        </Grid>
        <Grid item xl={4} lg={6} md={9} sm={12} xs={12}>
          <TextField label="Cidade" value={pagadorCidadeNome || ''} />
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
          <TextField label="Telefone" value={pagadorTelefoneFormatado} />
        </Grid>
        <Grid item xl={5} lg={9} md={8} sm={12} xs={12}>
          <TextField label="E-mail" value={pagadorEmail || ''} />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
