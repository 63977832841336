import { Grid, TextField } from '@mui/material'
import { FormProvider } from '@data-c/providers'

import DatePicker from '@components/DatePicker'
import CardDynamic from '@components/CardDynamic'

import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'

interface BoletoConvenioProps {
  boleto: BoletoDetalhadoModel | null
}

export default function BoletoConvenio({ boleto }: BoletoConvenioProps) {
  return (
    <FormProvider>
      <CardDynamic
        title="Convênio"
        disabledAllChildren
        initialOpacity
        gridContainer
        hiddenButton
      >
        <Grid item xl={2} lg={3} md={6} sm={12} xs={12}>
          <DatePicker
            label="Vencimento"
            value={boleto?.dataVencimento || null}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xl={2} lg={4} md={6} sm={12} xs={12}>
          <TextField label="Agência" value={boleto?.convenioAgencia || ''} />
        </Grid>
        <Grid item xl={3} lg={5} md={12} sm={12} xs={12}>
          <TextField
            label="Número do Convênio"
            value={boleto?.convenioNumero || null}
          />
        </Grid>
        <Grid item xl={3} lg={6} md={7} sm={12} xs={12}>
          <TextField label="Nosso Número" value={boleto?.nossoNumero || ''} />
        </Grid>
        <Grid item xl={2} lg={6} md={5} sm={12} xs={12}>
          <TextField
            label="Sequencial"
            value={boleto?.nossoNumeroSequencial || ''}
          />
        </Grid>
      </CardDynamic>
    </FormProvider>
  )
}
