import PasswordTextField from '@components/PasswordTextField'
import Button from '@components/Button'
import ButtonBox from '@components/ButtonBox'

import useCredentials, { LoginDataInterface } from '@hooks/useCredentials'
import { useState } from 'react'
import * as yup from 'yup'

import useValidations from '@hooks/useValidations'
import { Grid, TextField } from '@mui/material'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Informe um email válido')
    .required('Informe o email'),
  senha: yup.string().required('Informe a senha'),
})

interface LoginFormStateProps {
  email: string
  senha: string
}

interface LoginFormProps {
  onLoginSuccess: (loginData: LoginDataInterface) => void
}

const LoginForm = ({ onLoginSuccess }: LoginFormProps) => {
  const { login, isSubmitting } = useCredentials()
  const { validationProps, setValidationErrors } = useValidations()

  const [loginForm, setLoginForm] = useState<LoginFormStateProps>({
    email: '',
    senha: '',
  })

  const handleLogin = async () => {
    setValidationErrors(null)
    validationSchema
      .validate(loginForm, { abortEarly: false })
      .then(async () => {
        const loginResponse: LoginDataInterface | undefined = await login(
          loginForm.email,
          loginForm.senha,
        )
        if (loginResponse) {
          onLoginSuccess(loginResponse)
        }
      })
      .catch((err) => {
        console.log(err)
        setValidationErrors(err)
      })
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
        <TextField
          autoFocus
          name="email"
          value={loginForm?.email || ''}
          onChange={(e) =>
            setLoginForm((oldState) => ({
              ...oldState,
              email: e.target.value,
            }))
          }
          margin="none"
          variant="outlined"
          fullWidth
          label="E-mail"
          placeholder="Informe o seu e-mail de acesso"
          size="small"
          {...validationProps('email')}
        />
      </Grid>
      <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
        <PasswordTextField
          // validationErrors={validationErrors}
          name="senha"
          value={loginForm?.senha || ''}
          onChange={(e) =>
            setLoginForm((oldState) => ({
              ...oldState,
              senha: e.target.value,
            }))
          }
          fullWidth
          variant="outlined"
          label="Senha"
          placeholder="Informe a sua senha"
          size="small"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleLogin()
            }
          }}
          {...validationProps('senha')}
        />
      </Grid>
      <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
        <ButtonBox>
          <Button
            isLoading={isSubmitting}
            onClick={handleLogin}
            color="primary"
            variant="contained"
            size="small"
          >
            Login
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}

export default LoginForm
