import SelecaoAmbiente from '@components/Header/components/SelecaoAmbiente'
import { Paper, Stack, Typography } from '@mui/material'

import infoImage from 'assets/images/info.svg'

export default function AmbienteNotFound() {
  return (
    <Stack sx={{ alignItems: 'center', mt: 8, padding: 4, gap: 4 }}>
      <img src={infoImage} alt="Imagem de erro" />

      <Stack sx={{ maxWidth: '554px', textAlign: 'center', gap: 2 }}>
        <Typography variant="h1">
          Ops! Nenhuma empresa foi selecionada para o ambiente
        </Typography>

        <Typography variant="body2" fontSize="18px">
          Por favor, seleciona uma empresa no campo abaixo ou na barra superior
          do sistema para continuar
        </Typography>
      </Stack>

      <Stack component={Paper} padding={2}>
        <SelecaoAmbiente />
      </Stack>
    </Stack>
  )
}
