import { AppBar, Stack, styled, Toolbar } from '@mui/material'

import ProfileMenu from './components/ProfileMenu'
import SelecaoAmbiente from './components/SelecaoAmbiente'

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

export default function Header() {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: '100%',
          background: (theme) => theme.palette.background.default,
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <Stack
            width="100%"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={10}
          >
            <SelecaoAmbiente />
            <ProfileMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  )
}
