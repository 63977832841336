import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import useNotification from '@hooks/useNotifications'
import apiCadastro from 'services/api-cadastro'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import ErrorInterface from 'interfaces/ErrorInterface'
import { AxiosError } from 'axios'

export interface EmpresaCadastroModel {
  id?: string
  documentoId?: string | null
  codigo?: number
  nome: string
  nomeFantasia?: string
  logradouro?: string
  numero?: string | null
  complemento?: string | null
  bairro?: string | null
  cidadeId?: string | null
  cidadeNome?: string | null
  cidadeUF?: string | null
  cidadeUFCodigoIBGE?: string | null
  paisId?: string | null
  paisNome?: string | null
  cep?: string | null
  telefone?: string | null
  inscEstadual?: string | null
  inscEstadualId?: number | null
  inscSuframa?: string | null
  inscMunicipal?: string | null
  email?: string | null
  cnae?: string | null
  ativo: boolean
}

export async function obterEmpresasCadastro(
  query: string,
): Promise<HttpResponseInterface<EmpresaCadastroModel>> {
  const response = await apiCadastro.get<
    HttpResponseInterface<EmpresaCadastroModel>
  >(`Empresa/Pesquisar/${query}`)
  return response.data
}

export function useQueryObterEmpresasCadastro(query: string) {
  return useQuery<
    HttpResponseInterface<EmpresaCadastroModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['EMPRESA_CADASTRO', query], () => {
    return obterEmpresasCadastro(query)
  })
}

export function useObterEmpresaCadastroPorId() {
  const [isLoading, setLoading] = useState(false)
  const notifications = useNotification()

  async function obterEmpresaCadastroPorId(
    id: string,
    origem?: 'AMBIENTE',
  ): Promise<EmpresaCadastroModel | null> {
    try {
      setLoading(true)
      const response = await apiCadastro.get(`Empresa/${id}`)
      if (response?.data?.data) {
        return response?.data?.data
      }
    } catch (error) {
      if (!origem) {
        notifications.notifyException(error)
      }
    } finally {
      setLoading(false)
    }

    return null
  }

  return {
    obterEmpresaCadastroPorId,
    isLoadingObterEmpresaCadastroPorId: isLoading,
  }
}

export default function useEmpresaCadastro() {
  return {
    useQueryObterEmpresasCadastro,
    useObterEmpresaCadastroPorId,
    obterEmpresasCadastro,
  }
}
