import { EmpresaModel } from '@hooks/queries/useEmpresa'
import { createContext } from 'react'

export interface AmbienteProps {
  empresa: EmpresaModel
}

export interface AmbienteContextProps {
  ambiente: AmbienteProps | null
  trocarAmbiente: (ambiente: AmbienteProps | null) => void
}

export const AmbienteContext = createContext<AmbienteContextProps>({
  ambiente: null,
  trocarAmbiente: () => {},
})
