import { theme } from '@data-c/ui'

export enum DropDownAgendamentoStatusEnum {
  NAO_ENVIADO_0 = 0,
  ENVIADO_1 = 1,
  ERROR_2 = 2,
}

const { NAO_ENVIADO_0, ENVIADO_1, ERROR_2 } = DropDownAgendamentoStatusEnum

export const agendamentoStatusMapping: Record<number, string> = {
  [NAO_ENVIADO_0]: 'Não enviado',
  [ENVIADO_1]: 'Enviado',
  [ERROR_2]: 'Error',
}

export const agendamentoStatusColorMapping: Record<number, string> = {
  [NAO_ENVIADO_0]: theme.palette.primary.main,
  [ENVIADO_1]: theme.palette.success.main,
  [ERROR_2]: theme.palette.error.main,
}
