import { useCallback, useMemo } from 'react'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { findData } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'

import {
  LayoutEmailInterface,
  useLayoutEmail,
  useQuery,
} from '@hooks/queries/useLayoutEmail'

import Options from '@components/DataTable/components/Options'
import DataTable from '@components/DataTable'

const Table = () => {
  const { data, isLoading, isFetching } = useQuery({})
  const { useDuplicarLayout } = useLayoutEmail()
  const { mutateAsync: duplicarLayout } = useDuplicarLayout()

  const { openForm, openConfirm } = useFormApi<LayoutEmailInterface>()

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'duplicar', data: LayoutEmailInterface) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'duplicar':
          duplicarLayout(data)
          break
      }
    },
    [openForm, openConfirm, duplicarLayout],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'nome',
        label: 'Nome do Template',
      },
      {
        name: 'id',
        label: 'Opções',
        options: {
          setCellProps: () => ({
            style: {
              width: '150px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            const _data = findData(data?.data || [], value, 'id')

            return (
              <Options
                extraOptions={[
                  {
                    id: 'duplicar',
                    icon: 'content_copy',
                  },
                ]}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem],
  )

  return (
    <DataTable
      data={data?.data || []}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      options={{
        pagination: false,
      }}
    />
  )
}

export default Table
