import { Grid, MenuItem, TextField } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'
import { TemplateEmailInterface } from '@hooks/queries/useTemplateEmail'

const horas = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
]

interface HoraEnvioProps {
  validationProps: (fieldName: string) => {
    helperText: string
    error: boolean
  }
}

export default function HoraEnvio({ validationProps }: HoraEnvioProps) {
  const { onChangeFormValue } = useFormApi<TemplateEmailInterface>()
  const { formValues: data } = useFormData<TemplateEmailInterface>()

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
        <TextField
          select
          onChange={onChangeFormValue}
          // onBlur={handleBlurNomeTemplate}
          name="horaEnvio"
          label="Hora do envio"
          value={String(data?.horaEnvio) || ''}
          required
          {...validationProps('horaEnvio')}
        >
          {horas.map((h) => (
            <MenuItem key={h} value={h}>{`${h}h`}</MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}
