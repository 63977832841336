import { useNavigate } from 'react-router-dom'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { PageTitle } from '@data-c/ui'
import { BoletoDetalhadoModel } from '@hooks/queries/useBoleto'
import Actions from './components/Actions'

interface HeaderProps {
  boleto: BoletoDetalhadoModel | null
}

export default function Header({ boleto }: HeaderProps) {
  const theme = useTheme()
  const navigate = useNavigate()
  const isDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  function handleBack() {
    navigate('/boletos/lista')
  }

  return (
    <Box
      sx={{
        display: isDownLG ? 'column' : 'flex',
        justifyContent: 'space-between',
      }}
    >
      <PageTitle
        title={`Boleto - ${boleto?.statusDesc || ''}`}
        onBack={handleBack}
      />

      <Actions boleto={boleto} />
    </Box>
  )
}
