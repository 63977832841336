import { Grid, TextField } from '@mui/material'

import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { useForm } from '@data-c/hooks'

import useValidations from '@hooks/useValidations'
import * as yup from 'yup'
import useTemplateEmail, {
  TemplateEmailInterface,
} from '@hooks/queries/useTemplateEmail'
import useUtils from '@hooks/useUtils'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome do Agendamento'),
  identificador: yup.string().required('Informe o Identificador'),
})

const defaultData = {
  nome: '',
  identificador: '',
  envioAutomatico: true,
  ativo: true,
}

interface FormInitialProps {
  isOpen: boolean
  onClose: () => void
}

export default function FormInitial(props: FormInitialProps) {
  const { isOpen, onClose } = props

  const { snakeCase } = useUtils()
  const { openForm, toggleSubmit } = useFormApi<TemplateEmailInterface>()
  const { data, changeValue, handleChange, setData } =
    useForm<TemplateEmailInterface>(defaultData)

  const { validationProps, setValidationErrors } = useValidations()
  const { useSubmit } = useTemplateEmail()
  const { mutateAsync: salvarTemplate, isLoading } = useSubmit()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        const response = await salvarTemplate(data)
        openForm({ ...response, anexarPDF: true })
        handleClose()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  function handleClose() {
    onClose()
    setData({
      nome: '',
      identificador: '',
      envioAutomatico: true,
      ativo: true,
    })
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      title="Adicionar Agendamento"
      actions={
        <ButtonContainer>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            variant="contained"
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Adicionar
          </Button>
        </ButtonContainer>
      }
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            name="nome"
            label="Nome do Agendamento"
            value={data?.nome || ''}
            autoFocus
            required
            onChange={(e) => {
              const nome = e.target.value
              changeValue('nome', nome)
              changeValue('identificador', snakeCase(nome.trim()))
            }}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            required
            onChange={handleChange}
            name="identificador"
            label="Identificador"
            value={data?.identificador || ''}
            {...validationProps('identificador')}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
