import { Container } from '@mui/material'
import Table from './Table'
import { PageTitle } from '@data-c/ui'

const LogEnvio = () => {
  return (
    <Container>
      <PageTitle title="Histórico de Envios" />
      <Table />
    </Container>
  )
}

export default LogEnvio
