import { Grid } from '@mui/material'

import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import DatePicker from '@components/DatePicker'

import useBoleto, {
  AlterarBoletoDataLimitePagamentoParams,
} from '@hooks/queries/useBoleto'
import { getCurrentDate } from '@hooks/useDates'
import useValidations from '@hooks/useValidations'

import moment from 'moment'
import * as yup from 'yup'

const schema = yup.object().shape({
  novaDataLimitePagamento: yup
    .date()
    .typeError('Data Inválida')
    .required('Informe a nova data de limite de pagamento')
    .test(
      'dataVencimento',
      'A nova data de limite de pagamento não pode ser inferior a data atual',
      (dataVencimento) => {
        const dataAtual = getCurrentDate().format('yyyy-MM-DD')
        return moment(dataVencimento).isSameOrAfter(dataAtual)
      },
    ),
})

export default function Form() {
  const { setValidationErrors, validationProps } = useValidations()

  const { changeValue, closeForm, toggleSubmit } =
    useFormApi<AlterarBoletoDataLimitePagamentoParams>()
  const { formValues: data } =
    useFormData<AlterarBoletoDataLimitePagamentoParams>()

  const { useAlterarBoletoDataLimitePagamento } = useBoleto()
  const { mutateAsync: alterarBoletoDataLimitePagamento } =
    useAlterarBoletoDataLimitePagamento()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await alterarBoletoDataLimitePagamento(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Alterar Data Limite de Pagamento',
        maxWidth: 'xs',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePicker
            name="novaDataLimitePagamento"
            label="Nova Data Limite de Pagamento"
            required
            value={data?.novaDataLimitePagamento || null}
            onChange={(date) => {
              changeValue('novaDataLimitePagamento', date)
            }}
            {...validationProps('novaDataLimitePagamento')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
