import { Stack, Typography } from '@mui/material'

interface LogoProps {
  smallLogo?: boolean
}

export default function Logo(props: LogoProps) {
  const { smallLogo } = props

  const appLogo =
    'https://datac-site-assets.s3.sa-east-1.amazonaws.com/logoTopo.png'
  const smallAppLogo =
    'https://datac-site-assets.s3.sa-east-1.amazonaws.com/miniLogo.png'

  return (
    <Stack spacing={0.5} alignItems="center" p={1}>
      <img src={!smallLogo ? appLogo : smallAppLogo} alt="Emissor de Boletos" />
      <Typography variant="body2" fontWeight={!smallLogo ? '400' : '700'}>
        {!smallLogo ? 'Cobrança Smart' : 'CS'}
      </Typography>
    </Stack>
  )
}
