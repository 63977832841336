import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  TextField,
} from '@mui/material'

import useNotification from 'hooks/useNotifications'
import useAutocomplete, { AutoCompleteProps } from 'hooks/useAutocomplete'
import useEmpresaCadastro, {
  EmpresaCadastroModel,
} from '@hooks/queries/useEmpresaCadastro'

interface AutoCompleteEmpresaCadastroProps
  extends AutoCompleteProps<EmpresaCadastroModel> {
  isLoading?: boolean
}

export default function AutoCompleteEmpresaCadastro(
  props: AutoCompleteEmpresaCadastroProps,
) {
  const {
    onChange,
    value,
    autoFocus,
    name,
    error,
    helperText,
    disabled,
    isLoading: _isLoading,
  } = props

  const notifications = useNotification()

  const { obterEmpresasCadastro } = useEmpresaCadastro()

  const { options, onInputChange, inputValue, isLoading, setOptions } =
    useAutocomplete<EmpresaCadastroModel>({
      search: async (query: string) => {
        const response = await obterEmpresasCadastro(query)
        return response?.data
      },
    })

  async function handleObterEmpresasCadastro() {
    try {
      const empresas = await obterEmpresasCadastro('')
      setOptions(empresas?.data)
    } catch (error) {
      notifications.notifyException(error)
    }
  }

  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      value={value || null}
      onOpen={handleObterEmpresasCadastro}
      options={options || []}
      onChange={onChange}
      getOptionLabel={(empresa: EmpresaCadastroModel) => {
        const dash = empresa?.id ? ' - ' : ''
        return `${empresa.nome}${dash}${empresa.cidadeUF || ''}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          value={value}
          helperText={helperText}
          error={error}
          label="Empresa"
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {_isLoading ? (
                  <CircularProgress color="inherit" size={14} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onInputChange={onInputChange}
      inputValue={inputValue}
      loadingText="Carregando..."
      loading={isLoading || _isLoading}
      isOptionEqualToValue={(option, value) => {
        return option.nome === value.nome
      }}
    />
  )
}
