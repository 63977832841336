import { useCallback, useMemo, useState } from 'react'
import { Box, Checkbox } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { findData, onlyNumbers, useDialog, usePagination } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'
import {
  CurrencyCellStyle,
  DataTableOptions as Options,
  Button,
} from '@data-c/ui'

import ResponsiveDataTable from '@components/ResponsiveDataTable'

import { PessoaCadastroModel } from '@hooks/queries/usePessoaCadastro'
import { CidadeModel, UFModel } from '@hooks/queries/useCidade'
import useEnvioBoletos, {
  EnvioBoletoFilters1,
  BoletoModelInterface,
  EmailConsultaInterface,
  AgendamentoStatusModel,
} from '@hooks/queries/useEnvioBoletos'
import useTableCustomCell from '@hooks/useTableCustomCell'
import useCliente from '@hooks/queries/useClientes'
import useUtils from '@hooks/useUtils'
import useDates from '@hooks/useDates'
import useSort from '@hooks/useSort'

import DataTableProps from 'interfaces/DataTableProps'

import BoletosJaEnviadosDialog from './components/BoletosJaEnviadosDialog'
import BoletoLogEnvioDialog from './components/BoletoLogEnvioDialog'
import SelectHeader from './components/SelectHeader'

import { formatarFiltros } from '../Filter'

interface TableProps
  extends DataTableProps<BoletoModelInterface, EnvioBoletoFilters1> {}

export default function Table(props: TableProps) {
  const { filters } = props

  const { floatOptionsCellStyle, dateOptionsCellStyle, whiteSpace } =
    useTableCustomCell()
  const { toBrazilianDateTime } = useDates()
  const { formatDate, formatCurrency } = useUtils()
  const { changeSort, sort } = useSort()
  const { changePage, changePageSize, pagination } = usePagination()

  const {
    data: dataBoletosJaEnviadosDialog,
    isOpen: isOpenBoletosJaEnviadosDialog,
    openDialog: openBoletosJaEnviadosDialog,
    closeDialog: closeBoletosJaEnviadosDialog,
  } = useDialog<EmailConsultaInterface[]>()

  const {
    data: dataLogEnvioDialog,
    isOpen: isOpenLogEnvioDialog,
    openDialog: openLogEnvioDialog,
    closeDialog: closeLogEnvioDialog,
  } = useDialog<AgendamentoStatusModel[] | null>()

  const { openForm } = useFormApi<PessoaCadastroModel>()

  const [selected, setSelected] = useState<any[]>([])
  const [modeloId, setModeloId] = useState('')
  const [boletosParaEnvio, setBoletosParaEnvio] = useState<
    EmailConsultaInterface[]
  >([])

  const { useQuery, useEnviarBoletosManualmente, useDownloadBoleto } =
    useEnvioBoletos()
  const { download, isDownloading: isLoadingDownloadBoleto } =
    useDownloadBoleto()
  const { mutateAsync: enviarBoletosManualmente, isLoading: isSendingBoletos } =
    useEnviarBoletosManualmente()
  const { useObterPessoaPorDocumentoId } = useCliente()
  const {
    mutateAsync: obterPessoaPorDocumentoId,
    isLoading: isLoadingObterPessoaPorDocumentoId,
  } = useObterPessoaPorDocumentoId()
  const { data, isLoading, isFetching, error } = useQuery(
    formatarFiltros(filters),
    { pagination, sort },
  )

  const handleClickItem = useCallback(
    async (
      event: 'download_boleto' | 'log_envio',
      _data: EmailConsultaInterface,
    ) => {
      if (_data) {
        switch (event) {
          case 'download_boleto': {
            download(_data)
            break
          }
          case 'log_envio':
            openLogEnvioDialog(_data?.agendamentosStatus)
            break
          default:
            break
        }
      }
    },
    [],
  )

  const boletos = useMemo(() => {
    if (data) {
      return data?.data?.map((d) => {
        return {
          ...d,
          emissao: formatDate(d.dataEmissao),
          vencimento: formatDate(d.dataVencimento),
          registro: formatDate(d.dataRegistro),
          pagamento: formatDate(d.dataPagamento),
          valor: formatCurrency(d.valorTitulo),
          dataHoraEmailUltimoEnvio: toBrazilianDateTime(
            d?.dataHoraEmailUltimoEnvio || '',
          ),
        }
      })
    }
    return []
  }, [data, formatDate, formatCurrency])

  const handleObterPessoaPorDocumentoId = useCallback(
    async (rowIndex: number) => {
      const cpfCnpj = boletos[rowIndex]?.pagadorCNPJCPF
      const { data: pessoa } = await obterPessoaPorDocumentoId(cpfCnpj)

      const {
        cidadeUF,
        cidadeUFCodigoIBGE,
        cidadeId,
        cidadeNome,
        paisId,
        paisNome,
        inscEstadualId,
      } = pessoa || {}

      const ufModel = {
        id: cidadeUF || '',
        codigoIBGE: cidadeUFCodigoIBGE || '',
      } as UFModel

      const cidadeModel = {
        id: cidadeId || '',
        nome: cidadeNome || '',
        ufId: cidadeUF || '',
        paisId: paisId || '',
        paisNome: paisNome || '',
        uf: ufModel || null,
        pais: {
          id: paisId || '',
          nome: paisNome || '',
        },
      } as CidadeModel

      const inscEstadual =
        inscEstadualId === 2 ? 'ISENTO' : pessoa?.inscEstadual
      const telefone = onlyNumbers(pessoa?.telefone || '')

      if (pessoa) {
        openForm({
          ...pessoa,
          ufModel,
          cidadeModel,
          inscEstadual,
          telefone,
        })
        return
      }

      openForm({
        documentoId: cpfCnpj,
        nome: '',
        ativo: true,
        cidadeId: '',
        cidadeUF: '',
      })
    },
    [boletos],
  )

  function verificarExistenciaDeBoletosJaEnviados() {
    const boletosSelecionadosParaEnvio = selected.map((index) => boletos[index])
    const boletosJaEnviados = boletosSelecionadosParaEnvio.filter(
      ({ erroEnvioEmail, qtdEmailEnviadoSucesso }) =>
        erroEnvioEmail === false && qtdEmailEnviadoSucesso > 0,
    )
    const existeBoletosJaEnviados = boletosJaEnviados.length > 0

    if (existeBoletosJaEnviados) {
      setBoletosParaEnvio(boletosSelecionadosParaEnvio)
      openBoletosJaEnviadosDialog(boletosJaEnviados)
    }

    return existeBoletosJaEnviados
  }

  async function handleEnviarBoletos(
    origem: 'DIALOG' | 'SELECTED_HEADER',
    agendamentoId: string,
  ) {
    let boletosParaEnvioIds: string[] = []

    if (origem === 'SELECTED_HEADER') {
      const existeBoletosJaEnviados = verificarExistenciaDeBoletosJaEnviados()
      if (existeBoletosJaEnviados) return
      boletosParaEnvioIds = selected.map((index) => boletos[index]?.id)
    }

    if (origem === 'DIALOG') {
      boletosParaEnvioIds = boletosParaEnvio.map(
        (boletoPraEnvio) => boletoPraEnvio.id,
      )
    }

    await enviarBoletosManualmente({
      agendamentoId,
      boletosIds: boletosParaEnvioIds,
    })

    setSelected([])
    setBoletosParaEnvio([])
    closeBoletosJaEnviadosDialog()
  }

  function handleRemoveBoletoJaEnviado(
    boletoJaEnviado: EmailConsultaInterface,
  ) {
    const filteredBoletosParaEnvio = boletosParaEnvio.filter(
      (boletoParaEnvio) => boletoParaEnvio?.id !== boletoJaEnviado.id,
    )
    setBoletosParaEnvio(filteredBoletosParaEnvio)
  }

  const totalItensSelecionaveis = useMemo(() => {
    if (data?.data) {
      let total = 0

      for (let i = 0; i < data?.data?.length; i++) {
        if (data?.data[i]?.pagadorEmail) {
          total += 1
        }
      }
      return total
    }
    return 0
  }, [data?.data])

  const somaTotalValorTituloFormatado = formatCurrency(
    data?.data?.reduce(
      (acumulador, objeto) => acumulador + (objeto?.valorTitulo || 0),
      0,
    ) || 0,
  )

  const columns = useMemo((): MUIDataTableColumnDef[] => {
    return [
      {
        name: 'empresaNome',
        label: 'Empresa',
        options: {
          setCellProps: whiteSpace().setCellProps,
        },
      },
      {
        name: 'documentoSistema',
        label: 'Documento',
        options: {
          sort: false,
        },
      },
      {
        name: 'pagadorNome',
        label: 'Cliente',
        options: {
          setCellProps: whiteSpace().setCellProps,
        },
      },
      {
        name: 'pagadorEmail',
        label: 'E-mail de Cobrança',
        options: {
          sort: false,
          ...whiteSpace(),
          customBodyRender: (_, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            const temPagadorEmail = Boolean(boletos[rowIndex]?.pagadorEmail)
            return (
              <Box sx={{ textAlign: 'center' }}>
                {temPagadorEmail ? (
                  boletos[rowIndex].pagadorEmail
                ) : (
                  <Button
                    variant="text"
                    onClick={() => handleObterPessoaPorDocumentoId(rowIndex)}
                  >
                    Cadastrar
                  </Button>
                )}
              </Box>
            )
          },
        },
      },
      {
        name: 'emissao',
        label: 'Emissão',
        options: {
          sort: true,
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'registro',
        label: 'Processamento',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'vencimento',
        label: 'Vencimento',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'pagamento',
        label: 'Pagamento',
        options: {
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'valor',
        label: `Valor Título ${somaTotalValorTituloFormatado}`,
        options: {
          setCellHeaderProps: whiteSpace().setCellHeaderProps,
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'dataHoraEmailUltimoEnvio',
        label: 'Último Envio',
        options: {
          sort: false,
          setCellHeaderProps: whiteSpace().setCellHeaderProps,
          setCellProps: dateOptionsCellStyle,
        },
      },
      {
        name: 'qtdEmailEnviadoSucesso',
        label: 'Quantidade de emails enviados',
        options: {
          sort: false,
          setCellHeaderProps: whiteSpace().setCellHeaderProps,
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'id',
        label: `Opções (${boletos.length})`,
        options: {
          sort: false,
          ...floatOptionsCellStyle(),
          customBodyRender: (value) => {
            const _data = findData<EmailConsultaInterface>(
              data?.data || [],
              value,
              'id',
            )

            const isErroEnvioEmail = Boolean(_data?.erroEnvioEmail)

            return (
              <Options
                displayUpdateButton={false}
                displayDeleteButton={false}
                value={_data}
                onClick={handleClickItem}
                extraOptions={[
                  {
                    id: 'log_envio',
                    icon: 'pending_actions',
                    visible: true,
                    tooltip: isErroEnvioEmail
                      ? {
                          type: 'info',
                          content: 'Existem erros no envio desse e-mail',
                        }
                      : undefined,
                    iconButtonProps: {
                      title: isErroEnvioEmail ? '' : 'Visualizar log de envio',
                      sx: {
                        ':after': {
                          content: "' '",
                          width: '10px',
                          height: '10px',
                          position: 'absolute',
                          borderRadius: '50%',
                          right: 0,
                          top: 0,
                          backgroundColor: 'red',
                          display: isErroEnvioEmail ? 'initial' : 'none',
                        },
                      },
                    },
                  },
                  {
                    id: 'download_boleto',
                    icon: 'print',
                    iconButtonProps: {
                      title: 'Fazer download do boleto',
                    },
                    visible: true,
                  },
                ]}
              />
            )
          },
        },
      },
    ]
  }, [data?.data, handleClickItem, boletos, handleObterPessoaPorDocumentoId])

  const handleSelectAll = useCallback(() => {
    const allSelecteds = boletos.map((_, index) => index)
    setSelected(allSelecteds)
  }, [boletos])

  return (
    <>
      {selected.length > 0 && (
        <SelectHeader
          allSelected={selected.length === boletos.length}
          rowsSelected={selected}
          total={totalItensSelecionaveis}
          onSelectAll={handleSelectAll}
          onClearSelection={() => {
            setSelected([])
          }}
          onSendSelected={(modeloId) => {
            setModeloId(modeloId)
            handleEnviarBoletos('SELECTED_HEADER', modeloId)
          }}
          sending={isSendingBoletos}
        />
      )}
      <ResponsiveDataTable
        data={boletos}
        isLoading={isLoading || isLoadingObterPessoaPorDocumentoId}
        isFetching={
          isFetching ||
          isLoadingObterPessoaPorDocumentoId ||
          isLoadingDownloadBoleto
        }
        pagination={data?.pagination}
        changePage={changePage}
        changePageSize={changePageSize}
        columns={columns}
        options={{
          responsive: 'standard',
          sort: true,
          selectableRows: 'multiple',
          selectToolbarPlacement: 'none',
          rowsSelected: selected,
          onRowSelectionChange: (_, __, rowsSelected) => {
            if (rowsSelected) setSelected(rowsSelected)
          },
        }}
        error={error?.message}
        changeSort={changeSort}
        components={{
          Checkbox,
        }}
      />

      {isOpenBoletosJaEnviadosDialog && (
        <BoletosJaEnviadosDialog
          disabled={boletosParaEnvio.length === 0}
          isLoading={isSendingBoletos}
          boletosJaEnviados={dataBoletosJaEnviadosDialog}
          isOpen={isOpenBoletosJaEnviadosDialog}
          onClose={closeBoletosJaEnviadosDialog}
          onConfirm={() => handleEnviarBoletos('DIALOG', modeloId)}
          onRemoveBoletoJaEnviado={(boletoJaEnviado) =>
            handleRemoveBoletoJaEnviado(boletoJaEnviado)
          }
        />
      )}

      {isOpenLogEnvioDialog && (
        <BoletoLogEnvioDialog
          data={dataLogEnvioDialog || []}
          isOpen={isOpenLogEnvioDialog}
          onClose={closeLogEnvioDialog}
        />
      )}
    </>
  )
}
