import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import apiCadastro from 'services/api-cadastro'
import { CidadeModel, UFModel } from './useCidade'
import useNotification from 'hooks/useNotifications'
import { HttpResponseInterface } from '@data-c/hooks'
import { useState } from 'react'
import QueryObjResponseInterface from 'interfaces/QueryObjResponseInterface'
import { PessoaCadastroModel } from './usePessoaCadastro'

export interface ClienteModel {
  id?: string
  codigo?: number | null
  nome: string
  nomeFantasia?: string | null
  logradouro?: string | null
  bairro?: string | null
  cidadeId?: string | null
  cidadeNome?: string | null
  cidadeModel?: CidadeModel | null
  cidadeUF?: string | null
  ufModel?: UFModel | null
  documentoId: string
  numero?: string | null
  complemento?: string | null
  cidadeUFCodigoIBGE?: string | null
  paisId?: string | null
  paisNome?: string | null
  cep?: string | null
  telefone?: string | null
  inscEstadual?: string | null
  inscEstadualId?: number | string
  email?: string
  ativo: boolean
  createdDate?: string
  updatedDate?: string
}

export async function obterClientes(
  query: string,
): Promise<HttpResponseInterface<ClienteModel>> {
  const response = await apiCadastro.get<HttpResponseInterface<ClienteModel>>(
    `Pessoa/Pesquisar/${query}`,
  )

  return response.data
}

export async function obterPorDocumentoId(
  documentoId: string,
): Promise<HttpResponseInterface<ClienteModel>> {
  const response = await apiCadastro.get<HttpResponseInterface<ClienteModel>>(
    `Pessoa/ObterPorDocumentoId`,
    {
      params: {
        documentoId,
      },
    },
  )

  return response.data
}

export async function obterClientePorId(
  id?: string,
): Promise<ClienteModel | null> {
  if (!id) return null
  const response = await apiCadastro.get<{ data: ClienteModel }>(`Pessoa/${id}`)

  return response.data.data
}

export async function obterPessoaPorDocumentoId(
  documentoId?: string,
): Promise<QueryObjResponseInterface<PessoaCadastroModel>> {
  const response = await apiCadastro.get('Pessoa/ObterPorDocumentoId', {
    params: { documentoId },
  })
  return response.data
}

export async function createOrUpdateCliente(
  data: ClienteModel,
): Promise<HttpResponseInterface<ClienteModel>> {
  delete data.cidadeModel
  delete data.ufModel

  if (data?.id) {
    const response = await apiCadastro.put(`Pessoa/${data.id}`, data)
    return response.data
  }

  const response = await apiCadastro.post('Pessoa', data)
  return response.data
}

export async function removerCliente(
  data: ClienteModel,
): Promise<ClienteModel> {
  const response = await apiCadastro.delete(`Pessoa/${data.id}`)

  return response.data
}

export function useObterClientesPorCNPJ() {
  const [isLoading, setLoading] = useState(false)
  const notifications = useNotification()

  async function obterClientesPorCNPJ(
    clienteCnpj: string,
  ): Promise<HttpResponseInterface<ClienteModel> | null> {
    try {
      setLoading(true)
      const response = await apiCadastro.get(`Pessoa/Pesquisar/${clienteCnpj}`)
      if (response?.data) {
        return response?.data
      }
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }

    return null
  }

  return { obterClientesPorCNPJ, isLoadingObterClientesPorCNPJ: isLoading }
}

export function useQueryObterClientes(query: string) {
  return useQuery<
    HttpResponseInterface<ClienteModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CLIENTE', query], () => {
    return obterClientes(query)
  })
}

export function useQueryObterClientePorId(id?: string) {
  return useQuery<ClienteModel | null, AxiosError>(['CLIENTE', id], () => {
    return obterClientePorId(id)
  })
}

export function useObterPessoaPorDocumentoId() {
  const notifications = useNotification()

  return useMutation<
    QueryObjResponseInterface<PessoaCadastroModel>,
    AxiosError,
    string
  >((documentoId) => obterPessoaPorDocumentoId(documentoId), {
    onSuccess() {},
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useCreateOrUpdateCliente() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<ClienteModel>,
    AxiosError,
    ClienteModel
  >((data) => createOrUpdateCliente(data), {
    onSuccess(_, data) {
      const flexUpdateOrCreate = data?.id ? 'alterado' : 'adicionado'

      notifications.notifySuccess(`Cliente ${flexUpdateOrCreate} com sucesso!`)
      queryClient.invalidateQueries(['CLIENTE'])
      queryClient.invalidateQueries(['ENVIO_BOLETO_EMAIL'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useRemoverCliente() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<ClienteModel, AxiosError, ClienteModel>(
    (data) => removerCliente(data),
    {
      onSuccess() {
        notifications.notifySuccess('Cliente excluído com sucesso!')
        queryClient.invalidateQueries(['CLIENTE'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useCliente() {
  return {
    useQueryObterClientes,
    useQueryObterClientePorId,
    useObterClientesPorCNPJ,
    useObterPessoaPorDocumentoId,
    useCreateOrUpdateCliente,
    useRemoverCliente,
    obterClientes,
  }
}
