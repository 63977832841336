import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownIdentificacaoDistribuicaoBoletoEnum {
  BANCO_DISTRIBUI_1 = 1,
  CLIENTE_DISTRIBUI_2 = 2,
}

const menuItems = [
  {
    value: DropDownIdentificacaoDistribuicaoBoletoEnum.BANCO_DISTRIBUI_1,
    label: '01 - Banco distibui',
  },
  {
    value: DropDownIdentificacaoDistribuicaoBoletoEnum.CLIENTE_DISTRIBUI_2,
    label: '02 - Cliente distibui',
  },
]

export default function DropDownIdentificacaoDistribuicaoBoleto(
  props: DynamicDropDownProps,
) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="Identificação Distribuição de Boletos"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
