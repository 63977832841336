import { Box, Card, CardContent } from '@mui/material'
import React from 'react'

interface LayoutNaoAutenticadoProps {
  children?: React.ReactNode
}

const LayoutNaoAutenticado = (props: LayoutNaoAutenticadoProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          // background: (theme) => theme.palette.gradient.secondary,
          height: '40%',
        }}
      >
        <Box>
          <img
            src="https://datac-site-assets.s3.sa-east-1.amazonaws.com/logo.png"
            alt="Logo Data C"
          ></img>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          // [theme.breakpoints.up('xs')]: {
          //   paddingRight: theme.spacing(2),
          //   paddingLeft: theme.spacing(2),
          //   // paddingTop: 64,
          // },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            top: -60,
            width: 600,
          }}
        >
          <Card>
            <CardContent>{props.children}</CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

export default LayoutNaoAutenticado
