import { useState, useRef, useEffect, useMemo } from 'react'
import UnlayerEmailEditor from 'react-email-editor'

import { TextField, Box } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'

import {
  LayoutEmailInterface,
  useLayoutEmail,
} from '@hooks/queries/useLayoutEmail'
import useValidations from '@hooks/useValidations'

import Dialog, { DialogContent } from '@components/Dialog'
import Button from '@components/Button'

import * as yup from 'yup'
import moment from 'moment'

const validationSchema = yup.object().shape({
  nome: yup.string().required('Informe o nome do template').nullable(),
  // corpoEmail: yup.string().required('Informe o corpo do e-mail'),
})

const Form = () => {
  const { formValues: data, isOpen } = useFormData<LayoutEmailInterface>()
  const { closeForm, changeValues, changeValue } =
    useFormApi<LayoutEmailInterface>()

  const { useSubmit } = useLayoutEmail()
  const { mutateAsync: salvarLayout, isLoading } = useSubmit()

  const [isReady, setReady] = useState<boolean>(false)
  const { validationProps, setValidationErrors } = useValidations()

  const emailEditorRef = useRef(null)

  useEffect(() => {
    if (isReady) {
      const parsedDesign = data?.design ? JSON.parse(data?.design) : null

      // @ts-ignore
      emailEditorRef?.current?.editor?.loadDesign(parsedDesign)
    }
  }, [isReady, data])

  const handleSave = () => {
    setValidationErrors(null)
    validationSchema
      .validate(data, { abortEarly: false })
      .then(async () => {
        if (emailEditorRef.current !== null) {
          //@ts-ignore
          emailEditorRef.current.editor.exportHtml((templateEmail: any) => {
            const { design: jsonDesign, html } = templateEmail

            data.design = JSON.stringify(jsonDesign)
            data.html = html

            salvarLayout(data).then((_data: LayoutEmailInterface) => {
              changeValues({
                ...data,
                id: _data.id,
              })
              // if (onAfterSubmit) onAfterSubmit('afterSave', _data)
            })
          })
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  const onReady = () => {
    setReady(true)
  }

  const sampleDates: {
    dataAtual: string
    vencimento: string
    emissao: string
    pagamento: string
  } = useMemo(() => {
    const currentDate = moment().format('DD/MM/YYYY')
    const vencimentoDate = moment().add(15, 'days').format('DD/MM/YYYY')
    const pagamentoDate = moment().add(10, 'days').format('DD/MM/YYYY')
    const samples = {
      dataAtual: currentDate,
      vencimento: vencimentoDate,
      emissao: currentDate,
      pagamento: pagamentoDate,
    }
    return samples
  }, [])

  function handleClose() {
    setReady(false)
    closeForm()
  }

  return (
    <Dialog
      fullScreen
      title="Editar Template"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogContent
        sx={{ height: 'calc(100% - 66px)', padding: `2px 2px 2px` }}
      >
        {isReady && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
            paddingRight="16px"
            paddingBottom="16px"
            paddingTop="4px"
          >
            <Box
              sx={{
                width: '700px',
                display: 'flex',
                ml: (theme) => theme.spacing(2),
              }}
            >
              <TextField
                name="nome"
                label="Nome do Template"
                value={data?.nome || ''}
                inputProps={{ maxLength: 40 }}
                onChange={(e) => {
                  const nome = e.target.value
                  changeValue('nome', nome)
                }}
                {...validationProps('nome')}
              />
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                onClick={handleSave}
                isLoading={isLoading}
              >
                Salvar Template
              </Button>
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
          <UnlayerEmailEditor
            options={{
              mergeTags: [
                {
                  name: 'Título do E-mail',
                  value: '{{titulo}}',
                  sample: 'Este é o título do e-mail',
                },
                {
                  name: 'Nome do Pagador (Cliente)',
                  value: '{{nomeCliente}}',
                  sample: 'Pagador da Silva',
                },
                {
                  name: 'Valor do Documento',
                  value: '{{valorDocumento}}',
                  sample: 'R$ 5.098,70',
                },
                {
                  name: 'Data de Vencimento',
                  value: '{{vencimento}}',
                  sample: sampleDates.vencimento,
                },
                {
                  name: 'Data de Emissão',
                  value: '{{emissao}}',
                  sample: sampleDates.emissao,
                },
                {
                  name: 'Data de Pagamento',
                  value: '{{pagamento}}',
                  sample: sampleDates.pagamento,
                },
              ],
              locale: 'pt-BR',
            }}
            projectId={99952}
            ref={emailEditorRef}
            //   onLoad={onLoad}
            onReady={onReady}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

Form.defaultProps = {
  data: {},
}

export default Form
