import { FormControl, FormHelperText, Grid } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'
import {
  TemplateEmailInterface,
  tipoDataMapping,
} from '@hooks/queries/useTemplateEmail'
import ChooseOne from '@components/ChooseOne'

interface DataEnvioProps {
  campoValidationProps: {
    helperText: string
    error: boolean
  }
}

export default function DataEnvio({ campoValidationProps }: DataEnvioProps) {
  const { changeValue } = useFormApi<TemplateEmailInterface>()
  const { formValues: data } = useFormData<TemplateEmailInterface>()

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <ChooseOne
        gridItemSize={4}
        onChoose={(item) => {
          changeValue('regraEnvio', {
            ...data.regraEnvio,
            campo: item?.value || '',
          })

          const key = item?.value || ''
          const tipoData = tipoDataMapping[key]

          changeValue('tipoData', tipoData)
        }}
        value={data?.regraEnvio?.campo}
        options={[
          {
            title: 'Criação',
            value: 'registro',
            description: '',
            icon: 'event',
          },
          {
            title: 'Vencimento',
            value: 'vencimento',
            description: '',
            icon: 'event',
          },
          {
            title: 'Liquidação (Pagamento)',
            value: 'pagamento',
            description: '',
            icon: 'event',
          },
        ]}
      />
      {campoValidationProps.error && (
        <FormControl error>
          <FormHelperText id="component-error-text">
            {campoValidationProps.helperText}
          </FormHelperText>
        </FormControl>
      )}
    </Grid>
  )
}
