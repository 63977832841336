import { HttpResponseInterface } from '@data-c/hooks'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import api from '@services/api'

export interface EmpresaModel {
  id: string
  nome: string
}

export async function obterEmpresas(
  query: string,
): Promise<HttpResponseInterface<EmpresaModel>> {
  const response = await api.get<HttpResponseInterface<EmpresaModel>>(
    `Empresa/Pesquisar/${query}`,
  )
  return response.data
}

export function useQueryObterEmpresas(query: string) {
  return useQuery<
    HttpResponseInterface<EmpresaModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['EMPRESA', query], () => {
    return obterEmpresas(query)
  })
}

export default function useEmpresa() {
  return {
    useQueryObterEmpresas,
    obterEmpresas,
  }
}
