import { Grid } from '@mui/material'

import { CurrencyTextField, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useBoleto, {
  AlterarBoletoAbatimentoParams,
} from '@hooks/queries/useBoleto'

export default function Form() {
  const { changeValue, closeForm, toggleSubmit } =
    useFormApi<AlterarBoletoAbatimentoParams>()
  const { formValues: data } = useFormData<AlterarBoletoAbatimentoParams>()

  const { useAlterarBoletoAbatimento } = useBoleto()
  const { mutateAsync: alterarBoletoAbatimento } = useAlterarBoletoAbatimento()

  async function handleSubmit() {
    toggleSubmit(true)
    await alterarBoletoAbatimento(data).finally(() => toggleSubmit(false))
    closeForm()
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButton={<></>}
      dialogProps={{
        title: 'Alterar Valor de Abatimento',
        maxWidth: 'xs',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CurrencyTextField
            name="abatimento"
            label="Abatimento"
            value={data?.abatimento || ''}
            onChange={(_, value) => {
              changeValue('abatimento', value || 0)
            }}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
