import { Container } from '@mui/material'

import {
  LayoutEmailInterface,
  useLayoutEmail,
} from '@hooks/queries/useLayoutEmail'

import Button from '@components/Button'

import Form from './components/Form'
import Table from './components/Table'
import FormInitial from './components/FormInitial'
import { FormProvider } from '@data-c/providers'
import Delete from '@components/Delete'
import { useDialog } from '@data-c/hooks'
import { PageTitle } from '@data-c/ui'

const Templates = () => {
  const {
    isOpen: isOpenFormInitial,
    closeDialog: closeFormInitial,
    openDialog: openFormInitial,
  } = useDialog<LayoutEmailInterface>()

  const { useDelete } = useLayoutEmail()
  const { mutateAsync: deleteLayout } = useDelete()

  return (
    <Container>
      <FormProvider>
        <PageTitle title="Templates">
          <Button variant="contained" onClick={() => openFormInitial()}>
            Novo Template
          </Button>
        </PageTitle>

        <Table />
        <Delete onDelete={deleteLayout} />
        <Form />
        <FormInitial isOpen={isOpenFormInitial} onClose={closeFormInitial} />
      </FormProvider>
    </Container>
  )
}

export default Templates
