import { Stack, Typography } from '@mui/material'
import {
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  DiscountOutlined as DiscountOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  PriceChangeOutlined as PriceChangeOutlinedIcon,
  SdCardAlertOutlined as SdCardAlertOutlinedIcon,
  TrendingDownOutlined as TrendingDownOutlinedIcon,
} from '@mui/icons-material'

import { useBoletoContext } from 'contexts/BoletoContext/BoletoContext'

import { DropDownBoletoStatusEnum } from '@components/DropDownBoletoStatus'
import OptionsListContainer from '@components/OptionsListContainer'
import OptionListButton from '@components/OptionsListContainer/components/OptionListButton'

import DataVencimento from './components/DataVencimento'
import DataLimitePagamento from './components/DataLimitePagamento'
import Abatimento from './components/Abatimento'
import Juros from './components/Juros'
import Multa from './components/Multa'
import Desconto from './components/Desconto'
import PagadorEndereco from './components/PagadorEndereco'

export enum OrigemType {
  DATA_VENCIMENTO = 'DATA_VENCIMENTO',
  DATA_LIMITE_PAGAMENTO = 'DATA_LIMITE_PAGAMENTO',
  ABATIMENTO = 'ABATIMENTO',
  JUROS = 'JUROS',
  MULTA = 'MULTA',
  DESCONTO = 'DESCONTO',
  ENDERECO_DO_PAGADOR = 'ENDERECO_DO_PAGADOR',
}

export default function ActionsOptions() {
  const { boleto } = useBoletoContext()

  const isBoletoRegistrado =
    boleto?.status === DropDownBoletoStatusEnum.REGISTRADO_1

  return (
    <>
      <OptionsListContainer
        buttonOptionsProps={{
          variant: 'contained',
          disabled: !isBoletoRegistrado,
        }}
        tooltipTitle="Realiza alterações do boleto junto ao banco (Habilitado somente quando o boleto está registrado)"
        triggerLabel="Opções de Alteração"
      >
        <Stack padding={2} gap={1} flexDirection="row">
          <InfoOutlinedIcon />
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Realiza alterações do boleto junto ao banco
          </Typography>
        </Stack>
        <OptionListButton
          name={OrigemType.DATA_VENCIMENTO}
          label="Vencimento"
          icon={<CalendarMonthOutlinedIcon />}
        />
        <OptionListButton
          name={OrigemType.DATA_LIMITE_PAGAMENTO}
          label="Data Limite de Pagamento"
          icon={<CalendarMonthOutlinedIcon />}
        />
        <OptionListButton
          name={OrigemType.ABATIMENTO}
          label="Abatimento"
          icon={<TrendingDownOutlinedIcon />}
        />
        <OptionListButton
          name={OrigemType.JUROS}
          label="Juros"
          icon={<PriceChangeOutlinedIcon />}
        />
        <OptionListButton
          name={OrigemType.MULTA}
          label="Multa"
          icon={<SdCardAlertOutlinedIcon />}
        />
        <OptionListButton
          name={OrigemType.DESCONTO}
          label="Desconto"
          icon={<DiscountOutlinedIcon />}
        />
        <OptionListButton
          name={OrigemType.ENDERECO_DO_PAGADOR}
          label="Endereço do Pagador"
          icon={<HomeOutlinedIcon />}
        />
      </OptionsListContainer>

      <DataVencimento boleto={boleto} />
      <DataLimitePagamento boleto={boleto} />
      <Abatimento boleto={boleto} />
      <Juros boleto={boleto} />
      <Multa boleto={boleto} />
      <Desconto boleto={boleto} />
      <PagadorEndereco boleto={boleto} />
    </>
  )
}
