import AgendamentoTransporter from '@components/Transporters/AgendamentoTransporter'
import { Button } from '@data-c/ui'
import { TemplateEmailInterface } from '@hooks/queries/useTemplateEmail'
import { Box } from '@mui/material'
import { useState } from 'react'

interface SelecaoDeAgendamentoProps {
  onClick: (modeloId: string) => void
  sending: boolean
}

export default function SelecaoDeAgendamento(props: SelecaoDeAgendamentoProps) {
  const { onClick, sending } = props

  const [modelo, setModelo] = useState<TemplateEmailInterface | null>(null)

  return (
    <Box
      sx={{
        display: 'flex',
        width: '380px',
        alignItems: 'center',
        alignContent: 'center',
        gap: 2,
      }}
    >
      <Box width="100%">
        <AgendamentoTransporter
          value={modelo || null}
          onChange={(value) => {
            setModelo(value)
          }}
        />
      </Box>

      <Button
        isLoading={sending}
        variant="contained"
        onClick={() => onClick(modelo?.id || '')}
      >
        Enviar
      </Button>
    </Box>
  )
}
